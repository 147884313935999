import { useState, useEffect } from "react";
import { DatePicker } from "antd";
import { Input } from "reactstrap";
import "../../Styles/cardalloc.css";
import moment from "moment";
import { IoIosRefresh } from "react-icons/io";
import axios from "axios";
import {  Col } from "react-bootstrap";
import Newsidebar from "./../Dashboard/Newsidebar";
import { ip } from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Allocation from "./Allocation";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";

const CardAlloc = () => {
  const { RangePicker } = DatePicker;
  const [reallocmodal, setReallocateModal] = useState(false);
  const [refreshtoken, setrefreshtoken] = useState(sessionStorage.getItem("refreshtoken"));
  const [accesstoken, setaccesstoken] = useState(sessionStorage.getItem("accesstoken"));
  const [AllocatedTo, setAllocatedTo] = useState(sessionStorage.getItem("AllocatedTo"));
  const [anchorEl, setanchorEl] = useState(null);
  const [valueOfInput1, setValueOfInput1] = useState("");
  const [valueOfInput2, setValueOfInput2] = useState("");
  const [querysearch, setQuerySearch] = useState("");
  const [querysearch1, setQuerySearch1] = useState("");
  const [querysearch2, setQuerySearch2] = useState("");
  const [querysearch3, setQuerySearch3] = useState("");
  const [querysearch4, setQuerySearch4] = useState("");
  const [clientslist, setClientsList] = useState([]);
  const [client, setclient] = useState(sessionStorage.getItem("clientid"));
  const [clientname, setClientName] = useState(sessionStorage.getItem("clientname"));
  const [role, setRole] = useState(sessionStorage.getItem("role_type"));
  const [opendropdown, setOpenDropdown] = useState(false);
  const [filterstatus, setFilterStatus] = useState("custom");
  const [Location, setLocation] = useState("");
  const [locationslist, setLocationsList] = useState([]);
  const [claimids, setclaimIds] = useState([]);
  const [searchid, setSearchId] = useState("");
  const [openModal, setopenModal] = useState(true);
  const [openModal1, setopenModal1] = useState(false);
  const [separate, Setseparate] = useState([]);
  const [separate1, Setseparate1] = useState([]);
  const [separate2, Setseparate2] = useState([]);
  const [separate3, Setseparate3] = useState([]);
  const [separate4, Setseparate4] = useState([]);
  const [selected, Setseletced] = useState("");

  const toggledropdown = () =>{
    setOpenDropdown(!opendropdown)
  }
  const handleClick = (event) => {
    setanchorEl(event.currentTarget)
  }
  const  handleClose = () => {
    setanchorEl(null)
  }
    
 const getsearchid = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };

    if (searchid === "") {
      toast.error("Please fill the search value!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
       await axios
        .get(
          `${ip}/allocation/keyindicators/search?claimid=${searchid}`,
          {
            headers: headers,
          }
        )
        .then((res) => {
          if(res.data.DataView.length === 0){
            toast.error("Claim id not found", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }else{
            setQuerySearch(res.data.DataView[0]);
            setQuerySearch1(res.data.DataView[1]);
            setQuerySearch2(res.data.DataView[2]);
            setQuerySearch3( res.data.DataView[3]);
            setQuerySearch4( res.data.DataView[4]);
            Setseparate(querysearch.split(""));
            Setseparate1(querysearch1.split(""));
            Setseparate2(querysearch2.split(""));
            Setseparate3(querysearch3.split(""));
            Setseparate4(querysearch4.split(""));
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 500) {
              toast.error("Claim id not found", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }}
        });
    }
  };
 const reset = () => {
  setSearchId("");
  setQuerySearch("");
  setQuerySearch1("");
  setQuerySearch2("");
  setQuerySearch3("");
  setQuerySearch4("");
  };
 const getclients = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
     axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        if (res.data.clientonboard.length !== 0) {
          setClientsList(res.data.clientonboard)
        } else {
          setClientsList(res.data.clientonboard)
        }
      })
      .catch((err) => {});
  };
 //get Location
 const getLocation = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accesstoken}`,
  };
  let url;
  if (client) {
    url = `${ip}/location/activelocations?clientId=${client}`;
  } else {
    url = `${ip}/location/activelocations`;
  }
  const res = axios
    .get(url, { headers: headers })
    .then((res) => {
      let data = res.data.location;
      data = data.filter((item) => {
        return item.clientId == client;
      });
      data.sort((a, b) => {
        return a.Location < b.Location ? -1 : 1;
      });
      setLocationsList(data)
    })

    .catch((err) => {});
};

const renewaccesstoken = async () => {
  const renewheaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${refreshtoken}`,
  };
   await axios
    .get(`${ip}/auth/renewAccessToken`, {
      headers: renewheaders,
    })
    .then((renew) => {
      sessionStorage.setItem("accesstoken", renew.data.accessToken);
    })
    .catch((err) => {});
};
const icon1 = () => {
  setopenModal(false);
  setopenModal1(true);
};

const icon2 = () => {
  setopenModal(false);
  setopenModal1(true);
};
const handleChange = (selectedOptions) => {
  const locations = selectedOptions.map((data) => data.Location);
  setLocation(locations[0]);

  getclients();
  getLocation();
};
const handleDateChange = (dates, datestring) => {
  setValueOfInput1(datestring[0]);
  setValueOfInput2(datestring[1]);

  sessionStorage.setItem("querystartdate", datestring[0]);
  sessionStorage.setItem("queryenddate", datestring[1]);
};
const valuedate = (option, e) => {
  if (option === "yesterday") {
    setValueOfInput1(e.target.value);
    setValueOfInput2(moment().subtract(1, "days").format("MM-DD-YYYY"));
  } else {
    setValueOfInput1(e.target.value);
    setValueOfInput2(moment().format("MM-DD-YYYY"));
  }
  setFilterStatus(option);
};

useEffect(() => {
  getclients();
  getLocation();

  sessionStorage.removeItem("querystartdate");
  sessionStorage.removeItem("queryenddate");
  sessionStorage.removeItem("queryLocation");
}, [client]); // Dependency array ensures useEffect runs when `client` changes.

return (
  <div>
      <Newsidebar name="Key Indicators" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <ToastContainer />
        <div className="a-card-container">
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <form>
              <div className="input-group mb-4">
                <input
                  style={{ zIndex: 0.5 }}
                  type="search"
                  placeholder="Search by ID?"
                  className="form-control"
                  value={searchid}
                  onChange={(e) => setSearchId(e.target.value)}
                />
                <div className="input-group-append" style={{ zIndex: 1 }}>
                  <div className="btn-group">
                    <button
                      style={{ background: "#002755", color: "white" }}
                      className="btn btn-success"
                      onClick={(e) => {
                        e.preventDefault();
                        getsearchid();
                      }}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                    <button
                      style={{ background: "#EB1A23", color: "white" }}
                      className="btn btn-dark"
                      onClick={(e) => {
                        e.preventDefault();
                        reset();
                      }}
                    >
                      <IoIosRefresh style={{ color: "#fff", fontSize: "20px" }} />
                    </button>
                  </div>
                </div>
              </div>
            </form>
            {client && (
              <Typeahead
                id="typeahead-cardAlloc"
                clearButton
                onChange={handleChange}
                options={locationslist}
                labelKey={(option) => `${option.Location}`}
                placeholder="All Location"
                selected={selected}
                style={{ height: "35px" }}
              />
            )}
            <section style={{display:"flex", flexDirection:"row", gap:"20px"}}>
              <Input
                type="select"
                className="form-control form-select"
                onChange={(e) => {
                  const index = e.target.selectedIndex;
                  const el = e.target.childNodes[index];
                  const option = el.getAttribute("title");
                  valuedate(option, e);
                }}
              >
                <option value="" title="custom">
                  Custom
                </option>
                <option value={moment().format("MM-DD-YYYY")}>Today</option>
                <option value={moment().subtract(1, "days").format("MM-DD-YYYY")} title="yesterday">
                  Yesterday
                </option>
                <option value={moment().subtract(3, "days").format("MM-DD-YYYY")}>
                  Last 3 days
                </option>
                <option value={moment().subtract(7, "days").format("MM-DD-YYYY")}>
                  Last 7 days
                </option>
                <option value={moment().subtract(30, "days").format("MM-DD-YYYY")}>
                  Last 30 days
                </option>
                <option value={moment().subtract(365, "days").format("MM-DD-YYYY")}>
                  Last 365 days
                </option>
              </Input>
            {filterstatus === "custom" && (
              <RangePicker
                format="MM-DD-YYYY"
                onChange={handleDateChange}
                style={{
                  borderColor: "gray",
                  fontSize: "13px",
                  width: "150%",
                  height:"60%"
                }}
              />
            )}
          </section>
          </div>
    
          <Allocation
            search={querysearch}
            search1={querysearch1}
            search2={querysearch2}
            search3={querysearch3}
            search4={querysearch4}
            gridicon={openModal}
            gridicon1={openModal1}
            client={client}
            startdate={valueOfInput1}
            enddate={valueOfInput2}
            Location={Location}
          />
        </div>
      </div>
    </div>
)

}
export default CardAlloc;