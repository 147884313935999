import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from "recharts";
import { ip } from "../../../Api";

const defaultData = [
  { _id: "", totalamount: 0, totalcount: 0 },
  { _id: "", totalamount: 0, totalcount: 0 },
  { _id: "", totalamount: 0, totalcount: 0 },
  { _id: "", totalamount: 0, totalcount: 0 },
  { _id: "", totalamount: 0, totalcount: 0 },
];

// Custom Tooltips
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: "#0033cc" }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const ClaimTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: "#0033cc" }}>{payload[0].name}:&nbsp;{payload[0].value}</p>
      </div>
    );
  }
  return null;
};

const PripayerChart = ({ pripayerStart, pripayerEnd, client, Location, pripayamount }) => {
  const [pripayData, setPripayData] = useState(defaultData);
  const [refreshtoken] = useState(sessionStorage.getItem("refreshtoken"));
  const [accesstoken, setAccesstoken] = useState(sessionStorage.getItem("accesstoken"));

  // Renew access token
  const renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshtoken}`,
    };
    try {
      const response = await axios.get(`${ip}/auth/renewAccessToken`, { headers: renewheaders });
      sessionStorage.setItem("accesstoken", response.data.accessToken);
      setAccesstoken(response.data.accessToken);
    } catch (error) {
      console.error("Failed to renew access token", error);
    }
  };

  // Fetch inventory data
  const getinventory = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };

    let url = `${ip}/inventory/pripayer`;
    if (pripayerStart && pripayerEnd) url += `?start_date=${pripayerStart}&end_date=${pripayerEnd}`;
    if (client) url += `&clientId=${client}`;
    if (Location) url += `&Location=${Location}`;

    try {
      const response = await axios.get(url, { headers });
      if (response.data.touch.length) {
        setPripayData(response.data.touch);
      } else {
        setPripayData(defaultData);
      }
    } catch (error) {
      console.error("Failed to fetch inventory data", error);
    }
  };

  // Fetch data on mount and when relevant props change
  useEffect(() => {
    getinventory();
  }, [pripayerStart, pripayerEnd, client, Location, accesstoken]);

  return (
    <div>
      {pripayamount === "$" ? (
        <div>
          <ResponsiveContainer width="95%" height={400}>
            <ComposedChart
              data={pripayData}
              margin={{ top: 13, right: 30, left: 10, bottom: 35 }}
              layout="horizontal"
            >
              <XAxis dataKey="_id" angle={-45} tick={{ fontSize: 11, textAnchor: "end", dy: 6 }} />
              <YAxis dataKey="totalamount" />
              <Tooltip content={<CustomTooltip />} />
              <defs>
                <linearGradient id="colorYv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#0033cc" />
                  <stop offset="100%" stopColor="#33ccff" />
                </linearGradient>
              </defs>
              <Bar name="Total Amount" dataKey="totalamount" barSize={30} fill="url(#colorYv)" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div>
          <ResponsiveContainer width="95%" height={400}>
            <ComposedChart
              data={pripayData}
              margin={{ top: 13, right: 30, left: 10, bottom: 35 }}
              layout="horizontal"
            >
              <XAxis dataKey="_id" angle={-45} tick={{ fontSize: 11, textAnchor: "end", dy: 6 }} />
              <YAxis dataKey="totalcount" />
              <Tooltip content={<ClaimTooltip />} />
              <defs>
                <linearGradient id="colorYv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#0033cc" />
                  <stop offset="100%" stopColor="#33ccff" />
                </linearGradient>
              </defs>
              <Bar name="Total Claims" dataKey="totalcount" barSize={30} fill="url(#colorYv)" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default PripayerChart;
