import React, { useState, useEffect } from "react";
import axios from "axios";
import {  Input, Button } from "reactstrap";
import moment from "moment";
import { DatePicker } from "antd";
const buttonStyle = {
  background:"#002755",
  color:"#fff"
};

const TabContent = ({ url }) => {
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const perPage = 10;
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [filterstatus, setFilterstatus] = useState("custom");

  const { RangePicker } = DatePicker;
  const valuedate = (option, e) => {
    if (option === "yesterday") {
      setStartdate(e.target.value);
      setEnddate(moment().subtract(1, "days").format("MM-DD-YYYY"));
      setFilterstatus(option);
    } else {
      setStartdate(e.target.value);
      setEnddate(moment().format("MM-DD-YYYY"));
      setFilterstatus(option);
    }
  };

  const fetchData = async (page = 0) => {
    setLoading(true);
    const token = sessionStorage.getItem("accesstoken");
  
    // Define parameters object
    let params = { pageno: page };
    
    // Conditionally add date filters if they are set
    if (startdate && enddate) {
      params.startdate = startdate;
      params.enddate = enddate;
    }
  
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
        params: params, // Pass params dynamically
      });
  
      setData(response.data.data);
      setPageCount(Math.ceil(response.data.count / perPage));
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  

  const generateReport = () => {
    const token = sessionStorage.getItem("accesstoken");
  
    // Build URL with query parameters dynamically
    let queryParams = "";
    if (startdate && enddate) {
      queryParams = `&startdate=${startdate}&enddate=${enddate}`;
    }
  
    fetch(`${url}${queryParams}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "Report.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => console.error("Error downloading report:", error));
  };
  

  useEffect(() => {
    fetchData(currentPage);
  }, [url,  currentPage]);

  return (
    <div>
    <br/>
      {/* Date Filters */}
      <div>
      <Input
        type="select"
        style={{ width: "20%", float: "right" }}
        className="form-control form-select"
        onChange={(e) => {
          const index = e.target.selectedIndex;
          const el = e.target.childNodes[index];
          const option = el.getAttribute("title");
          valuedate(option, e);
        }}
      >
        <option value="" title="custom">
          Custom
        </option>
        <option value={moment().format("MM-DD-YYYY")} title="">
          Today
        </option>
        <option value={moment().subtract(1, "days").format("MM-DD-YYYY")} title="yesterday">
          Yesterday
        </option>
        <option value={moment().subtract(3, "days").format("MM-DD-YYYY")} title="">
          Last 3 days
        </option>
        <option value={moment().subtract(7, "days").format("MM-DD-YYYY")} title="">
          Last 7 days
        </option>
        <option value={moment().subtract(30, "days").format("MM-DD-YYYY")} title="">
          Last 30 days
        </option>
        <option value={moment().subtract(365, "days").format("MM-DD-YYYY")} title="">
          Last 365 days
        </option>
      </Input>
      {filterstatus === "custom" && (
        <RangePicker
          format="MM-DD-YYYY"
          onChange={(dates, datestring) => {
            setStartdate(datestring[0]);
            setEnddate(datestring[1]);
          }}
          allowClear={true}
          width="200%"
          style={{
            borderColor: "gray",
            float: "right",
            marginRight: "2%",
          }}
        />
      )}
    </div>
      {/* Generate Report Button */}
      <Button onClick={generateReport} style={buttonStyle}
      >
        Generate Report
      </Button>
    </div>
  );
};

export default TabContent;
