import React, { useState, useEffect } from "react";
import axios from "axios";
import { ip } from "../../../Api";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import { Spinner } from "react-bootstrap";
import { Row, Col, Input, Button } from "reactstrap";
import moment from "moment-timezone";
import { DatePicker } from "antd";
const Claim = () => {
  const [refreshtoken] = useState(sessionStorage.getItem("refreshtoken"));
  const [accesstoken] = useState(sessionStorage.getItem("accesstoken"));
  const [client] = useState(sessionStorage.getItem("clientid"));
  const [claimwise, setClaimwise] = useState([]);
  // const [allclaims, setAllClaims] = useState([]);
  const [count, setCount] = useState(0);
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [currentpage1, setCurrentPage1] = useState(0);
  const [loading, setLoading] = useState(true);
  const [countofarlog, setCountOfArLog] = useState("");
  const [buttonsize, setButtonSize] = useState(0);
  const [download, setDownload] = useState(false);
  const [filterstatus, setFilterStatus] = useState("custom");
  const { RangePicker } = DatePicker;

  const toggleDownload = () => {
    setDownload((prev) => !prev);
  };

  const getCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    let url;
    if (startdate && enddate) {
      url = `${ip}/report/getcounts?clientId=${client}&feature=claims&start_date=${startdate}&end_date=${enddate}`;
    } else {
      url = `${ip}/report/getcounts?clientId=${client}&feature=claims`;
    }
    try {
      const res = await axios.get(url, { headers });
      setCountOfArLog(Math.ceil(res.data.count / 20000));
    } catch (err) {
      console.error(err);
    }
  };

  const increment = () => {
    if (buttonsize < countofarlog - 1) {
      setButtonSize((prev) => prev + 1);
    } else {
      alert("No data here so please click next arrow");
    }
  };

  const decrement = () => {
    if (buttonsize > 0) {
      setButtonSize((prev) => prev - 1);
    } else {
      alert("No data here so please click next arrow");
    }
  };

  const getClaimWiseReports = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    let url;
    if (startdate && enddate) {
      url = `${ip}/report/claims?clientId=${client}&start_date=${startdate}&end_date=${enddate}&pageno=${currentpage1}`;
    } else {
      url = `${ip}/report/claims?clientId=${client}&pageno=${currentpage1}`;
    }
    try {
      const res = await axios.get(url, { headers });
      setClaimwise(res.data.data);
      // setFetchingClaims(false);
    } catch (err) {
      console.error(err);
    }
  };

  const getDownloadButton = async () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    let url;
    let filename;
    if (startdate && enddate) {
      filename = `Claims_${startdate}_${enddate}`;
      url = `${ip}/report/claims?clientId=${client}&start_date=${startdate}&end_date=${enddate}&button=${buttonsize}`;
    } else {
      filename = `Claims`;
      url = `${ip}/report/claims?clientId=${client}&button=${buttonsize}`;
    }
    fetch(url, { headers })
      .then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.setAttribute("download", filename);
          a.click();
          URL.revokeObjectURL(a);
          setLoading(false);
        });
      })
      .catch((err) => console.error(err));
  };
  //value of the date picker
  const valuedate = (option, e) => {
    if (option === "yesterday") {
      setStartDate(e.target.value);
      setEndDate(moment().subtract(1, "days").format("MM-DD-YYYY"));
    } else {
      setStartDate(e.target.value);
      setEndDate(moment().format("MM-DD-YYYY"));
    }
  };

  useEffect(() => {
    getClaimWiseReports();
    getCount();
  }, [startdate, enddate, currentpage1]);

  return (
    <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
      <Row className="align-items-center">
        <Col md={6} xs={12} className="d-flex">
          <Input
            type="select"
            style={{ width: "20%" }}
            className="form-control form-select"
            onChange={(e) => {
              const index = e.target.selectedIndex;
              const el = e.target.childNodes[index];
              const option = el.getAttribute("title");

              // Update the filter status based on the selected option
              if (option === "custom") {
                setFilterStatus("custom");
              } else {
                setFilterStatus("");
              }

              valuedate(option, e);
            }}
          >
            <option value="" title="custom">
              Custom
            </option>
            <option value={moment().format("MM-DD-YYYY")} title="">
              Today
            </option>
            <option
              value={moment().subtract(1, "days").format("MM-DD-YYYY")}
              title="yesterday"
            >
              Yesterday
            </option>
            <option
              value={moment().subtract(3, "days").format("MM-DD-YYYY")}
              title=""
            >
              Last 3 days
            </option>
            <option
              value={moment().subtract(7, "days").format("MM-DD-YYYY")}
              title=""
            >
              Last 7 days
            </option>
            <option
              value={moment().subtract(30, "days").format("MM-DD-YYYY")}
              title=""
            >
              Last 30 days
            </option>
            <option
              value={moment().subtract(365, "days").format("MM-DD-YYYY")}
              title=""
            >
              Last 365 days
            </option>
          </Input>
          &nbsp;&nbsp;
          {filterstatus === "custom" && (
            <RangePicker
              format="MM-DD-YYYY"
              onChange={(dates) => {
                if (dates) {
                  setStartDate(dates[0].format("MM-DD-YYYY"));
                  setEndDate(dates[1].format("MM-DD-YYYY"));
                } else {
                  setStartDate("");
                  setEndDate("");
                }
              }}
              style={{ borderColor: "gray" }}
            />
          )}
        </Col>
        <Col md={6} xs={12} className="text-md-right mt-2 mt-md-0">
          <Button
          style={{
             background:"#002755",
             color:"#fff"
          }}
            onClick={() => {
              toggleDownload();
            }}
          >
            Generate Report
          </Button>
        </Col>
      </Row>
      <div class="claim-details">
      <div class="row">
          <div class="cell label-cell">ClientId</div>
          <div class="cell description-cell">Unique identifier for the client</div>
      </div>
      <div class="row">
          <div class="cell label-cell">BillingOrganizationName</div>
          <div class="cell description-cell">Name of the organization responsible for billing services.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">StatusCodeValue</div>
          <div class="cell description-cell">Numeric or alphanumeric code representing the claim's specific status.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">StatusCode</div>
          <div class="cell description-cell">Descriptive code for the status of the claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">StatusCategoryCodeValue</div>
          <div class="cell description-cell">Numeric or alphanumeric code representing the category of the claim's status.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">StatusCategoryCode</div>
          <div class="cell description-cell">General category code that classifies the claim’s status.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">PlanCode</div>
          <div class="cell description-cell">Code identifying the insurance plan associated with the claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">TradingPartnerServiceId</div>
          <div class="cell description-cell">Identifier for the trading partner or service provider involved in the claim transaction.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">Reason</div>
          <div class="cell description-cell">Explanation or reason associated with the claim's status or action.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">Type</div>
          <div class="cell description-cell">Type or classification of the claim or insurance policy.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">Touch</div>
          <div class="cell description-cell">Indicator of the claim's processing stage or interaction level.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">ClaimStatus</div>
          <div class="cell description-cell">Current processing status of the claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">Notes</div>
          <div class="cell description-cell">Additional notes or remarks regarding the claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">PrimaryPolicyNo</div>
          <div class="cell description-cell">Policy number for the primary insurance coverage.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">Pripayer</div>
          <div class="cell description-cell">Primary payer responsible for claim payment.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">Physician</div>
          <div class="cell description-cell">Name of the physician involved in the patient’s treatment.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">PatientDOB</div>
          <div class="cell description-cell">Date of birth of the patient associated with the claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">ClaimID</div>
          <div class="cell description-cell">Unique identifier for the insurance claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">CreatedAt</div>
          <div class="cell description-cell">Timestamp indicating when the claim record was created.</div>
      </div>
  </div>
      {download && (
        <Modal
          style={{ width: "30%" }}
          isOpen={download}
          toggle={toggleDownload}
          centered
        >
          <ModalHeader toggle={toggleDownload}>
            <h6>Generate Claims Report</h6>
          </ModalHeader>
          <ModalBody
            style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}
          >
            <div
              id="container-generate-button"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <BsFillArrowLeftCircleFill
                style={{ fontSize: "183%" }}
                onClick={decrement}
                disabled={buttonsize <= 0}
              />

              {loading ? (
                <button
                  style={{
                    background: "green",
                    borderRadius: "6px",
                    border: "3px solid green",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "#ffffff",
                    fontSize: "15px",
                    fontWeight: "bold",
                    padding: "6px 24px",
                    textDecoration: "none",
                    width: "70px",
                    height: "40px",
                  }}
                >
                  <Spinner animation="border" />
                </button>
              ) : (
                <button
                  style={{
                    background: "green",
                    borderRadius: "6px",
                    border: "3px solid green",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "#ffffff",
                    fontSize: "15px",
                    fontWeight: "bold",
                    padding: "6px 24px",
                    textDecoration: "none",
                  }}
                  onClick={getDownloadButton}
                >
                  Download
                </button>
              )}

              <BsFillArrowRightCircleFill
                style={{ fontSize: "183%" }}
                onClick={increment}
                disabled={buttonsize >= countofarlog - 1}
              />
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};
export default Claim;
