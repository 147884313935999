import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import "../../../Styles/subclaim.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import { ip } from "../../../Api";
class DataReporting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: sessionStorage.getItem("queryurl"),
      client: sessionStorage.getItem("queryclient"),
      Location: sessionStorage.getItem("queryLocation"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
      show2: sessionStorage.getItem("queryclaimid"),
      subcategoryvalue: null,
      subcategoryvalue: null,
      receipttotalamt: "",
      receipttotalcnt: "",
      acceptdrtotalamt: "",
      acceptdrtotalcnt: "",
      ructotalamt: "",
      ructotalcnt: "",
      nftotalamt: "",
      nftotalcnt: "",
      rmitotalamt: "",
      rmitotalcnt: "",
      riitotalamt: "",
      riitotalcnt: "",
      rretotalamt: "",
      rretotalcnt: "",
      warningtotalamt: "",
      warningtotalcnt: "",
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getdatareportingdata = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryenddate && this.state.querystartdate) {
      url = `${ip}/claimstatus/subcategory?${this.state.value}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
    } else {
      url = `${ip}/claimstatus/subcategory?${this.state.value}`;
    }
    const res = await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ subcategoryvalue: res.data.data });
        if (res.data.data.length !== 0) {
          res.data.data.map((data, index) => {
            switch (data._id) {
              case "DR01":
                return this.setState({
                  receipttotalamt: Math.round(
                    data.totalamount
                  ).toLocaleString(),
                  receipttotalcnt: data.totalcount,
                });
              case "DR02":
                return this.setState({
                  acceptdrtotalamt: Math.round(
                    data.totalamount
                  ).toLocaleString(),
                  acceptdrtotalcnt: data.totalcount,
                });
              case "DR03":
                return this.setState({
                  ructotalamt: Math.round(data.totalamount).toLocaleString(),
                  ructotalcnt: data.totalcount,
                });
              case "DR04":
                return this.setState({
                  nftotalamt: Math.round(data.totalamount).toLocaleString(),
                  nftotalcnt: data.totalcount,
                });
              case "DR05":
                return this.setState({
                  rmitotalamt: Math.round(data.totalamount).toLocaleString(),
                  rmitotalcnt: data.totalcount,
                });
              case "DR06":
                return this.setState({
                  riitotalamt: Math.round(data.totalamount).toLocaleString(),
                  riitotalcnt: data.totalcount,
                });
              case "DR07":
                return this.setState({
                  rretotalamt: Math.round(data.totalamount).toLocaleString(),
                  rretotalcnt: data.totalcount,
                });
              case "DR08":
                return this.setState({
                  warningtotalamt: Math.round(
                    data.totalamount
                  ).toLocaleString(),
                  warningtotalcnt: data.totalcount,
                });
            }
          });
        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    
    this.getdatareportingdata();
  }
  render() {
    return (
      <div className="align-row" style={{ overflowX: "hidden" }}>
        <Newsidebar>
        <p>
              <Link
                to="/claim-management"
                style={{ textDecoration: "none", color: "white" }}
              >
                Claim Status Management
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Data
              Reporting Acknowledgements
            </p>
        </Newsidebar>
        <div className="align-column">
          <br/>
          <br/>
          <div className="row" style={{ marginLeft: "2%" }}>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Receipt card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr01"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR01");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("querysub", "Receipt");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR01&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR01");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr01"
                        );
                        sessionStorage.setItem("querysub", "Receipt");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR01&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR01");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr01"
                        );
                        sessionStorage.setItem("querysub", "Receipt");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR01&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR01");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr01"
                        );
                        sessionStorage.setItem("querysub", "Receipt");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR01&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR01");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr01"
                      );
                      sessionStorage.setItem("querysub", "Receipt");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR01&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR01");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr01"
                      );
                      sessionStorage.setItem("querysub", "Receipt");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR01`
                      );
                    }
                  }
                }}
              >        
                <div
                class="card-header bg-white"
              >
              <div
              style={{
                background: this.state.show2 === "DR01" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
              }}>
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Receipt
                  </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter has been received. This does not
                          mean the claim has been accepted into the data
                          reporting.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.receipttotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.receipttotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Acceptance into the data reporting card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr02"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR02");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Acceptance into the data reporting"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR02&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR02");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr02"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Acceptance into the data reporting"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR02&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR02");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr02"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Acceptance into the data reporting"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR02&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR02");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr02"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Acceptance into the data reporting"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR02&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR02");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr02"
                      );
                      sessionStorage.setItem(
                        "querysub",
                        "Acceptance into the data reporting"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR02&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR02");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr02"
                      );
                      sessionStorage.setItem(
                        "querysub",
                        "Acceptance into the data reporting"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR02`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "DR02" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Acceptance into the data reporting
                  </span>
                  </div>
      
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter has been accepted into the data
                          reporting/processing system.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.acceptdrtotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.acceptdrtotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Returned as unprocessable claim card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR03");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr03"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Returned as unprocessable claim"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR03&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR03");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr03"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Returned as unprocessable claim"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR03&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR03");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr03"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Returned as unprocessable claim"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR03&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR03");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr03"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Returned as unprocessable claim"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR03&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR03");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr03"
                      );
                      sessionStorage.setItem(
                        "querysub",
                        "Returned as unprocessable claim"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR03&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR03");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr03"
                      );
                      sessionStorage.setItem(
                        "querysub",
                        "Returned as unprocessable claim"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR03`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "DR03" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Returned as unprocessable claim
                  </span>
                  </div>
                
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter has been rejected and has not been
                          entered into the data reporting.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.ructotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.ructotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Not found card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR04");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr04"
                        );
                        sessionStorage.setItem("querysub", "Not Found");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR04&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR04");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr04"
                        );
                        sessionStorage.setItem("querysub", "Not Found");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR04&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR04");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr04"
                        );
                        sessionStorage.setItem("querysub", "Not Found");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR04&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr04"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR04");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("querysub", "Not Found");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR04&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr04"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR04");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("querysub", "Not Found");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR04&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr04"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR04");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("querysub", "Not Found");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR04`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "DR04" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Not Found
                  </span>
                  </div>
               
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter can not be found in the data
                          reporting/processing system.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.nftotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.nftotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Rejected for missing claims card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR05");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr05"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Missing Information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR05&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR05");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr05"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Missing Information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR05&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR05");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr05"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Missing Information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR05&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR05");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr05"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Missing Information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR05&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR05");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr05"
                      );
                      sessionStorage.setItem(
                        "querysub",
                        "Rejected for Missing Information"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR05&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr05"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR05");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "querysub",
                        "Rejected for Missing Information"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR05`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "DR05" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Rejected for Missing Information
                  </span>
                  </div>

                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter is missing the information
                          specified in the Status details and has been rejected.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.rmitotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.rmitotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Rejected for invalid information card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR06");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr06"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for invalid information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR06&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr06"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR06");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for invalid information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR06&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr06"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR06");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for invalid information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR06&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR06");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr06"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for invalid information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR06&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR06");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr06"
                      );
                      sessionStorage.setItem(
                        "querysub",
                        "Rejected for invalid information"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR06&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR06");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr06"
                      );
                      sessionStorage.setItem(
                        "querysub",
                        "Rejected for invalid information"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR06`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "DR06" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Rejected for invalid information
                  </span>
                </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter has invalid information as
                          specified in the Status details and has been rejected.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.riitotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.riitotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Rejected for relational field in error card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr07"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR07");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for relational field in error"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR07&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR07");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr07"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for relational field in error"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR07&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR07");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr07"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for relational field in error"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR07&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR07");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr07"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for relational field in error"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR07&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR07");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr07"
                      );
                      sessionStorage.setItem(
                        "querysub",
                        "Rejected for relational field in error"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR07&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR07");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr07"
                      );
                      sessionStorage.setItem(
                        "querysub",
                        "Rejected for relational field in error"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR07`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "DR07" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Rejected for relational field in error
                  </span>
                  </div>
        
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <br />
                      <br />
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.rretotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.rretotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div
                class="card"
                // warning card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR08");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr08"
                        );
                        sessionStorage.setItem("querysub", "Warning");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR08&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr08"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR08");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("querysub", "Warning");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR08&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR08");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr08"
                        );
                        sessionStorage.setItem("querysub", "Warning");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR08&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "DR08");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Data Reporting Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "datareportingacknowledgementsdr08"
                        );
                        sessionStorage.setItem("querysub", "Warning");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR08&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR08");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr08"
                      );
                      sessionStorage.setItem("querysub", "Warning");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR08&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem("queryCategoryCode", "DR08");
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Data Reporting Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "datareportingacknowledgementsdr08"
                      );
                      sessionStorage.setItem("querysub", "Warning");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Data Reporting Acknowledgements&statusCategoryCode=DR08`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "DR08" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Warning
                  </span>
                  </div>
      
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                     
                          The claim/encounter is accepted for data reporting with a warning; see Status details for specifics
                        </Col>
                      </Row>

                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.warningtotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.warningtotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DataReporting;
