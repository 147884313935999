import { React, Component } from "react";
import "../../../Styles/claimmanagement.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { ip } from "../../../Api";
import ClaimAllocation from "../ClaimAllocation";
export class ClaimBuckets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role: sessionStorage.getItem("role_type"),
      searchidkeyindicator: sessionStorage.getItem("searchidkeyindicator"),
      searchidkeyindicator1: localStorage.getItem("searchidkeyindicator1"),
      clientslist: [],
      opendropdown: false,
      categoryvalue: null,
      Acktotalamount: "",
      Acktotalcount: "",
      Pendingtotalamount: "",
      Pendingtotalcount: "",
      DataReporttotalamount: "",
      DataReporttotalcount: "",
      Finalizedtotalamount: "",
      Finalizedtotalcount: "",
      Searchestotalamount: "",
      Searchestotalcount: "",
      Requesttotalamount: "",
      Requesttotalcount: "",
      NIStotalcount: "",
      NIStotalamount: "",
      ppaidtotalamount: "",
      ppaidtotalcount: "",
      closedtotalamount: "",
      closedtotalcount: "",
      recentlyclosedtotalamount: "",
      recentlyclosedtotalcount: "",
      adjustedtotalamount: "",
      adjustedtotalcount: "",
      systemerrortotalamount: "",
      systemerrortotalcount: "",
      availityemptycount: "",
      availityemptyamount: "",
      searchesaamount: "",
      searchesacount: "",
      erroraamount: "",
      erroracount: "",
      machine1searchesamount:"",
      machine1searchescount:"",
      totalerramount:"",
      totalerrcount:"",
    };
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        localStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getAckCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/acknowledgement?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/acknowledgement?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/acknowledgement?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/acknowledgement?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/claimcategory/acknowledgement?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/claimstatus/claimcategory/acknowledgement`;
      }
    }
     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data) {
          this.setState({
            Acktotalamount: res.data.data.totalamount,
            Acktotalcount: res.data.data.totalcount,
          });
        } else {
          this.setState({ Acktotalamount: 0, Acktotalcount: 0 });
        }
      })
      .catch((err) => {});
  };

  getDataRepAckCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/datarportingacknowledgement?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/datarportingacknowledgement?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/datarportingacknowledgement?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/datarportingacknowledgement?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/claimcategory/datarportingacknowledgement?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/claimstatus/claimcategory/datarportingacknowledgement`;
      }
    }
     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data) {
          this.setState({
            DataReporttotalamount: res.data.data.totalamount,
            DataReporttotalcount: res.data.data.totalcount,
          });
        } else {
          this.setState({ DataReporttotalamount: 0, DataReporttotalcount: 0 });
        }
      })
      .catch((err) => {});
  };

  getpendingCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/pending?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/pending?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/pending?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/pending?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/claimcategory/pending?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/claimstatus/claimcategory/pending`;
      }
    }
     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data) {
          this.setState({
            Pendingtotalamount: res.data.data.totalamount,
            Pendingtotalcount: res.data.data.totalcount,
          });
        } else {
          this.setState({ Pendingtotalamount: 0, Pendingtotalcount: 0 });
        }
      })
      .catch((err) => {});
  };

  getFinalizedCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/finalized?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/finalized?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/finalized?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/finalized?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/claimcategory/finalized?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/claimstatus/claimcategory/finalized`;
      }
    }
     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data) {
          this.setState({
            Finalizedtotalamount: res.data.data.totalamount,
            Finalizedtotalcount: res.data.data.totalcount,
          });
        } else {
          this.setState({ Finalizedtotalamount: 0, Finalizedtotalcount: 0 });
        }
      })
      .catch((err) => {});
  };

  getRequestsCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/request?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/request?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/request?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/request?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/claimcategory/request?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/claimstatus/claimcategory/request`;
      }
    }
     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data) {
          this.setState({
            Requesttotalamount: res.data.data.totalamount,
            Requesttotalcount: res.data.data.totalcount,
          });
        } else {
          this.setState({ Requesttotalamount: 0, Requesttotalcount: 0 });
        }
      })
      .catch((err) => {});
  };

  getErrorCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/allerrorcount?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/allerrorcount?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/allerrorcount?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/allerrorcount?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/allerrorcount?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/claimstatus/allerrorcount`;
      }
    }
     await axios
      .get(url, { headers: headers })
      .then((res) => {
        
        if (res.data.data.length !== 0) {
          this.setState({
            totalerramount: res.data.data[0].totalamount,
            totalerrcount: res.data.data[0].totalcount,
          });
        } else {
          this.setState({ totalerramount: 0, totalerrcount: 0 });
        }
      })
      .catch((err) => {});
  };

  getSearchesCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/searches?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/searches?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/searches?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/searches?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/claimcategory/searches?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/claimstatus/claimcategory/searches`;
      }
    }
     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data) {
          this.setState({
            Searchestotalamount: res.data.data.totalamount,
            Searchestotalcount: res.data.data.totalcount,
          });
        } else {
          this.setState({ Searchestotalamount: 0, Searchestotalcount: 0 });
        }
      })
      .catch((err) => {});
  };

  getNISCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/nis?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/nis?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/nis?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/nis?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/claimcategory/nis?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/claimstatus/claimcategory/nis`;
      }
    }
     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data.length !== 0) {
          this.setState({
            NIStotalamount: res.data.data[0].totalamount,
            NIStotalcount: res.data.data[0].totalcount,
          });
        } else {
          this.setState({ NIStotalamount: 0, NIStotalcount: 0 });
        }
      })
      .catch((err) => {});
  };

  getPartiallyPaidCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/partiallypaid?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/partiallypaid?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/partiallypaid?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/partiallypaid?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/claimcategory/partiallypaid?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/claimstatus/claimcategory/partiallypaid`;
      }
    }
     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data.length !== 0) {
          this.setState({
            ppaidtotalamount: res.data.data[0].totalamount,
            ppaidtotalcount: res.data.data[0].totalcount,
          });
        } else {
          this.setState({ ppaidtotalamount: 0, ppaidtotalcount: 0 });
        }
      })
      .catch((err) => {});
  };
  getAlreadyPaidCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/closedclaim?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/closedclaim?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/closedclaim?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/closedclaim?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/claimcategory/closedclaim?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/claimstatus/claimcategory/closedclaim`;
      }
    }
     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data.length !== 0) {
          this.setState({
            closedtotalamount: res.data.data[0].totalamount,
            closedtotalcount: res.data.data[0].totalcount,
          });
        } else {
          this.setState({ closedtotalamount: 0, closedtotalcount: 0 });
        }
      })
      .catch((err) => {});
  };
  getRecentlyclosedCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/recentlysubmitted?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/recentlysubmitted?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/recentlysubmitted?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/recentlysubmitted?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/claimcategory/recentlysubmitted?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/claimstatus/claimcategory/recentlysubmitted`;
      }
    }
     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data.length !== 0) {
          this.setState({
            recentlyclosedtotalamount: res.data.data[0].totalamount,
            recentlyclosedtotalcount: res.data.data[0].totalcount,
          });
        } else {
          this.setState({
            recentlyclosedtotalamount: 0,
            recentlyclosedtotalcount: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getWorkedCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/recentlyworked?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/recentlyworked?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/recentlyworked?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/recentlyworked?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/claimcategory/recentlyworked?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/claimstatus/claimcategory/recentlyworked`;
      }
    }
     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data.length !== 0) {
          this.setState({
            adjustedtotalamount: res.data.data[0].totalamount,
            adjustedtotalcount: res.data.data[0].totalcount,
          });
        } else {
          this.setState({ adjustedtotalamount: 0, adjustedtotalcount: 0 });
        }
      })
      .catch((err) => {});
  };
  
  getAvailityCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/availityempty?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/availityempty?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/availityempty?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/availityempty?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/claimcategory/availityempty?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/claimstatus/claimcategory/availityempty`;
      }
    }
     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data.length !== 0) {
          this.setState({
            availityemptyamount: res.data.data[0].totalamount,
            availityemptycount: res.data.data[0].totalcount,
          });
        } else {
          this.setState({
            availityemptyamount: 0,
            availityemptycount: 0,
          });
        }
      })
      .catch((err) => {});
  };

  
  //Machine 1 searches  (Change health)
  getmachine1SearchesCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/searches?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/searches?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/searches?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/searches?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/claimcategory/searches?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
      url = `${ip}/claimstatus/claimcategory/searches?clientId=${this.props.client}`;
    }
  }

     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data) {
          this.setState({
            machine1searchesamount: res.data.data.totalamount,
            machine1searchescount: res.data.data.totalcount,
          });
        } else {
          this.setState({
            machine1searchesamount: 0,
            machine1searchescount: 0,
          });
        }
      })
      .catch((err) => {});
  };

  //avality search count
  getSearchesACount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/availitysearches?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/availitysearches?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/claimstatus/claimcategory/availitysearches?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/availitysearches?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate.length !== 0 && this.props.enddate.length !== 0) {
        url = `${ip}/claimstatus/claimcategory/availitysearches?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
      url = `${ip}/claimstatus/claimcategory/availitysearches?clientId=${this.props.client}`;
    }
  }

     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data) {
          this.setState({
            searchesaamount: res.data.data.totalamount,
            searchesacount: res.data.data.totalcount,
          });
        } else {
          this.setState({
            searchesaamount: 0,
            searchesacount: 0,
          });
        }
      })
      .catch((err) => {});
  };
  componentDidMount() {
    this.getAckCount();
    this.getDataRepAckCount();
    this.getpendingCount();
    this.getFinalizedCount();
    this.getRequestsCount();
    this.getErrorCount();
    this.getSearchesCount();
    this.getNISCount();
    this.getPartiallyPaidCount();
    this.getAlreadyPaidCount();
    this.getRecentlyclosedCount();
    this.getWorkedCount();
    this.getSearchesACount();
    this.getmachine1SearchesCount();
    // let value = localStorage.getItem("searchkeyidlocal")
  }
  // componentWillUnmount() {
  //   let input = JSON.stringify(this.props.searchid)
  //   let input1 = input ? input : null
  //   localStorage.setItem("searchkeyidlocal",input1)
  // }

  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.startdate === prevProps.startdate &&
        this.props.enddate === prevProps.enddate
      )
    ) {
      this.getAckCount();
      this.getDataRepAckCount();
      this.getpendingCount();
      this.getFinalizedCount();
      this.getRequestsCount();
      this.getErrorCount();
      this.getSearchesCount();
      this.getNISCount();
      this.getPartiallyPaidCount();
      this.getAlreadyPaidCount();
      this.getRecentlyclosedCount();
      this.getWorkedCount();
      this.getSearchesACount();
      this.getmachine1SearchesCount();
    } else if (this.props.client !== prevProps.client) {
      this.getAckCount();
      this.getDataRepAckCount();
      this.getpendingCount();
      this.getFinalizedCount();
      this.getRequestsCount();
      this.getErrorCount();
      this.getSearchesCount();
      this.getNISCount();
      this.getPartiallyPaidCount();
      this.getAlreadyPaidCount();
      this.getRecentlyclosedCount();
      this.getWorkedCount();
      this.getSearchesACount();
      this.getmachine1SearchesCount();
    } else if (this.props.Location !== prevProps.Location) {
      this.getAckCount();
      this.getDataRepAckCount();
      this.getpendingCount();
      this.getFinalizedCount();
      this.getRequestsCount();
      this.getErrorCount();
      this.getSearchesCount();
      this.getNISCount();
      this.getPartiallyPaidCount();
      this.getAlreadyPaidCount();
      this.getRecentlyclosedCount();
      this.getWorkedCount();
      this.getSearchesACount();
      this.getmachine1SearchesCount();
    }
  }

  
  render() {
    return (
      <div style={{ fontSize: "12px" }}>
        <div class="row">
          {
            //  Acknowledgement card
            this.props.search === "Acknowledgement" ? (
              <div className="col-md-3 mb-3">
                <div className="card">
                  <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)", color:"white", padding:"3px"
                  }}
                >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        class="d-inline-block"
                        style={{ fontWeight: "bold" }}
                      >
                        ACKNOWLEDGEMENTS
                      </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={1}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                
                  </div>
                  <div class="card-text">
                  <Link
                    to={{
                      pathname: "/claim/acknowledgements",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Row
                      onClick={() => {
                        if (this.props.client) {
                          if (this.props.Location) {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Acknowledgement&clientId=${this.props.client}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem(
                              "queryLocation",
                              this.props.Location
                            );
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Acknowledgement&clientId=${this.props.client}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem("queryLocation", "");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        } else {
                          sessionStorage.setItem(
                            "queryurl",
                            `ClaimCategory=Acknowledgement`
                          );
                          sessionStorage.setItem("queryclient", "");
                          sessionStorage.setItem(
                            "searchid",
                            this.props.search
                          );
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.props.show2
                          );
                        }
                      }}
                    >
                      <Col
                        className="claim"
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Acknowledgement&clientId=${this.props.client}&Location=${this.props.Location}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Acknowledgement&clientId=${this.props.client}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Acknowledgement`
                            );
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        Total Claims:
                        <span>
                          <div
                            className="to"
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "13px",
                            }}
                          >
                            {this.state.Acktotalcount}
                          </div>
                        </span>
                      </Col>
                      <Col className="total">
                        Grand Total:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontSize: "13px",
                              fontWeight: "600",
                            }}
                          >
                            $ {this.state.Acktotalamount}
                          </div>
                        </span>
                      </Col>
                    </Row>
                  </Link>
                </div>
                </div>
              </div>
              </div>
            ) : (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"
                  }}
                >
                      <span
                        class="d-inline-block"
                        style={{ fontWeight: "bold" }}
                      >
                        ACKNOWLEDGEMENTS
                      </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={1}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/acknowledgements",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Acknowledgement&clientId=${this.props.client}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Acknowledgement&clientId=${this.props.client}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Acknowledgement`
                            );
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        <Col
                          className="claim"
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=Acknowledgement&clientId=${this.props.client}&Location=${this.props.Location}`
                                );
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=Acknowledgement&clientId=${this.props.client}`
                                );
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Acknowledgement`
                              );
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {this.state.Acktotalcount}
                            </div>
                          </span>
                        </Col>
                        <Col className="total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.Acktotalamount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            )
          }
          {this.props.search === "Data Reporting Acknowledgements" ? (
            <div class="col-md-3 mb-3">
              <div class="card">
              <div
              class="card-header bg-white"
            >
            <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)", color:"white", padding:"3px"
                }}
              >
                  <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                    ACK-DATA REPORTING
                    </span>
                    <span class="d-inline-block">
                      {this.state.role === "Admin" && (
                        <ClaimAllocation
                          data={2}
                          client={this.props.client}
                          Location={this.props.Location}
                          startdate={this.props.startdate}
                          enddate={this.props.enddate}
                        ></ClaimAllocation>
                      )}
                    </span>
                  </div>
                  <div class="card-text">
                  <Link
                    to={{
                      pathname: "/claim/data reporting acknowledgements",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Row
                      onClick={() => {
                        if (this.props.client) {
                          if (this.props.Location) {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Data Reporting Acknowledgements&clientId=${this.props.client}&Location=${this.props.Location}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem(
                              "queryLocation",
                              this.props.Location
                            );
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Data Reporting Acknowledgements&clientId=${this.props.client}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem("queryLocation", "");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        } else {
                          sessionStorage.setItem(
                            "queryurl",
                            `ClaimCategory=Data Reporting Acknowledgements`
                          );
                          sessionStorage.setItem("queryclient", "");
                          sessionStorage.setItem(
                            "searchid",
                            this.props.search
                          );
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.props.show2
                          );
                        }
                      }}
                    >
                      <Col
                        className="claim"
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Data Reporting Acknowledgements&clientId=${this.props.client}&Location=${this.props.Location}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Data Reporting Acknowledgements&clientId=${this.props.client}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Data Reporting Acknowledgements`
                            );
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        Total Claims:
                        <span>
                          <div
                            className="to"
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "13px",
                            }}
                          >
                            {" "}
                            {this.state.DataReporttotalcount}
                          </div>
                        </span>
                      </Col>
                      <Col className="total">
                        Grand Total:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontSize: "13px",
                              fontWeight: "600",
                            }}
                          >
                            $ {this.state.DataReporttotalamount}
                          </div>
                        </span>
                      </Col>
                    </Row>
                  </Link>
                </div>
                </div>
              </div>
            </div>
          ) : (
            <div class="col-md-3 mb-3">
              <div class="card">
              <div
              class="card-header bg-white"
            >
            <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"
                }}
              >
                  <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                    ACK-DATA REPORTING
                    </span>
                    <span class="d-inline-block">
                      {this.state.role === "Admin" && (
                        <ClaimAllocation
                          data={2}
                          client={this.props.client}
                          Location={this.props.Location}
                          startdate={this.props.startdate}
                          enddate={this.props.enddate}
                        ></ClaimAllocation>
                      )}
                    </span>
                  </div>
                  <div class="card-text">
                  <Link
                    to={{
                      pathname: "/claim/data reporting acknowledgements",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Row
                      onClick={() => {
                        if (this.props.client) {
                          if (this.props.Location) {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Data Reporting Acknowledgements&clientId=${this.props.client}&Location=${this.props.Location}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem(
                              "queryLocation",
                              this.props.Location
                            );
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Data Reporting Acknowledgements&clientId=${this.props.client}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem("queryLocation", "");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        } else {
                          sessionStorage.setItem(
                            "queryurl",
                            `ClaimCategory=Data Reporting Acknowledgements`
                          );
                          sessionStorage.setItem("queryclient", "");
                          sessionStorage.setItem(
                            "searchid",
                            this.props.search
                          );
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.props.show2
                          );
                        }
                      }}
                    >
                      <Col
                        className="claim"
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Data Reporting Acknowledgements&clientId=${this.props.client}&Location=${this.props.Location}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Data Reporting Acknowledgements&clientId=${this.props.client}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Data Reporting Acknowledgements`
                            );
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        Total Claims:
                        <span>
                          <div
                            className="to"
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "13px",
                            }}
                          >
                            {" "}
                            {this.state.DataReporttotalcount}
                          </div>
                        </span>
                      </Col>
                      <Col className="total">
                        Grand Total:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontSize: "13px",
                              fontWeight: "600",
                            }}
                          >
                            $ {this.state.DataReporttotalamount}
                          </div>
                        </span>
                      </Col>
                    </Row>
                  </Link>
                </div>
                </div>
              </div>
            </div>
          )}
          {
            // Pending card
            this.props.search === "pending" ? (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      PENDING
                      </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={3}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/pending",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=pending&clientId=${this.props.client}&Location=${this.props.Location}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=pending&clientId=${this.props.client}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=pending`
                            );
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        <Col
                          className="claim"
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=pending&clientId=${this.props.client}&Location=${this.props.Location}`
                                );
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=pending&clientId=${this.props.client}`
                                );
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=pending`
                              );
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {" "}
                              {this.state.Pendingtotalcount}
                            </div>
                          </span>
                        </Col>
                        <Col className="total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.Pendingtotalamount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="col-md-3 mb-3">
                <div class="card">
                  <div
                    class="card-header bg-white"
                  >
                  <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        background: "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"
                      }}
                    >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      PENDING
                     </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={3}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/pending",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=pending&clientId=${this.props.client}&Location=${this.props.Location}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=pending&clientId=${this.props.client}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=pending`
                            );
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        <Col
                          className="claim"
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=pending&clientId=${this.props.client}&Location=${this.props.Location}`
                                );
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=pending&clientId=${this.props.client}`
                                );
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=pending`
                              );
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {" "}
                              {this.state.Pendingtotalcount}
                            </div>
                          </span>
                        </Col>
                        <Col className="total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.Pendingtotalamount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            )
          }
          {
            // Finalised card
            this.props.search === "Finalized" ? (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      FINALIZED
                      </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={4}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                      <Link
                        to={{
                          pathname: "/claim/finalized",
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Row
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=Finalized&clientId=${this.props.client}&Location=${this.props.Location}`
                                );
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=Finalized&clientId=${this.props.client}`
                                );
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Finalized`
                              );
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          <Col
                            className="claim"
                            onClick={() => {
                              if (this.props.client) {
                                if (this.props.Location) {
                                  sessionStorage.setItem(
                                    "queryurl",
                                    `ClaimCategory=Finalized&clientId=${this.props.client}&Location=${this.props.Location}`
                                  );
                                  sessionStorage.setItem(
                                    "queryclient",
                                    this.props.client
                                  );
                                  sessionStorage.setItem(
                                    "queryLocation",
                                    this.props.Location
                                  );
                                  sessionStorage.setItem(
                                    "searchid",
                                    this.props.search
                                  );
                                  sessionStorage.setItem(
                                    "queryclaimid",
                                    this.props.show2
                                  );
                                } else {
                                  sessionStorage.setItem(
                                    "queryurl",
                                    `ClaimCategory=Finalized&clientId=${this.props.client}`
                                  );
                                  sessionStorage.setItem(
                                    "queryclient",
                                    this.props.client
                                  );
                                  sessionStorage.setItem("queryLocation", "");
                                  sessionStorage.setItem(
                                    "searchid",
                                    this.props.search
                                  );
                                  sessionStorage.setItem(
                                    "queryclaimid",
                                    this.props.show2
                                  );
                                }
                              } else {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=Finalized`
                                );
                                sessionStorage.setItem("queryclient", "");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            }}
                          >
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {" "}
                                {this.state.Finalizedtotalcount}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                $ {this.state.Finalizedtotalamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      FINALIZED
                      </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={4}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/finalized",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Finalized&clientId=${this.props.client}&Location=${this.props.Location}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Finalized&clientId=${this.props.client}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Finalized`
                            );
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        <Col
                          className="claim"
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=Finalized&clientId=${this.props.client}&Location=${this.props.Location}`
                                );
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=Finalized&clientId=${this.props.client}`
                                );
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Finalized`
                              );
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {" "}
                              {this.state.Finalizedtotalcount}
                            </div>
                          </span>
                        </Col>
                        <Col className="total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.Finalizedtotalamount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                  </div>
    
                </div>
              </div>
            )
          }
          {
            // Request add's informational card
            this.props.search === "Request" ? (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      REQUEST-ADD'L&nbsp;INFORMATION
                      </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={5}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                      <Link
                        to={{
                          pathname:
                            "/claim/requests for additional information",
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Row
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=Request&clientId=${this.props.client}&Location=${this.props.Location}`
                                );
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=Request&clientId=${this.props.client}`
                                );
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Request`
                              );
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          <Col
                            className="claim"
                            onClick={() => {
                              if (this.props.client) {
                                if (this.props.Location) {
                                  sessionStorage.setItem(
                                    "queryurl",
                                    `ClaimCategory=Request&clientId=${this.props.client}&Location=${this.props.Location}`
                                  );
                                  sessionStorage.setItem(
                                    "queryclient",
                                    this.props.client
                                  );
                                  sessionStorage.setItem(
                                    "queryLocation",
                                    this.props.Location
                                  );
                                  sessionStorage.setItem(
                                    "searchid",
                                    this.props.search
                                  );
                                  sessionStorage.setItem(
                                    "queryclaimid",
                                    this.props.show2
                                  );
                                } else {
                                  sessionStorage.setItem(
                                    "queryurl",
                                    `ClaimCategory=Request&clientId=${this.props.client}`
                                  );
                                  sessionStorage.setItem(
                                    "queryclient",
                                    this.props.client
                                  );
                                  sessionStorage.setItem("queryLocation", "");
                                  sessionStorage.setItem(
                                    "searchid",
                                    this.props.search
                                  );
                                  sessionStorage.setItem(
                                    "queryclaimid",
                                    this.props.show2
                                  );
                                }
                              } else {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=Request`
                                );
                                sessionStorage.setItem("queryclient", "");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            }}
                          >
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {" "}
                                {this.state.Requesttotalcount}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                $ {this.state.Requesttotalamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      REQUEST-ADD'L&nbsp;INFORMATION
                      </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={5}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                    <Link
                      to={{
                        pathname:
                          "/claim/requests for additional information",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Request&clientId=${this.props.client}&Location=${this.props.Location}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Request&clientId=${this.props.client}`
                              );
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Request`
                            );
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        <Col
                          className="claim"
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=Request&clientId=${this.props.client}&Location=${this.props.Location}`
                                );
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=Request&clientId=${this.props.client}`
                                );
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Request`
                              );
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {" "}
                              {this.state.Requesttotalcount}
                            </div>
                          </span>
                        </Col>
                        <Col className="total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.Requesttotalamount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            )
          }
          {
            // Closed / already paid card
            this.props.search === "Closed/Already Paid" ? (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      CLOSED/ ALREADY PAID
                      </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={6}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
             
                    <div class="card-text">
                      <Link
                        to={{
                          pathname: "/claim/sub-category-details",
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Row
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Closed/Already Paid"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "closed"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Closed/Already Paid"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "closed"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Closed/Already Paid"
                              );
                              sessionStorage.setItem("subclaimname", "closed");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          <Col
                            className="claim"
                            onClick={() => {
                              if (this.props.client) {
                                if (this.props.Location) {
                                  sessionStorage.setItem(
                                    "queryclient",
                                    this.props.client
                                  );
                                  sessionStorage.setItem(
                                    "queryLocation",
                                    this.props.Location
                                  );
                                  sessionStorage.setItem(
                                    "querycategory",
                                    "Closed/Already Paid"
                                  );
                                  sessionStorage.setItem(
                                    "subclaimname",
                                    "closed"
                                  );
                                  sessionStorage.setItem(
                                    "searchid",
                                    this.props.search
                                  );
                                  sessionStorage.setItem(
                                    "queryclaimid",
                                    this.props.show2
                                  );
                                } else {
                                  sessionStorage.setItem(
                                    "queryclient",
                                    this.props.client
                                  );
                                  sessionStorage.setItem("queryLocation", "");
                                  sessionStorage.setItem(
                                    "querycategory",
                                    "Closed/Already Paid"
                                  );
                                  sessionStorage.setItem(
                                    "subclaimname",
                                    "closed"
                                  );
                                  sessionStorage.setItem(
                                    "searchid",
                                    this.props.search
                                  );
                                  sessionStorage.setItem(
                                    "queryclaimid",
                                    this.props.show2
                                  );
                                }
                              } else {
                                sessionStorage.setItem("queryclient", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Closed/Already Paid"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "closed"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            }}
                          >
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {" "}
                                {this.state.closedtotalcount}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                $ {this.state.closedtotalamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      CLOSED/ ALREADY PAID
                      </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={6}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "querycategory",
                                "Closed/Already Paid"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "closed"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Closed/Already Paid"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "closed"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "querycategory",
                              "Closed/Already Paid"
                            );
                            sessionStorage.setItem("subclaimname", "closed");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        <Col
                          className="claim"
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Closed/Already Paid"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "closed"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Closed/Already Paid"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "closed"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Closed/Already Paid"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "closed"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {" "}
                              {this.state.closedtotalcount}
                            </div>
                          </span>
                        </Col>
                        <Col className="total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.closedtotalamount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            )
          }
          {
            // Recently submited card
            this.props.search === "Recently Submitted" ? (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      RECENTLY SUBMITTED
                      </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={7}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                      <Link
                        to={{
                          pathname: "/claim/sub-category-details",
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Row
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Recently Submitted"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "submitted"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Recently Submitted"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "submitted"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Recently Submitted"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "submitted"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          <Col
                            className="claim"
                            onClick={() => {
                              if (this.props.client) {
                                if (this.props.Location) {
                                  sessionStorage.setItem(
                                    "queryclient",
                                    this.props.client
                                  );
                                  sessionStorage.setItem(
                                    "queryLocation",
                                    this.props.Location
                                  );
                                  sessionStorage.setItem(
                                    "querycategory",
                                    "Recently Submitted"
                                  );
                                  sessionStorage.setItem(
                                    "subclaimname",
                                    "submitted"
                                  );
                                  sessionStorage.setItem(
                                    "searchid",
                                    this.props.search
                                  );
                                  sessionStorage.setItem(
                                    "queryclaimid",
                                    this.props.show2
                                  );
                                } else {
                                  sessionStorage.setItem(
                                    "queryclient",
                                    this.props.client
                                  );
                                  sessionStorage.setItem("queryLocation", "");
                                  sessionStorage.setItem(
                                    "querycategory",
                                    "Recently Submitted"
                                  );
                                  sessionStorage.setItem(
                                    "subclaimname",
                                    "submitted"
                                  );
                                  sessionStorage.setItem(
                                    "searchid",
                                    this.props.search
                                  );
                                  sessionStorage.setItem(
                                    "queryclaimid",
                                    this.props.show2
                                  );
                                }
                              } else {
                                sessionStorage.setItem("queryclient", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Recently Submitted"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "submitted"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            }}
                          >
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {" "}
                                {this.state.recentlyclosedtotalcount}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                $ {this.state.recentlyclosedtotalamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      RECENTLY SUBMITTED
                      </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={7}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "querycategory",
                                "Recently Submitted"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "submitted"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Recently Submitted"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "submitted"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "querycategory",
                              "Recently Submitted"
                            );
                            sessionStorage.setItem(
                              "subclaimname",
                              "submitted"
                            );
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        <Col
                          className="claim"
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Recently Submitted"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "submitted"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Recently Submitted"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "submitted"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Recently Submitted"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "submitted"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {" "}
                              {this.state.recentlyclosedtotalcount}
                            </div>
                          </span>
                        </Col>
                        <Col className="total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.recentlyclosedtotalamount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            )
          }
          {
            // recently worked card
            this.props.search === "Recently Worked" ? (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      RECENTLY WORKED
                     </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={8}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                      <Link
                        to={{
                          pathname: "/claim/sub-category-details",
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Row
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Recently Worked"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "Recently Worked"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Recently Worked"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "Recently Worked"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Recently Worked"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "Recently Worked"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          <Col
                            className="claim"
                            onClick={() => {
                              if (this.props.client) {
                                if (this.props.Location) {
                                  sessionStorage.setItem(
                                    "queryclient",
                                    this.props.client
                                  );
                                  sessionStorage.setItem(
                                    "queryLocation",
                                    this.props.Location
                                  );
                                  sessionStorage.setItem(
                                    "querycategory",
                                    "Recently Worked"
                                  );
                                  sessionStorage.setItem(
                                    "subclaimname",
                                    "Recently Worked"
                                  );
                                  sessionStorage.setItem(
                                    "searchid",
                                    this.props.search
                                  );
                                  sessionStorage.setItem(
                                    "queryclaimid",
                                    this.props.show2
                                  );
                                } else {
                                  sessionStorage.setItem(
                                    "queryclient",
                                    this.props.client
                                  );
                                  sessionStorage.setItem("queryLocation", "");
                                  sessionStorage.setItem(
                                    "querycategory",
                                    "Recently Worked"
                                  );
                                  sessionStorage.setItem(
                                    "subclaimname",
                                    "Recently Worked"
                                  );
                                  sessionStorage.setItem(
                                    "searchid",
                                    this.props.search
                                  );
                                  sessionStorage.setItem(
                                    "queryclaimid",
                                    this.props.show2
                                  );
                                }
                              } else {
                                sessionStorage.setItem("queryclient", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Recently Worked"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "Recently Worked"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            }}
                          >
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {" "}
                                {this.state.adjustedtotalcount}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                $ {this.state.adjustedtotalamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      RECENTLY WORKED
                     </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={8}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "querycategory",
                                "Recently Worked"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "Recently Worked"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Recently Worked"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "Recently Worked"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "querycategory",
                              "Recently Worked"
                            );
                            sessionStorage.setItem(
                              "subclaimname",
                              "Recently Worked"
                            );
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        <Col
                          className="claim"
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Recently Worked"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "Recently Worked"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Recently Worked"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "Recently Worked"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Recently Worked"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "Recently Worked"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {" "}
                              {this.state.adjustedtotalcount}
                            </div>
                          </span>
                        </Col>
                        <Col className="total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.adjustedtotalamount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            )
          }


          {
            // Partially Paid card
            this.props.search === "PartiallyPaid" ? (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "400" }}>
                      PARTIALLY&nbsp;PAID
                     </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={10}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                      <Link
                        to={{
                          pathname: "/claim/sub-category-details",
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Row
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Partially Paid"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "partiallypaid"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Partially Paid"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "partiallypaid"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Partially Paid"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "partiallypaid"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          <Col
                            className="claim"
                            onClick={() => {
                              if (this.props.client) {
                                if (this.props.Location) {
                                  sessionStorage.setItem(
                                    "queryclient",
                                    this.props.client
                                  );
                                  sessionStorage.setItem(
                                    "queryLocation",
                                    this.props.Location
                                  );
                                  sessionStorage.setItem(
                                    "querycategory",
                                    "Partially Paid"
                                  );
                                  sessionStorage.setItem(
                                    "subclaimname",
                                    "partiallypaid"
                                  );
                                  sessionStorage.setItem(
                                    "searchid",
                                    this.props.search
                                  );
                                  sessionStorage.setItem(
                                    "queryclaimid",
                                    this.props.show2
                                  );
                                } else {
                                  sessionStorage.setItem(
                                    "queryclient",
                                    this.props.client
                                  );
                                  sessionStorage.setItem("queryLocation", "");
                                  sessionStorage.setItem(
                                    "querycategory",
                                    "Partially Paid"
                                  );
                                  sessionStorage.setItem(
                                    "subclaimname",
                                    "partiallypaid"
                                  );
                                  sessionStorage.setItem(
                                    "searchid",
                                    this.props.search
                                  );
                                  sessionStorage.setItem(
                                    "queryclaimid",
                                    this.props.show2
                                  );
                                }
                              } else {
                                sessionStorage.setItem("queryclient", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Partially Paid"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "partiallypaid"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            }}
                          >
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {" "}
                                {this.state.ppaidtotalcount}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                $ {this.state.ppaidtotalamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      PARTIALLY&nbsp;PAID
                     </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={10}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "querycategory",
                                "Partially Paid"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "partiallypaid"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Partially Paid"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "partiallypaid"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "querycategory",
                              "Partially Paid"
                            );
                            sessionStorage.setItem(
                              "subclaimname",
                              "partiallypaid"
                            );
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        <Col
                          className="claim"
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Partially Paid"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "partiallypaid"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Partially Paid"
                                );
                                sessionStorage.setItem(
                                  "subclaimname",
                                  "partiallypaid"
                                );
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Partially Paid"
                              );
                              sessionStorage.setItem(
                                "subclaimname",
                                "partiallypaid"
                              );
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {" "}
                              {this.state.ppaidtotalcount}
                            </div>
                          </span>
                        </Col>
                        <Col className="total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.ppaidtotalamount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            )
          }

          {
            // NIS card
            this.props.search === "NIS" ? (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      NOT IN SYSTEM
                      </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={11}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
            
                    <div class="card-text">
                      <Link
                        to={{
                          pathname: "/claim/sub-category-details",
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Row
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Not In System"
                                );
                                sessionStorage.setItem("subclaimname", "nis");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Not In System"
                                );
                                sessionStorage.setItem("subclaimname", "nis");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Not In System"
                              );
                              sessionStorage.setItem("subclaimname", "nis");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          <Col
                            className="claim"
                            onClick={() => {
                              if (this.props.client) {
                                if (this.props.Location) {
                                  sessionStorage.setItem(
                                    "queryclient",
                                    this.props.client
                                  );
                                  sessionStorage.setItem(
                                    "queryLocation",
                                    this.props.Location
                                  );
                                  sessionStorage.setItem(
                                    "querycategory",
                                    "Not In System"
                                  );
                                  sessionStorage.setItem("subclaimname", "nis");
                                  sessionStorage.setItem(
                                    "searchid",
                                    this.props.search
                                  );
                                  sessionStorage.setItem(
                                    "queryclaimid",
                                    this.props.show2
                                  );
                                } else {
                                  sessionStorage.setItem(
                                    "queryclient",
                                    this.props.client
                                  );
                                  sessionStorage.setItem("queryLocation", "");
                                  sessionStorage.setItem(
                                    "querycategory",
                                    "Not In System"
                                  );
                                  sessionStorage.setItem("subclaimname", "nis");
                                  sessionStorage.setItem(
                                    "searchid",
                                    this.props.search
                                  );
                                  sessionStorage.setItem(
                                    "queryclaimid",
                                    this.props.show2
                                  );
                                }
                              } else {
                                sessionStorage.setItem("queryclient", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Not In System"
                                );
                                sessionStorage.setItem("subclaimname", "nis");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            }}
                          >
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {" "}
                                {this.state.NIStotalcount}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                $ {this.state.NIStotalamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      NOT IN SYSTEM
                     </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={11}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "querycategory",
                                "Not In System"
                              );
                              sessionStorage.setItem("subclaimname", "nis");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Not In System"
                              );
                              sessionStorage.setItem("subclaimname", "nis");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "querycategory",
                              "Not In System"
                            );
                            sessionStorage.setItem("subclaimname", "nis");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        <Col
                          className="claim"
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Not In System"
                                );
                                sessionStorage.setItem("subclaimname", "nis");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Not In System"
                                );
                                sessionStorage.setItem("subclaimname", "nis");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Not In System"
                              );
                              sessionStorage.setItem("subclaimname", "nis");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {" "}
                              {this.state.NIStotalcount}
                            </div>
                          </span>
                        </Col>
                        <Col className="total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.NIStotalamount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            )
          }
          {
            //Error card
            this.props.search === "error" || this.props.search === "System Error"
            || this.props.search === "AvailityEmpty" || this.props.search === "Upload Error"
            ? (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      Error
                      </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={11}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/error",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "machinetype",
                                this.props.machinetype
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "querycategory",
                                "Error"
                              );
                              sessionStorage.setItem("subclaimname", "err");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "machinetype",
                                this.props.machinetype
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Error"
                              );
                              sessionStorage.setItem("subclaimname", "err");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "querycategory",
                              "Error"
                            );
                            sessionStorage.setItem(
                              "machinetype",
                              this.props.machinetype
                            );
                            sessionStorage.setItem("subclaimname", "err");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        <Col
                          className="claim"
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "machinetype",
                                  this.props.machinetype
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Error"
                                );
                                sessionStorage.setItem("subclaimname", "err");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "machinetype",
                                  this.props.machinetype
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Error"
                                );
                                sessionStorage.setItem("subclaimname", "err");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Error"
                              );
                              sessionStorage.setItem(
                                "machinetype",
                                this.props.machinetype
                              );
                              sessionStorage.setItem("subclaimname", "err");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {" "}
                              {this.state.totalerrcount}
                            </div>
                          </span>
                        </Col>
                        <Col className="total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.totalerramount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="col-md-3 mb-3">
                <div class="card">
                <div
                class="card-header bg-white"
              >
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"
                  }}
                >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      Error
                     </span>
                      <span class="d-inline-block">
                        {this.state.role === "Admin" && (
                          <ClaimAllocation
                            data={11}
                            client={this.props.client}
                            Location={this.props.Location}
                            startdate={this.props.startdate}
                            enddate={this.props.enddate}
                          ></ClaimAllocation>
                        )}
                      </span>
                    </div>
                    <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/error",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "querycategory",
                                "Error"
                              );
                              sessionStorage.setItem("subclaimname", "error");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Error"
                              );
                              sessionStorage.setItem("subclaimname", "error");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "querycategory",
                              "Error"
                            );
                            sessionStorage.setItem("subclaimname", "error");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        <Col
                          className="claim"
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Not In System"
                                );
                                sessionStorage.setItem("subclaimname", "nis");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Not In System"
                                );
                                sessionStorage.setItem("subclaimname", "nis");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Not In System"
                              );
                              sessionStorage.setItem("subclaimname", "nis");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {" "}
                              {this.state.totalerrcount}
                            </div>
                          </span>
                        </Col>
                        <Col className="total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.totalerramount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            )
          }

          
            {this.props.search === "Searches" &&
            this.props.machinetype !== "Availity" ? 
            //machine 1 searches
              (<div class="col-md-3 mb-3">
              <div class="card">
              <div
              class="card-header bg-white"
            >
            <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)", color:"white", padding:"3px"
                }}
              >
                    <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                      MACHINE 1 SEARCHES
                    </span>
                    <span class="d-inline-block">
                      {this.state.role === "Admin" && (
                        <ClaimAllocation
                          data={11}
                          client={this.props.client}
                          Location={this.props.Location}
                          startdate={this.props.startdate}
                          enddate={this.props.enddate}
                        ></ClaimAllocation>
                      )}
                    </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/error/machine1 searches",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Changehealthcare&Location=${this.props.Location}`
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "querycategory",
                                "Searches"
                              );
                              sessionStorage.setItem("subclaimname", "searches");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Searches"
                              );
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Changehealthcare`
                              );
                              sessionStorage.setItem("subclaimname", "searches");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "querycategory",
                              "Searches"
                            );
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Searches&Machine=Changehealthcare`
                            );
                            sessionStorage.setItem("subclaimname", "searches");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        <Col
                          className="claim"
                          onClick={() => {
                            if (this.props.client) {
                              if (this.props.Location) {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Changehealthcare$Location${this.props.Location}`
                                );
                                sessionStorage.setItem(
                                  "queryLocation",
                                  this.props.Location
                                );
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Searches"
                                );
                                sessionStorage.setItem("subclaimname", "searches");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              } else {
                                sessionStorage.setItem(
                                  "queryclient",
                                  this.props.client
                                );
                                sessionStorage.setItem("queryLocation", "");
                                sessionStorage.setItem(
                                  "querycategory",
                                  "Searches"
                                );
                                sessionStorage.setItem(
                                  "queryurl",
                                  `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Changehealthcare`
                                );
                                sessionStorage.setItem("subclaimname", "searches");
                                sessionStorage.setItem(
                                  "searchid",
                                  this.props.search
                                );
                                sessionStorage.setItem(
                                  "queryclaimid",
                                  this.props.show2
                                );
                              }
                            } else {
                              sessionStorage.setItem("queryclient", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Searches"
                              );
                              sessionStorage.setItem(
                                "queryurl",
                                `ClaimCategory=Searches&Machine=Changehealthcare`
                              );
                              sessionStorage.setItem("subclaimname", "searches");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          }}
                        >
                          Total Claims:
                          <span>
                            <div
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {" "}
                              {this.state.machine1searchescount}
                            </div>
                          </span>
                        </Col>
                        <Col className="total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.machine1searchesamount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            ) :
            (<div class="col-md-3 mb-3">
            <div class="card">
            <div
            class="card-header bg-white"
          >
          <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"
              }}
            >
                  <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                    MACHINE 1 SEARCHES
                  </span>
                  <span class="d-inline-block">
                    {this.state.role === "Admin" && (
                      <ClaimAllocation
                        data={11}
                        client={this.props.client}
                        Location={this.props.Location}
                        startdate={this.props.startdate}
                        enddate={this.props.enddate}
                      ></ClaimAllocation>
                    )}
                  </span>
                </div>
                <div class="card-text">
                <Link
                  to={{
                    pathname: "/error/machine1 searches",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row
                    onClick={() => {
                      if (this.props.client) {
                        if (this.props.Location) {
                          sessionStorage.setItem(
                            "queryclient",
                            this.props.client
                          );
                          sessionStorage.setItem(
                            "queryurl",
                            `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Changehealthcare&Location=${this.props.Location}`
                          );
                          sessionStorage.setItem(
                            "queryLocation",
                            this.props.Location
                          );
                          sessionStorage.setItem(
                            "querycategory",
                            "Searches"
                          );
                          sessionStorage.setItem("subclaimname", "searches");
                          sessionStorage.setItem(
                            "searchid",
                            this.props.search
                          );
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.props.show2
                          );
                        } else {
                          sessionStorage.setItem(
                            "queryclient",
                            this.props.client
                          );
                          
                          sessionStorage.setItem("queryLocation", "");
                          sessionStorage.setItem(
                            "querycategory",
                            "Searches"
                          );
                          sessionStorage.setItem(
                            "queryurl",
                            `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Changehealthcare`
                          );
                          sessionStorage.setItem("subclaimname", "searches");
                          sessionStorage.setItem(
                            "searchid",
                            this.props.search
                          );
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.props.show2
                          );
                        }
                      } else {
                        sessionStorage.setItem("queryclient", "");
                        sessionStorage.setItem(
                          "querycategory",
                          "Searches"
                        );
                        sessionStorage.setItem(
                          "queryurl",
                          `ClaimCategory=Searches&Machine=Changehealthcare`
                        );
                        sessionStorage.setItem("subclaimname", "searches");
                        sessionStorage.setItem(
                          "searchid",
                          this.props.search
                        );
                        sessionStorage.setItem(
                          "queryclaimid",
                          this.props.show2
                        );
                      }
                    }}
                  >
                    <Col
                      className="claim"
                      onClick={() => {
                        if (this.props.client) {
                          if (this.props.Location) {
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Changehealthcare$Location${this.props.Location}`
                            );
                            sessionStorage.setItem(
                              "queryLocation",
                              this.props.Location
                            );
                            sessionStorage.setItem(
                              "querycategory",
                              "Searches"
                            );
                            sessionStorage.setItem("subclaimname", "searches");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem("queryLocation", "");
                            sessionStorage.setItem(
                              "querycategory",
                              "Searches"
                            );
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Changehealthcare`
                            );
                            sessionStorage.setItem("subclaimname", "searches");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        } else {
                          sessionStorage.setItem("queryclient", "");
                          sessionStorage.setItem(
                            "querycategory",
                            "Searches"
                          );
                          sessionStorage.setItem(
                            "queryurl",
                            `ClaimCategory=Searches&Machine=Changehealthcare`
                          );
                          sessionStorage.setItem("subclaimname", "searches");
                          sessionStorage.setItem(
                            "searchid",
                            this.props.search
                          );
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.props.show2
                          );
                        }
                      }}
                    >
                      Total Claims:
                      <span>
                        <div
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "13px",
                          }}
                        >
                          {" "}
                          {this.state.machine1searchescount}
                        </div>
                      </span>
                    </Col>
                    <Col className="total">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "13px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.machine1searchesamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
              </div>
            </div>
          </div>)
        }
          {
            this.props.search === "Searches" &&
            this.props.machinetype === "Availity" ?
            //machine 2 searches (AVaility)
        (    <div class="col-md-3 mb-3">
            <div class="card">
            <div
            class="card-header bg-white"
          >
          <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)", color:"white", padding:"3px"
              }}
            >
                  <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                    MACHINE 2 SEARCHES
                  </span>
                  <span class="d-inline-block">
                    {this.state.role === "Admin" && (
                      <ClaimAllocation
                        data={11}
                        client={this.props.client}
                        Location={this.props.Location}
                        startdate={this.props.startdate}
                        enddate={this.props.enddate}
                      ></ClaimAllocation>
                    )}
                  </span>
                </div>
         
                <div class="card-text">
                  <Link
                    to={{
                      pathname: "/error/machine2 Searches",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Row
                      onClick={() => {
                        if (this.props.client) {
                          if (this.props.Location) {
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Availity&Location=${this.props.Location}`
                            );
                            sessionStorage.setItem(
                              "queryLocation",
                              this.props.Location
                            );
                            sessionStorage.setItem(
                              "querycategory",
                              "Avality Searches"
                            );
                            sessionStorage.setItem("subclaimname", "avalitysearches");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem("queryLocation", "");
                            sessionStorage.setItem(
                              "querycategory",
                              "Avality Searches"
                            );
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Availity`
                            );
                            sessionStorage.setItem("subclaimname", "avalitysearches");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        } else {
                          sessionStorage.setItem("queryclient", "");
                          sessionStorage.setItem(
                            "querycategory",
                            "Avality Searches"
                          );
                          sessionStorage.setItem(
                            "queryurl",
                            `ClaimCategory=Searches&Machine=Availity&Location=${this.props.Location}`
                          );
                          sessionStorage.setItem("subclaimname", "avalitysearches");
                          sessionStorage.setItem(
                            "searchid",
                            this.props.search
                          );
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.props.show2
                          );
                        }
                      }}
                    >
                      <Col
                        className="claim"
                        onClick={() => {
                          if (this.props.client) {
                            if (this.props.Location) {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Availity&Location=${this.props.Location}`
                            );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.props.Location
                              );
                              sessionStorage.setItem(
                                "querycategory",
                                "Avality Searches"
                              );
                              sessionStorage.setItem("subclaimname", "avalitysearches");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryclient",
                                this.props.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "Avality Searches"
                              );
                              sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Availity`
                            );
                              sessionStorage.setItem("subclaimname", "avalitysearches");
                              sessionStorage.setItem(
                                "searchid",
                                this.props.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.props.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "querycategory",
                              "Avality Searches"
                            );
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=Searches&Machine=Availity`
                            );
                            sessionStorage.setItem("subclaimname", "avalitysearches");
                            sessionStorage.setItem(
                              "searchid",
                              this.props.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.props.show2
                            );
                          }
                        }}
                      >
                        Total Claims:
                        <span>
                          <div
                            className="to"
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "13px",
                            }}
                          >
                            {" "}
                            {this.state.searchesacount}
                          </div>
                        </span>
                      </Col>
                      <Col className="total">
                        Grand Total:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontSize: "13px",
                              fontWeight: "600",
                            }}
                          >
                            $ {this.state.searchesaamount}
                          </div>
                        </span>
                      </Col>
                    </Row>
                  </Link>
                </div>
              </div>
            </div>
          </div>) :
          (    <div class="col-md-3 mb-3">
          <div class="card">
          <div
          class="card-header bg-white"
        >
        <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"
            }}
          >
                <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                  MACHINE 2 SEARCHES
                </span>
                <span class="d-inline-block">
                  {this.state.role === "Admin" && (
                    <ClaimAllocation
                      data={11}
                      client={this.props.client}
                      Location={this.props.Location}
                      startdate={this.props.startdate}
                      enddate={this.props.enddate}
                    ></ClaimAllocation>
                  )}
                </span>
              </div>
              <div class="card-text">
              <Link
                to={{
                  pathname: "/error/machine2 Searches",
                }}
                style={{ textDecoration: "none" }}
              >
                <Row
                  onClick={() => {
                    if (this.props.client) {
                      if (this.props.Location) {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryurl",
                          `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Availity&Location=${this.props.Location}`
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                        sessionStorage.setItem(
                          "querycategory",
                          "Avality Searches"
                        );
                        sessionStorage.setItem("subclaimname", "avalitysearches");
                        sessionStorage.setItem(
                          "searchid",
                          this.props.search
                        );
                        sessionStorage.setItem(
                          "queryclaimid",
                          this.props.show2
                        );
                      } else {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem("queryLocation", "");
                        sessionStorage.setItem(
                          "querycategory",
                          "Avality Searches"
                        );
                        sessionStorage.setItem(
                          "queryurl",
                          `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Availity`
                        );
                        sessionStorage.setItem("subclaimname", "avalitysearches");
                        sessionStorage.setItem(
                          "searchid",
                          this.props.search
                        );
                        sessionStorage.setItem(
                          "queryclaimid",
                          this.props.show2
                        );
                      }
                    } else {
                      sessionStorage.setItem("queryclient", "");
                      sessionStorage.setItem(
                        "querycategory",
                        "Avality Searches"
                      );
                      sessionStorage.setItem(
                        "queryurl",
                        `ClaimCategory=Searches&Machine=Availity&Location=${this.props.Location}`
                      );
                      sessionStorage.setItem("subclaimname", "avalitysearches");
                      sessionStorage.setItem(
                        "searchid",
                        this.props.search
                      );
                      sessionStorage.setItem(
                        "queryclaimid",
                        this.props.show2
                      );
                    }
                  }}
                >
                  <Col
                    className="claim"
                    onClick={() => {
                      if (this.props.client) {
                        if (this.props.Location) {
                          sessionStorage.setItem(
                            "queryclient",
                            this.props.client
                          );
                          sessionStorage.setItem(
                          "queryurl",
                          `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Availity&Location=${this.props.Location}`
                        );
                          sessionStorage.setItem(
                            "queryLocation",
                            this.props.Location
                          );
                          sessionStorage.setItem(
                            "querycategory",
                            "Avality Searches"
                          );
                          sessionStorage.setItem("subclaimname", "avalitysearches");
                          sessionStorage.setItem(
                            "searchid",
                            this.props.search
                          );
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.props.show2
                          );
                        } else {
                          sessionStorage.setItem(
                            "queryclient",
                            this.props.client
                          );
                          sessionStorage.setItem("queryLocation", "");
                          sessionStorage.setItem(
                            "querycategory",
                            "Avality Searches"
                          );
                          sessionStorage.setItem(
                          "queryurl",
                          `ClaimCategory=Searches&clientId=${this.props.client}&Machine=Availity`
                        );
                          sessionStorage.setItem("subclaimname", "avalitysearches");
                          sessionStorage.setItem(
                            "searchid",
                            this.props.search
                          );
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.props.show2
                          );
                        }
                      } else {
                        sessionStorage.setItem("queryclient", "");
                        sessionStorage.setItem(
                          "querycategory",
                          "Avality Searches"
                        );
                        sessionStorage.setItem(
                          "queryurl",
                          `ClaimCategory=Searches&Machine=Availity`
                        );
                        sessionStorage.setItem("subclaimname", "avalitysearches");
                        sessionStorage.setItem(
                          "searchid",
                          this.props.search
                        );
                        sessionStorage.setItem(
                          "queryclaimid",
                          this.props.show2
                        );
                      }
                    }}
                  >
                    Total Claims:
                    <span>
                      <div
                        className="to"
                        style={{
                          color: "black",
                          fontWeight: "600",
                          fontSize: "13px",
                        }}
                      >
                        {" "}
                        {this.state.searchesacount}
                      </div>
                    </span>
                  </Col>
                  <Col className="total">
                    Grand Total:
                    <span>
                      {" "}
                      <div
                        style={{
                          color: "black",
                          fontSize: "13px",
                          fontWeight: "600",
                        }}
                      >
                        $ {this.state.searchesaamount}
                      </div>
                    </span>
                  </Col>
                </Row>
              </Link>
            </div>
            </div>
          </div>
        </div>) 
          }
        </div>
      </div>
    );
  }
}

export default ClaimBuckets;
