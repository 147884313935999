import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import { Button, Input, Row, Col, Label, FormGroup } from "reactstrap";
import { Modal, Form, Table } from "react-bootstrap";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Tabs, { Tab } from "react-best-tabs";
import { ip} from "../../../Api";
import { VscChecklist } from "react-icons/vsc";
import axios from "axios";
import "../../../Styles/viewclaimtable.css";
import moment from "moment";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";
import wheatlogo from "../../../Images/shinechamp.jpg";
import warning from "../../../Images/error.png";
class ViewClaim extends Component {
  constructor(props) {
    super(props);
    // Extracting timerValue, claimID, and modulename from props
    const modulename = props.location.state?.modulename || sessionStorage.getItem("Module");
    const initialTimerValue = props.location.state?.timerValue || 0;
    const claimID = props.location.state?.claimID || sessionStorage.getItem("ClaimID");
    this.state = {
      Timermodal: false,
      isLoading: false,
      startTime: new Date(Date.now() - initialTimerValue * 1000), // adjust start time based on passed timer value
      currentTime: new Date(),
      endTime: null,
      elapsedTime: 0,
      timerValue: initialTimerValue,
      ClaimID: claimID,
      ChartID: sessionStorage.getItem("ChartID"),
      clientId: sessionStorage.getItem("queryclient"),
      clientid: sessionStorage.getItem("clientid"),
      MRN: sessionStorage.getItem("MRN"),
      Patient: sessionStorage.getItem("Patient"),
      Pripayer: sessionStorage.getItem("Pripayer"),
      DateOfService: sessionStorage.getItem("DateOfService"),
      ClaimDate: sessionStorage.getItem("ClaimDate"),
      PatientDOB: sessionStorage.getItem("PatientDOB"),
      AllocatedTo: sessionStorage.getItem("AllocatedTo"),
      cpts: sessionStorage.getItem("cpt"),
      historydata: [],
      viewclosedcpt: sessionStorage.getItem("pre-toggle") === "true",
      cptdata: [],
      Actiondata: [],
      show: false,
      cptcodearray: [],
      loadingModal: false,
      cptmodal: false,
      updateclosed: "",
      updateTotalBilledAmount: "",
      categoryerr: "",
      subcategoryerr: "",
      overallstatusErr: "",
      notesErr: "",
      submitted__notes__err: "",
      actioncodeerr: "",
      actionstatuscodeerr: "",
      showCptpopup:false,
      username:
        sessionStorage.getItem("firstname") +
        " " +
        sessionStorage.getItem("lastname"),
      redirecturl: "",
      recentlyworkedcpt: "",
      statusErr: "",
      days15: false,
      dayss15: false,
      dayss30: false,
      dayss45: false,
      recent: "",
      submit: "",
      closedal: "",
      days30: false,
      takecptmodal: false,
      days45: false,
      customdate: false,
      customdate1: false,
      addNotes: "",
      modulename: modulename,
      Updateclaimunder: "",
      statuslist: [
        {
          name: "Acknowledgement",
          category: "Acknowledgement",
          subcategory: "select",
        },
        {
          name: "Data Reporting Acknowledgements",
          category: "Data Reporting Acknowledgements",
          subcategory: "select",
        },
        { name: "Pending", category: "pending", subcategory: "select" },
        { name: "Finalized", category: "Finalized", subcategory: "select" },
        { name: "Request", category: "Request", subcategory: "select" },
        { name: "error", category: "error", subcategory: "select" },
        { name: "searches", category: "Searches", subcategory: "select" },
        { name: "paid", category: "Finalized", subcategory: "select" },
        { name: "denied", subcategory: "select" },
        { name: "NIS", category: "NIS", subcategory: "NIS" },
        {
          name: "PartiallyPaid",
          category: "PartiallyPaid",
          subcategory: "PartiallyPaid",
        },

        {
          name: "Recently Worked",
          category: "Recently Worked",
          subcategory: "Recently Worked",
        },
        {
          name: "System error",
          category: "System error",
          subcategory: "System error",
        },

        {
          name: "Closed/Already Paid",
          category: "Closed/Already Paid",
          subcategory: "Closed/Already Paid",
        },
        {
          name: "Recently Submitted",
          category: "Recently Submitted",
          subcategory: "Recently Submitted",
        },
      ],
      modal: false,
      action: "",
      ActionStatuscode: "",
      overallStatusActions: "",
      Notes: "",
      actioncodelist: [],
      actioncode: "",
      statuscode: "",
      statuscodelist: [],
      postCommentsErr: "",
      postEventdateErr: "",
      activeTab: "tab1", 
      dataforupdate: null,
      selectedIds: [],
      takeonaction: false,
      claimdata: [],
      CheckNo: "",
      cptdata1: [],
      mandatory: 0,
      totalamt: "",
      updateAge: "",
      updateIcdCode: "",
      updatePayerCategory: "",
      updatePayerId: "",
      updateArClass: "",
      updatePolicyNo: "",
      changeCPTcode: "",
      updateDOS: "",
      updatePripayer: "",
      updateClaimDate: "",
      updatePhysician: "",
      updatePatientDOB: "",
      updateLastName: "",
      updateFirstName: "",
      updateMRN: "",
      updateChartId: "",
      updateClaimID: "",
      updateAgeGroup: "",
      updateTotalBillAmt: "",
      updateTotalBalAmt: "",
      updateTotallPaidAmt: "",
      updateClaimStatus: "",
      updateOverAllClaimStatus: "",
      OverallClaimStatus: "",
      updateGender: "",
      updateNpi: "",
      updateServiceProvider: "",
      updateServiceProviderType: "",
      updateTaxID: "",
      updatePripayerContact: "",
      updateControlNo: "",
      updatePayerIdentification: "",
      updateTouch: "",
      updateTfl: "",
      updateid: "",
      updatetype: "",
      updateicd: "",
      updateprovider: "",
      updateunits: "",
      offset: 0,
      updatePartnerId: "",
      updateCheckIssueDate: "",
      updateEndDOS: "",
      updateBeginingDOS: "",
      updateBillingProviderType: "",
      updateFollowUpdate: "",
      updateCreatedAT: "",
      updateCPTS: "",
      closedInsurancePayment: "",
      closedPatientResponsibility: "",
      closedinsuranceadjustment: "",
      closedpaymentmode: "",
      updateClaimCategory: "",
      updateClaimSubCategory: "",
      updateSubmittedAmount: "",
      cptvaluearray: [],
      updateClaimCategoryActions: "",
      updateClaimStatusActions: "",
      updateClaimSubCategoryActions: "",
      updateStatusCategoryCodeActions: "",
      updateNotesActions: "",
      postUserId: sessionStorage.getItem("user_id"),
      postClaimId: "",
      postCheckNo: "",
      postNextdate: "",
      recentworked: "",
      postModule: "ViewClaims",
      postComments: "",
      postPayertype: "",
      postEventdate: "",
      postStatus: "",
      updatemode: "",
      updatesource: "",
      updateactioncodeActions: "",
      updateActionCodeStatusActions: "",
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      anchorEl: null,
      loading: true,
      mandatory1: 0,
      mandatory2: 0,
      selectedcpt: "",
      selectedcptaction: [],
      PatientFirstName: "",
      count: 0,
      PatientLastName: "",
      Arfollowdata: [],
      worked__notes__err: "",
      searcharfollowdata: [],
      perPage: 10,
      worked__notes: "",
      currentpage: 0,
      submitted__notes: "",
      touch: "",
      cptInactive: [],
      searchbycptvalue: "",
      searched: false,
      cptNotesErr: "",
      cptStatusErr: "",
      cptStatusCatCodeErr: "",
      cptStatusCodeErr: "",
      cptSubcatErr: "",
      addIcd: "",
      addProvider: "",
      addTotoalBalancedAmount: "",
      units: "",
      addDos: "",
      change_cheque: "",
      addStatus: "Paid",
      addsubmittedUnits: "",
      modeofpayment: ["Check", "EFT", "VCC"],
      //all claim categories & their subcategories
      acksubcategory: [
        { name: "Forwarded", displayName: "Forwarded", code: "A0" },
        { name: "Receipt", displayName: "Receipt", code: "A1" },
        {
          name: "Accepted",
          displayName: "Acceptance into Adjudicated System",
          code: "A2",
        },
        {
          name: "Rejected",
          displayName: "Returned as Unprocessable Claim",
          code: "A3",
        },
        { name: "Claim Not found", displayName: "Not Found", code: "A4" },
        { name: "Split Claim", displayName: "Split Claim", code: "A5" },
        {
          name: "Rejected",
          displayName: "Rejected for Missing Information",
          code: "A6",
        },
        {
          name: "Rejected",
          displayName: "Rejected for Invalid Information",
          code: "A7",
        },
        {
          name: "Rejected",
          displayName: "Rejected for Relational Field in Error",
          code: "A8",
        },
      ],
      datarepacksubcategory: [
        { name: "Receipt", displayName: "Receipt", code: "DR01" },
        {
          name: "Acceptance",
          displayName: "Acceptance into the data reporting",
          code: "DR02",
        },
        {
          name: "Returned",
          displayName: "Returned as unprocessable claim",
          code: "DR03",
        },
        { name: "Not found", displayName: "Not Found", code: "DR04" },
        {
          name: "Rejected",
          displayName: "Rejected for Missing Information",
          code: "DR05",
        },
        {
          name: "Rejected",
          displayName: "Rejected for Invalid Information",
          code: "DR06",
        },
        {
          name: "Rejected",
          displayName: "Rejected for Relational Field in Error",
          code: "DR07",
        },
        { name: "Warning", displayName: "Warning", code: "DR08" },
      ],
      pendingsubcategory: [
        { name: "Pended claims", displayName: "Details", code: "P0" },
        { name: "In process", displayName: "In Process", code: "P1" },
        { name: "Payer review", displayName: "Payer Review", code: "P2" },
        {
          name: "Pended claims",
          displayName: "Provider Requested Information",
          code: "P3",
        },
        {
          name: "Pended claims",
          displayName: "Patient Requested Information",
          code: "P4",
        },
        { name: "Hold", displayName: "Payer Administrative", code: "P5" },
      ],
      finalizedsubcategory: [
        { name: "Claim inproces", displayName: "The Claim", code: "F0" },
        { name: "Calling", displayName: "Revised", code: "F3" },
        { name: "Forwarded", displayName: "Forwarded", code: "F3F" },
        { name: "Forwarded", displayName: "Not Forwarded", code: "F3N" },
        {
          name: "Claim inproces",
          displayName: "Adjudication Complete",
          code: "F4",
        },
      ],
      requestsubcategory: [
        {
          name: "Additional Information",
          displayName: "General Requests",
          code: "R0",
        },
        {
          name: "Additional Information",
          displayName: "Entity Requests",
          code: "R1",
        },
        { name: "Additional Information", displayName: "Claim", code: "R3" },
        {
          name: "Medical record",
          displayName: "Documental Requests",
          code: "R4",
        },
        {
          name: "Additional Information",
          displayName: "More Specific Detail",
          code: "R5",
        },
        {
          name: "Additional Information",
          displayName: "Regulatory Requirements",
          code: "R6",
        },
        {
          name: "Additional Information",
          displayName:
            "Confirm Care is Consistent with Health Plan Policy Coverage",
          code: "R7",
        },
        {
          name: "Additional Information",
          displayName:
            "Confirm Care is Consistent with Health Plan Policy Exceptions",
          code: "R8",
        },
        {
          name: "Medical record",
          displayName: "Determination of Medical Necessity",
          code: "R9",
        },
        {
          name: "Additional Information",
          displayName: "Support a Filed Grievance or Appeal",
          code: "R10",
        },
        {
          name: "Additional Information",
          displayName: "Pre-Payment Review of Claims",
          code: "R11",
        },
        {
          name: "Medical record",
          code: "R12",
          displayName:
            "Clarification or Justification of use for specified procedure code",
        },
        {
          name: "Additional Information",
          code: "R13",
          displayName: "Original documents submitted are not readable",
        },
        {
          name: "Additional Information",
          code: "R14",
          displayName: "Original documents are not what was requested",
        },
        {
          name: "Additional Information",
          code: "R15",
          displayName: "Workers Compensation Coverage Determination",
        },
        {
          name: "Additional Information",
          code: "R16",
          displayName: "Eligibility Determination",
        },
        {
          name: "Additional Information",
          code: "R17",
          displayName: "Replacement of a Prior Request",
        },
      ],
      errorsubcategory: [
        { name: "Calling", code: "E0", displayName: "Response not Possible" },
        { name: "Calling", code: "E1", displayName: "Response not Possible" },
        {
          name: "Calling",
          code: "E2",
          displayName: "Information holder is not responding",
        },
        { name: "Calling", code: "E3", displayName: "Correction Required" },
        {
          name: "Calling",
          code: "E4",
          displayName: "Trading parter agreement specific requirement not met",
        },
      ],
      searchessubcategory: [
        {
          name: "Calling",
          code: "D0",
          displayName: "Data Search unsuccessful",
        },
      ],
      paidsubcategory: [
        { name: "Copay", displayName: "Payment", code: "F1" },
        { name: "Coinsurance", displayName: "Payment", code: "F1" },
        { name: "Deductible", displayName: "Payment", code: "F1" },
        { name: "Claim paid", displayName: "Payment", code: "F1" },
      ],
      //denied categories
      deniedcategories: [
        "Calling/Web Portal",
        "Eligibility Issue",
        "Authorization",
        "TFL Issue",
        "Coding",
        "Provider",
        "Appeal",
        "Payment Posting",
        "Capitation",
        "Others",
      ],
      //denied subcategories
      callingsubcategory: [
        {
          name: "Filling Indicator invalid",
          displayName: "Filling Indicator invalid",
          code: "",
        },
        {
          name: "Claim form invalid",
          displayName: "Claim form invalid",
          code: "",
        },
        { name: "No Claim on file", displayName: "No Claim on file", code: "" },
        { name: "Non covered", displayName: "Non covered", code: "" },
        { name: "ICN# invalid", displayName: "ICN# invalid", code: "" },
        { name: "Webportal", displayName: "Webportal", code: "" },
        { name: "Duplicate", displayName: "Duplicate", code: "" },
        { name: "Cross over claim", displayName: "Cross over claim", code: "" },
        { name: "Others", displayName: "Others", code: "" },
        { name: "Claim inprocess", displayName: "Claim inprocess", code: "" },
        { name: "Pre Existing", displayName: "Pre Existing", code: "" },
        { name: "Claim Rejected", displayName: "Claim Rejected", code: "" },
        { name: "Voided claim", displayName: "Voided claim", code: "" },
        {
          name: "Information missing",
          displayName: "Information missing",
          code: "",
        },
        { name: "Premium issue", displayName: "Premium issue", code: "" },
        { name: "Spending Amount", displayName: "Spending Amount", code: "" },
        { name: "Credential issue", displayName: "Credential issue", code: "" },
      ],
      eligibilitysubcategory: [
        {
          name: "Benefit Exhausted",
          displayName: "Benefit Exhausted",
          code: "",
        },
        { name: "Coverage Termed", displayName: "Coverage Termed", code: "" },
        { name: "Subscriber issue", displayName: "Subscriber issue", code: "" },
        {
          name: "Policy Details missing/invalid",
          displayName: "Policy Details missing/invalid",
          code: "",
        },
        { name: "COB Issue", displayName: "COB Issue", code: "" },
        {
          name: "New Born coverage issue",
          displayName: "New Born coverage issue",
          code: "",
        },
        {
          name: "PCP missing/invalid",
          displayName: "PCP missing/invalid",
          code: "",
        },
        {
          name: "Covered by another Payer",
          displayName: "Covered by another Payer",
          code: "",
        },
        {
          name: "Benefit Exhausted",
          displayName: "Benefit Exhausted",
          code: "",
        },
        { name: "Hospice", displayName: "Hospice", code: "" },
        {
          name: "Patient information missing/invalid",
          displayName: "Patient information missing/invalid",
          code: "",
        },
      ],
      authorizationsubcategory: [
        {
          name: "Need Prior Authorization",
          displayName: "Need Prior Authorization",
          code: "",
        },
        { name: "Referral Auth", displayName: "Referral Auth", code: "" },
        { name: "Predetermination", displayName: "Predetermination", code: "" },
      ],
      tflsubcategory: [
        { name: "TFL Exceeded", displayName: "TFL Exceeded", code: "" },
      ],
      codingsubcategory: [
        { name: "Modifier Issue", displayName: "Modifier Issue", code: "" },
        { name: "CPT Issue", displayName: "CPT Issue", code: "" },
        {
          name: "Dates & Time Issue",
          displayName: "Dates & Time Issue",
          code: "",
        },
        { name: "NDC# issue", displayName: "NDC# issue", code: "" },
        { name: "Units issue", displayName: "Units issue", code: "" },
        { name: "DX code issue", displayName: "DX code Issue", code: "" },
        {
          name: "Place of Service Issue",
          displayName: "Place of Service Issue",
          code: "",
        },
        { name: "CLIA# Issue", displayName: "CLIA# Issue", code: "" },
        {
          name: "Inclusive/Bundled",
          displayName: "Inclusive/Bundled",
          code: "",
        },
        { name: "Type of service", displayName: "Type of service", code: "" },
      ],
      providersubcategory: [
        { name: "EDI issue", displayName: "EDI issue", code: "" },
        { name: "Credential issue", displayName: "Credential issue", code: "" },
        { name: "Tax id issue", displayName: "Tax id issue", code: "" },
        {
          name: "Provider ID issue",
          displayName: "Provider ID issue",
          code: "",
        },
        { name: "Provider license", displayName: "Provider license", code: "" },
        { name: "Taxonomy issue", displayName: "Taxonomy issue", code: "" },
        {
          name: "Rendering provider issue",
          displayName: "Rendering provider issue",
          code: "",
        },
      ],
      appealsubcategory: [
        {
          name: "Additional Information",
          displayName: "Additional Information",
          code: "",
        },
        { name: "Medical Record", displayName: "Medical Record", code: "" },
        {
          name: "Need itemized bill",
          displayName: "Need itemized bill",
          code: "",
        },
        {
          name: "Need invoice information",
          displayName: "Need invoice information",
          code: "",
        },
        {
          name: "Medical Necessity",
          displayName: "Medical Necessity",
          code: "",
        },
      ],
      paymentpostingsubcategory: [
        { name: "Claim paid", displayName: "Claim paid", code: "" },
        { name: "Deductible", displayName: "Deductible", code: "" },
        { name: "Coinsurance", displayName: "Coinsurance", code: "" },
        { name: "Copay", displayName: "Copay", code: "" },
      ],
      capitationsubcategory: [
        { name: "Capitation", displayName: "Capitation", code: "" },
      ],
      otherssubcategory: [{ name: "Others", displayName: "Others", code: "" }],
      currentpagesearch: 0,
    };
    // Set up an interval to update the current time every second
    this.intervalId = setInterval(() => {
      this.setState({
        currentTime: new Date(),
      });
    }, 1000);
    // Initialize the timer interval
    this.timerInterval = null;
  }

  handleClose = () => this.setState({ anchorEl: null });
  toggleTimer = () => {
    this.setState({
      Timermodal: !this.state.Timermodal,
    });
  };
  toggleaction = () => {
    this.setState({
      takeonaction: !this.state.takeonaction,
    });
    this.reset();
  };
  toggleAlert = () => {
    if(this.state.cptdata.length !== 0){
      this.setState({
        showCptpopup: false,
      })
    }else{
      this.setState({
        showCptpopup: true,
      })
    }
  }
  getActionCodes = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/actioncode/all?clientId=${this.state.clientid}`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ actioncodelist: res.data.actioncode });
      })
      .catch((err) => {});
  };
  searchbyCPT = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    axios
      .get(
        `${ip}/arfollowuplog/advancedmultiplefilter?CPTcode=${this.state.searchbycptvalue}&ClaimID=${this.state.ClaimID}&pageno=${this.state.currentpagesearch}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          pageCountSearch: Math.ceil(res.data.claimscount / this.state.perPage),
          searcharfollowdata: res.data.claims,
          searched: true,
        });
      })
      .catch((err) => {});
  };
  toggle = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };
  getStatusCodes = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/statuscode/all?clientId=${this.state.clientid}`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ statuscodelist: res.data.statuscode });
      })
      .catch((err) => {});
  };
  getclaims = async () => {
    //api call to get all user
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = await axios
      .get(`${ip}/claim/viewclaim?claimid=${this.state.ClaimID}&clientId=${this.state.clientid}`, {
        headers: headers,
      })
      .then((res) => {   
        const claim = res.data.Claim[0];
        const cpt = claim.cpt && claim.active.length > 0 ? claim.cpt[0] : {};
    
        this.setState({
            // viewclosedcpt: res.data.toggle.closedcpt,
            Updateclaimunder: claim.Claimunder,
            closedInsurancePayment: cpt.InsurancePayment || '',
            closedPatientResponsibility: cpt.PatientResponsibility || '',
            closedpaymentmode: cpt.PaymentMode || '',
            touch: claim.Touch,
            updateCreatedAT: claim.CreatedAt,
            updateCPTS: claim.CPTS,
            updateBillingProviderType: claim.BillingProviderType,
            updateEndDOS: claim.EndDOS,
            updateAge: claim.Age,
            updateIcdCode: claim.ICD10Code,
            updatePayerCategory: claim.Payercategory,
            updatePayerId: claim.PayerID,
            updateArClass: claim.ARClass,
            updatePolicyNo: claim.PrimaryPolicyNo,
            updateDOS: claim.DateOfService,
            updatePripayer: claim.Pripayer,
            updateClaimDate: claim.ClaimDate,
            updatePhysician: claim.Physician,
            updatePatientDOB: claim.PatientDOB,
            updateLastName: claim.PatientLastName,
            updateFirstName: claim.PatientFirstName,
            updateMRN: claim.patientAccountNumber,
            updateChartId: claim.ChartID,
            updateClaimID: claim.ClaimID,
            updateAgeGroup: claim.AgeGrp,
            updateTotalBillAmt: claim.TotalBilledAmount,
            updateTotalBalAmt: claim.TotalBalanceAmount,
            updateTotallPaidAmt: claim.TotalAmountPaid,
            updateClaimStatus: claim.ClaimStatus,
            updateOverAllClaimStatus: claim.OverallClaimStatus,
            updateGender: claim.Gender,
            updateNpi: claim.Npi,
            updateServiceProvider: claim.ServiceProvider,
            updateServiceProviderType: claim.ServiceProviderType,
            updateTaxID: claim.TaxID,
            updatePripayerContact: claim.Pripayerphone,
            updateControlNo: claim.controlNumber,
            updatePayerIdentification: claim.payerIdentification,
            updateTouch: claim.Touch,
            updateTfl: claim.Tfl,
            updatetype: claim.type,
            updateStatusCategoryCode: claim.statusCategoryCode,
            updateStatusCategoryCodeActions: claim.statusCategoryCode,
            updateStatusCode: claim.statusCode,
            updateStatusValue: claim.statusCodeValue,
            updateStatusCategoryValue: claim.statusCategoryCodeValue,
            updateAmountPaid: claim.amountPaid,
            updateCheckNumber: claim.checkNumber,
            updateeffectiveDate: claim.effectiveDate,
            updateTrackingNumber: claim.trackingNumber,
            updateid: claim._id,
            updateCheckIssueDate: claim.checkIssueDate,
            updateBeginingDOS: claim.BeginingDOS,
            updateFollowUpdate: claim.Followup_date,
            updateClaimSubCategory: claim.claimSubCategory,
            updateClaimCategory: claim.ClaimCategory,
            updateSubmittedAmount: claim.submittedAmount,
            updateTotalBilledAmount: claim.TotalBilledAmount,
            statuscode: claim.statusCode,
            action: claim.ActionCode,
            ActionStatuscode: claim.ActionStatuscode,
            Notes: claim.Notes,
            updateClaimStatusActions: claim.ClaimStatus,
            updateClaimCategoryActions: claim.ClaimCategory,
            updateClaimSubCategoryActions: claim.claimSubCategory,
            updateNotesActions: claim.Notes,
            worked__notes: cpt.cptNotes || '',
            submitted__notes: cpt.cptNotes || '',
            updateActionCodeStatusActions: claim.ActionStatuscode,
            updateactioncodeActions: claim.ActionCode,
            cptdata: claim.active,
            cptInactive: claim.inactive,
        });
    })
      .catch((err) => {});
  };
  Mandatoryupdate = async () => {
    if (this.state.mandatory < 1) {
      alert("Please edit the CPT");
      this.state.mandatory = this.state.mandatory + 1;
    }
    if (this.state.mandatory >= 1) {
    }
  };
  Mandatoryupdate1 = async () => {
    this.state.mandatory = this.state.mandatory + 1;
  };
  Mandatoryupdate2 = async () => {
    this.state.mandatory = this.state.mandatory - 1;
  };
  Mandatorysubmitted = async () => {
    if (this.state.mandatory1 < 1) {
      alert("Please edit the CPT");
      this.state.mandatory1 = this.state.mandatory1 + 1;
    }
    if (this.state.mandatory1 >= 1) {
    }
  };
  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  };
  Mandatorysubmitted1 = async () => {
    this.state.mandatory1 = this.state.mandatory1 + 1;
  };
  Mandatorysubmitted2 = async () => {
    this.state.mandatory1 = this.state.mandatory1 - 1;
  };
  Mandatoryclosed = async () => {
    if (this.state.mandatory2 < 1) {
      alert("Please edit the CPT");
      this.state.mandatory2 = this.state.mandatory2 + 1;
    }
    if (this.state.mandatory2 >= 1) {
    }
  };
  Mandatoryclosed1 = async () => {
    this.state.mandatory2 = this.state.mandatory2 + 1;
  };
  Mandatoryclosed2 = async () => {
    this.state.mandatory2 = this.state.mandatory2 - 1;
  };
  addCpt = async () => {
    const valid = this.addValidate();
    if (valid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      let date = new Date();
      const addCptdata = {
        DOS: this.state.updateDOS,
        Amount: this.state.addAmount,
        CPTCode: this.state.selectedcpt,
        ICD: this.state.addIcd,
        Provider: this.state.addProvider,
        Secondary_Resp: this.state.addSecondaryResp,
        submittedAmount: this.state.addTotoalBalancedAmount,
        amountPaid: this.state.addamountPaid,
        Date: this.state.addDate,
        submittedUnits: this.state.addsubmittedUnits,
        PaymentMode: this.state.addPaymentMode,
        InsuranceAdjustment: this.state.addInsuranceAdjustment,
        Source: this.state.addSource,
        InsurancePayment: this.state.addInsurancePayment,
        CoPayment: this.state.addCoPayment,
        check_no: this.state.addcheck_no,
        ClaimID: this.state.ClaimID,
        statusCodeValue: this.state.addstatusCodeValue,
        statusCode: this.state.addstatusCode,
        statusCategoryCode: this.state.addstatusCategoryCode,
        statusCategoryCodeValue: this.state.addstatusCategoryCodeValue,
        serviceIdQualifierCode: this.state.addserviceIdQualifierCode,
        serviceIdQualifier: this.state.addserviceIdQualifier,
        effectiveDate: this.state.addeffectiveDate,
        Source: this.state.addSource,
        SEC: this.state.addSEC,
        PRT: this.state.addPRT,
        clientId: parseInt(this.state.clientid),
        Charge: this.state.addCharge,
        // Claimunder: this.state.AllocatedTo,
        // Notes: this.state.addNotes,
        Notes: "Notes added",
        UserId: this.state.addUserId,
        Module: "addcpt",
        NextFollowupDate: moment(date).format("MM-DD-YYYY"),
        Payertype: this.state.addPayertype,
        OverallServiceLevelStatus: this.state.addStatus,
        Eventdate: this.state.addEventdate,
        CheckNo: this.state.addCheckNo,
        cptOperationTeamStatus: "Active",
      };
      await axios
        .post(`${ip}/claim/addcpt?claimid=${this.state.ClaimID}`, addCptdata, {
          headers: headers,
        })
        .then((res) => {
          alert("CPT Added Successfully");
          this.cpttoggle();
          this.getclaims();
          this.getClaimdata();
          this.getCPTdata();
          this.getHistorydata();

          this.setState({
            showCptpopup:false,
            addNextdate: "",
            addNextdateErr: "",
            days15: false,
            days30: false,
            days45: false,
            customdate: false,
            selectedcpt: "",
            addNotes: "",
          });
        })
        .catch((err) => {});
    }
  };
  addValidate = () => {
    let hasErr = true;
    let cpterr = "";
    // let notesErr = "";
    // let actioncodeerr = "";
    // let actionstatuscodeerr = "";
    // let addNextdateErr = "";
    // if (this.state.addNextdate === "") {
    //   addNextdateErr = "This field is required";
    //   this.setState({ addNextdateErr });
    //   hasErr = false;
    // }
    // if (this.state.addNotes === "") {
    //   notesErr = "This field is required";
    //   this.setState({ notesErr });
    //   hasErr = false;
    // }
    // if (this.state.addNotes === "") {
    //   notesErr = "This field is required";
    //   this.setState({ notesErr });
    //   hasErr = false;
    // }
    if (this.state.selectedcpt === "") {
      cpterr = "This field is required";
      this.setState({ cpterr });
      hasErr = false;
    }
    return hasErr;
  };
  cpttoggle = () => {
    const { cptmodal } = this.state;
    this.setState({ cptmodal: !cptmodal });
  };
  handlePageSearchClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpagesearch: selectedPage,
        offset: offset,
      },
      () => this.searchbyCPT()
    );
  };
  handleChange = (selected) => {
    if (selected && selected.length > 0) {
      const { name = "", category = "", subcategory = "" } = selected[0];
      this.setState((prevState) => ({
        updateClaimCategoryActions: category !== prevState.updateClaimCategoryActions ? category : prevState.updateClaimCategoryActions,
        updateClaimStatusActions: name !== prevState.updateClaimStatusActions ? name : prevState.updateClaimStatusActions,
        updateClaimSubCategoryActions: subcategory !== prevState.updateClaimSubCategoryActions ? subcategory : prevState.updateClaimSubCategoryActions,
        updateStatusCategoryCodeActions: "",
        updateActionCodeStatusActions: "",
        updateactioncodeActions: "",
        categoryerr: "",
        statusErr: "",
      }));
    } else {
      this.setState((prevState) => ({
        updateClaimCategoryActions: prevState.updateClaimCategoryActions ? "" : prevState.updateClaimCategoryActions,
        updateClaimStatusActions: prevState.updateClaimStatusActions ? "" : prevState.updateClaimStatusActions,
        updateClaimSubCategoryActions: prevState.updateClaimSubCategoryActions ? "" : prevState.updateClaimSubCategoryActions,
        updateStatusCategoryCodeActions: "",
        updateActionCodeStatusActions: "",
        updateactioncodeActions: "",
        categoryerr: "",
        statusErr: "",
      }));
    }
  };
  getClaimdata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    axios
      .get(
        `${ip}/arfollowuplog/arlog/claimlog?claimid=${this.state.ClaimID}&clientId=${this.state.clientid}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          historydata: res.data.arfollowuplog,
        });
      })
      .catch((err) => {});
  };
  getCPTdata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    axios
      .get(
        `${ip}/arfollowuplog/arlog/cptlog?claimid=${this.state.ClaimID}&clientId=${this.state.clientid}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          cptdata1: res.data.arfollowuplog,
        });
      })
      .catch((err) => {});
  };
  checkstatustoggle = () => {
    this.setState({ loadingModal: !this.state.loadingModal });
  };
  getHistorydata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    axios
      .get(
        `${ip}/arfollowuplog/arlog/claimhistory?claimid=${this.state.ClaimID}&clientId=${this.state.clientid}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const sorter = (a, b) => {
          var s1 = new Date(a.workedDate).toLocaleString(undefined, {
            timeZone: "Asia/Kolkata",
          });
          var s2 = new Date(b.workedDate).toLocaleString(undefined, {
            timeZone: "Asia/Kolkata",
          });
          return (
            new Date(s2) - new Date(s1) &&
            new Date(s2).getTime() - new Date(s1).getTime()
          );
        };
        const sort__value =  res.data.arfollowuplog.sort(sorter);
        const slice = sort__value.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          Actiondata: slice,
        });
      })
      .catch((err) => {});
  };
  datefilter = () => {
    this.setState({
      recentworked: moment().subtract(30, "days").format("YYYY-MM-DD"),
    });
  };
  updateActions = async () => {
    let headers;
    let value;
    // Convert startTime and endTime to the desired format (HH:mm)
    let startTime1 = moment.tz(this.state.startTime, "Asia/Kolkata").format();
    let endTime1 = moment(this.state.endTime).tz("Asia/Kolkata").format();
    if (this.state.updateClaimStatusActions === "Closed/Already Paid") {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      value = {
        NextFollowupDate: this.state.postEventdate.substring(0, 10),
        Module: "TakeAction",
        ClaimID: this.state.ClaimID,
        clientId: parseInt(this.state.clientid),
        ClaimCategory: this.state.updateClaimCategoryActions,
        claimSubCategory: this.state.updateClaimSubCategoryActions,
        ClaimStatus: this.state.updateClaimStatusActions,
        Actioncode: "Closed/Already Paid",
        Statuscode: "Closed/Already Paid",
        Notes: this.state.updateNotesActions,
        OverallServiceLevelStatus: this.state.overallStatusActions,
        statusCategoryCode: this.state.updateStatusCategoryCodeActions,
        Age: this.state.updateAge,
        AgeGrp: this.state.updateAgeGroup,
        Claimunder: this.state.username,
        Touch: this.state.touch,
        TotalBilledAmount: this.state.updateTotalBillAmt,
        TotalAmountPaid: this.state.updateTotallPaidAmt,
        statusCode: "-",
        cptStatus: "-",
        CPTcode: "-",
        preNotes: this.state.Notes,
        preActioncode: this.state.action,
        preStatuscode: this.state.ActionStatuscode,
        preClaimStatus: this.state.updateClaimStatus,
        preTotalBilledAmount: this.state.updateTotalBillAmt,
        preClaimunder: this.state.Updateclaimunder,
        startTime:startTime1,
        endTime:endTime1,
      };
    } else if (this.state.updateClaimStatusActions === "Recently Worked") {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      value = {
        NextFollowupDate: this.state.postEventdate.substring(0, 10),
        Module: "TakeAction",
        ClaimID: this.state.ClaimID,
        clientId: parseInt(this.state.clientid),
        ClaimCategory: this.state.updateClaimCategoryActions,
        claimSubCategory: this.state.updateClaimSubCategoryActions,
        ClaimStatus: this.state.updateClaimStatusActions,
        Actioncode: "Set For Follow-Up",
        Statuscode: "Recently Worked",
        Notes: this.state.updateNotesActions,
        OverallServiceLevelStatus: this.state.overallStatusActions,
        statusCategoryCode: this.state.updateStatusCategoryCodeActions,
        Age: this.state.updateAge,
        AgeGrp: this.state.updateAgeGroup,
        Claimunder: this.state.username,
        Touch: this.state.touch,
        TotalBilledAmount: this.state.updateTotalBillAmt,
        TotalAmountPaid: this.state.updateTotallPaidAmt,
        statusCode: "-",
        cptStatus: "-",
        CPTcode: "-",
        preNotes: this.state.Notes,
        preActioncode: this.state.action,
        preStatuscode: this.state.ActionStatuscode,
        preClaimStatus: this.state.updateClaimStatus,
        preTotalBilledAmount: this.state.updateTotalBillAmt,
        preClaimunder: this.state.Updateclaimunder,
        startTime: startTime1,
        endTime: endTime1,
      };
    } else if (this.state.updateClaimStatusActions === "Recently Submitted") {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      value = {
        NextFollowupDate: this.state.postEventdate.substring(0, 10),
        Module: "TakeAction",
        ClaimID: this.state.ClaimID,
        clientId: parseInt(this.state.clientid),
        ClaimCategory: this.state.updateClaimCategoryActions,
        claimSubCategory: this.state.updateClaimSubCategoryActions,
        ClaimStatus: this.state.updateClaimStatusActions,
        Actioncode: "Set For Follow-Up",
        Statuscode: "Recently Submitted",
        Notes: this.state.updateNotesActions,
        OverallServiceLevelStatus: this.state.overallStatusActions,
        statusCategoryCode: this.state.updateStatusCategoryCodeActions,
        Age: this.state.updateAge,
        AgeGrp: this.state.updateAgeGroup,
        Claimunder: this.state.username,
        Touch: this.state.touch,
        TotalBilledAmount: this.state.updateTotalBillAmt,
        TotalAmountPaid: this.state.updateTotallPaidAmt,
        statusCode: "-",
        cptStatus: "-",
        CPTcode: "-",
        preNotes: this.state.Notes,
        preActioncode: this.state.action,
        preStatuscode: this.state.ActionStatuscode,
        preClaimStatus: this.state.updateClaimStatus,
        preTotalBilledAmount: this.state.updateTotalBillAmt,
        preClaimunder: this.state.Updateclaimunder,
        startTime:startTime1,
        endTime:endTime1,
      };
    } else {
      const isValid = this.takeonactionvalidate();
      if (isValid) {
        headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.state.accesstoken}`,
        };
        value = {
          NextFollowupDate: this.state.postEventdate.substring(0, 10),
          Module: "TakeAction",
          ClaimID: this.state.ClaimID,
          clientId: parseInt(this.state.clientid),
          ClaimCategory: this.state.updateClaimCategoryActions,
          claimSubCategory: this.state.updateClaimSubCategoryActions,
          ClaimStatus: this.state.updateClaimStatusActions,
          Actioncode: this.state.updateactioncodeActions,
          Statuscode: this.state.updateActionCodeStatusActions,
          Notes: this.state.updateNotesActions,
          OverallServiceLevelStatus: this.state.overallStatusActions,
          statusCategoryCode: this.state.updateStatusCategoryCodeActions,
          Age: this.state.updateAge,
          AgeGrp: this.state.updateAgeGroup,
          Claimunder: this.state.username,
          Touch: this.state.touch,
          TotalBilledAmount: this.state.updateTotalBillAmt,
          TotalAmountPaid: this.state.updateTotallPaidAmt,
          statusCode: "-",
          cptStatus: "-",
          CPTcode: "-",
          preNotes: this.state.Notes,
          preActioncode: this.state.action,
          preStatuscode: this.state.ActionStatuscode,
          preClaimStatus: this.state.updateClaimStatus,
          preTotalBilledAmount: this.state.updateTotalBillAmt,
          preClaimunder: this.state.Updateclaimunder,
          startTime: startTime1,
          endTime: endTime1,
        };
      }
    }
    await axios
      .put(`${ip}/claim/takeaction/${this.state.updateid}`, value, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          takeonaction: false,
          Timermodal: true,
          count: 0,
          isLoading:false,
          // postEventdate: "",
          // overallStatusActions: "",
          days15: false,
          days30: false,
          days45: false,
          dayss15: false,
          dayss30: false,
          dayss45: false,
          customdate: false,
          customdate1: false,
          // updateActionCodeStatusActions:"",
          // updateactioncodeActions:""
        });
        this.getclaims();
        this.getClaimdata();
        this.getCPTdata();
        this.getHistorydata();
      })
      .catch((err) => {});
  };
  RerunApis = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      Machinetype: this.state.updateMachinetype,
      clientId: parseInt(this.state.clientid),
      trackingNumber: this.state.updateTrackingNumber,
      BillingOrganizationName: this.state.updateBillingOrganizationName,
      ServiceOrganizationName: this.state.updateServiceOrganizationName,
      EndDOS: this.state.updateEndDOS,
      BeginingDOS: this.state.updateBeginingDOS,
      Gender: this.state.updateGender,
      BillingProviderType: this.state.updateBillingProviderType,
      ServiceProviderType: this.state.updateServiceProviderType,
      Npi: this.state.updateNpi,
      ServiceProvider: this.state.updateServiceProvider,
      PrimaryPolicyNo: this.state.updatePolicyNo,
      TaxID: this.state.updateTaxID,
      tradingPartnerServiceId: this.state.updatePartnerId,
      controlNumber: this.state.updateControlNo,
      PayerID: this.state.updatePayerId,
      PatientDOB: this.state.PatientDOB,
      PatientLastName: this.state.updateLastName,
      PatientFirstName: this.state.updateFirstName,
    };
    axios
      .put(`${ip}/claim/autocheck?claimid=${this.state.ClaimID}`, value, {
        headers: headers,
      })
      .then((res) => {
        alert("Auto Claim check successfully completed");
        this.getclaims();
        this.checkstatustoggle();
      })
      .catch((err) => {});
  };
  takeonactionvalidate = () => {
    let hasErr = "true";

    if (this.state.updateClaimStatusActions === "Closed/Already Paid") {
      let closedal = "";
      let recentworkedErr = "";
      let overallstatusErr = "";
      let postEventdateErr = "";
      if (this.state.overallStatusActions === "") {
        overallstatusErr = "This field is required";
        this.setState({ overallstatusErr });
        hasErr = false;
      }
      if (
        this.state.postEventdate === "" ||
        this.state.postEventdate === null
      ) {
        postEventdateErr = "This field is required";
        this.setState({ postEventdateErr });
        hasErr = false;
      }
      return hasErr;
    } else if (this.state.updateClaimStatusActions === "Recently Worked") {
      let overallstatusErr = "";
      let postEventdateErr = "";
      if (this.state.overallStatusActions === "") {
        overallstatusErr = "This field is required";
        this.setState({ overallstatusErr });
        hasErr = false;
      }
      if (
        this.state.postEventdate === "" ||
        this.state.postEventdate === null
      ) {
        postEventdateErr = "This field is required";
        this.setState({ postEventdateErr });
        hasErr = false;
      }
      return hasErr;
    } else if (this.state.updateClaimStatusActions === "Recently Submitted") {
      let overallstatusErr = "";
      let postEventdateErr = "";
      if (this.state.overallStatusActions === "") {
        overallstatusErr = "This field is required";
        this.setState({ overallstatusErr });
        hasErr = false;
      }
      if (
        this.state.postEventdate === "" ||
        this.state.postEventdate === null
      ) {
        postEventdateErr = "This field is required";
        this.setState({ postEventdateErr });
        hasErr = false;
      }
      return hasErr;
    } else {
      let overallstatusErr = "";
      let subcategoryerr = "";
      let categoryerr = "";
      let notesErr = "";
      let actioncodeerr = "";
      let actionstatuscodeerr = "";
      let statusErr = "";
      let postEventdateErr = "";
      if (
        this.state.updateClaimStatusActions === "" ||
        this.state.updateClaimStatusActions === null
      ) {
        statusErr = "This field is required";
        this.setState({ statusErr });
        hasErr = false;
      }
      if (
        this.state.postEventdate === "" ||
        this.state.postEventdate === null
      ) {
        postEventdateErr = "This field is required";
        this.setState({ postEventdateErr });
        hasErr = false;
      }
      if (
        this.state.updateactioncodeActions === "" ||
        this.state.updateactioncodeActions === null
      ) {
        actioncodeerr = "This field is required";
        this.setState({ actioncodeerr });
        hasErr = false;
      }
      if (
        this.state.updateActionCodeStatusActions === "" ||
        this.state.updateActionCodeStatusActions === null
      ) {
        actionstatuscodeerr = "This field is required";
        this.setState({ actionstatuscodeerr });
        hasErr = false;
      }
      if (
        this.state.updateClaimCategoryActions === "select" ||
        this.state.updateClaimCategoryActions === "" ||
        this.state.updateClaimCategoryActions === null
      ) {
        categoryerr = "This field is required";
        this.setState({ categoryerr });
        hasErr = false;
      }
      if (
        this.state.updateClaimSubCategoryActions === "select" ||
        this.state.updateClaimSubCategoryActions === "" ||
        this.state.updateClaimSubCategoryActions === null
      ) {
        subcategoryerr = "This field is required";
        this.setState({ subcategoryerr });
        hasErr = false;
      }
      if (
        this.state.updateNotesActions === "" ||
        this.state.updateNotesActions === null
      ) {
        notesErr = "This field is required";
        this.setState({ notesErr });
        hasErr = false;
      }

      if (this.state.overallStatusActions === "") {
        overallstatusErr = "This field is required";
        this.setState({ overallstatusErr });
        hasErr = false;
      }
      return hasErr;
    }
    return hasErr;
  };
  editCpt = async () => {
    // Only clear the timer when the validation passes
 clearInterval(this.timerInterval);
    // Ensure `endTime` is properly set before proceeding
    const endTimeValue = this.state.endTime || new Date(); // Use the existing endTime or set it to the current time
   const headers = {
     "Content-Type": "application/json",
     Authorization: `Bearer ${this.state.accesstoken}`,
   };
   var date = new Date();

   var dateformat = moment.tz(date, "Asia/Kolkata").format();
   // Convert startTime and endTime to the desired format (HH:mm)
 var startTime1 = moment.tz(this.state.startTime, "Asia/Kolkata").format();
 var endTime1 = moment(endTimeValue).tz("Asia/Kolkata").format();
   let cptidarray = this.state.cptdata.map((data, index) => {
     return data._id;
   });
   const value = {
     Module: "cptUpdate",
     clientId: this.state.clientid,
     id: cptidarray,
     ClaimStatus: this.state.updateClaimStatusActions,
     ClaimCategory: this.state.updateClaimCategoryActions,
     cptSubCategory: this.state.updateClaimSubCategoryActions,
     TotalBilledAmount: this.state.updateTotalBillAmt,
     statusCategoryCode: this.state.updateStatusCategoryCodeActions,
     Notes: this.state.updateNotesActions,
     NextFollowupDate: this.state.postEventdate.substring(0, 10),
     OverallServiceLevelStatus: this.state.overallStatusActions,
     ClaimID: this.state.ClaimID,
     Actioncode: this.state.updateactioncodeActions,
     Statuscode: this.state.updateActionCodeStatusActions,
     startTime: startTime1,
     endTime: endTime1,
     Claimunder: this.state.username
   };
   await axios
     .put(`${ip}/claim/updateCpt`, value, {
       headers: headers,
     })
     .then((res) => {
       this.setState(
         {
           count: this.state.count + 1,
         },
         () => {
           // this.getclaims();
           this.updateActions();
           setTimeout(() => {
             // Assuming the API call is successful
             this.setState({ endTime: new Date() }, () => {
               this.calculateTimeSpent();
             });
           }, 2000); // Simulated API response delay of 2 seconds
         }
       );
       this.getClaimdata();
       this.getCPTdata();
       this.getHistorydata();
       this.Mandatoryclosed1();
       this.Mandatorysubmitted1();
       this.Mandatoryupdate1();
     })
     .catch((err) => {});
};
  toggle_cpt = () => {
    this.setState({
      takecptmodal: !this.state.takecptmodal,
    });
  };
  componentDidMount() {
    this.initializeData();
    this.setRedirectUrl(this.state.modulename);
    this.timerInterval = setInterval(() => {
      this.setState({ currentTime: new Date() });
    }, 1000);
  }
  initializeData() {
    Promise.all([
      this.getclaims(),
      this.datefilter(),
    ]).catch((error) => console.error("Data initialization error:", error));
  }
  setRedirectUrl(modulename) {
    const moduleUrlMap = {
      "Key Indicators": "/allocation-buckets",
      "Denial Management": "/denial-management",
      "Claim Status Management": "/claim-management",
      "Web Claims": "/claims/Web",
      "IVR Claims": "/claims/IVR",
      "Manual Claims": "/claims/Manual",
      "Rejection": "/allocate/rejection",
      "Work Queue": "/actions",
      "UnAllocated": "/allocate/unallocated",
      "Allocated": "/allocate/allocated",
      "TFLExceed": "/TFLexceed",
      "TwoTouchClaim": "/TwoTouch-Claims",
      "ThreeTouchClaim": "/ThreeTouch-Claims",
      "OneTouchClaim": "/one-touch-claims",
      "NotTouchClaim": "/zero-touch-claims",
      "MoreThan180Days": "/more-than-180days-notfollow",
      "MoreThan90Days": "/more-than-90days-notfollow",
      "MoreThan60Days": "/more-than-60days-notfollow",
      "MoreThan30Days": "/more-than-30days-notfollow",
      "LowFollowUp": "/lowfollow-up",
      "LessThan180Days": "/less-than-180days-claims",
      "LessThan30Days": "/less-than-30days-NotFollow",
      "FastApproaching": "/Fast-Approaching",
      "FourOrMore": "/four-or-more-touch",
      "FourTouchClaims": "/four-touch-claims",
      "AgeBetween31to60Days": "/age-between-31-60-days",
      "AgeBetween61to90Days": "/age-between-61-90-days",
      "AgeBetween91to180Days": "/age-between-91-180-days",
      "AgeBetween181to365Days": "/age-between-181-365-days",
      "AgeGreaterThan365": "/agegreaterthan365days",
    };
  
    const redirectUrl = moduleUrlMap[modulename];
    if (redirectUrl) {
      this.setState({ redirecturl: redirectUrl });
    } else {
      console.warn(`No redirect URL mapped for modulename: ${modulename}`);
    }
  }
  componentWillUnmount() {
    // Clean up the interval on component unmount
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }
  handleUpdateButtonClick = () => {
    const isValid = this.takeonactionvalidate();
    if (isValid) {
        this.calculateTimeSpent(); // Call calculateTimeSpent only if validation passes
        this.editCpt(); // Proceed to call editCpt
        this.setState({ isLoading: true }); //manual click lock

    } else {
        // If validation fails, don't stop the timer or call editCpt
        console.log("Validation failed. Timer continues to run.");
    }
};
  //calculateTimeSpent function
  calculateTimeSpent() {
    const { startTime, endTime } = this.state;
    if (startTime && !endTime) {
        const endTimeValue = new Date();
        const timeSpent = endTimeValue - startTime;

        this.setState({ endTime: endTimeValue, elapsedTime: timeSpent });

        const formattedTime = this.formatMillisecondsToHHMMSS(timeSpent);
        console.log(`Time spent on screen: ${formattedTime}`);
    }
}
  formatMillisecondsToHHMMSS(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);
    const hh = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const mm = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const ss = (seconds % 60).toString().padStart(2, "0");
    return `${hh}:${mm}:${ss}`;
  }
  reset = () => {
    this.setState({
      postCommentsErr: "",
      postEventdateErr: "",
      notesErr: "",
      categoryerr: "",
      subcategoryerr: "",
    });
  };
  render() {
    const { activeTab } = this.state;
    const { elapsedTime } = this.state;
    const formattedTime = this.formatMillisecondsToHHMMSS(elapsedTime);
    const dropdownStyle = {
      width: "300px", 
    };
    return (
      <div className="align-row" style={{ overflow: "hidden" }}>
        <Newsidebar>
          <div>
            {this.state.count >= 1 ? (
              <p
                onClick={() =>
                  this.setState({ takeonaction: !this.state.takeonaction })
                }
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: "15px",
                  fontWeight: 500,
                }}
              >
                {this.state.modulename}
                <MdKeyboardArrowRight></MdKeyboardArrowRight>
                &nbsp;View Claim
              </p>
            ) : (
              <div style={{ color: "white" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <Link
                      to={this.state.redirecturl}
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontSize: "15px",
                        fontWeight: 500,
                      }}
                    >
                      {this.state.modulename}
                    </Link>
                    <MdKeyboardArrowRight />
                    View Claim
                  </div>

                  {/* Centered formatted time */}
                  <div
                    style={{
                      flexGrow: 1,
                      textAlign: "center",
                      fontSize: "26px",
                      marginLeft:"420px"
                    }}
                  >
                    {elapsedTime ? (
                      <span> {formattedTime}</span>
                    ) : (
                      <span>
                        {" "}
                        {this.state.currentTime && this.state.startTime
                          ? this.formatMillisecondsToHHMMSS(
                              this.state.currentTime - this.state.startTime
                            )
                          : "00:00:00"}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Newsidebar>
        <br></br>     
    
            <div style={{height:"100%", width:"97%"}}>
            <div>
        <Row style={{ alignItems: "center" }}>
          <Col xs={6}>
            <h5 style={{ textAlign: "left", fontWeight: "600" }}>
              Claim details
            </h5>
          </Col>
          <Col xs={6} style={{ textAlign: "right" }}>
            <div class="btn-group" role="group">
              <button
                type="button"
                class="btn"
                style={{ backgroundColor: "#89CFF0", color: "#fff" }}
                onClick={this.checkstatustoggle}
              >
                <VscChecklist />
              </button>
              <button
                type="button"
                class="btn"
                style={{ backgroundColor: "#041c3c", color: "#fff" }}
                onClick={() => {
                  this.toggle();
                  this.handleClose();
                  this.getClaimdata();
                  this.getCPTdata();
                  this.getHistorydata();
                }}
              >
                Ar Follow Up Log
              </button>
              {this.state.cptdata.length === 0 ?
                <button
                type="button"
                class="btn"
                style={{ backgroundColor: "#7DD657", color: "#fff" }}
                onClick={this.toggleAlert}
              >
                Take Action
              </button> :
              <button
              type="button"
              class="btn"
              style={{ backgroundColor: "#7DD657", color: "#fff" }}
              onClick={() => {
                this.toggleaction();
                this.getActionCodes();
                this.getStatusCodes();
              }}
            >
              Take Action
            </button>
              }
              <button
              type="button"
              class="btn"
              style={{ backgroundColor: "#041c3c", color: "#fff" }}
              onClick={() => {
                this.cpttoggle();
              }}
            >
              Add CPT
            </button>
              <button
                type="button"
                class="btn"
                style={{ backgroundColor: "#538392", color: "#fff", fontWeight:"500" }}
              >
                <p>
                  <span>Over All Touches</span>:
                  <span> {this.state.touch ? this.state.touch : 0}</span>
                </p>
              </button>
            </div>
          </Col>
        </Row>
           </div> 
           <div className="viewclaim_table1">
              <Table responsive="md" bordered>
                <thead>
                  <tr>
                    <th
                      colSpan={2}
                      style={{
                        backgroundColor: "#041c3c",
                        color: "white",
                        textAlign: "center",
                        width: "20px",
                      }}
                    >
                      Patient
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        backgroundColor: "#041c3c",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Payer
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        backgroundColor: "#041c3c",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Provider
                    </th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    textAlign: "center",
                  }}
                >
                  <tr className="datatablevalue">
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Claim ID
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateClaimID}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Pripayer
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                        whiteSpace: "normal",
                        textAlign: "center",
                      }}
                    >
                      {this.state.updatePripayer}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Physician
                    </td>

                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updatePhysician}
                    </td>
                  </tr>

                  <tr className="datatablevalue">
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      First Name
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {" "}
                      {this.state.updateFirstName}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Payer ID
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updatePayerId}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Tax ID
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateTaxID}
                    </td>
                  </tr>

                  <tr className="datatablevalue">
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Last Name
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateLastName}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Pripayer Contact
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updatePripayerContact}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      NPI
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateNpi}
                    </td>
                  </tr>

                  <tr className="datatablevalue">
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Account Number
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateMRN}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Payer Category
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updatePayerCategory}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Service Provider
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateServiceProvider}
                    </td>
                  </tr>

                  <tr className="datatablevalue">
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Primary Policy No
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updatePolicyNo}
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Service Provider Type
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateServiceProviderType}
                    </td>
                  </tr>

                  <tr className="datatablevalue">
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      DOB
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updatePatientDOB}
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Billing Provider type
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateBillingProviderType}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      DOS
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateDOS}
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                  </tr>
                  <tr className="datatablevalue">
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Gender
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateGender}
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                  </tr>
                </tbody>
              </Table>
              </div>
            </div>
          <br />
            <div style={{ height: "100%", width: "97%" }}>
              <div>
                <h5
                  style={{
                    textAlign: "left",
                    marginLeft: "2%",
                    fontWeight: "600",
                  }}
                >
                  CLAIM Status
                </h5>
              </div>
              <div className="viewclaim_table1">
                <Table className="table_container" responsive="md" bordered>
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Claim Status
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Claim SubCategory
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Claim Category
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Total Billed Amount
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        Total Paid Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          backgroundColor: "whitesmoke",
                          textAlign: "center",
                        }}
                      >
                        {this.state.updateClaimStatus}
                      </td>
                      <td
                        style={{
                          backgroundColor: "whitesmoke",
                          textAlign: "center",
                        }}
                      >
                        {this.state.updateClaimSubCategory}
                      </td>
                      <td
                        style={{
                          backgroundColor: "whitesmoke",
                          textAlign: "center",
                        }}
                      >
                        {this.state.updateClaimCategory}
                      </td>
                      <td
                        style={{
                          backgroundColor: "whitesmoke",
                          textAlign: "center",
                        }}
                      >
                        $
                        {this.state.updateTotalBilledAmount
                          ? this.state.updateTotalBilledAmount
                          : 0}
                      </td>
                      <td
                        style={{
                          backgroundColor: "whitesmoke",
                          textAlign: "center",
                        }}
                      >
                        $
                        {this.state.updateTotallPaidAmt
                          ? this.state.updateTotallPaidAmt
                          : 0}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div style={{ height: "100%", width: "97%" }}>
              <div>
                <Tabs
                  activityClassName="text-secondary"
                  style={{ margin: "5%" }}
                >
                  <Tab 
                  title="CPT Details">
                  <br/>
                    <div className="viewclaim_table1">
                      <Table
                        className="table_container"
                        responsive="md"
                        bordered
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              CODE
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Submitted Amount
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Paid Amount
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Status
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Subcategory
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Notes
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Status Code
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Status Category Code
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Touches
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.cptdata &&
                            this.state.cptdata.map((data, index) => {
                              return (
                                <tr style={{ textAlign: "center" }}>
                                  <td style={{ backgroundColor: "whitesmoke" }}>
                                    {data.CPTCode}
                                  </td>
                                  <td style={{ backgroundColor: "whitesmoke" }}>
                                    ${" "}
                                    {data.submittedAmount
                                      ? data.submittedAmount
                                      : 0}
                                  </td>
                                  <td style={{ backgroundColor: "whitesmoke" }}>
                                    $ {data.amountPaid ? data.amountPaid : 0}
                                  </td>
                                  <td style={{ backgroundColor: "whitesmoke" }}>
                                    {data.ClaimStatus}
                                  </td>
                                  <td style={{ backgroundColor: "whitesmoke" }}>
                                    {data.cptSubCategory}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "whitesmoke",
                                      whiteSpace: "normal",
                                      textAlign: "justify",
                                    }}
                                  >
                                    {data.cptNotes}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "whitesmoke",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {data.statusCode}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "whitesmoke",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {data.statusCategoryCode}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "whitesmoke",
                                      textAlign: "center",
                                    }}
                                  >
                                    {data.Touch ? data.Touch : 0}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                  <Tab title="Transaction">
                  <br/>
                    <div className="viewclaim_table1">
                      <Table
                        className="table_container"
                        responsive="md"
                        bordered
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              DOS
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              CPT
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              ICN
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Source
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Units
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Provider
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Total Balance
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              MOP
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Cheque No
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Insurance Payment
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.cptdata &&
                            this.state.cptdata.map((data, index) => {
                              return (
                                <tr style={{ textAlign: "center" }}>
                                  <td style={{ backgroundColor: "whitesmoke" }}>
                                    {this.state.updateDOS}
                                  </td>
                                  <td style={{ backgroundColor: "whitesmoke" }}>
                                    {data.CPTCode}
                                  </td>
                                  <td style={{ backgroundColor: "whitesmoke" }}>
                                    {this.state.icn_no
                                      ? this.state.icn_no
                                      : "-"}
                                  </td>
                                  <td style={{ backgroundColor: "whitesmoke" }}>
                                    {data.Source ? data.Source : "-"}
                                  </td>
                                  <td style={{ backgroundColor: "whitesmoke" }}>
                                    {data.submittedUnits
                                      ? data.submittedUnits
                                      : "-"}
                                  </td>
                                  <td style={{ backgroundColor: "whitesmoke" }}>
                                    {data.Provider ? data.Provider : "-"}
                                  </td>
                                  <td style={{ backgroundColor: "whitesmoke" }}>
                                    $
                                    {data.TotalBalanceAmount
                                      ? data.TotalBalanceAmount
                                      : 0}
                                  </td>
                                  <td style={{ backgroundColor: "whitesmoke" }}>
                                    {data.PaymentMode}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "whitesmoke",
                                      whiteSpace: "normal",
                                      textAlign: "justify",
                                    }}
                                  >
                                    {data.check_no ? data.check_no : 0}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "whitesmoke",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {data.InsurancePayment
                                      ? data.InsurancePayment
                                      : 0}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                  {this.state.viewclosedcpt === true ? (
                    <Tab title="Closed CPT">
                      <br />
                      <div className="viewclaim_table1">
                        <Table
                          className="table_container"
                          responsive="md"
                          bordered
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                  textAlign: "center",
                                }}
                              >
                                CODE
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                  textAlign: "center",
                                }}
                              >
                                Submitted Amount
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                  textAlign: "center",
                                }}
                              >
                                Paid Amount
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                  textAlign: "center",
                                }}
                              >
                                Status
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                  textAlign: "center",
                                }}
                              >
                                Subcategory
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                  textAlign: "center",
                                }}
                              >
                                Notes
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                  textAlign: "center",
                                }}
                              >
                                Status Code
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                  textAlign: "center",
                                }}
                              >
                                Status Category Code
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                  textAlign: "center",
                                }}
                              >
                                Touches
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.cptInactive &&
                              this.state.cptInactive.map((data, index) => {
                                return (
                                  <tr>
                                    <td
                                      style={{ backgroundColor: "whitesmoke" }}
                                    >
                                      {data.CPTCode}
                                    </td>
                                    <td
                                      style={{ backgroundColor: "whitesmoke" }}
                                    >
                                      ${" "}
                                      {data.submittedAmount
                                        ? data.submittedAmount
                                        : 0}
                                    </td>
                                    <td
                                      style={{ backgroundColor: "whitesmoke" }}
                                    >
                                      $ {data.amountPaid ? data.amountPaid : 0}
                                    </td>
                                    <td
                                      style={{ backgroundColor: "whitesmoke" }}
                                    >
                                      {data.ClaimStatus}
                                    </td>
                                    <td
                                      style={{ backgroundColor: "whitesmoke" }}
                                    >
                                      {data.cptSubCategory}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: "whitesmoke",
                                        whiteSpace: "normal",
                                        textAlign: "justify",
                                      }}
                                    >
                                      {data.cptNotes}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: "whitesmoke",
                                        whiteSpace: "normal",
                                      }}
                                    >
                                      {data.statusCode}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: "whitesmoke",
                                        whiteSpace: "normal",
                                      }}
                                    >
                                      {data.statusCategoryCode}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: "whitesmoke",
                                        textAlign: "center",
                                      }}
                                    >
                                      {data.Touch ? data.Touch : 0}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </div>
                    </Tab>
                  ) : (
                    <Tab></Tab>
                  )}
                </Tabs>
              </div>
            </div>
            <div style={{ height: "100%", width: "97%" }}>
              <div>
                {" "}
                <h5
                  style={{
                    textAlign: "left",
                    marginLeft: "2%",
                    fontWeight: "600",
                  }}
                >
                  Action
                </h5>
              </div>
              <div className="viewclaim_table1">
                <Table className="table_container" responsive="md" bordered>
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Action Code
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Action StatusCode
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Notes
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "whitesmoke" }}>
                        {this.state.action}
                      </td>
                      <td style={{ backgroundColor: "whitesmoke" }}>
                        {this.state.ActionStatuscode}
                      </td>
                      <td
                        style={{
                          backgroundColor: "whitesmoke",
                          whiteSpace: "normal",
                          textAlign: "justify",
                        }}
                      >
                        {this.state.Notes}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <Modal
              show={this.state.takeonaction}
              onHide={() =>
                this.setState({ takeonaction: !this.state.takeonaction })
              }
              onExit={this.reset}
              animation={false}
              centered
              size="xl"
              contentClassName="modal-open"
            >
              <Modal.Header closeButton closeLabel="">
                <Modal.Title>
                <p style={{ color: "#041c3c", fontWeight: "600", fontSize: "26px" }}>
                Take Action 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>
                  {elapsedTime ? (
                    <span>{"(" + formattedTime + ")"}</span>
                  ) : (
                    <span>
                      {this.state.currentTime && this.state.startTime
                        ? this.formatMillisecondsToHHMMSS(
                            this.state.currentTime - this.state.startTime
                          )
                        : "00:00:00"}
                    </span> 
                  )}
                </span>
              </p>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <Form.Label>Claim Status</Form.Label>
                    <Typeahead
                    id="viewclaim-typeahead"
                    clearButton={true}
                    options={this.state.statuslist}
                    labelKey="name"
                    onChange={this.handleChange}
                    placeholder="Select Claim Status..."
                    onInputChange={(input) => {
                      if (!input) {
                        this.setState({
                          updateClaimStatusActions: "",
                        });
                      }
                    }}
                    selected={
                      this.state.updateClaimStatusActions
                        ? [{ name: this.state.updateClaimStatusActions }]
                        : []
                    }
                  />
                    <div style={{ color: "red" }}>{this.state.statusErr}</div>
                    <br />
                  </Col>
                  {this.state.updateClaimStatusActions ===
                    "Closed/Already Paid" && (
                    <Col>
                      <Form.Group>
                        <Form.Label>DOS</Form.Label>
                        <Input
                          type="text"
                          disabled
                          value={this.state.updateDOS}
                        >
                          {this.state.updateDOS}
                        </Input>
                      </Form.Group>
                    </Col>
                  )}
                  <br />
                  <br />
                  <br />
                  <Col>
                    {this.state.updateClaimStatusActions === "denied" ? (
                      <Form.Group>
                        <Form.Label>Claim Category</Form.Label>
                        <Typeahead
                          id="viewclaim-typeahead-denial"
                          clearButton={true}
                          options={this.state.deniedcategories}
                          labelKey={(option) => `${option}`}
                          placeholder="All Category"
                          selected={
                            this.state.updateClaimCategoryActions
                              ? [this.state.updateClaimCategoryActions]
                              : []
                          }
                          onChange={(selected) => {
                            if (selected && selected.length > 0) {
                              this.setState({
                                updateClaimCategoryActions: selected[0],
                                updateStatusCategoryCodeActions: "F2",
                                categoryerr: "",
                              });
                            } else {
                              this.setState({
                                updateClaimCategoryActions: "",
                              });
                            }
                          }}
                        />
                      </Form.Group>
                    ) : (
                      <Form.Group>
                        <Form.Label>Claim Category</Form.Label>
                        <Input value={this.state.updateClaimCategoryActions} />
                      </Form.Group>
                    )}
                    <div style={{ fontSize: 14, color: "red" }}>
                      {this.state.categoryerr}
                    </div>
                    <br />
                  </Col>
                  <Col>
                    {this.state.updateClaimStatusActions === "NIS" ||
                    this.state.updateClaimStatusActions === "PartiallyPaid" ? (
                      <div>
                        <Form.Group>
                          <Form.Label>Claim SubCategory</Form.Label>
                          <Input
                            value={this.state.updateClaimSubCategoryActions}
                          />
                        </Form.Group>
                      </div>
                    ) : this.state.updateClaimStatusActions ===
                        "Recently Submitted" ||
                      this.state.updateClaimStatusActions ===
                        "Recently Worked" ||
                      this.state.updateClaimStatusActions ===
                        "Closed/Already Paid" ? (
                      <div>
                        <Form.Group>
                          <Form.Label>Claim SubCategory</Form.Label>
                          <Input
                            value={this.state.updateClaimSubCategoryActions}
                          />
                        </Form.Group>
                      </div>
                    ) : (
                      <div>
                        <Form.Group>
                          <Form.Label>Claim Subcategory</Form.Label>
                          {this.state.updateClaimStatusActions ===
                            "Acknowledgement" && (
                            <Typeahead
                            id="viewclaim-typeahead-ack"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.acksubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimStatusActions ===
                            "Data Reporting Acknowledgements" && (
                            <Typeahead
                            id="viewclaim-typeahead-dataack"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.datarepacksubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimStatusActions ===
                            "Pending" && (
                            <Typeahead
                             id="viewclaim-typeahead-pending"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.pendingsubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimStatusActions ===
                            "Finalized" && (
                            <Typeahead
                            id="viewclaim-typeahead-finalised"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.finalizedsubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimStatusActions ===
                            "Request" && (
                            <Typeahead
                            id="viewclaim-typeahead-req"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.requestsubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimStatusActions === "error" && (
                            <Typeahead
                            id="viewclaim-typeahead-error"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.errorsubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimStatusActions ===
                            "searches" && (
                            <Typeahead
                            id="viewclaim-typeahead-searches"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.searchessubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimStatusActions === "paid" && (
                            <Typeahead
                            id="viewclaim-typeahead-paid"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.paidsubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimCategoryActions ===
                            "Calling/Web Portal" && (
                            <Typeahead
                            id="viewclaim-typeahead-calling"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.callingsubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimCategoryActions ===
                            "Eligibility Issue" && (
                            <Typeahead
                            id="viewclaim-typeahead-eligibility"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.eligibilitysubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimCategoryActions ===
                            "Authorization" && (
                            <Typeahead
                            id="viewclaim-typeahead-auth"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.authorizationsubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimCategoryActions ===
                            "TFL Issue" && (
                            <Typeahead
                            id="viewclaim-typeahead-tfl"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.tflsubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimCategoryActions ===
                            "Coding" && (
                            <Typeahead
                            id="viewclaim-typeahead-coding"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.codingsubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimCategoryActions ===
                            "Provider" && (
                            <Typeahead
                            id="viewclaim-typeahead-provider"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.providersubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimCategoryActions ===
                            "Appeal" && (
                            <Typeahead
                            id="viewclaim-typeahead-appeal"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.appealsubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimCategoryActions ===
                            "Payment Posting" && (
                            <Typeahead
                            id="viewclaim-typeahead-paypost"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.paymentpostingsubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimCategoryActions ===
                            "Capitation" && (
                            <Typeahead
                            id="viewclaim-typeahead-capitation"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.capitationsubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {this.state.updateClaimCategoryActions ===
                            "Others" && (
                            <Typeahead
                            id="viewclaim-typeahead-others"
                              clearButton={true}
                              labelKey="displayName" // Replace this with the key that you want to display as the option label
                              options={this.state.otherssubcategory}
                              onChange={(e, index) => {
                                const { name, code } = e[0] ? e[0] : "";

                                this.setState({
                                  updateClaimSubCategoryActions: name,
                                  updateStatusCategoryCodeActions: code,
                                  subcategoryerr: "",
                                });
                              }}
                              // selected={selectedOptions}
                              placeholder="Type to search..."
                            />
                          )}
                          {/* <Input
                              value={this.state.updateClaimSubCategoryActions}
                              onChange={(e) => {
                                const index = e.target.selectedIndex;
                                const el = e.target.childNodes[index];
                                const option = el.getAttribute("id");
                                this.setState({
                                  updateClaimSubCategoryActions: e.target.value,
                                  updateStatusCategoryCodeActions: option,
                                  subcategoryerr: "",
                                });
                              }}
                              required
                              type="select"
                              className="form-control form-select"
                            >
                              <option value="select" hidden selected>
                                Select SubCategory...
                              </option>
                              {this.state.updateClaimStatusActions ===
                              "Acknowledgement"
                                ? this.state.acksubcategory.map(
                                    (data, index) => (
                                      <option
                                        id={data.code}
                                        value={data.name}
                                        selected={
                                          data.name ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data.displayName}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimStatusActions ===
                                  "Data Reporting Acknowledgements"
                                ? this.state.datarepacksubcategory.map(
                                    (data, index) => (
                                      <option
                                        id={data.code}
                                        value={data.name}
                                        selected={
                                          data.name ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data.displayName}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimStatusActions ===
                                  "Pending"
                                ? this.state.pendingsubcategory.map(
                                    (data, index) => (
                                      <option
                                        id={data.code}
                                        value={data.name}
                                        selected={
                                          data.name ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data.displayName}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimStatusActions ===
                                  "Finalized"
                                ? this.state.finalizedsubcategory.map(
                                    (data, index) => (
                                      <option
                                        id={data.code}
                                        value={data.name}
                                        selected={
                                          data.name ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data.displayName}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimStatusActions ===
                                  "Request"
                                ? this.state.requestsubcategory.map(
                                    (data, index) => (
                                      <option
                                        id={data.code}
                                        value={data.name}
                                        selected={
                                          data.name ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data.displayName}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimStatusActions ===
                                  "error"
                                ? this.state.errorsubcategory.map(
                                    (data, index) => (
                                      <option
                                        id={data.code}
                                        value={data.name}
                                        selected={
                                          data.name ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data.displayName}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimStatusActions ===
                                  "searches"
                                ? this.state.searchessubcategory.map(
                                    (data, index) => (
                                      <option
                                        id={data.code}
                                        value={data.name}
                                        selected={
                                          data.name ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data.displayName}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimStatusActions === "paid"
                                ? this.state.paidsubcategory.map(
                                    (data, index) => (
                                      <option
                                        id={data.code}
                                        value={data.name}
                                        selected={
                                          data.name ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data.displayName}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimCategoryActions ===
                                  "Calling/Web Portal"
                                ? this.state.callingsubcategory.map(
                                    (data, index) => (
                                      <option
                                        value={data}
                                        selected={
                                          data ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimCategoryActions ===
                                  "Eligibility Issue"
                                ? this.state.eligibilitysubcategory.map(
                                    (data, index) => (
                                      <option
                                        value={data}
                                        selected={
                                          data ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimCategoryActions ===
                                  "Authorization"
                                ? this.state.authorizationsubcategory.map(
                                    (data, index) => (
                                      <option
                                        value={data}
                                        selected={
                                          data ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimCategoryActions ===
                                  "TFL Issue"
                                ? this.state.tflsubcategory.map(
                                    (data, index) => (
                                      <option
                                        value={data}
                                        selected={
                                          data ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimCategoryActions ===
                                  "Coding"
                                ? this.state.codingsubcategory.map(
                                    (data, index) => (
                                      <option
                                        value={data}
                                        selected={
                                          data ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimCategoryActions ===
                                  "Provider"
                                ? this.state.providersubcategory.map(
                                    (data, index) => (
                                      <option
                                        value={data}
                                        selected={
                                          data ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimCategoryActions ===
                                  "Appeal"
                                ? this.state.appealsubcategory.map(
                                    (data, index) => (
                                      <option
                                        value={data}
                                        selected={
                                          data ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimCategoryActions ===
                                  "Payment Posting"
                                ? this.state.paymentpostingsubcategory.map(
                                    (data, index) => (
                                      <option
                                        value={data}
                                        selected={
                                          data ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimCategoryActions ===
                                  "Capitation"
                                ? this.state.capitationsubcategory.map(
                                    (data, index) => (
                                      <option
                                        value={data}
                                        selected={
                                          data ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data}
                                      </option>
                                    )
                                  )
                                : this.state.updateClaimCategoryActions ===
                                  "Others"
                                ? this.state.otherssubcategory.map(
                                    (data, index) => (
                                      <option
                                        value={data}
                                        selected={
                                          data ===
                                          this.state
                                            .updateClaimSubCategoryActions
                                        }
                                      >
                                        {data}
                                      </option>
                                    )
                                  )
                                : null}
                            </Input> */}
                        </Form.Group>
                      </div>
                    )}

                    <div style={{ fontSize: 14, color: "red" }}>
                      {this.state.subcategoryerr}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Status category Code</Form.Label>
                      <Input
                        type="text"
                        value={this.state.updateStatusCategoryCodeActions}
                      />
                    </Form.Group>
                    <br />
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label className="required">
                        Action Status Code
                      </Form.Label>
                      <div style={{ justifyContent: "center" }}>
                        {this.state.updateClaimStatusActions ===
                        "Recently Worked" ? (
                          <Input
                            type="text"
                            disabled
                            defaultValue={"Recently Worked"}
                          />
                        ) : this.state.updateClaimStatusActions ===
                          "Recently Submitted" ? (
                          <Input
                            type="text"
                            disabled
                            defaultValue={"Recently Submitted"}
                          />
                        ) : this.state.updateClaimStatusActions ===
                          "Closed/Already Paid" ? (
                          <Input
                            type="text"
                            defaultValue={"Closed/Already Paid"}
                            disabled
                          />
                        ) : (
                          <Typeahead
                          id="viewclaim-typeahead-closed"
                          clearButton={true}
                          options={this.state.statuscodelist}
                          labelKey={(option) => `${option.Description}`}
                          style={dropdownStyle}
                          placeholder="All Action status code"
                          selected={
                            this.state.updateActionCodeStatusActions
                              ? [{ Description: this.state.updateActionCodeStatusActions }]
                              : []
                          }
                          align="left" // Align the dropdown menu to the left of the input field
                          onChange={(selected) => {
                            if (selected && selected.length > 0) {
                              this.setState({
                                updateActionCodeStatusActions: selected[0].Description,
                                actionstatuscodeerr: "",
                              });
                            } else {
                              this.setState({
                                updateActionCodeStatusActions: "",
                                actionstatuscodeerr: "",
                              });
                            }
                          }}
                          onInputChange={(input) => {
                            if (!input) {
                              this.setState({
                                updateActionCodeStatusActions: "",
                              });
                            }
                          }}
                        />
                        
                        )}
                      </div>

                      <div style={{ fontSize: 14, color: "red" }}>
                        {this.state.actionstatuscodeerr}
                      </div>
                    </Form.Group>
                    <br />
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label className="required">Action Code</Form.Label>
                      {this.state.updateClaimStatusActions ===
                      "Recently Worked" ? (
                        <Input
                          type="text"
                          disabled
                          defaultValue={"Set For Follow-Up"}
                        />
                      ) : this.state.updateClaimStatusActions ===
                        "Recently Submitted" ? (
                        <Input
                          type="text"
                          defaultValue={"Set For Follow-Up"}
                          disabled
                        />
                      ) : this.state.updateClaimStatusActions ===
                        "Closed/Already Paid" ? (
                        <Input
                          type="text"
                          defaultValue={"Closed/Already Paid"}
                          disabled
                        />
                      ) : (
                        <Typeahead
                        id="viewclaim-typeahead-actioncode"
                        clearButton={true}
                        options={this.state.actioncodelist}
                        labelKey={(option) => `${option.Description}`}
                        placeholder="All Action code"
                        selected={
                          this.state.updateactioncodeActions
                            ? [{ Description: this.state.updateactioncodeActions }]
                            : []
                        }
                        onChange={(selected) => {
                          if (selected && selected.length > 0) {
                            this.setState({
                              updateactioncodeActions: selected[0].Description,
                              actioncodeerr: "",
                            });
                          } else {
                            this.setState({
                              updateactioncodeActions: "",
                              actioncodeerr: "",
                            });
                          }
                        }}
                        onInputChange={(input) => {
                          if (!input) {
                            this.setState({
                              updateactioncodeActions: "",
                              actioncodeerr: "",
                            });
                          }
                        }}
                      />  
                      )}
                      <div style={{ fontSize: 14, color: "red" }}>
                        {this.state.actioncodeerr}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label className="required">Notes</Form.Label>
                      <Input
                        type="textarea"
                        style={{ height: "200px" }}
                        required
                        value={this.state.updateNotesActions}
                        onChange={(e) =>
                          this.setState({
                            updateNotesActions: e.target.value,
                            notesErr: "",
                          })
                        }
                        onBlur={() => {
                          if (!this.state.updateNotesActions) {
                            // Clear state if input is empty
                            this.setState({ updateNotesActions: "" });
                          }}}
                      >
                      </Input>
                      <div style={{ fontSize: 14, color: "red" }}>
                        {this.state.notesErr}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Col>
                      <Row>
                        <Form.Label className="required">
                          Follow Up date{" "}
                        </Form.Label>
                        <Col>
                          <Form.Group>
                            <Input
                              type="checkbox"
                              checked={this.state.days15}
                              onChange={() => {
                                var date = new Date();
                                var dateformat = moment
                                  .tz(date, "Asia/Kolkata")
                                  .add(15, "days")
                                  .format("MM-DD-YYYY");
                                this.setState({
                                  days15: !this.state.days15,
                                  days30: false,
                                  days45: false,
                                  customdate: false,
                                  postEventdate: dateformat,
                                  postEventdateErr: "",
                                });
                              }}
                            />
                            &nbsp;15days
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Input
                              type="checkbox"
                              checked={this.state.days30}
                              onChange={() => {
                                var date = new Date();
                                var dateformat = moment
                                  .tz(date, "Asia/Kolkata")
                                  .add(30, "days")
                                  .format("MM-DD-YYYY");
                                this.setState({
                                  days30: !this.state.days30,
                                  postEventdate: dateformat,
                                  days15: false,
                                  days45: false,
                                  customdate: false,
                                  postEventdateErr: "",
                                });
                              }}
                            />
                            &nbsp;30days
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Input
                              type="checkbox"
                              checked={this.state.days45}
                              onChange={() => {
                                var date = new Date();
                                var dateformat = moment
                                  .tz(date, "Asia/Kolkata")
                                  .add(45, "days")
                                  .format("MM-DD-YYYY");
                                this.setState({
                                  days45: !this.state.days45,
                                  postEventdate: dateformat,
                                  days15: false,
                                  days30: false,
                                  customdate: false,
                                  postEventdateErr: "",
                                });
                              }}
                            />
                            &nbsp;45days
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Input
                              type="checkbox"
                              checked={this.state.customdate}
                              onChange={() =>
                                this.setState({
                                  customdate: !this.state.customdate,
                                  days30: false,
                                  days45: false,
                                  days15: false,
                                  postEventdate: "",
                                })
                              }
                            />
                            &nbsp;Custom
                          </Form.Group>
                        </Col>
                      </Row>
                      <br />
                      {this.state.customdate ? (
                        <Form.Group>
                          <Input
                            type="date"
                            required
                            // disabled={this.state.enable}
                            value={this.state.postEventdate}
                            onChange={(e) =>
                              this.setState({
                                postEventdate: e.target.value.substring(0, 10),
                                postEventdateErr: "",
                              })
                            }
                          ></Input>
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.postEventdateErr}
                          </div>
                        </Form.Group>
                      ) : (
                        <Form.Group>
                          <Input
                            type="text"
                            disabled={this.state.enable}
                            value={this.state.postEventdate.substring(0, 10)}
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.postEventdateErr}
                          </div>
                        </Form.Group>
                      )}
                    </Col>
                    <br />
                    <Col>
                      <Form.Group>
                        <Form.Label className="required">
                          Overall Claim Status
                        </Form.Label>
                        <Typeahead
                        id="viewclaim-typeahead-overall"
                          clearButton={true}
                          options={["TO-DO", "Completed"]}
                          placeholder="Select Status..."
                          selected={
                            this.state.overallStatusActions
                              ? [this.state.overallStatusActions]
                              : []
                          }
                          onChange={(selected) => {
                            if (selected && selected.length > 0) {
                              this.setState({
                                overallStatusActions: selected[0],
                                overallstatusErr: "",
                              });
                            } else {
                              this.setState({
                                overallStatusActions: "",
                              });
                            }
                          }}
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.overallstatusErr}
                        </div>
                      </Form.Group>
                      <br />
                    </Col>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>             
              <Button 
              style={{ backgroundColor: "#041c3c", color: "white" }}
              onClick={this.handleUpdateButtonClick} disabled={this.state.isLoading}>
              {this.state.isLoading ? 'Processing...' : 'Update'}
            </Button>
            </Modal.Footer>     
            </Modal>
            {this.state.show && (
              <Modal
                show={this.state.show}
                onHide={this.toggle}
                size="xl"
                centered
                animation={false}
              >
                <Modal.Header toggle={this.toggle} closeButton closeLabel="">
                  AR Follow Up Log
                </Modal.Header>
                <Modal.Body
                  style={{
                    "max-height": "calc(100vh - 210px)",
                    "overflow-y": "auto",
                  }}
                >
                  <div
                    className="tabs_prior"
                    activeTab="1"
                    style={{ cursor: "pointer" }}
                    activityClassName="text-secondary"
                    onClick={(event, tab) => {
                      this.setState({ tabindex: tab });
                    }}
                  >
                    <button
                      style={{
                        border: "0.5px solid #B2BEB5",
                        padding: "7px",
                        width: "15%",
                        fontSize: "14px",
                      }}
                      className={activeTab === "tab1" ? "active-tab" : ""}
                      onClick={() => this.handleTabChange("tab1")}
                    >
                      CPT Update
                    </button>
                    <button
                      style={{
                        border: "0.5px solid #B2BEB5",
                        padding: "7px",
                        width: "15%",
                        fontSize: "14px",
                      }}
                      className={activeTab === "tab2" ? "active-tab" : ""}
                      onClick={() => this.handleTabChange("tab2")}
                    >
                      Claim Update
                    </button>
                    <button
                      style={{
                        border: "0.5px solid #B2BEB5",
                        padding: "7px",
                        width: "15%",
                        fontSize: "14px",
                      }}
                      className={activeTab === "tab3" ? "active-tab" : ""}
                      onClick={() => this.handleTabChange("tab3")}
                    >
                      History
                    </button>
                  </div>
                  <br />
                  <div className="tab-content">
                    {activeTab === "tab1" && (
                      <div>
                        {this.state.cptdata1.length !== 0 ? (
                          <Table bordered>
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Worked
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  User
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  CPT
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Notes
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Next F/U
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Claim Status
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Action Status Code
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Action Code
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Total Billed Amount
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Overall Status
                                </th>
                              </tr>
                            </thead>
                            {this.state.cptdata1.map((data, index) => {
                              return (
                                <tbody>
                                  <tr>
                                    <td>
                                      {data.workedDate
                                        ? moment(
                                            data.workedDate.substring(0, 10)
                                          ).format("MM/DD/YYYY")
                                        : "-"}
                                    </td>
                                    <td>{data.UserId}</td>
                                    <td>{data.CPTcode}</td>
                                    <td style={{ whiteSpace: "normal" }}>
                                      {data.Notes}
                                    </td>
                                    <td>
                                      {moment(data.NextFollowupDate).format(
                                        "MM-DD-YYYY"
                                      )}
                                    </td>
                                    <td>{data.claimStatus}</td>
                                    <td style={{ whiteSpace: "normal" }}>
                                      {data.Statuscode}
                                    </td>
                                    <td style={{ whiteSpace: "normal" }}>
                                      {data.Actioncode}
                                    </td>
                                    <td>{data.TotalBilledAmount}</td>
                                    <td>{data.OverallServiceLevelStatus}</td>
                                  </tr>
                                </tbody>
                              );
                            })}
                          </Table>
                        ) : (
                          <div
                            style={{ fontStyle: "italic", textAlign: "center" }}
                          >
                            No results found!
                          </div>
                        )}
                      </div>
                    )}
                    {activeTab === "tab2" && (
                      <div style={{ marginLeft: "1%" }}>
                        {this.state.historydata.length !== 0 ? (
                          <Table bordered>
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Worked
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  User
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Notes
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Next F/U
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Claim Status
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Action Status Code
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Action Code
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Total Billed Amount
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Overall ClaimStatus
                                </th>
                              </tr>
                            </thead>
                            {this.state.historydata.map((data, index) => {
                              return (
                                <tbody>
                                  <tr>
                                    <td>
                                      {data.workedDate
                                        ? moment(
                                            data.workedDate.substring(0, 10)
                                          ).format("MM/DD/YYYY")
                                        : "-"}
                                    </td>
                                    <td>{data.UserId}</td>
                                    <td style={{ whiteSpace: "normal" }}>
                                      {data.Notes}
                                    </td>
                                    <td>
                                      {moment(data.NextFollowupDate).format(
                                        "MM/DD/YYYY"
                                      )}
                                    </td>
                                    <td style={{ whiteSpace: "normal" }}>
                                      {data.claimStatus}
                                    </td>
                                    <td style={{ whiteSpace: "normal" }}>
                                      {data.Statuscode}
                                    </td>
                                    <td style={{ whiteSpace: "normal" }}>
                                      {data.Actioncode}
                                    </td>
                                    <td>{data.TotalBilledAmount}</td>
                                    <td>{data.OverallServiceLevelStatus}</td>
                                  </tr>
                                </tbody>
                              );
                            })}
                          </Table>
                        ) : (
                          <div
                            style={{ fontStyle: "italic", textAlign: "center" }}
                          >
                            No results found!
                          </div>
                        )}
                      </div>
                    )}
                    {activeTab === "tab3" && (
                      <div>
                        {this.state.Actiondata.length !== 0 ? (
                          <Table bordered>
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Worked date
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  Status
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                  }}
                                >
                                  User
                                </th>
                              </tr>
                            </thead>
                            {this.state.Actiondata.map((data, index) => {
                              return (
                                <tbody>
                                  <tr>
                                    <td>
                                      {data.workedDate
                                        ? moment(
                                            data.workedDate.substring(0, 10)
                                          ).format("MM/DD/YYYY")
                                        : "-"}
                                    </td>
                                    <td>{data.claimStatus}</td>
                                    <td>{data.UserId}</td>
                                  </tr>
                                </tbody>
                              );
                            })}
                          </Table>
                        ) : (
                          <div
                            style={{ fontStyle: "italic", textAlign: "center" }}
                          >
                            No results found!
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Modal.Body>
              </Modal>
            )}
            {this.state.showCptpopup && (
              <Modal
                show={this.state.showCptpopup}
                onHide={this.toggleAlert}
                size="s"
                centered
                animation={false}
              >
              <Modal.Header>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                  <h5 style={{ margin: '0', fontWeight:"600" }}>Alert</h5>
                </div>
                <img
                  src={warning} 
                  alt="close"
                  style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                  onClick={this.toggleAlert}
                />
              </div>
            </Modal.Header>
                <Modal.Body>
                <p style={{fontSize:"18px", fontWeight:"300"}}>No cpt is avaliable for this claim. So kindly add CPT's </p>
                </Modal.Body>
                <Modal.Footer>
                <button className="btn btn-danger" onClick={this.toggleAlert}>Close</button>
                <button
                type="button"
                class="btn"
                style={{ backgroundColor: "#041c3c", color: "#fff" }}
                onClick={() => {
                  this.cpttoggle();
                }}
              >
                Add CPT
              </button>

                </Modal.Footer>
              </Modal>
            )}
            <Modal
              show={this.state.cptmodal}
              onHide={this.cpttoggle}
              onExit={this.reset}
              centered
              size="lg"
            >
              <Modal.Header closeLabel="" closeButton>
                <Modal.Title>Create CPT</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "scroll",
                }}
              >
                <Row>
                  <Col>
                    <Form.Group>
                      <Label style={{ fontWeight: "600" }}>DOS</Label>
                      <Input type="text" disabled value={this.state.updateDOS}  />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Label className="required" style={{ fontWeight: "600" }}>
                      CPT
                    </Label>
                    <Input
                      type="text"
                      value={this.state.selectedcpt}
                      onChange={(e) =>
                        this.setState({ selectedcpt: e.target.value })
                      }
                    ></Input>
                    {this.state.selectedcpt === "" ? (
                      <div style={{ color: "red" }}>{this.state.cpterr}</div>
                    ) : null}
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label style={{ fontWeight: "600" }}>ICD</Label>
                      <Input
                        type="text"
                        value={this.state.addIcd}
                        onChange={(e) =>
                          this.setState({ addIcd: e.target.value })
                        }
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label style={{ fontWeight: "600" }}>Units</Label>
                      <Input
                        type="text"
                        value={this.state.addsubmittedUnits}
                        onChange={(e) =>
                          this.setState({ addsubmittedUnits: e.target.value })
                        }
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label style={{ fontWeight: "600" }}>Provider</Label>
                      <Input
                        type="text"
                        value={this.state.addProvider}
                        onChange={(e) =>
                          this.setState({ addProvider: e.target.value })
                        }
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label style={{ fontWeight: "600" }}>
                        Submitted Amount
                      </Label>
                      <Input
                        type="text"
                        value={this.state.addTotoalBalancedAmount}
                        onChange={(e) =>
                          this.setState({
                            addTotoalBalancedAmount: e.target.value,
                          })
                        }
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <br />
              </Modal.Body>
              <br />
              <Modal.Footer>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.cpttoggle();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.addCpt}
                >
                  Add CPT
                </Button>
              </Modal.Footer>
            </Modal>  
            <Modal
              show={this.state.loadingModal}
              onHide={this.checkstatustoggle}
              animation="false"
              size="xl"
              centered
            >
              <Modal.Header closeLabel="" closeButton>
                <Modal.Title>Check Status</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row>
                  <div>
                    <table className="viewtablename1">
                      <tr className="datatablevalue">
                        <td className="dataheading">Name</td>
                        <td className="viewdata">
                          {this.state.updateFirstName +
                            " " +
                            this.state.updateLastName}
                        </td>
                        <td className="dataheading">DOB</td>
                        <td className="viewdata">
                          {moment(this.state.updatePatientDOB).format(
                            "MM/DD/YYYY"
                          )}
                        </td>
                      </tr>

                      <tr className="datatablevalue">
                        <td className="dataheading">Status</td>
                        <td className="viewdata">
                          {this.state.updateClaimStatus}
                        </td>
                        <td className="dataheading">DOS</td>
                        <td className="viewdata">
                          {moment(this.state.updateBeginingDOS).format(
                            "MM/DD/YYYY"
                          )}
                        </td>
                      </tr>

                      <tr className="datatablevalue">
                        <td className="dataheading">InsuranceName</td>
                        <td className="viewdata">
                          {this.state.updatePripayer}
                        </td>
                        <td className="dataheading">Insurance ID </td>
                        <td className="viewdata">{this.state.updatePayerId}</td>
                      </tr>
                      <tr className="datatablevalue">
                        <td className="dataheading">Claim Status</td>
                        <td colSpan={3} className="viewdata">
                          {this.state.updateCheckNumber}
                        </td>
                      </tr>
                      <tr className="datatablevalue">
                        <th className="checkhead">CPT </th>
                        <td colSpan={3} className="viewdata">
                          {" "}
                          CPT Notes
                        </td>
                      </tr>
                      {this.state.cptcodearray.map((data, index) => {
                        return (
                          <tr className="datatablevalue">
                            <th className="checkhead">
                              <input type="checkbox" /> &nbsp;
                              {data[0]}
                            </th>
                            <td colSpan={3} className="viewdata">
                              {data[1]}
                            </td>
                          </tr>
                        );
                      })}

                      <tr className="datatablevalue">
                        <td className="viewdata"></td>
                        <td className="viewdata"></td>
                        <td
                          className="viewdata"
                          style={{ background: "#041c3c", color: "white" }}
                          onClick={() => this.RerunApis()}
                        >
                          Rerun
                        </td>
                        <td
                          className="viewdata"
                          style={{ background: "green", color: "white" }}
                        >
                          Add to Notes
                        </td>
                      </tr>
                    </table>
                  </div>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <button
                  class="button-35"
                  role="button"
                  onClick={() => {
                    this.checkstatustoggle();
                  }}
                >
                  cancel
                </button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={this.state.Timermodal}
              onHide={this.toggleTimer}
              centered
            >
              <Modal.Body>
                <div className="flex-container1">
                  <img
                    src={wheatlogo}
                    alt="logo"
                    style={{ width: "20%", height: "30%", textAlign: "center" }}
                  />
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    Time spent:{" "}
                    <span style={{ fontSize: "2rem" }}> {formattedTime}</span>
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-danger" onClick={this.toggleTimer}>
                  OK
                </button>
              </Modal.Footer>
            </Modal>
      </div>
    );
  }
}

export default ViewClaim;
