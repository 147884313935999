import { React, Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import "../../Styles/denial.css";
import "../../Styles/subdenial.css";
import { Col, Input } from "reactstrap";
import moment from "moment";
import "../../Styles/cardalloc.css"; 
import axios from "axios";
import { ip } from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { IoIosRefresh } from "react-icons/io";
import {  DatePicker} from "antd";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import DenialBuckets from "./SubDenial/DenialBuckets";

const { RangePicker } = DatePicker;

class DenialManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ClaimID: sessionStorage.getItem("ClaimID"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      role: sessionStorage.getItem("role_type"),
      value: sessionStorage.getItem("Allocatedenialtable"),
      clientslist: [],
      filterstatus: "custom",
      valueOfInput1: "",
      valueOfInput2: "",
      clientname: "",
      opendropdown: false,
      Location: "",
      locationslist: [],
      subcategorydenial: "",
      searchid: "",
      querySearch: "",
      searchBy: "claimCategory",
      category: "",
      thirdcard: "",
    };
  }

  toggle = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };
  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        if (res.data.clientonboard.length !== 0) {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        } else {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        }
      })
      .catch((err) => {});
  };
  getLocation = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      url = `${ip}/location/activelocations?clientId=${this.state.client}`;
    } else {
      url = `${ip}/location/activelocations`;
    }
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        let data = res.data.location;
        data = data.filter((item) => {
          return item.clientId == this.state.client;
        });
        data.sort((a, b) => {
          return a.Location < b.Location ? -1 : 1;
        });

        this.setState({ locationslist: data });
      })

      .catch((err) => {});
  };
  getsearchid = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    if (this.state.searchid === "") {
      toast.error("Please fill the search value!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("Show id");
    } else {
      const res = await axios
        .get(`${ip}/claim/viewclaim?claimid=${this.state.searchid}&feature=DenialManagement`, {
          headers: headers,
        })

        .then((res) => {
          if(res.data.Claim.length !== 0){
            res.data.Claim.map((ele) => {
              this.setState({
                querySearch: ele.Touch,
                Secondcard: ele.ClaimCategory,
                thirdcard: ele.AgeGrp,
                subcategorydenial: ele.claimSubCategory,
              });
              console.log(this.state.subcategorydenial, "denial");
            });
          }else{
            toast.error("Claim id not found", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            })
          }
  
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 500) {
              toast.error("Claim id not found", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }}
        });
    }
  };
  reset = () => {
    this.setState({
      searchid: "",
      querySearch: "",
      Secondcard: "",
      thirdcard: "",
    });
    localStorage.removeItem("searchidkeydenial");
    sessionStorage.removeItem("queryclaimiddenial");
    sessionStorage.removeItem("querysubcategorytouch");
    sessionStorage.removeItem("querysubcategoryagegrp");
    sessionStorage.removeItem("querysubcategoryvalue");
    sessionStorage.removeItem("querysearchid");
    sessionStorage.removeItem("denialsub");
  };
  valuedate = (option, e) => {
    if (option === "yesterday") {
      this.setState({
        valueOfInput1: e.target.value,
        valueOfInput2: moment().subtract(1, "days").format("MM-DD-YYYY"),
        filterstatus: option,
      });
    } else {
      this.setState({
        valueOfInput1: e.target.value,
        valueOfInput2: moment().format("MM-DD-YYYY"),
        filterstatus: option,
      });
    }
  };
  componentDidMount() {
    this.getclients();
    this.getLocation();
    let searchid1 = localStorage.getItem("searchidkeydenial");
    let val = JSON.parse(searchid1);
    if (val !== "" && val !== null) {
      this.setState(
        {
          searchid: JSON.parse(searchid1),
        },
        () => {
          this.getsearchid();
        }
      );
    }
    sessionStorage.removeItem("querystartdate");
    sessionStorage.removeItem("queryenddate");
    sessionStorage.removeItem("queryLocation");
    sessionStorage.removeItem("denialsub");
  }
  componentWillUnmount() {
    let input1 = JSON.stringify(this.state.searchid);
    localStorage.setItem("searchidkeydenial", input1);
  }
  handleChange = (selectedOptions) => {
    let Location = selectedOptions.map((data) => {
      return data.Location;
    });
    this.setState(
      {
        Location: Location[0],
      },
      () => {
        this.getclients();
        this.getLocation();
      }
    );
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  render() {
    return (
      <div>
      <Newsidebar name="Denial Management" />
      <div className="align-row" style={{ overflow: "hidden"}}>
        <div className="align-column">
          <ToastContainer></ToastContainer>
          <div className="claim-card-container">
          <div style={{ display: "flex", flexDirection: "row", gap:"20px" }}>
          <Col md="4">
          <form>
            <div class="input-group mb-4">
              <input
                style={{ zIndex: 0.5 }}
                type="search"
                placeholder="Search by ID?"
                aria-describedby="button-addon5"
                class="form-control"
                value={this.state.searchid}
                onChange={(e) => {
                  this.setState({ searchid: e.target.value });
                }}
              />
              <div class="input-group-append" style={{ zIndex: 1 }}>
                <div class="btn-group">
                  <button
                    id="button-addon5"
                    type="submit"
                    style={{background:"#002755", color:"white"}}
                    class="btn btn-success"
                    onClick={(e) => {
                      e.preventDefault();
                      this.getsearchid();
                    }}
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <button
                  style={{background:"#EB1A23", color:"white"}}
                    id="button-addon5"
                    type="submit"
                    class="btn btn-dark"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the default form submission
                      this.reset();
                    }}
                  >
                    <IoIosRefresh
                      style={{
                        color: "#fff",
                        fontSize: "20px",
                      }}
                    ></IoIosRefresh>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Col>
          <section>
          {this.state.role === "SuperAdmin" ? (
            <ButtonDropdown
              isOpen={this.state.opendropdown}
              toggle={this.toggle}
              style={{
                width: "12%",
                height:"5%",
                position: "inherit",
              }}
            >
              <DropdownToggle caret>
                {this.state.clientname ? this.state.clientname : "All"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() =>
                    this.setState({ clientname: "", client: "" }, () =>
                      this.getLocation()
                    )
                  }
                >
                  All
                </DropdownItem>
                {this.state.clientslist.map((e) => {
                  return (
                    <DropdownItem
                      id={e.clientId}
                      key={e.clientId}
                      onClick={(e) => {
                        this.setState(
                          {
                            client: e.target.id,
                            clientname: e.target.innerText,
                          },
                          () => this.getLocation()
                        );
                      }}
                    >
                      {e.client}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </ButtonDropdown>
          ) : null}
          {this.state.client && (
            <Typeahead
              style={{height:"35px"}}
              id="typeahead-deial-management"
              clearButton={true}
              onChange={this.handleChange}
              options={this.state.locationslist}
              labelKey={(option) => `${option.Location}`}
              placeholder="All Location"
              selected={this.state.selected}
            />
          )}
          </section>
            <section>        
            <div
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize:"12px",
              gap:"20px"
            }}>
            <Input
              type="select"
              style={{  height:"5%" ,
              fontSize:"12px"
            }}
              className="form-control form-select"
              onChange={(e) => {
                const index = e.target.selectedIndex;
                const el = e.target.childNodes[index];
                const option = el.getAttribute("title");
                this.valuedate(option, e);
                this.setState(() => {
                  sessionStorage.setItem(
                    "querystartdate",
                    this.state.valueOfInput1
                  );
                  sessionStorage.setItem(
                    "queryenddate",
                    this.state.valueOfInput2
                  );
                });
              }}
            >
              <option value="" title="custom">
                Custom
              </option>
              <option value={moment().format("MM-DD-YYYY")} title="">
                Today
              </option>
              <option
                value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                title="yesterday"
              >
                Yesterday
              </option>
              <option
                value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 3 days
              </option>
              <option
                value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 7 days
              </option>
              <option
                value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 30 days
              </option>
              <option
                value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 365 days
              </option>
            </Input>
            {this.state.filterstatus === "custom" && (
              <RangePicker
                format="MM-DD-YYYY"
                onChange={(dates, datestring) => {
                  this.setState(
                    {
                      valueOfInput1: datestring[0],
                      valueOfInput2: datestring[1],
                    },
                    () => {
                      sessionStorage.setItem(
                        "querystartdate",
                        this.state.valueOfInput1
                      );
                      sessionStorage.setItem(
                        "queryenddate",
                        this.state.valueOfInput2
                      );
                    }
                  );
                }}
                height={"40%"}
                style={{
                  borderColor: "gray",
                  fontSize:"13px",
                  width:"150%"
                }}
              />
            )}
          </div>
            </section>
          </div>
          </div>
        <br/>
          <div className="d-card-container">
            <DenialBuckets
              search2={this.state.querySearch}
              querytouch={this.state.querySearch}
              queryagegrp={this.state.thirdcard}
              claimcategory={this.state.Secondcard}
              client={this.state.client}
              startdate={this.state.valueOfInput1}
              enddate={this.state.valueOfInput2}
              Location={this.state.Location}
              searchid={this.state.searchid}
              denialmg={sessionStorage.setItem(
                "denialmg",
                this.state.Secondcard
              )}
              denialsub={sessionStorage.setItem(
                "denialsub",
                this.state.thirdcard
              )}
              subcategorydenial={this.state.subcategorydenial}
            />
          </div>
        </div>
      </div>
      </div>
    );
  }
}
export default DenialManagement;
