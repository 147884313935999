import React, { useState, useEffect } from "react";
import axios from "axios";
import Newsidebar from "../Dashboard/Newsidebar";
import { ip } from "../../Api";
import Tabs, { Tab } from "react-best-tabs";
import "../../Styles/databasesync.css";
import { Button, Input } from "reactstrap";
import { Row, Col, Card } from "react-bootstrap";

const DatabaseSync = () => {
  const [refreshtoken, setRefreshtoken] = useState(sessionStorage.getItem("refreshtoken"));
  const [accesstoken, setAccesstoken] = useState(sessionStorage.getItem("accesstoken"));

  // Renew access token
  const renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshtoken}`,
    };
    try {
      const renew = await axios.get(`${ip}/auth/renewAccessToken`, { headers: renewheaders });
      sessionStorage.setItem("accesstoken", renew.data.accessToken);
      setAccesstoken(renew.data.accessToken); // Update access token state
    } catch (err) {
      console.error("Error renewing access token", err);
    }
  };
  return (
    <div>
      <Newsidebar name="Database Sync" />
      <div className="align-row">
        <div className="align-column">
          <div className="dbsync-tabs">
            <Tabs activeTab="1" activityClassName="bg-info">
              <Tab title="SQL DB">
                <div style={{ paddingTop: "3%" }} className="nosql-container">
                  <div className="nosql1">
                    <Card
                      style={{
                        background: "#07326c",
                        height: "50px",
                        color: "white",
                      }}
                    >
                      <Col
                        style={{
                          textAlign: "left",
                          paddingTop: "0.5%",
                          fontSize: "25px",
                          fontWeight: "500",
                          marginLeft: "2%",
                        }}
                      >
                        Setup New Connection
                      </Col>
                    </Card>

                    <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                      <Col md="4">
                        <h5 className="label" style={{ textAlign: "left" }}>
                          Hostname
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input placeholder="127.0.0.1" type="text" />
                      </Col>
                    </Row>
                    <br />
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="4">
                        <h5 className="label" style={{ textAlign: "left" }}>
                          Port
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input placeholder="3306" type="text" />
                      </Col>
                      <Row>
                        <Col md="4"></Col>
                        <Col
                          md="6"
                          style={{
                            fontSize: "11px",
                            textAlign: "left",
                            marginLeft: "9%",
                          }}
                        >
                          Name or IP address of the server host - and TCP/IP
                          port.
                        </Col>
                      </Row>
                    </Row>
                    <br />
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="4">
                        <h5 className="label" style={{ textAlign: "left" }}>
                          Username
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input placeholder="root" type="text" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">{""}</Col>
                      <Col
                        md="6"
                        style={{
                          textAlign: "left",
                          fontSize: "11px",
                          marginLeft: "9%",
                        }}
                      >
                        Name of the user to connect with.
                      </Col>
                    </Row>

                    <br />
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="4">
                        <h5 className="label" style={{ textAlign: "left" }}>
                          Password
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input placeholder="Password" type="password" />
                      </Col>
                    </Row>
                    <br />
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="4">
                        <h5 className="label" style={{ textAlign: "left" }}>
                          Default Schema
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input type="text" />
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col
                        md="11"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button style={{ background: "#07326c" }}>
                          Test Connection
                        </Button>{" "}
                        &nbsp;
                        <Button style={{ background: "#07326c" }}>Cancel</Button>{" "}
                        &nbsp;
                        <Button style={{ background: "#07326c" }}>Save</Button>
                      </Col>
                    </Row>
                    <br />
                  </div>
                </div>
              </Tab>
              <Tab title="NOSQL DB">
                <br />
                <div className="nosql-container">
                  <div className="nosql1">
                    <Card
                      style={{
                        background: "#07326c",
                        height: "50px",
                        color: "white",
                      }}
                    >
                      <Col
                        style={{
                          textAlign: "left",
                          paddingTop: "0.5%",
                          fontSize: "25px",
                          fontWeight: "500",
                          marginLeft: "2%",
                        }}
                      >
                        Connect To Host
                      </Col>
                    </Card>

                    <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                      <Col md="4">
                        <h5 className="label" style={{ textAlign: "left" }}>
                          Hostname
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input type="text" placeholder="localhost" />
                      </Col>
                    </Row>
                    <br />
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="4">
                        <h5 className="label" style={{ textAlign: "left" }}>
                          Port
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input placeholder="27017" type="text" />
                      </Col>
                    </Row>
                    <br />
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="4">
                        <h5 className="label" style={{ textAlign: "left" }}>
                          Authentication
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input placeholder="None" type="text" />
                      </Col>
                    </Row>
                    <br />
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="4">
                        <h5 className="label" style={{ textAlign: "left" }}>
                          Replica Set Name
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input placeholder="off" type="text" />
                      </Col>
                    </Row>
                    <br />
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="4">
                        <h5 className="label" style={{ textAlign: "left" }}>
                          Read Preference
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input placeholder="Primary" type="text" />
                      </Col>
                    </Row>
                    <br />
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="4">
                        <h5 className="label" style={{ textAlign: "left" }}>
                          SSL
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input type="text" />
                      </Col>
                    </Row>
                    <br />
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="4">
                        <h5 className="label" style={{ textAlign: "left" }}>
                          SSH Tunnel
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input placeholder="off" type="text" />
                      </Col>
                    </Row>
                    <br />
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="4">
                        <h5 className="label" style={{ textAlign: "left" }}>
                          Favorite Name
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input
                          placeholder="eg. shared dev, QA box, production"
                          type="text"
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col
                        md="11"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button style={{ background: "#07326c" }}>Save</Button>{" "}
                        &nbsp;
                        <Button style={{ background: "#07326c" }}>Cancel</Button>{" "}
                        &nbsp;
                      </Col>
                    </Row>
                    <br />
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatabaseSync;
