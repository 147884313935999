import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import TabContentComponent from "./TabContent";
import { ip } from "../../../Api"; 
import Newsidebar from "../../Dashboard/Newsidebar";

const AutomationTracker = () => {
  const [activeTab, setActiveTab] = useState("1");
  const clientId = sessionStorage.getItem("clientid");


  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // Mapping URLs and tab names to respective keys
  const tabsConfig = {
    "1": { 
      name: "Follow Up Rundate", 
      url: `${ip}/helper/ageequalto30claims?clientId=${clientId}` 
    },
    "2": { 
      name: "Automate Follow Up-date", 
      url: `${ip}/helper/automatefollowupdateclaims?clientId=${clientId}` 
    },
    "3": { 
      name: "Searches and Error", 
      url: `${ip}/helper/searchanderrorclaims?clientId=${clientId}` 
    },
  };

  return (
    <div>
    <Newsidebar name="Automation track" />

    <div className="automation-tracker">
      <Nav tabs>
        {Object.entries(tabsConfig).map(([tabId, tabInfo]) => (
          <NavItem key={tabId}>
            <NavLink
              className={classnames({ active: activeTab === tabId })}
              onClick={() => toggle(tabId)}
            >
              {tabInfo.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={activeTab}>
        {Object.entries(tabsConfig).map(([tabId, tabInfo]) => (
          <TabPane tabId={tabId} key={tabId}>
            <TabContentComponent
              url={tabInfo.url}
            />
          </TabPane>
        ))}
      </TabContent>
    </div>
    </div>
  );
};

export default AutomationTracker;
