import React, { useState } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col } from "react-bootstrap";
import { Button } from "reactstrap";
import { DatePicker } from "antd";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import Switch from "react-switch";
import { ip } from "../../Api";
import axios from "axios";
import { Modal, ModalHeader, ModalBody, Input } from "reactstrap";

const { RangePicker } = DatePicker;

const AdvancedMD = () => {
  const [settingsModal, setSettingsModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [appointment, setAppointment] = useState("");
  const [client] = useState(sessionStorage.getItem("clientid"));
  const [officeKey] = useState(sessionStorage.getItem("officekey"));
  const [accessToken] = useState(sessionStorage.getItem("accesstoken"));
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleChange = (checked) => {
    setChecked(checked);
  };

  const handleCallVisit = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    
    const value = {
      officekey: parseInt(officeKey),
      clientId: parseInt(client),
      date: startDate,
    };

    try {
      const res = await axios.post(`${ip}/advancedmdapisync/advancedmdClaimstatusApi`, value, { headers });
    } catch (err) {
    }
  };

  const toggleSettings = () => {
    setSettingsModal(!settingsModal);
  };

  return (
    <div>
      <Newsidebar name="Advanced MD" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column">
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontSize: "13px" }}>
              <span>
                <Link to="/api-sync" style={{ textDecoration: "none" }}>
                  API Sync
                </Link>
                &nbsp;<MdKeyboardArrowRight />&nbsp;
                <span style={{ color: "#0D6EFD" }}>Advanced MD</span>
              </span>
            </div>
            <div>
              <button
                style={{
                  backgroundColor: "#E5E1DA",
                  border: "1px solid #E5E1DA",
                  width: "50%",
                  padding: "5px",
                  marginRight: "40px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                onClick={toggleSettings}
              >
                <FiSettings style={{ fontSize: "18px" }} />
              </button>
            </div>
          </div>

          <div className="nosql-container">
            <div className="nosql1">
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label">Call Visit</h5>
                </Col>
                <Col md="6">
                  <RangePicker
                    format="MM-DD-YYYY"
                    onChange={(dates, datestring) => {
                      setStartDate(datestring[0]);
                      setEndDate(datestring[1]);
                    }}
                    style={{
                      borderColor: "gray",
                      float: "right",
                      width: "98%",
                      marginRight: "2%",
                    }}
                  />
                </Col>
                <Col>
                  <Button style={{ background: "#07326c" }} onClick={handleCallVisit}>
                    Run
                  </Button>
                </Col>
              </Row>
              <br />

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label">Updated Visit</h5>
                </Col>
                <Col md="6">
                  <RangePicker
                    format="MM-DD-YYYY"
                    onChange={(dates, datestring) => {
                      setStartDate(datestring[0]); 
                      setEndDate(datestring[1]);
                    }}
                    style={{
                      borderColor: "gray",
                      float: "right",
                      width: "98%",
                      marginRight: "2%",
                    }}
                  />
                </Col>
                <Col>
                  <Button style={{ background: "#07326c" }}>Run</Button>
                </Col>
              </Row>
              <br />

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label">Appointment</h5>
                </Col>
                <Col md="6">
                  <DatePicker
                    format="MM/DD/YYYY"
                    style={{
                      borderColor: "gray",
                      float: "right",
                      marginRight: "2%",
                      width: "98%",
                    }}
                  />
                </Col>
                <Col>
                  <Button style={{ background: "#07326c" }}>Run</Button>
                </Col>
              </Row>
              <br />
            </div>
          </div>
        </div>
        <Modal
          isOpen={settingsModal}
          toggle={toggleSettings}
          size="L"
          centered
        >
          <ModalHeader toggle={toggleSettings}>Settings</ModalHeader>
          <ModalBody>
            <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
              <Col md="4">
                <h5 className="label">Chart Note</h5>
              </Col>
              <Col>
                <Switch onChange={handleChange} checked={checked} />
              </Col>
            </Row>

            <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
              <Col md="4">
                <h5 className="label">Collection Note</h5>
              </Col>
              <Col>
                <Switch onChange={handleChange} checked={checked} />
              </Col>
            </Row>

            <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
              <Col md="4">
                <h5 className="label">Note Type</h5>
              </Col>
              <Col>
                <Input type="text" />
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default AdvancedMD;
