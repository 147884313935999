import React, { useState, useEffect } from "react";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ip } from "../../../../Api";
import axios from "axios";

const data2 = [
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
];
let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}: ${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const ClaimTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}: {payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};

const PayerChartsNIS = ({ client, from, to, Location, payerwisepaid }) => {
  const [payerWise, setPayerWise] = useState([]);
  const [accessToken, setAccessToken] = useState(
    sessionStorage.getItem("accesstoken")
  );
  const refreshToken = sessionStorage.getItem("refreshtoken");

  const renewAccessToken = async () => {
    const renewHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshToken}`,
    };
    try {
      const renew = await axios.get(`${ip}/auth/renewAccessToken`, {
        headers: renewHeaders,
      });
      const newAccessToken = renew.data.accessToken;
      sessionStorage.setItem("accesstoken", newAccessToken);
      setAccessToken(newAccessToken);
    } catch (error) {
      console.error("Error renewing access token:", error);
    }
  };

  const getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    let url = `${ip}/outcome/payerwise`;
    if (client) {
      if (Location) {
        url += from && to
          ? `?start_date=${from}&end_date=${to}&clientId=${client}&Location=${Location}`
          : `?clientId=${client}&Location=${Location}`;
      } else {
        url += from && to
          ? `?start_date=${from}&end_date=${to}&clientId=${client}`
          : `?clientId=${client}`;
      }
    } else if (from && to) {
      url += `?start_date=${from}&end_date=${to}`;
    }

    try {
      const response = await axios.get(url, { headers });
      const data = response.data.payer_wise?.nis?.length
        ? response.data.payer_wise.nis.slice(0, 5)
        : data2;
      setPayerWise(data);
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  useEffect(() => {
    getDetails();
  }, [client, from, to, Location, accessToken]);

  return (
    <div>
      {payerwisepaid === "$" && (
        <ResponsiveContainer width={"100%"} height={400}>
          <ComposedChart
            data={payerWise}
            margin={{
              top: 30,
              right: 0,
              bottom: 20,
              left: 50,
            }}
          >
            <XAxis
              dataKey="_id"
              height={120}
              interval={0}
              angle={-45}
              minTickGap={6}
              tick={{
                strokeWidth: 0,
                fontSize: 11,
                textAnchor: "end",
                dy: 6,
              }}
            />
            <YAxis dataKey="totalAmount" />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="totalAmount"
              fill="#6ab187"
              stroke="#6ab187"
              name="Total Amount"
            />
            <Bar
              dataKey="totalAmount"
              barSize={20}
              fill="#4cb5f5"
              name="Total Amount"
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
      {payerwisepaid === "#" && (
        <ResponsiveContainer width={"95%"} height={400}>
          <ComposedChart
            data={payerWise}
            margin={{
              top: 30,
              right: 0,
              bottom: 20,
              left: 50,
            }}
          >
            <XAxis
              dataKey="_id"
              height={120}
              interval={0}
              angle={-45}
              minTickGap={6}
              tick={{
                strokeWidth: 0,
                fontSize: 11,
                textAnchor: "end",
                dy: 6,
              }}
            />
            <YAxis dataKey="totalClaims" />
            <Tooltip content={<ClaimTooltip />} />
            <Area
              type="monotone"
              dataKey="totalClaims"
              fill="#6ab187"
              stroke="#6ab187"
              name="Total Claims"
            />
            <Bar
              dataKey="totalClaims"
              barSize={20}
              fill="#4cb5f5"
              name="Total Claims"
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default PayerChartsNIS;
