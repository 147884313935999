import React, { Component } from "react";
import axios from "axios";
import "../../Styles/admin.css";
import "../../Styles/Category.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import ReactPaginate from "react-paginate";
import { default as ReactSelect } from "react-select";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { styled } from "@mui/material/styles";
import {Table} from "react-bootstrap";
import { Card } from "react-bootstrap";
import { ip } from "../../Api";
import Select from "react-dropdown-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loading from "../../Images/loader.gif";
import { components } from "react-select";
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
class BillingProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      locationlist:[],
      payercategories: [],
      payervalue1: [],
      modal: false,
      updatemodal: false,
      deletemodal: false,
      createmodal: false,
      dataforupdate: null,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      billingprovider: [],
      displayname: "",
      entitytype: "",
      billingfirstname: "",
      billinglastname: "",
      contactperson: "",
      phone: "",
      addressline1: "",
      addressline2: "",
      fedfaxid: "",
      npi: "",
      ssnnumber: "",
      city: "",
      country: "",
      state: "",
      taxonomycode: "",
      zipcode: "",
      defaultbillingprovider: "",
      email: "",
      footer: "",
      fax: "",
      sentedi: false,
      paytoprovider: false,
      arstatement: false,
      updatedisplayname: "",
      updateentitytype: "",
      updatebillingfirstname: "",
      updatebillinglastname: "",
      updatecontactperson: "",
      updatephone: "",
      updateaddressline1: "",
      updateaddressline2: "",
      updatefedfaxid: "",
      updatenpi: "",
      updatessnnumber: "",
      updatecity: "",
      updatecountry: "",
      updatestate: "",
      updatetaxonomycode: "",
      updatezipcode: "",
      updatedefaultbillingprovider: "",
      updateemail: "",
      updatefooter: "",
      updatefax: "",
      updatesentedi: false,
      updatepaytoprovider: false,
      selectedOptions: [], 
      selectedOptions1:[],
      updatearstatement: false,
      code: "",
      zipcodeErr: "",
      billingmethodErr: "",
      categoryErr: "",
      recvnameErr: "",
      offset: 0,
      currentpage: 0,
      perPage: 10,
      singleid: "",
      searchClaimId: "",
      searchClaimStatus: [],
      serachCategory: [],
      claim__id: "",
      billingProviderCode:"",
      taxid:"",
      locations:[],
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  handleSelectChange = (selectedOptions) => {
    this.setState({ selectedOptions });
  };
  updatedhandleSelectChange = (selectedOptions1) => {
    this.setState({ selectedOptions1 },()=> {
    });
  };
  // location list 
  getLocation = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.client) {
      url = `${ip}/location/all?clientId=${this.state.client}`;
    } else {
      url = `${ip}/location/all`;
    }
     axios
      .get(url, { headers: headers })
      .then((res) => {
        var data = res.data.location.map((e) => ({
          value: e.Location,
          label: e.Location,
        }));
        this.setState({
          locationlist: data,
        });
      })
      .catch((err) => {});
  };
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  toggle2 = () => {
    const { modal2 } = this.state;
    this.setState({ modal2: !modal2 });
  };
  // toogle modal for delete
  toggledelete = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  //toggle modal for update
  toggleupdate = () => {
    this.setState({ updatemodal: !this.state.updatemodal });
  };

  getpayercategory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(`${ip}/payercategory/all`, {
        headers: headers,
      })
      .then((res) => {
        let other = {
          category_name: "Others",
        };
        let data = res.data.payer;
        data.push(other);
        this.setState({ payercategories: data }, () => {});
      })

      .catch((err) => {});
  };
  reset__one = (e) => {
    this.setState({
      claim__id: "",
      update_claimStatus: [],
      payervalue1: [],
      searchClaimId: "",
      searchClaimStatus: [],
      serachCategory: [],
    });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getbillingprovider();
      }
    );
  };

  handleSubmit = async () => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let locationarr;
    locationarr = this.state.selectedOptions.map((data,index) => {
      return data.value
    })
    const value = {
      Display_name: this.state.displayname,
      billingProviderCode: this.state.billingProviderCode,
      locations: locationarr,
      TaxID: this.state.taxid,
      clientId: this.state.client,
      Entity_type: this.state.entitytype,
      Billing_lastname: this.state.billinglastname,
      Billing_firstname: this.state.billingfirstname,
      Address1: this.state.addressline1,
      Address2: this.state.addressline2,
      City: this.state.city,
      Country: this.state.country,
      State: this.state.state,
      Zipcode: this.state.zipcode,
      Email: this.state.email,
      Fax: this.state.fax,
      Phone: this.state.phone,
      Contactperson: this.state.contactperson,
      FedTaxId: this.state.fedfaxid,
      NPI: this.state.npi,
      SSN: this.state.ssnnumber,
      Send_to_EDI: this.state.sentedi,
      Taxonomycode: this.state.taxonomycode,
      default_billingprovider: this.state.defaultbillingprovider,
      Footer: this.state.footer,
      Pay_to_provider: this.state.paytoprovider,
      AR_statement_Address: this.state.arstatement,
    };
    // eslint-disable-next-line
     await axios
      .post(`${ip}/billingprovider/create`, value, {
        headers: headers,
      })
      .then((res) => {
        //this.getusers();
        if (res) {
          this.getbillingprovider();
          this.toggle2();
          this.setState({ createmodal: !this.state.createmodal });
          toast.success("Billing Provider created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.getbillingprovider();
          this.toggle2();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
    // }
  };

  // fuction for prepopulate the particular data
  settingupdatestate = function () {
    var data = this.state.dataforupdate.locations.map((e) => ({
      value: e,
      label: e,
    }));
    this.setState(
      {
        singleid: this.state.dataforupdate._id,
        selectedOptions1: data,
        updatedbillingProviderCode: this.state.dataforupdate.billingProviderCode,
        updatedtaxid: this.state.dataforupdate.TaxID,
        updatedisplayname: this.state.dataforupdate.Display_name,
        updateentitytype: this.state.dataforupdate.Entity_type,
        updatebillingfirstname: this.state.dataforupdate.Billing_firstname,
        updatebillinglastname: this.state.dataforupdate.Billing_lastname,
        updatecontactperson: this.state.dataforupdate.Contactperson,
        updatephone: this.state.dataforupdate.Phone,
        updateaddressline1: this.state.dataforupdate.Address1,
        updateaddressline2: this.state.dataforupdate.Address2,
        updatefedfaxid: this.state.dataforupdate.FedTaxId,
        updatefednpi: this.state.dataforupdate.NPI,
        updatessnnumber: this.state.dataforupdate.SSN,
        updatecity: this.state.dataforupdate.City,
        updatecountry: this.state.dataforupdate.Country,
        updatestate: this.state.dataforupdate.State,
        updatetaxonomycode: this.state.dataforupdate.Taxonomycode,
        updatezipcode: this.state.dataforupdate.Zipcode,
        updatedefaultbillingprovider:
          this.state.dataforupdate.default_billingprovider,
        updateemail: this.state.dataforupdate.Email,
        updatefooter: this.state.dataforupdate.Footer,
        updatefax: this.state.dataforupdate.Fax,
        updatesentedi: this.state.dataforupdate.Send_to_EDI,
        updatepaytoprovider: this.state.dataforupdate.Pay_to_provider,
        updatearstatement: this.state.dataforupdate.AR_statement_Address,
      },
      (res) => {
      }
    );
  };

  putrequests = async (dataid) => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let locationarr;
    locationarr = this.state.selectedOptions1.map((data,index) => {
      return data.value
    })
    const data = {
      billingProviderCode: this.state.updatedbillingProviderCode,
      locations: locationarr,
      TaxID: this.state.updatedtaxid,
      Display_name: this.state.updatedisplayname,
      Entity_type: this.state.updateentitytype,
      Billing_lastname: this.state.updatebillinglastname,
      Billing_firstname: this.state.updatebillingfirstname,
      Address1: this.state.updateaddressline1,
      Address2: this.state.updateaddressline2,
      City: this.state.updatecity,
      Country: this.state.updatecountry,
      State: this.state.updatestate,
      Zipcode: this.state.updatezipcode,
      Email: this.state.updateemail,
      Fax: this.state.updatefax,
      Phone: this.state.updatephone,
      Contactperson: this.state.updatecontactperson,
      FedTaxId: this.state.updatefedfaxid,
      NPI: this.state.updatenpi,
      SSN: this.state.updatessnnumber,
      Send_to_EDI: this.state.updatesentedi,
      Taxonomycode: this.state.updatetaxonomycode,
      default_billingprovider: this.state.updatedefaultbillingprovider,
      Footer: this.state.updatefooter,
      Pay_to_provider: this.state.updatepaytoprovider,
      AR_statement_Address: this.state.updatearstatement,

    };
    // eslint-disable-next-line
     await axios
      .put(`${ip}/billingprovider/${dataid}`, data, {
        headers: headers,
      })
      .then((res) => {
        if (res) {
          this.toggleupdate();
          this.getbillingprovider();
          this.setState({ createmodal: !this.state.createmodal });
          toast.info("Billing Provider updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })

      .catch((err) => {
        if (err.response) {
          this.toggleupdate();
          this.getbillingprovider();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
    // }
  };

  deleterequests = async (DataID) => {
    this.setState({ createmodal: !this.state.createmodal });
    //api call to delete requests
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/billingprovider/${DataID}`, {
        headers: headers,
      })

      .then((req) => {
        if (req) {
          this.toggledelete();
          this.getbillingprovider();
          this.setState({ createmodal: !this.state.createmodal });
          toast.error("Billing Provider deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.toggledelete();
          this.getbillingprovider();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //getusers
  getbillingprovider = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     axios
      .get(`${ip}/billingprovider/all`, { headers: headers })
      .then((res) => {
        const slice = res.data.billingprovider.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          pageCount: Math.ceil(
            res.data.billingprovider.length / this.state.perPage
          ),
          billingprovider: slice,
        });
      })

      .catch((err) => {});
  };

  validate = () => {
    let hasErr = "true";
    let zipcodeErr = "";
    let billingmethodErr = "";
    let categoryErr = [];
    let recvnameErr = "";

    if (this.state.zipcode === "") {
      zipcodeErr = "This field is required";
      this.setState({ zipcodeErr });
      hasErr = false;
    }
    if (this.state.billingmethod.length === 0) {
      billingmethodErr = "This field is required";
      this.setState({ billingmethodErr });
      hasErr = false;
    }

    if (this.state.category.length === 0) {
      categoryErr = "This field is required";
      this.setState({ categoryErr });
      hasErr = false;
    }
    if (this.state.recvname.length === 0) {
      recvnameErr = "This field is required";
      this.setState({ recvnameErr });
      hasErr = false;
    }
    return hasErr;
  };

  updatevalidate = () => {
    let hasErr = "true";
    let firstnameErr = "";
    let lastnameErr = "";
    let phnoErr = "";
    let emailErr = "";

    if (this.state.updatefirstname === "") {
      firstnameErr = "This field is required";
      this.setState({ firstnameErr });
      hasErr = false;
    }
    if (this.state.updatelastname === "") {
      lastnameErr = "This field is required";
      this.setState({ lastnameErr });
      hasErr = false;
    }
    if (this.state.updatephno === "") {
      phnoErr = "This field is required";
      this.setState({ phnoErr });
      hasErr = false;
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
        this.state.updatephno
      )
    ) {
      phnoErr = "Invalid phone number";
      this.setState({ phnoErr });
      hasErr = false;
    }
    if (this.state.updateemail === "") {
      emailErr = "This field is required";
      this.setState({ emailErr });
      hasErr = false;
    } else if (
      // eslint-disable-next-line
      !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
        this.state.updateemail
      )
    ) {
      //var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      emailErr = "Invalid email address";
      this.setState({ emailErr });
      hasErr = false;
    }
    return hasErr;
  };

  reset = () => {
    this.setState({
      displayname: "",
      entitytype: "",
      billingfirstname: "",
      billinglastname: "",
      contactperson: "",
      phone: "",
      addressline1: "",
      addressline2: "",
      fedfaxid: "",
      npi: "",
      ssnnumber: "",
      city: "",
      country: "",
      state: "",
      taxonomycode: "",
      zipcode: "",
      defaultbillingprovider: "",
      email: "",
      footer: "",
      fax: "",
      sentedi: false,
      paytoprovider: false,
      arstatement: false,
    });
  };

  componentDidMount() {
    this.getbillingprovider();
    this.getpayercategory();
    this.getLocation();
  }
  render() {
    return (
      <div>
      <Newsidebar name="Billing Provider" />
      <div className="align-row" style={{overflow:"hidden"}}>
        <div className="align-column">
          <ToastContainer />
            <button className="addNew" onClick={this.toggle2}>
              Add Billing Provider
            </button>
            {this.state.billingprovider.length === 0 ?
              <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3 align="center">No data found</h3>
            </div> :
            <div style={{marginTop:"1%", marginLeft:"1%"}}>
            <Table bordered className="StandardTable">
              <thead>
                <tr>
                  <th>
                    Display Name
                  </th>
                  <th>
                    Location
                  </th>
                  <th>
                    Entity Type
                  </th>
                  <th>
                    Billing First Name
                  </th>
                  <th>
                    Billing First Name
                  </th>
                  <th>
                    Phone
                  </th>
                  <th>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.billingprovider &&
                  this.state.billingprovider.map((data, index) => (
                    <tr key={data._id}>
                      <td component="th" scope="row">
                        {data.Display_name}
                      </td>
                      <td>
                      {data.locations ? (
                        <ul style={{ listStyleType: "disc" }}>
                          {data.locations.map((location, ind) => (
                            <li key={ind}>{location}</li>
                          ))}
                        </ul>
                      ) : (
                        "-"
                      )}
                      </td>
                      <td>{data.Entity_type}</td>
                      <td>
                        {data.Billing_firstname}
                      </td>
                      <td>
                        {data.Billing_lastname}
                      </td>
                      <td>{data.Phone}</td>
                      <td>
                        <button
                          className="Edit"
                          onClick={() => {
                            this.setState({ dataforupdate: data }, () => {
                              this.settingupdatestate();
                              this.toggleupdate();
                            });
                          }}
                        >
                          Edit
                        </button>
                        &nbsp;
                        <button
                          className="Delete"
                          onClick={() => {
                            this.setState({ singleid: data._id }, () => {
                              this.toggledelete();
                            });
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          <div style={{ marginLeft: "3%", marginTop: "20px" }}>
            <ReactPaginate
              previousLabel={<IoIosArrowBack />}
              nextLabel={<IoIosArrowForward />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>

            }
      
          {this.state.modal2 && (
            <Modal
              isOpen={this.state.modal2}
              onHide={this.toggle2}
              centered
              animation="false"
              size="lg"
            >
              <ModalHeader toggle={this.toggle2}>
                Create Billing Provider
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Form>
                <Row>
                <Col>
                  <FormGroup>
                    <Label>Location</Label>
                    <ReactSelect
                    options={this.state.locationlist}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={this.handleSelectChange}
                    value={this.state.selectedOptions}
                  />
                  </FormGroup>
                </Col>
              </Row>
              <br/>
                 <Row>
                    <Col>
                      <FormGroup>
                        <Label>Billing Provider</Label>
                        <Input
                          type="text"
                          value={this.state.billingProviderCode}
                          onChange={(e) =>
                            this.setState({
                              billingProviderCode : e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Tax ID</Label>
                        <Input
                          type="text"
                          value={this.state.taxid}
                          onChange={(e) =>
                            this.setState({
                              taxid : e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Display Name</Label>
                        <Input
                          type="text"
                          value={this.state.displayname}
                          onChange={(e) =>
                            this.setState({
                              displayname: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Entity Type</Label>
                        <Input
                          type="text"
                          value={this.state.entitytype}
                          onChange={(e) =>
                            this.setState({
                              entitytype: e.target.value,
                              codeErr: "",
                            })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Billing First Name</Label>
                        <Input
                          type="text"
                          value={this.state.billingfirstname}
                          onChange={(e) =>
                            this.setState({
                              billingfirstname: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Phone</Label>
                        <Input
                          type="text"
                          value={this.state.phone}
                          onChange={(e) =>
                            this.setState({
                              phone: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Billing Last Name</Label>
                        <Input
                          type="text"
                          value={this.state.billinglastname}
                          onChange={(e) =>
                            this.setState({
                              billinglastname: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Contact Person</Label>
                        <Input
                          type="text"
                          value={this.state.contactperson}
                          onChange={(e) =>
                            this.setState({
                              contactperson: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Address Line 1</Label>
                        <Input
                          type="text"
                          value={this.state.addressline1}
                          onChange={(e) =>
                            this.setState({
                              addressline1: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Fed Tax Id</Label>
                        <Input
                          type="text"
                          value={this.state.fedfaxid}
                          onChange={(e) =>
                            this.setState({
                              fedfaxid: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Address Line 2</Label>
                        <Input
                          type="text"
                          value={this.state.addressline2}
                          onChange={(e) =>
                            this.setState({
                              addressline2: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>National Provider Identifier(NPI)</Label>
                        <Input
                          type="text"
                          value={this.state.npi}
                          onChange={(e) =>
                            this.setState({
                              npi: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>City</Label>
                        <Input
                          type="text"
                          value={this.state.city}
                          onChange={(e) =>
                            this.setState({
                              city: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Social Security Number</Label>
                        <Input
                          type="text"
                          value={this.state.ssnnumber}
                          onChange={(e) =>
                            this.setState({
                              ssnnumber: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Country</Label>
                        <Input
                          type="text"
                          value={this.state.country}
                          onChange={(e) =>
                            this.setState({
                              country: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Send to EDI</Label>
                        <Input
                          type="checkbox"
                          name="sentedi"
                          id="sentedi"
                          value={this.state.sentedi}
                          onChange={() => {
                            this.setState({
                              sentedi: !this.state.sentedi,
                              roleErr: "",
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>State</Label>
                        <Input
                          type="text"
                          value={this.state.state}
                          onChange={(e) =>
                            this.setState({
                              state: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Taxonomy Code</Label>
                        <Input
                          type="text"
                          value={this.state.taxonomycode}
                          onChange={(e) =>
                            this.setState({
                              taxonomycode: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Zip Code</Label>
                        <Input
                          type="text"
                          value={this.state.zipcode}
                          onChange={(e) =>
                            this.setState({
                              zipcode: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Default Billing Provider</Label>
                        <Input
                          type="text"
                          value={this.state.defaultbillingprovider}
                          onChange={(e) =>
                            this.setState({
                              defaultbillingprovider: e.target.value,
                              codeErr: "",
                            })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Email</Label>
                        <Input
                          type="text"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({
                              email: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Footer</Label>
                        <Input
                          type="text"
                          value={this.state.footer}
                          onChange={(e) =>
                            this.setState({
                              footer: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Fax</Label>
                        <Input
                          type="text"
                          value={this.state.fax}
                          onChange={(e) =>
                            this.setState({
                              fax: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Pay to Provider</Label>
                        <Input
                          type="checkbox"
                          name="paytoprovider"
                          id="paytoprovider"
                          value={this.state.paytoprovider}
                          onChange={() => {
                            this.setState({
                              paytoprovider: !this.state.paytoprovider,
                              roleErr: "",
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>
                          A/R Statement Address for Patient Payments
                        </Label>
                        <Input
                          type="checkbox"
                          name="arstatement"
                          id="arstatement"
                          value={this.state.arstatement}
                          onChange={() => {
                            this.setState({
                              arstatement: !this.state.arstatement,
                              roleErr: "",
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle2();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.handleSubmit}
                >
                  Create
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {this.state.updatemodal && this.state.dataforupdate && (
            <Modal
              classsName="insuranceedit"
              isOpen={this.state.updatemodal}
              onHide={this.toggleupdate}
              onExit={this.reset}
              centered
              animation="false"
              size="lg"
            >
              <ModalHeader toggle={this.toggleupdate}>
                Edit Payer Master
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Form>
                <Row>
                <Col>
                  <FormGroup>
                    <Label>Location</Label>
                    <ReactSelect
                     isMulti
                    options={this.state.locationlist}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    defaultValue={this.state.selectedOptions1}
                    onChange={this.updatedhandleSelectChange}
                  />
                  </FormGroup>
                </Col>
              </Row>
              <br/>
                 <Row>
                    <Col>
                      <FormGroup>
                        <Label>Billing Provider</Label>
                        <Input
                          type="text"
                          value={this.state.updatedbillingProviderCode}
                          onChange={(e) =>
                            this.setState({
                              updatedbillingProviderCode : e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Tax ID</Label>
                        <Input
                          type="text"
                          value={this.state.updatedtaxid}
                          onChange={(e) =>
                            this.setState({
                              updatedtaxid : e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Display Name</Label>
                        <Input
                          type="text"
                          value={this.state.updatedisplayname}
                          onChange={(e) =>
                            this.setState({
                              updatedisplayname: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Entity Type</Label>
                        <Input
                          type="text"
                          value={this.state.updateentitytype}
                          onChange={(e) =>
                            this.setState({
                              updateentitytype: e.target.value,
                              codeErr: "",
                            })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Billing First Name</Label>
                        <Input
                          type="text"
                          value={this.state.updatebillingfirstname}
                          onChange={(e) =>
                            this.setState({
                              updatebillingfirstname: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Phone</Label>
                        <Input
                          type="text"
                          value={this.state.updatephone}
                          onChange={(e) =>
                            this.setState({
                              updatephone: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Billing Last Name</Label>
                        <Input
                          type="text"
                          value={this.state.updatebillinglastname}
                          onChange={(e) =>
                            this.setState({
                              updatebillinglastname: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Contact Person</Label>
                        <Input
                          type="text"
                          value={this.state.updatecontactperson}
                          onChange={(e) =>
                            this.setState({
                              updatecontactperson: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Address Line 1</Label>
                        <Input
                          type="text"
                          value={this.state.updateaddressline1}
                          onChange={(e) =>
                            this.setState({
                              updateaddressline1: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Fed Tax Id</Label>
                        <Input
                          type="text"
                          value={this.state.updatefedfaxid}
                          onChange={(e) =>
                            this.setState({
                              updatefedfaxid: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Address Line 2</Label>
                        <Input
                          type="text"
                          value={this.state.updateaddressline2}
                          onChange={(e) =>
                            this.setState({
                              updateaddressline2: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>National Provider Identifier(NPI)</Label>
                        <Input
                          type="text"
                          value={this.state.updatenpi}
                          onChange={(e) =>
                            this.setState({
                              updatenpi: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>City</Label>
                        <Input
                          type="text"
                          value={this.state.updatecity}
                          onChange={(e) =>
                            this.setState({
                              updatecity: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Social Security Number</Label>
                        <Input
                          type="text"
                          value={this.state.updatessnnumber}
                          onChange={(e) =>
                            this.setState({
                              updatessnnumber: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Country</Label>
                        <Input
                          type="text"
                          value={this.state.updatecountry}
                          onChange={(e) =>
                            this.setState({
                              updatecountry: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Send to EDI</Label>
                        <Input
                          type="checkbox"
                          name="sentedi"
                          id="sentedi"
                          checked={this.state.updatesentedi}
                          value={this.state.updatesentedi}
                          onChange={() => {
                            this.setState({
                              updatesentedi: !this.state.updatesentedi,
                              roleErr: "",
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>State</Label>
                        <Input
                          type="text"
                          value={this.state.updatestate}
                          onChange={(e) =>
                            this.setState({
                              updatestate: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Taxonomy Code</Label>
                        <Input
                          type="text"
                          value={this.state.updatetaxonomycode}
                          onChange={(e) =>
                            this.setState({
                              updatetaxonomycode: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Zip Code</Label>
                        <Input
                          type="text"
                          value={this.state.updatezipcode}
                          onChange={(e) =>
                            this.setState({
                              updatezipcode: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Default Billing Provider</Label>
                        <Input
                          type="text"
                          value={this.state.updatedefaultbillingprovider}
                          onChange={(e) =>
                            this.setState({
                              updatedefaultbillingprovider: e.target.value,
                              codeErr: "",
                            })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Email</Label>
                        <Input
                          type="text"
                          value={this.state.updateemail}
                          onChange={(e) =>
                            this.setState({
                              updateemail: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>Footer</Label>
                        <Input
                          type="text"
                          value={this.state.updatefooter}
                          onChange={(e) =>
                            this.setState({
                              updatefooter: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Fax</Label>
                        <Input
                          type="text"
                          value={this.state.updatefax}
                          onChange={(e) =>
                            this.setState({
                              updatefax: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Pay to Provider</Label>
                        <Input
                          type="checkbox"
                          name="paytoprovider"
                          id="paytoprovider"
                          checked={this.state.updatepaytoprovider}
                          value={this.state.updatepaytoprovider}
                          onChange={() => {
                            this.setState({
                              updatepaytoprovider:
                                !this.state.updatepaytoprovider,
                              roleErr: "",
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label>
                          A/R Statement Address for Patient Payments
                        </Label>
                        <Input
                          type="checkbox"
                          name="arstatement"
                          id="arstatement"
                          checked={this.state.updatearstatement}
                          value={this.state.updatearstatement}
                          onChange={() => {
                            this.setState({
                              updatearstatement: !this.state.updatearstatement,
                              roleErr: "",
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggleupdate();
                    //this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    this.putrequests(this.state.singleid);
                  }}
                >
                  Update
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {this.state.deletemodal && (
            <Modal
              isOpen={this.state.deletemodal}
              onHide={this.toggledelete}
              // onExit={this.reloadpage}
              centered
            >
              <ModalHeader toggle={this.toggledelete}>
                Delete Master
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid gray",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.toggledelete}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "rgb(25,25,25)" }}
                  onClick={() => {
                    this.deleterequests(this.state.singleid);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
          <Modal isOpen={this.state.createmodal} centered>
            <ModalBody>
              <img
                src={loading}
                style={{ width: "200px", height: "200px", marginLeft: "30%" }}
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
      </div>
    );
  }
}
export default BillingProvider;
