import React, { useState, useEffect } from "react";
import { ip } from "../../../../Api";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

const style = {
  top: "92%",
  right: 0,
  transform: "translate(0, -50%)",
  lineHeight: "24px",
};

const data2 = [
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
];

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const getPath = (x, y, width, height) => `M${x},${y + height}
  C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
  x + width / 2
}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
  x + width
}, ${y + height}
  Z`;
let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}: ${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const ClaimTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}: {payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};

const PayerCategoryCharts = ({ client, from, to, Location, categorywise }) => {
  const [payerClassWise, setPayerClassWise] = useState([]);
  const [accessToken, setAccessToken] = useState(
    sessionStorage.getItem("accesstoken")
  );
  const refreshToken = sessionStorage.getItem("refreshtoken");

  const renewAccessToken = async () => {
    const renewHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshToken}`,
    };
    try {
      const renew = await axios.get(`${ip}/auth/renewAccessToken`, {
        headers: renewHeaders,
      });
      const newAccessToken = renew.data.accessToken;
      sessionStorage.setItem("accesstoken", newAccessToken);
      setAccessToken(newAccessToken);
    } catch (error) {
      console.error("Error renewing access token:", error);
    }
  };

  const getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    let url = `${ip}/outcome/payerclasswise`;
    if (client) {
      if (Location) {
        url += from && to
          ? `?start_date=${from}&end_date=${to}&clientId=${client}&Location=${Location}`
          : `?clientId=${client}&Location=${Location}`;
      } else {
        url += from && to
          ? `?start_date=${from}&end_date=${to}&clientId=${client}`
          : `?clientId=${client}`;
      }
    } else if (from && to) {
      url += `?start_date=${from}&end_date=${to}`;
    }

    try {
      const response = await axios.get(url, { headers });
      const data = response.data.payerclass_wise?.nis?.length
        ? response.data.payerclass_wise.nis.slice(0, 5)
        : data2;
      setPayerClassWise(data);
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  useEffect(() => {
    getDetails();
  }, [client, from, to, Location, accessToken]);

  return (
    <div>
      {categorywise === "$" && (
        <div onMouseOver={() => (tooltip = "totalAmount")}>
          <BarChart
            width={700}
            height={400}
            data={payerClassWise}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey={"_id"}
              height={120}
              interval={0}
              angle={"-45"}
              minTickGap={6}
              tick={{
                strokeWidth: 0,
                fontSize: "11",
                textAnchor: "end",
                dy: 6,
              }}
            />
            <YAxis dataKey={"totalAmount"} />
            <Tooltip cursor={{ fill: "transparent" }} content={<CustomTooltip />} />
            <Bar
              dataKey={"totalAmount"}
              shape={<TriangleBar />}
              radius={[16, 16, 16, 16]}
              fill="#00A0FC"
              barSize={30}
              name="Total Amount"
            />
          </BarChart>
        </div>
      )}
      {categorywise === "#" && (
        <div onMouseOver={() => (tooltip = "totalClaims")}>
          <BarChart
            width={700}
            height={400}
            data={payerClassWise}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey={"_id"}
              height={120}
              interval={0}
              angle={"-45"}
              minTickGap={6}
              tick={{
                strokeWidth: 0,
                fontSize: "11",
                textAnchor: "end",
                dy: 6,
              }}
            />
            <YAxis dataKey={"totalClaims"} />
            <Tooltip cursor={{ fill: "transparent" }} content={<ClaimTooltip />} />
            <Bar
              dataKey={"totalClaims"}
              shape={<TriangleBar />}
              radius={[16, 16, 16, 16]}
              fill="#00A0FC"
              barSize={30}
              name="Total Claims"
            />
          </BarChart>
        </div>
      )}
    </div>
  );
};

export default PayerCategoryCharts;
