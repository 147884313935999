import { Component } from "react";
import { Row, Col, Label } from "reactstrap";
import { DatePicker } from "antd";
import { Input } from "reactstrap";
import "../../Styles/cardalloc.css";
import { BiCube } from "react-icons/bi";
import AllocationMenu from "./AllocationMenu";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { BiDollar } from "react-icons/bi";
import { FiTrendingDown } from "react-icons/fi";
import { Card } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import { IoIosRefresh } from "react-icons/io";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import Newsidebar from "./../Dashboard/Newsidebar";

import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { ip } from "../../Api";
import { notification, Divider } from "antd";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Select from "react-dropdown-select";
const openNotificationWithIcon = (success) => {
  notification[success]({
    message: "Filtered Succesfully",
  });
};
const openNotificationWithIcon1 = (error) => {
  notification[error]({
    message: "No Values Entered",
  });
};
const openNotificationWithIcon3 = (success) => {
  notification[success]({
    message: "Reset Successfully",
  });
};
const openNotificationWithIcon2 = (error) => {
  notification[error]({
    message: "*Please Remove the Dates",
  });
};

export class Allocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allocatemodal: false,
      tflexceedclaims: null,
      tflexceedamount: null,
      fastclaims: null,
      twotouchclaims: null,
      threetouchclaims: null,
      fourormoreclaims: null,
      lessthan180daysclaims: null,
      lowfollowupclaims: null,
      tflexceedamount: null,
      fastclaimamount: null,
      twotouchamount: null,
      threetouchamount: null,
      fourormoreamount: null,
      lessthan180daysamount: null,
      lowfollowupamount: null,
      querysearch: "",
      claimsmorethan30daysclaims: null,
      claimsmorethan30daysamount: null,
      claimslessthan30daysclaims: null,
      claimslessthan30daysamount: null,
      claimsmorethan90daysclaims: null,
      claimsmorethan90daysamount: null,
      claimsmorethan60daysclaims: null,
      claimsmorethan60daysamount: null,
      claimsmorethan180daysamount: null,
      claimsmorethan180daysclaims: null,
      agegreaterthan365daysamount: null,
      agegreaterthan365daysclaims: null,
      agebetween181and365claims: null,
      agebetween181and365amount: null,
      agebetween91and180claims: null,
      agebetween91and180amount: null,
      agebetween61and90claims: null,
      agebetween61and90amount: null,
      agebetween31and60claims: null,
      agebetween31and60amount: null,
      touch4timesamount: null,
      touch4timesclaims: null,
      onetouchclaimamount: null,
      onetouchclaimclaims: null,
      claimnottouchamount: null,
      claimnottouchclaims: null,
      reallocmodal: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      AllocatedTo: sessionStorage.getItem("AllocatedTo"),
      role: sessionStorage.getItem("role_type"),
      anchorEl: null,
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  toggle4 = () => {
    this.setState(() => {
      this.setState({ allocatemodal: !this.state.allocatemodal });
    });
  };
  toggle = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };
  toggle3 = () => {
    if (JSON.parse(sessionStorage.getItem("AllocatedTo")) === null) {
      this.toggle4();
    } else if (JSON.parse(sessionStorage.getItem("AllocatedTo")) !== null) {
      this.setState({ reallocmodal: !this.state.reallocmodal });
    }
  };
  getsearchid = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    if (this.state.searchid === "") {
      toast.error("Please fill the search value!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("Show id");
    } else {
      await axios
        .get(`${ip}/claim/viewclaim?claimid=${this.state.searchid}`, {
          headers: headers,
        })

        .then((res) => {
          res.data.Claim.map((ele) => {
            this.setState({
              querysearch: res.data.DataView[0],
            });
          });
        })

        .catch((err) => {});
    }
    console.log(this.state.querysearch, "secondcardname");
  };

  getTflCount = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/tfl?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/tfl?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/tfl?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/tfl?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/tfl?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/tfl?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.tflcount.length !== 0) {
          this.setState({
            tflexceedclaims: res.data.tflcount[0].totalcount,
            tflexceedamount: res.data.tflcount[0].totalamount,
          });
        } else {
          this.setState({
            tflexceedclaims: 0,
            tflexceedamount: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getFastApproachCount = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/fastapproach?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/fastapproach?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/fastapproach?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/fastapproach?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/fastapproach?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/fastapproach?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.fastapproachcount.length !== 0) {
          this.setState({
            fastclaims: res.data.fastapproachcount[0].totalcount,
            fastclaimamount: res.data.fastapproachcount[0].totalamount,
          });
        } else {
          this.setState({
            fastclaimamount: 0,
            fastclaims: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getTwoTouchCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/twotouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/twotouch?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/twotouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/twotouch?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/twotouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/twotouch?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.twotouchcount.length !== 0) {
          this.setState({
            twotouchclaims: res.data.twotouchcount[0].totalcount,
            twotouchamount: res.data.twotouchcount[0].totalamount,
          });
        } else {
          this.setState({
            twotouchclaims: 0,
            twotouchamount: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getThreeTouchCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/threetouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/threetouch?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/threetouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/threetouch?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/threetouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/threetouch?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.threetouchcount.length !== 0) {
          this.setState({
            threetouchclaims: res.data.threetouchcount[0].totalcount,
            threetouchamount: res.data.threetouchcount[0].totalamount,
          });
        } else {
          this.setState({
            threetouchclaims: 0,
            threetouchamount: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getFourorMoreCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/fourormore?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/fourormore?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/fourormore?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/fourormore?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/fourormore?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/fourormore?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.fourormorecount.length !== 0) {
          this.setState({
            fourormoreclaims: res.data.fourormorecount[0].totalcount,
            fourormoreamount: res.data.fourormorecount[0].totalamount,
          });
        } else {
          this.setState({
            fourormoreclaims: 0,
            fourormoreamount: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getLowFollowupCount = async () => {
    //api call to get all users
    console.log("inside get call");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/lowfollowup?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/lowfollowup?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/lowfollowup?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/lowfollowup?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/lowfollowup?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/lowfollowup?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.lowfollowupcount.length !== 0) {
          this.setState({
            lowfollowupclaims: res.data.lowfollowupcount[0].totalcount,
            lowfollowupamount: res.data.lowfollowupcount[0].totalamount,
          });
        } else {
          this.setState({
            lowfollowupclaims: 0,
            lowfollowupamount: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getLessthan180daysCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/lessthan180days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/lessthan180days?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/lessthan180days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/lessthan180days?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/lessthan180days?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/lessthan180days?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.lessthan180dayscount.length !== 0) {
          this.setState({
            lessthan180daysclaims: res.data.lessthan180dayscount[0].totalcount,
            lessthan180daysamount: res.data.lessthan180dayscount[0].totalamount,
          });
        } else {
          this.setState({
            lessthan180daysclaims: 0,
            lessthan180daysamount: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getNFlessthan30daysCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/lessthan30days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/lessthan30days?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/lessthan30days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/lessthan30days?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/lessthan30days?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/lessthan30days?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.lessthan30dayscount.length !== 0) {
          this.setState({
            claimslessthan30daysclaims:
              res.data.lessthan30dayscount[0].totalcount,
            claimslessthan30daysamount: res.data.lessthan30dayscount[0].totalamount,
          });
        } else {
          this.setState({
            claimslessthan30daysamount: 0,
            claimslessthan30daysclaims: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getNFmorethan30daysCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/morethan30days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/morethan30days?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/morethan30days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/morethan30days?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/morethan30days?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/morethan30days?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.morethan30dayscount.length !== 0) {
          this.setState({
            claimsmorethan30daysclaims:
              res.data.morethan30dayscount[0].totalcount,
            claimsmorethan30daysamount: res.data.morethan30dayscount[0].totalamount,
          });
        } else {
          this.setState({
            claimsmorethan30daysamount: 0,
            claimsmorethan30daysclaims: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getNFmorethan60daysCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/morethan60days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/morethan60days?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/morethan60days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/morethan60days?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/morethan60days?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/morethan60days?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.morethan60dayscount.length !== 0) {
          this.setState({
            claimsmorethan60daysclaims:
              res.data.morethan60dayscount[0].totalcount,
            claimsmorethan60daysamount: res.data.morethan60dayscount[0].totalamount,
          });
        } else {
          this.setState({
            claimsmorethan60daysamount: 0,
            claimsmorethan60daysclaims: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getNFmorethan90daysCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/morethan90days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/morethan90days?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/morethan90days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/morethan90days?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/morethan90days?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/morethan90days?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.moretha90dayscount.length !== 0) {
          this.setState({
            claimsmorethan90daysclaims:
              res.data.moretha90dayscount[0].totalcount,
            claimsmorethan90daysamount: res.data.moretha90dayscount[0].totalamount,
          });
        } else {
          this.setState({
            claimsmorethan90daysamount: 0,
            claimsmorethan90daysclaims: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getNFmorethan180daysCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/morethan180days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/morethan180days?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/morethan180days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/morethan180days?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/morethan180days?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/morethan180days?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.moretha180dayscount.length !== 0) {
          this.setState({
            claimsmorethan180daysclaims:
              res.data.moretha180dayscount[0].totalcount,
            claimsmorethan180daysamount: res.data.moretha180dayscount[0].totalamount,
          });
        } else {
          this.setState({
            claimsmorethan180daysamount: 0,
            claimsmorethan180daysclaims: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getagegreaterthan365 = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/agegreaterthan365days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/agegreaterthan365days?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/agegreaterthan365days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/agegreaterthan365days?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/agegreaterthan365days?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/agegreaterthan365days?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.agegreaterthan365dayscount.length !== 0) {
          this.setState({
            agegreaterthan365daysclaims:
              res.data.agegreaterthan365dayscount[0].totalcount,
            agegreaterthan365daysamount:
              res.data.agegreaterthan365dayscount[0].totalamount,
          });
        } else {
          this.setState({
            agegreaterthan365daysamount: 0,
            agegreaterthan365daysclaims: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getbetween181and365 = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/agebetween181to365days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/agebetween181to365days?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/agebetween181to365days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/agebetween181to365days?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/agebetween181to365days?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/agebetween181to365days?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.agebetween181to365dayscount.length !== 0) {
          this.setState({
            agebetween181and365claims:
              res.data.agebetween181to365dayscount[0].totalcount,
            agebetween181and365amount:
              res.data.agebetween181to365dayscount[0].totalamount,
          });
        } else {
          this.setState({
            agebetween181and365amount: 0,
            agebetween181and365claims: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getAgebetween91and180bucket = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/agebetween91to180days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/agebetween91to180days?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/agebetween91to180days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/agebetween91to180days?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/agebetween91to180days?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/agebetween91to180days?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.agebetween91to180dayscount.length !== 0) {
          this.setState({
            agebetween91and180claims:
              res.data.agebetween91to180dayscount[0].totalcount,
            agebetween91and180amount:
              res.data.agebetween91to180dayscount[0].totalamount,
          });
        } else {
          this.setState({
            agebetween91and180amount: 0,
            agebetween91and180claims: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getAgebetween61and90 = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/agebetween61to90days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/agebetween61to90days?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/agebetween61to90days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/agebetween61to90days?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/agebetween61to90days?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/agebetween61to90days?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.agebetween61to90dayscount.length !== 0) {
          this.setState({
            agebetween61and90claims:
              res.data.agebetween61to90dayscount[0].totalcount,
            agebetween61and90amount:
              res.data.agebetween61to90dayscount[0].totalamount,
          });
        } else {
          this.setState({
            agebetween61and90claims: 0,
            agebetween61and90amount: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getAgebetween31and60 = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/agebetween31to60days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/agebetween31to60days?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/agebetween31to60days?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/agebetween31to60days?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/agebetween31to60days?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/agebetween31to60days?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.agebetween31to60dayscount.length !== 0) {
          this.setState({
            agebetween31and60claims:
              res.data.agebetween31to60dayscount[0].totalcount,
            agebetween31and60amount:
              res.data.agebetween31to60dayscount[0].totalamount,
          });
        } else {
          this.setState({
            agebetween31and60claims: 0,
            agebetween31and60amount: 0,
          });
        }
      })
      .catch((err) => {});
  };
  gettouch4timeclaims = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/fourtouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/fourtouch?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/fourtouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/fourtouch?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/fourtouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/fourtouch?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.fourtouchcount.length !== 0) {
          this.setState({
            touch4timesclaims: res.data.fourtouchcount[0].totalcount,
            touch4timesamount: res.data.fourtouchcount[0].totalamount,
          });
        } else {
          this.setState({
            touch4timesclaims: 0,
            touch4timesamount: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getonetouchclaims = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/onetouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/onetouch?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/onetouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/onetouch?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/onetouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/onetouch?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.onetouchcount.length !== 0) {
          this.setState({
            onetouchclaimclaims: res.data.onetouchcount[0].totalcount,
            onetouchclaimamount: res.data.onetouchcount[0].totalamount,
          });
        } else {
          this.setState({
            onetouchclaimamount: 0,
            onetouchclaimclaims: 0,
          });
        }
      })
      .catch((err) => {});
  };
  getnottouchclaims = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/zerotouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/allocation/bucket/count/zerotouch?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/allocation/bucket/count/zerotouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/allocation/bucket/count/zerotouch?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/allocation/bucket/count/zerotouch?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/allocation/bucket/count/zerotouch?clientId=${this.props.client}`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.zerotouchcount.length !== 0) {
          this.setState({
            claimnottouchclaims: res.data.zerotouchcount[0].totalcount,
            claimnottouchamount: res.data.zerotouchcount[0].totalamount,
          });
        } else {
          this.setState({
            claimnottouchamount: 0,
            claimnottouchclaims: 0,
          });
        }
      })
      .catch((err) => {});
  };
  componentDidMount() {
    this.getTflCount();
    this.getFastApproachCount();
    this.getFourorMoreCount();
    // this.getLessthan180daysCount();
    this.getLowFollowupCount();
    this.getThreeTouchCount();
    this.getTwoTouchCount();
    this.getNFlessthan30daysCount();
    this.getNFmorethan30daysCount();
    this.getNFmorethan60daysCount();
    this.getNFmorethan90daysCount();
    this.getNFmorethan180daysCount();
    this.getagegreaterthan365();
    this.getbetween181and365();
    this.getAgebetween91and180bucket();
    this.getAgebetween61and90();
    this.getAgebetween31and60();
    this.gettouch4timeclaims();
    this.getonetouchclaims();
    this.getnottouchclaims();
    this.getsearchid();
  }

  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.startdate === prevProps.startdate &&
        this.props.enddate === prevProps.enddate
      )
    ) {
      this.getTflCount();
      this.getFastApproachCount();
      this.getFourorMoreCount();
      this.getLessthan180daysCount();
      this.getLowFollowupCount();
      this.getThreeTouchCount();
      this.getTwoTouchCount();
      this.getNFlessthan30daysCount();
      this.getNFmorethan30daysCount();
      this.getNFmorethan60daysCount();
      this.getNFmorethan90daysCount();
      this.getNFmorethan180daysCount();
      this.getagegreaterthan365();
      this.getbetween181and365();
      this.getAgebetween91and180bucket();
      this.getAgebetween61and90();
      this.getAgebetween31and60();
      this.gettouch4timeclaims();
      this.getonetouchclaims();
      this.getnottouchclaims();
    } else if (this.props.client !== prevProps.client) {
      this.getTflCount();
      this.getFastApproachCount();
      this.getFourorMoreCount();
      this.getLessthan180daysCount();
      this.getLowFollowupCount();
      this.getThreeTouchCount();
      this.getTwoTouchCount();
      this.getNFlessthan30daysCount();
      this.getNFmorethan30daysCount();
      this.getNFmorethan60daysCount();
      this.getNFmorethan90daysCount();
      this.getNFmorethan180daysCount();
      this.getagegreaterthan365();
      this.getbetween181and365();
      this.getAgebetween91and180bucket();
      this.getAgebetween61and90();
      this.getAgebetween31and60();
      this.gettouch4timeclaims();
      this.getonetouchclaims();
      this.getnottouchclaims();
    } else if (this.props.Location !== prevProps.Location) {
      this.getTflCount();
      this.getFastApproachCount();
      this.getFourorMoreCount();
      this.getLessthan180daysCount();
      this.getLowFollowupCount();
      this.getThreeTouchCount();
      this.getTwoTouchCount();
      this.getNFlessthan30daysCount();
      this.getNFmorethan30daysCount();
      this.getNFmorethan60daysCount();
      this.getNFmorethan90daysCount();
      this.getNFmorethan180daysCount();
      this.getagegreaterthan365();
      this.getbetween181and365();
      this.getAgebetween91and180bucket();
      this.getAgebetween61and90();
      this.getAgebetween31and60();
      this.gettouch4timeclaims();
      this.getonetouchclaims();
      this.getnottouchclaims();
    }
  }
  render() {
    
    return (
      <div>
        {this.props.gridicon ? (
          <div style={{ width: "78%", marginLeft:"4%", marginRight:"4%"}}>
            <div className="row" style={{ gap: "40px" }}>
              <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/agegreaterthan365days",
                          }}
                          style={{
                            textDecoration: "none",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          Age greater than 365 Days
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={12}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "2" ||
                        this.props.search1 === "2" ||
                        this.props.search2 === "2" ||
                        this.props.search3 === "2" ||
                        this.props.search4 === "2"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/agegreaterthan365days",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.agegreaterthan365daysclaims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.agegreaterthan365daysamount !== null ? this.state.agegreaterthan365daysamount.toFixed(2):0}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/four-or-more-touch",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Claim Touched more than (4 times)
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={5}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "3" ||
                        this.props.search1 === "3" ||
                        this.props.search2 === "3" ||
                        this.props.search3 === "3" ||
                        this.props.search4 === "3"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/four-or-more-touch",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.fourormoreclaims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.fourormoreamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/Fast-Approaching",
                          }}
                          style={{
                            textDecoration: "none",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          Timely Filling Limited (Fast Approaching)
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={2}
                            client={this.props.client}
                            Location={this.props.Location}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={`${
                        this.props.search === "1" ||
                        this.props.search1 === "1" ||
                        this.props.search2 === "1" ||
                        this.props.search3 === "1" ||
                        this.props.search4 === "1"
                          ? "al-card"
                          : ""
                      }`}
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/Fast-Approaching",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.fastclaims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.fastclaimamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div className="row" style={{ gap: "40px" }}>
              <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/age-between-181-365-days",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Age between 181 and 365 Days
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={12}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "5" ||
                        this.props.search1 === "5" ||
                        this.props.search2 === "5" ||
                        this.props.search3 === "5" ||
                        this.props.search4 === "5"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/age-between-181-365-days",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.agebetween181and365claims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.agebetween181and365amount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/four-touch-claims",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Claim Touched 4 Times
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={12}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "6" ||
                        this.props.search1 === "6" ||
                        this.props.search2 === "6" ||
                        this.props.search3 === "6" ||
                        this.props.search4 === "6"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/four-touch-claims",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.touch4timesclaims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.touch4timesamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{ pathname: "/TFLexceed" }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Timely Filling Limited Exceeded
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={1}
                            client={this.props.client}
                            Location={this.props.Location}
                            onClick={sessionStorage.setItem("Bucket_No", 1)}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "4" ||
                        this.props.search1 === "4" ||
                        this.props.search2 === "4" ||
                        this.props.search3 === "4" ||
                        this.props.search4 === "4"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{ pathname: "/TFLexceed" }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.tflexceedclaims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.tflexceedamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div className="row" style={{ gap: "40px" }}>
            <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/age-between-91-180-days",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Age between 91 and 180 Days
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={12}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "8" ||
                        this.props.search1 === "8" ||
                        this.props.search2 === "8" ||
                        this.props.search3 === "8"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/age-between-91-180-days",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.agebetween91and180claims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.agebetween91and180amount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/ThreeTouch-Claims",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Claim Touched (3 times)
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={4}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "9" ||
                        this.props.search1 === "9" ||
                        this.props.search2 === "9" ||
                        this.props.search3 === "9"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/ThreeTouch-Claims",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.threetouchclaims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.threetouchamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/more-than-30days-notfollow",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Claims not followed up (more than 30 days)
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={8}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "13" ||
                        this.props.search1 === "13" ||
                        this.props.search2 === "13" ||
                        this.props.search3 === "13"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/more-than-30days-notfollow",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.claimsmorethan30daysclaims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.claimsmorethan30daysamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div className="row" style={{ gap: "40px" }}>
            <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/age-between-61-90-days",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Age between 61 and 90 Days
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={12}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "11" ||
                        this.props.search1 === "11" ||
                        this.props.search2 === "11" ||
                        this.props.search3 === "11"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/age-between-61-90-days",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.agebetween61and90claims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.agebetween61and90amount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/TwoTouch-Claims",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Claim Touched (2 times)
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={3}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "12" ||
                        this.props.search1 === "12" ||
                        this.props.search2 === "12" ||
                        this.props.search3 === "12"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/TwoTouch-Claims",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.twotouchclaims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.twotouchamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/more-than-60days-notfollow",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Claims not followed up (more than 60 days)
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={10}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "10" ||
                        this.props.search1 === "10" ||
                        this.props.search2 === "10" ||
                        this.props.search3 === "10"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/more-than-60days-notfollow",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.claimsmorethan60daysclaims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.claimsmorethan60daysamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div className="row" style={{ gap: "40px" }}>
            <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/age-between-31-60-days",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Age between 31 and 60 Days
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={12}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "14" ||
                        this.props.search1 === "14" ||
                        this.props.search2 === "14" ||
                        this.props.search3 === "14"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/age-between-31-60-days",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.agebetween31and60claims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.agebetween31and60amount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/one-touch-claims",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Claim Touched (1 Time)
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={12}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "15" ||
                        this.props.search1 === "15" ||
                        this.props.search2 === "15" ||
                        this.props.search3 === "15"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/one-touch-claims",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.onetouchclaimclaims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.onetouchclaimamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/more-than-90days-notfollow",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Claims not followed up (more than 90 days)
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={11}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "7" ||
                        this.props.search1 === "7" ||
                        this.props.search2 === "7" ||
                        this.props.search3 === "7" ||
                        this.props.search4 === "7"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/more-than-90days-notfollow",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.claimsmorethan90daysclaims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.claimsmorethan90daysamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div className="row" style={{ gap: "40px" }}>
            <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/less-than-30days-NotFollow",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          less than 30 days
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={9}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "17" ||
                        this.props.search1 === "17" ||
                        this.props.search2 === "17" ||
                        this.props.search3 === "17"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/less-than-30days-NotFollow",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.claimslessthan30daysclaims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.claimslessthan30daysamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/zero-touch-claims",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          <span>
                            {" "}
                            Claim Not Touch
                          </span>
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={9}
                            client={this.props.client}
                            Location={this.props.Location}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "18" ||
                        this.props.search1 === "18" ||
                        this.props.search2 === "18" ||
                        this.props.search3 === "18"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/zero-touch-claims",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.claimnottouchclaims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.claimnottouchamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="col-md-3 mb-3">
                <div class="card p-1">
                  <div
                    class="card-body"
                    style={{
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                        color: "white",
                      }}
                    >
                      {/* Left Section */}
                      <div>
                        <Link
                          to={{
                            pathname: "/lowfollow-up",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Low Balance Follow- up
                        </Link>
                      </div>

                      {/* Right Section */}
                      <div>
                        {this.state.role === "Admin" && (
                          <AllocationMenu
                            data={7}
                            client={this.props.client}
                            Location={this.props.Location}
                            onClick={sessionStorage.setItem("Bucket_No", 7)}
                          ></AllocationMenu>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        this.props.search === "16" ||
                        this.props.search1 === "16" ||
                        this.props.search2 === "16" ||
                        this.props.search3 === "16"
                          ? "al-card"
                          : ""
                      }
                      onClick={() => {
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.props.Location
                        );
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/lowfollow-up",
                          client: this.props.client,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Row>
                          <Col className="claim">
                            Total Claims:
                            <span>
                              <div
                                className="to"
                                style={{
                                  color: "black",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {this.state.lowfollowupclaims}
                              </div>
                            </span>
                          </Col>
                          <Col className="total">
                            Grand Total:
                            <span>
                              {" "}
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                ${this.state.lowfollowupamount}
                              </div>
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.props.gridicon1 ? (
          <div className="allocate-card">
            <Table className="tableforkey">
              <thead
                style={{ backgroundColor: "rgb(211,195,241,1)", width: "23%" }}
              >
                <tr>
                  <th>Card Name</th>

                  <th>Total Claims</th>

                  <th>Total Amount</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Timely Filling Limited(Fast Approaching)</td>

                  <td>{this.state.fastclaims}</td>

                  <td>${this.state.fastclaimamount}</td>
                </tr>

                <tr>
                  <td>Age greater than 365 days</td>
                  <td>{this.state.agegreaterthan365daysclaims}</td>
                  <td> ${this.state.agegreaterthan365daysamount}</td>
                </tr>
                <tr>
                  <td>Claim Touched more than (4 times)</td>
                  <td>{this.state.fourormoreclaims}</td>
                  <td>${this.state.fourormoreamount}</td>
                </tr>
                <tr>
                  <td>Timely Filling Limited(Exceeded)</td>
                  <td>{this.state.tflexceedclaims}</td>
                  <td> ${this.state.tflexceedamount}</td>
                </tr>
                <tr>
                  <td>Age between 181 and 365 days</td>
                  <td>{this.state.agebetween181and365claims}</td>
                  <td>${this.state.agebetween181and365amount}</td>
                </tr>
                <tr>
                  <td>Claim Touched 4 times</td>
                  <td> {this.state.touch4timesclaims}</td>
                  <td> ${this.state.touch4timesamount}</td>
                </tr>
                <tr>
                  <td>Claim not followed up(more than 90 days)</td>
                  <td>{this.state.claimsmorethan90daysclaims}</td>
                  <td>${this.state.claimsmorethan90daysamount}</td>
                </tr>
                <tr>
                  <td>Age between 91 and 180 days</td>
                  <td>{this.state.agebetween91and180claims}</td>
                  <td> ${this.state.agebetween91and180amount}</td>
                </tr>
                <tr>
                  <td>Claim Touched(3 times)</td>
                  <td> {this.state.threetouchclaims}</td>
                  <td> ${this.state.threetouchamount}</td>
                </tr>
                <tr>
                  <td>Claim not followed up(more than 60 days)</td>
                  <td> {this.state.claimsmorethan60daysclaims}</td>
                  <td>${this.state.claimsmorethan60daysamount}</td>
                </tr>
                <tr>
                  <td>Age between 61 and 90 days</td>
                  <td> {this.state.agebetween61and90claims}</td>
                  <td>${this.state.agebetween61and90amount}</td>
                </tr>

                <tr>
                  <td>Claim Touched(2 times)</td>
                  <td>{this.state.twotouchclaims}</td>
                  <td> ${this.state.twotouchamount}</td>
                </tr>

                <tr>
                  {" "}
                  <td>Claim not followed up(more than 30 days)</td>
                  <td>{this.state.claimsmorethan30daysclaims}</td>
                  <td> ${this.state.claimsmorethan30daysamount}</td>
                </tr>

                <tr>
                  <td>Age between 31 and 60 days</td>
                  <td>{this.state.agebetween31and60claims}</td>
                  <td> ${this.state.agebetween31and60amount}</td>
                </tr>

                <tr>
                  <td>Claim Touched(1 times)</td>
                  <td>{this.state.onetouchclaimclaims}</td>
                  <td> ${this.state.onetouchclaimamount}</td>
                </tr>

                <tr>
                  <td>Low Balance follow-up</td>
                  <td>{this.state.lowfollowupclaims}</td>
                  <td> ${this.state.lowfollowupamount}</td>
                </tr>

                <tr>
                  <td>Less than 30 days</td>
                  <td>{this.state.claimslessthan30daysclaims}</td>
                  <td> ${this.state.claimslessthan30daysamount}</td>
                </tr>

                <tr>
                  <td>Claim Not Touch</td>
                  <td>{this.state.claimnottouchclaims}</td>
                  <td> ${this.state.claimnottouchamount}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        ) : null}
        {this.state.allocatemodal && (
          <Modal
            isOpen={this.state.allocatemodal}
            onHide={this.toggle4}
            onExit={this.reset}
            size="lg"
            centered
          >
            <ModalHeader toggle={this.toggle4}>Create Allocation</ModalHeader>
            <ModalBody>
              <Row style={{ margin: "0px" }}>
                <Col>
                  <Label>Agent/Team Member Name</Label>
                  <Select
                    options={this.state.users}
                    values={this.state.selecteduser}
                    searchable={true}
                    dropdownHandle={true}
                    searchBy="first_name"
                    labelField="first_name"
                    noDataLabel="No matches found"
                    onChange={(values) =>
                      this.setState({
                        selecteduser: values,
                        selectuserErr: "",
                      })
                    }
                    required
                  />
                </Col>

                {/* <Col>
                <Input></Input>
                </Col> */}
                <div style={{ fontSize: 16, color: "red" }}>
                  {this.state.selectuserErr}
                </div>
              </Row>
              <br />
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  border: "1px solid black",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.toggle4();
                }}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                style={{ backgroundColor: "black", color: "white" }}
                onClick={() => {
                  //this.toggle4();
                  this.allocateclaims();
                }}
              >
                Allocate
              </Button>
            </ModalFooter>
          </Modal>
        )}
        {this.state.reallocmodal && (
          <Modal
            style={{ width: "30%" }}
            isOpen={this.state.reallocmodal}
            onHide={this.toggle3}
            onExit={this.reset}
            size="md"
            centered
          >
            <ModalHeader toggle={this.toggle3}>Reallocate</ModalHeader>
            <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <Row style={{ margin: "0px" }}>
                Do you want to Reallocate this claim ?
              </Row>

              <br />
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  border: "1px solid black",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.toggle3();
                }}
              >
                NO
              </Button>
              &nbsp;&nbsp;
              <Button
                onClick={() => {
                  this.toggle3();
                  this.toggle4();
                }}
                style={{ backgroundColor: "black", color: "white" }}
              >
                YES
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}

export default Allocation;
