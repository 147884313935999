import React, { useState, useEffect } from "react";
import axios from "axios";
import { ip } from "../../../Api";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Spinner } from "react-bootstrap";
import { Row, Col, Input, Button } from "reactstrap";
import moment from "moment-timezone";
import { DatePicker } from "antd";


const Cpt = () => {
  const [fetchingcpt, setFetchingcpt] = useState(true);
  const [refreshtoken] = useState(sessionStorage.getItem("refreshtoken"));
  const [accesstoken] = useState(sessionStorage.getItem("accesstoken"));
  const [client] = useState(sessionStorage.getItem("clientid"));
  const [cptwise, setCptwise] = useState([]);
  const [currentpage2, setCurrentpage2] = useState(0);
  const [perPage2] = useState(10);
  const [loading, setLoading] = useState(true);
  const [download, setDownload] = useState(false);
  const [countofarlog, setCountofarlog] = useState("");
  const [buttonsize, setButtonsize] = useState(0);
  const [pageCount2, setPageCount2] = useState(0);
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [filterstatus, setFilterStatus] = useState("custom");

  const { RangePicker } = DatePicker;


  useEffect(() => {
    getcptwisereports();
    getcount();
  }, [currentpage2, startdate, enddate]);

  const downloaddata = () => {
    setDownload(!download);
  };
  const getcptwisereports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    let url = startdate && enddate
      ? `${ip}/report/cpts?clientId=${client}&start_date=${startdate}&end_date=${enddate}&pageno=${currentpage2}`
      : `${ip}/report/cpts?clientId=${client}&pageno=${currentpage2}`;

    axios.get(url, { headers })
      .then((res) => {
        setPageCount2(Math.ceil(res.data.count / perPage2));
        setCptwise(res.data.data);
        setFetchingcpt(false);
      });
  };

  const getdownloadbutton = () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    let url;
    let filename = startdate && enddate
      ? `Cpts_${startdate}_${enddate}`
      : `Cpts`;

    url = startdate && enddate
      ? `${ip}/report/cpts?clientId=${client}&start_date=${startdate}&end_date=${enddate}&button=${buttonsize}`
      : `${ip}/report/cpts?clientId=${client}&button=${buttonsize}`;

    fetch(url, { headers })
      .then((response) => response.blob())
      .then((blob) => {
        let downloadUrl = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = downloadUrl;
        a.setAttribute("download", filename);
        a.click();
        URL.revokeObjectURL(a);
        setLoading(false);
      });
  };

  const getcount = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    let url = startdate && enddate
      ? `${ip}/report/getcounts?clientId=${client}&feature=cpts&start_date=${startdate}&end_date=${enddate}`
      : `${ip}/report/getcounts?clientId=${client}&feature=cpts`;

    axios.get(url, { headers })
      .then((res) => {
        setCountofarlog(Math.ceil(res.data.count / 5000));
      });
  };

  const increment = () => {
    if (buttonsize < countofarlog - 1) {
      setButtonsize(buttonsize + 1);
    }
  };

  const decrement = () => {
    if (buttonsize > 0) {
      setButtonsize(buttonsize - 1);
    }
  };
   //value of the date picker
   const valuedate = (option, e) => {
    if (option === "yesterday") {
      setStartDate(e.target.value);
      setEndDate(moment().subtract(1, "days").format("MM-DD-YYYY"));
    } else {
      setStartDate(e.target.value);
      setEndDate(moment().format("MM-DD-YYYY"));
    }
  };

  return (
    <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
    <Row className="align-items-center">
      <Col md={6} xs={12} className="d-flex">
                  <Input
                  type="select"
                  style={{ width: "20%", marginTop: "1%" }}
                  className="form-control form-select"
                  onChange={(e) => {
                    const index = e.target.selectedIndex;
                    const el = e.target.childNodes[index];
                    const option = el.getAttribute("title");
                    if (option === "custom") {
                      setFilterStatus("custom");
                    } else {
                      setFilterStatus("");
                    }
              
                    valuedate(option, e); 
                  }}
                >
                    <option value="" title="custom">
                      Custom
                    </option>
                    <option value={moment().format("MM-DD-YYYY")} title="">
                      Today
                    </option>
                    <option
                      value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                      title="yesterday"
                    >
                      Yesterday
                    </option>
                    <option
                      value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 3 days
                    </option>
                    <option
                      value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 7 days
                    </option>
                    <option
                      value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 30 days
                    </option>
                    <option
                      value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 365 days
                    </option>
                  </Input> &nbsp;&nbsp;
                  {filterstatus === "custom" && (
                    <RangePicker
                      format="MM-DD-YYYY"
                      onChange={(dates) => {
                        if (dates) {
                          setStartDate(dates[0].format("MM-DD-YYYY"));
                          setEndDate(dates[1].format("MM-DD-YYYY"));
                        } else {
                          setStartDate("");
                          setEndDate("");
                        }
                      }}
                      width="200%"
                      style={{ borderColor: "gray",  marginTop: "1%" }}
                    />
                  )}
                </Col>
                <Col md={6} xs={12} className="text-md-right mt-2 mt-md-0">
        <Button
          style={buttonStyle}
          onClick={() => {
            downloaddata();
            getdownloadbutton();
          }}
        >
          Generate Report
        </Button>
      </Col>
      </Row>
      <div class="claim-details">
      <div class="row">
          <div class="cell label-cell">Touch</div>
          <div class="cell description-cell">Indicator of the claim's processing stage or interaction level.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">cptNotes</div>
          <div class="cell description-cell">Additional notes or information about CPT (Current Procedural Terminology) codes related to the claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">cptSubCategory</div>
          <div class="cell description-cell">Subcategory associated with the CPT code used in the claim, specifying a more detailed classification.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">clientId</div>
          <div class="cell description-cell">Unique identifier for the client</div>
      </div>
      <div class="row">
          <div class="cell label-cell">ClaimStatus</div>
          <div class="cell description-cell">Current processing status of the claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">CoPayment</div>
          <div class="cell description-cell">The amount the insured party is required to pay out of pocket for the service covered by the claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">check_no</div>
          <div class="cell description-cell">The check number issued for claim payment.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">ClaimID</div>
          <div class="cell description-cell">Unique identifier for the insurance claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">statusCodeValue</div>
          <div class="cell description-cell">Numeric or alphanumeric code representing the specific status of the claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">statusCode</div>
          <div class="cell description-cell">Descriptive code for the status of the claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">statusCategoryCode</div>
          <div class="cell description-cell">General category code that classifies the claim’s status.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">amountPaid</div>
          <div class="cell description-cell">The total amount paid out for the claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">CPTCode</div>
          <div class="cell description-cell">Current Procedural Terminology (CPT) code indicating the specific medical service provided in the claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">CreatedAt</div>
          <div class="cell description-cell">Timestamp indicating when the claim record was created.</div>
      </div>
  </div>
      {download && (
        <Modal
          style={{ width: "30%" }}
          isOpen={download}
          onHide={downloaddata}
          size="md"
          centered
        >
          <ModalHeader toggle={downloaddata}>Download</ModalHeader>
          <ModalBody style={{ "max-height": "calc(100vh - 210px)", "overflow-y": "auto" }}>
            <Row style={{ margin: "0px" }}>
              <Col>
                <div id="container">
                  <BsFillArrowLeftCircleFill
                    style={{ fontSize: "183%" }}
                    onClick={decrement}
                    disabled={loading}
                  />
                  {loading ? (
                    <button style={loadingButtonStyle}>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      &nbsp;&nbsp;Loading data...{buttonsize + 1}
                    </button>
                  ) : (
                    <button style={reportButtonStyle} onClick={getdownloadbutton}>
                      Report {buttonsize + 1}
                    </button>
                  )}
                  <BsFillArrowRightCircleFill
                    style={{ marginLeft: "49px", fontSize: "183%" }}
                    onClick={increment}
                    disabled={loading}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </div>
    )};

const buttonStyle = {
  background:"#002755",
  color:"#fff"
};

const loadingButtonStyle = {
  background: "green",
  backgroundColor: "green",
  borderRadius: "6px",
  border: "3px solid green",
  display: "inline-block",
  cursor: "pointer",
  color: "#ffffff",
  fontSize: "15px",
  fontWeight: "bold",
  padding: "-4px 24px",
  textDecoration: "none",
  width: "45%",
  whiteSpace: "nowrap",
  marginLeft: "20%",
  textShadow: "0px 1px 0px #9b14b3",
};

const reportButtonStyle = {
  background: "Black",
  backgroundColor: "Black",
  borderRadius: "6px",
  border: "3px solid Black",
  display: "inline-block",
  cursor: "pointer",
  color: "#ffffff",
  fontSize: "15px",
  fontWeight: "bold",
  padding: "-4px 24px",
  textDecoration: "none",
  width: "42%",
  marginLeft: "20%",
  textShadow: "0px 1px 0px #9b14b3",
};

export default Cpt;
