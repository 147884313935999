// import React, {
//   createContext,
//   useState,
//   useEffect,
//   useCallback,
//   useContext,
// } from 'react'; // Correct import for useContext
// import { useHistory, useLocation } from 'react-router-dom'; // React Router hooks
// import Modal from 'react-modal';

// const INACTIVITY_TIMEOUT = 60 * 1000; // 1 minute for testing purposes
// const AUTO_LOGOUT_DELAY = 2 * 1000; // 2 seconds for testing

// // User activity context to track activity and reset timers
// const UserActivityContext = createContext();

// // Path context to track the current route path
// const PathContext = createContext(); // Correct creation of context

// // Session expired popup component
// const SessionExpiredPopup = ({ onConfirm }) => (
//   <Modal
//     isOpen={true}
//     onRequestClose={onConfirm}
//     contentLabel="Session Expired"
//     ariaHideApp={false}
//     style={{
//       overlay: {
//         backgroundColor: 'rgba(0, 0, 0, 0.5)',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//       },
//       content: {
//         width: '350px',
//         height: '350px',
//         textAlign: 'center',
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//       },
//     }}
//   >
//     <h2>Session Expired</h2>
//     <p>Your session has expired. You will be logged out.</p>
//     <button className='btn btn-danger' onClick={onConfirm}>Okay</button>
//   </Modal>
// );

// // User activity provider component
// const UserActivityProvider = ({ children }) => {
//   const [lastActivity, setLastActivity] = useState(Date.now());
//   const [showPopup, setShowPopup] = useState(false);
//   const [currentPath, setPath] = useState('/');

//   const history = useHistory();
//   const location = useLocation();

//   const excludedPaths = ['/', '/logout','/data','/dataCleaning', '/dataconversionocr', '/datamigration', '/dataDeleting']; // Example list of excluded paths

//   const resetTimer = useCallback(() => {
//     setLastActivity(Date.now()); // Reset activity timer
//     setShowPopup(false); // Hide popup
//   }, []);

//   // Ensure the timer is reset when there's user activity
//   useEffect(() => {
//     const isExcludedPath = excludedPaths.includes(currentPath);

//     if (isExcludedPath) {
//       return; // If path is excluded, do not set timers
//     }

//     const interval = setInterval(() => {
//       const currentTime = Date.now();
//       if (currentTime - lastActivity > INACTIVITY_TIMEOUT) {
//         setShowPopup(true); // Show the session expired popup

//         const timerId = setTimeout(() => {
//           history.push('/'); // Redirect to home or login
//           window.location.reload();
//         }, AUTO_LOGOUT_DELAY); // Auto-logout delay

//         return () => {
//           clearTimeout(timerId); // Cleanup to avoid memory leaks
//         };
//       }
//     }, 1000); // Check every second

//     return () => {
//       clearInterval(interval); // Cleanup when the component unmounts
//     };
//   }, [currentPath, lastActivity]); // Effect dependencies to ensure reactivity

//   // Track the current path in the PathContext
//   useEffect(() => {
//     setPath(location.pathname); // Keep track of the current path
//   }, [location.pathname]); // Track when the path changes

//   return (
//     <PathContext.Provider value={{ currentPath, setPath }}>
//       <UserActivityContext.Provider value={{ resetTimer }}>
//         {children}
//         {showPopup && <SessionExpiredPopup onConfirm={() => setShowPopup(false)} />} 
//       </UserActivityContext.Provider>
//     </PathContext.Provider>
//   );
// };

// export { UserActivityContext, UserActivityProvider, PathContext };
