import React, { useState, useEffect } from "react";
import axios from "axios";
import { ip } from "../../../Api";
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Modal, ModalHeader, ModalBody, Row, Col, Input, Button } from "reactstrap";
import { Spinner } from "react-bootstrap";
import { DatePicker } from "antd";
import moment from "moment-timezone";

const OpenedClaims = () => {
  const [fetchingClaims, setFetchingClaims] = useState(true);
  const [accessToken, setAccessToken] = useState(sessionStorage.getItem("accesstoken"));
  const [refreshToken] = useState(sessionStorage.getItem("refreshtoken"));
  const [client] = useState(sessionStorage.getItem("clientid"));
  const [openClaim, setOpenClaim] = useState([]);
  const [currentpage1, setCurrentPage1] = useState(0);
  const [download, setDownload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonSize, setButtonSize] = useState(0);
  const [countOfArlog, setCountOfArlog] = useState("");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [filterstatus, setFilterStatus] = useState("custom");
  const perPage1 = 10;
  const { RangePicker } = DatePicker;

  const getDownloadButton = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    let url;
    let filename;
    if (startdate && enddate) {
      filename = `Opened_Claims_${startdate}_${enddate}`;
      url = `${ip}/report/openclaims?clientId=${client}&start_date=${startdate}&end_date=${enddate}&button=${buttonSize}`;
    } else {
      filename = `Opened_Claims`;
      url = `${ip}/report/openclaims?clientId=${client}&button=${buttonSize}`;
    }
    fetch(url, { headers }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        a.click();
        URL.revokeObjectURL(a);
        setLoading(false);
      });
    });
  };
  //value of the date picker
const valuedate = (option, e) => {
  if (option === "yesterday") {
    setStartDate(e.target.value);
    setEndDate(moment().subtract(1, "days").format("MM-DD-YYYY"));
  } else {
    setStartDate(e.target.value);
    setEndDate(moment().format("MM-DD-YYYY"));
  }
};


  const increment = () => {
    if (buttonSize < countOfArlog - 1) {
      setButtonSize((prev) => prev + 1);
    }
  };

  const decrement = () => {
    if (buttonSize > 0) {
      setButtonSize((prev) => prev - 1);
    } else {
      alert("No data here so please click next arrow");
      setButtonSize(0);
    }
  };

  const getCount = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    let url;
    if (startdate && enddate) {
      url = `${ip}/report/getcounts?clientId=${client}&feature=openclaims&start_date=${startdate}&end_date=${enddate}`;
    } else {
      url = `${ip}/report/getcounts?clientId=${client}&feature=openclaims`;
    }
    axios.get(url, { headers }).then((res) => {
      setCountOfArlog(Math.ceil(res.data.count / 20000));
    });
  };

  const toggleDownload = () => {
    setDownload((prev) => !prev);
  };

  const getOpenClaimReports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    let url;
    if (startdate && enddate) {
      url = `${ip}/report/openclaims?clientId=${client}&start_date=${startdate}&end_date=${enddate}&pageno=${currentpage1}`;
    } else {
      url = `${ip}/report/openclaims?clientId=${client}&pageno=${currentpage1}`;
    }
    axios.get(url, { headers }).then((res) => {
      setOpenClaim(res.data.data);
      setFetchingClaims(false);
    });
  };

  useEffect(() => {
    getOpenClaimReports();
    getCount();
  }, [startdate, enddate]);

  return (
    <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
    <Row className="align-items-center">
      <Col md={6} xs={12} className="d-flex">
                  <Input
                  type="select"
                  style={{ width: "20%", marginTop: "1%" }}
                  className="form-control form-select"
                  onChange={(e) => {
                    const index = e.target.selectedIndex;
                    const el = e.target.childNodes[index];
                    const option = el.getAttribute("title");
              
                    if (option === "custom") {
                      setFilterStatus("custom");
                    } else {
                      setFilterStatus("");
                    }
              
                    valuedate(option, e); 
                  }}
                >
                    <option value="" title="custom">
                      Custom
                    </option>
                    <option value={moment().format("MM-DD-YYYY")} title="">
                      Today
                    </option>
                    <option
                      value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                      title="yesterday"
                    >
                      Yesterday
                    </option>
                    <option
                      value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 3 days
                    </option>
                    <option
                      value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 7 days
                    </option>
                    <option
                      value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 30 days
                    </option>
                    <option
                      value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 365 days
                    </option>
                  </Input> &nbsp;&nbsp;
                  {filterstatus === "custom" && (
                    <RangePicker
                      format="MM-DD-YYYY"
                      onChange={(dates) => {
                        if (dates) {
                          setStartDate(dates[0].format("MM-DD-YYYY"));
                          setEndDate(dates[1].format("MM-DD-YYYY"));
                        } else {
                          setStartDate("");
                          setEndDate("");
                        }
                      }}
                      width="200%"
                      style={{ borderColor: "gray",  marginTop: "1%" }}
                    />
                  )}
                </Col>
                <Col md={6} xs={12} className="text-md-right mt-2 mt-md-0">
                <Button
                style={{
                  background:"#002755",
                  color:"#fff"
               }}
                onClick={() => {
                  toggleDownload();
                  getDownloadButton();
                }}
              >
                Generate Report
              </Button>
      </Col>
      </Row>
      {download && (
        <Modal
          style={{ width: "30%" }}
          isOpen={download}
          onHide={toggleDownload}
          size="md"
          centered
        >
          <ModalHeader toggle={toggleDownload}>Download</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <div id="container">
                  {loading ? (
                    <BsFillArrowLeftCircleFill
                      style={{ fontSize: "183%" }}
                      disabled
                      onClick={decrement}
                    />
                  ) : (
                    <BsFillArrowLeftCircleFill
                      style={{ fontSize: "183%" }}
                      onClick={decrement}
                    />
                  )}
                  {loading ? (
                    <button
                      style={{
                        background: "green",
                        borderRadius: "6px",
                        border: "3px solid green",
                        cursor: "pointer",
                        color: "#ffffff",
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "-4px 24px",
                        width: "45%",
                        marginLeft: "20%",
                      }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;&nbsp;Loading data...{buttonSize + 1}
                    </button>
                  ) : (
                    <button
                      style={{
                        background: "Black",
                        borderRadius: "6px",
                        border: "3px solid Black",
                        cursor: "pointer",
                        color: "#ffffff",
                        fontSize: "15px",
                        fontWeight: "bold",
                        width: "42%",
                        marginLeft: "20%",
                      }}
                      onClick={getDownloadButton}
                    >
                      Report {buttonSize + 1}
                    </button>
                  )}
                  {loading ? (
                    <BsFillArrowRightCircleFill
                      disabled
                      style={{ marginLeft: "49px", fontSize: "183%" }}
                      onClick={increment}
                    />
                  ) : (
                    <BsFillArrowRightCircleFill
                      style={{ marginLeft: "49px", fontSize: "183%" }}
                      onClick={increment}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default OpenedClaims;
