import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { ip } from "../../../../Api";

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const getPath = (x, y, width, height) => `M${x},${y + height}
  C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
  x + width / 2
}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
  x + width
}, ${y + height}
  Z`;

const data2 = [
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};

const PayerClasspaidStatus = ({ client, from, to, Location, classwise }) => {
  const [payerClassWise, setPayerClassWise] = useState([]);
  const [accessToken, setAccessToken] = useState(
    sessionStorage.getItem("accesstoken")
  );
  const refreshToken = sessionStorage.getItem("refreshtoken");

  const renewAccessToken = async () => {
    try {
      const renewHeaders = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`,
      };
      const renew = await axios.get(`${ip}/auth/renewAccessToken`, {
        headers: renewHeaders,
      });
      sessionStorage.setItem("accesstoken", renew.data.accessToken);
      setAccessToken(renew.data.accessToken);
    } catch (err) {
      console.error("Error renewing access token", err);
    }
  };

  const getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    let url = `${ip}/outcome/payerclasswise`;
    if (client) {
      url += `?clientId=${client}`;
      if (Location) {
        url += `&Location=${Location}`;
      }
      if (from && to) {
        url += `&start_date=${from}&end_date=${to}`;
      }
    } else if (from && to) {
      url += `?start_date=${from}&end_date=${to}`;
    }

    try {
      const res = await axios.get(url, { headers });
      const data =
        res.data.payerclass_wise.paid.length > 0
          ? res.data.payerclass_wise.paid.slice(0, 5)
          : data2;
      setPayerClassWise(data);
    } catch (err) {
      console.error("Error fetching payer class details", err);
    }
  };

  useEffect(() => {
    getDetails();
  }, [client, from, to, Location]);

  return (
    <div>
      {classwise === "$" && (
        <BarChart
          width={700}
          height={400}
          data={payerClassWise}
          margin={{ top: 5, right: 30, left: 10, bottom: 5 }}
        >
          <XAxis
            dataKey={"_id"}
            height={120}
            interval={0}
            angle={"-45"}
            tick={{
              strokeWidth: 0,
              fontSize: "11",
              textAnchor: "end",
              dy: 6,
            }}
          />
          <YAxis dataKey={"totalAmount"} />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={<CustomTooltip />}
          />
          <Bar
            dataKey={"totalAmount"}
            shape={<TriangleBar />}
            radius={[16, 16, 16, 16]}
            fill="#00A0FC"
            barSize={30}
            name="Total Amount"
          />
        </BarChart>
      )}
      {classwise === "#" && (
        <BarChart
          width={700}
          height={400}
          data={payerClassWise}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis
            dataKey={"_id"}
            height={120}
            interval={0}
            angle={"-45"}
            tick={{
              strokeWidth: 0,
              fontSize: "11",
              textAnchor: "end",
              dy: 6,
            }}
          />
          <YAxis dataKey={"totalClaims"} />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={<ClaimTooltip />}
          />
          <Bar
            dataKey={"totalClaims"}
            shape={<TriangleBar />}
            radius={[16, 16, 16, 16]}
            fill="#00A0FC"
            barSize={30}
            name="Total Claims"
          />
        </BarChart>
      )}
    </div>
  );
};

export default PayerClasspaidStatus;
