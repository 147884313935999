import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { ip } from "../../../Api";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import moment from "moment-timezone";
import { Row, Col, Input, Button } from "reactstrap";
import { Spinner } from "react-bootstrap";
import { DatePicker } from "antd";

const CombinedReport = () => {
  const [fetching, setFetching] = useState("true");
  const [refreshToken] = useState(sessionStorage.getItem("refreshtoken"));
  const [accessToken, setAccessToken] = useState(sessionStorage.getItem("accesstoken"));
  const [currentpage, setCurrentpage] = useState(0);
  const [client, setClient] = useState(sessionStorage.getItem("clientid"));
  const [combineddata, setCombinedData] = useState([]);
  const [download, setDownload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [filterstatus, setFilterStatus] = useState("custom");
  const { RangePicker } = DatePicker;

  useEffect(()=> {
    getcombinedreport();
  },[startdate, enddate])
  const toggleDownload = () => {
    setDownload(!download)
  };
  const getDownloadButton = () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    let url;
    let filename;
    if (startdate && enddate) {
      filename = `COMBINED_REPORTS_${startdate}_${enddate}`;
      url = `${ip}/report/combainedreport?clientId=${client}&start_date=${startdate}&end_date=${enddate}&button=0`;
    } else {
      filename = `COMBINED_REPORTS`;
      url = `${ip}/report/combainedreport?clientId=${client}&button=0`;
    }
    fetch(url, { headers: headers }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        a.click();
        URL.revokeObjectURL(a);
        setLoading(false);
      });
    });
  };  
  const getcombinedreport = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    let url;
    if (startdate && enddate) {
      url = `${ip}/report/combainedreport?clientId=${client}&start_date=${startdate}&end_date=${enddate}&pageno=${currentpage}`;
    } else {
      url = `${ip}/report/combainedreport?clientId=${client}&pageno=${currentpage}`;
    }
    const res = axios .get(url, {
        headers: headers,
      })
      .then((res) => {
        setCombinedData(res.data.data);
        setFetching(false);
      });
  };
  const valuedate = (option, e) => {
    if (option === "yesterday") {
      setStartDate(e.target.value);
      setEndDate(moment().subtract(1, "days").format("MM-DD-YYYY"));
    } else {
      setStartDate(e.target.value);
      setEndDate(moment().format("MM-DD-YYYY"));
    }
  };
   return(
    <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
    <Row className="align-items-center">
      <Col md={6} xs={12} className="d-flex">
                  <Input
                  type="select"
                  style={{ width: "20%", marginTop: "1%" }}
                  className="form-control form-select"
                  onChange={(e) => {
                    const index = e.target.selectedIndex;
                    const el = e.target.childNodes[index];
                    const option = el.getAttribute("title");
              
                    if (option === "custom") {
                      setFilterStatus("custom");
                    } else {
                      setFilterStatus("");
                    }
              
                    valuedate(option, e); 
                  }}
                >
                    <option value="" title="custom">
                      Custom
                    </option>
                    <option value={moment().format("MM-DD-YYYY")} title="">
                      Today
                    </option>
                    <option
                      value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                      title="yesterday"
                    >
                      Yesterday
                    </option>
                    <option
                      value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 3 days
                    </option>
                    <option
                      value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 7 days
                    </option>
                    <option
                      value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 30 days
                    </option>
                    <option
                      value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 365 days
                    </option>
                  </Input> &nbsp;&nbsp;
                  {filterstatus === "custom" && (
                    <RangePicker
                      format="MM-DD-YYYY"
                      onChange={(dates) => {
                        if (dates) {
                          setStartDate(dates[0].format("MM-DD-YYYY"));
                          setEndDate(dates[1].format("MM-DD-YYYY"));
                        } else {
                          setStartDate("");
                          setEndDate("");
                        }
                      }}
                      width="200%"
                      style={{ borderColor: "gray",  marginTop: "1%" }}
                    />
                  )}
                </Col>
                <Col md={6} xs={12} className="text-md-right mt-2 mt-md-0">
                <Button
                style={{
                  background:"#002755",
                  color:"#fff"
               }}
                onClick={() => {
                  toggleDownload();
                  getDownloadButton();
                }}
              >
                Generate Report
              </Button>
      </Col>
      </Row>
      {download && (
        <Modal
          style={{ width: "30%" }}
          isOpen={download}
          onHide={toggleDownload}
          size="md"
          centered
        >
          <ModalHeader toggle={toggleDownload}>Download</ModalHeader>
          <ModalBody
            style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}
          >
            <Row style={{ margin: "0px" }}>
              <Col>
                <div id="container">
                  {loading ? (
                    <button
                      style={{
                        background: "green",
                        backgroundColor: "green",
                        borderRadius: "6px",
                        border: "3px solid green",
                        display: "inline-block",
                        cursor: "pointer",
                        color: "#ffffff",
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "-4px 24px",
                        width: "45%",
                        whiteSpace: "nowrap",
                        marginLeft: "20%",
                        textShadow: "0px 1px 0px #9b14b3",
                      }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;&nbsp;Loading data...
                    </button>
                  ) : (
                    <button
                      style={{
                        background: "Black",
                        backgroundColor: "Black",
                        borderRadius: "6px",
                        border: "3px solid Black",
                        display: "inline-block",
                        cursor: "pointer",
                        color: "#ffffff",
                        fontSize: "15px",
                        fontWeight: "bold",
                        width: "42%",
                        marginLeft: "20%",
                        textShadow: "0px 1px 0px #9b14b3",
                      }}
                      onClick={getDownloadButton}
                    >
                      Report
                    </button>
                  )}
                </div>
              </Col>
              <br />
              <br />
            </Row>
            <br />
          </ModalBody>
        </Modal>
      )}
   </div>
   );
};
export default CombinedReport;
