import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ip } from "../../../Api";

const data1 = [
  {
    _id: "",
    totalamt: 800,
    totalclaims: 30,
    percent: 82,
  },
  {
    _id: "",
    totalamt: 800,
    totalclaims: 20,
    percent: 59,
  },
  {
    _id: "",
    totalamt: 400,
    totalclaims: 10,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 10,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 5,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 5,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 5,
    percent: 27,
  },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: "#f5234a" }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: "#f5234a" }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};

const Ageingchart = (props) => {
  const [agegrpwiseamount, setAgeGrpWiseAmount] = useState([]);
  const [agegrpwisecount, setAgeGrpWiseCount] = useState([]);
  const refreshtoken = sessionStorage.getItem("refreshtoken");
  const accesstoken = sessionStorage.getItem("accesstoken");

  const renewaccesstoken = async () => {
    try {
      const renewheaders = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshtoken}`,
      };
      const renew = await axios.get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      });
      sessionStorage.setItem("accesstoken", renew.data.accessToken);
    } catch (err) {
      console.error("Error renewing access token", err);
    }
  };

  const getinventory = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };

    let url = `${ip}/inventory/agegrpwise`;

    if (props.client) {
      if (props.Location) {
        url += `?clientId=${props.client}&Location=${props.Location}`;
        if (props.ageStart && props.ageEnd) {
          url += `&start_date=${props.ageStart}&end_date=${props.ageEnd}`;
        }
      } else if (props.ageStart && props.ageEnd) {
        url += `?clientId=${props.client}&start_date=${props.ageStart}&end_date=${props.ageEnd}`;
      } else {
        url += `?clientId=${props.client}`;
      }
    } else if (props.ageStart && props.ageEnd) {
      url += `?start_date=${props.ageStart}&end_date=${props.ageEnd}`;
    }

    try {
      const res = await axios.get(url, { headers });
      const inventory = res.data.inventory.length ? res.data.inventory : data1;
      setAgeGrpWiseAmount(inventory);
      setAgeGrpWiseCount(inventory);
    } catch (err) {
      console.error("Error fetching inventory", err);
      setAgeGrpWiseAmount(data1);
      setAgeGrpWiseCount(data1);
    }
  };

  useEffect(() => {
    getinventory();
  }, [props.ageStart, props.ageEnd, props.client, props.Location]);

  return (
    <>
      {props.agegrpwise === "$" ? (
        <div>
          <ResponsiveContainer width={"99%"} height={400}>
            <ComposedChart
              data={agegrpwiseamount}
              margin={{ top: 20, right: 20, bottom: 0, left: 20 }}
            >
              <XAxis
                dataKey="_id"
                height={120}
                interval={0}
                angle={"-45"}
                tick={{
                  strokeWidth: 0,
                  fontSize: "11",
                  textAnchor: "end",
                  dy: 6,
                }}
              />
              <YAxis dataKey="totalamount" />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="100%">
                  <stop offset="0" stopColor="#f5234a" />
                  <stop offset="1" stopColor="#fa859a" />
                </linearGradient>
              </defs>
              <Bar
                dataKey="totalamount"
                barSize={30}
                fill="url(#colorUv)"
                radius={[10, 10, 0, 0]}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div>
          <ResponsiveContainer width={"99%"} height={400}>
            <ComposedChart
              data={agegrpwisecount}
              margin={{ top: 20, right: 20, bottom: 0, left: 20 }}
            >
              <XAxis
                dataKey="_id"
                height={120}
                interval={0}
                angle={"-45"}
                tick={{
                  strokeWidth: 0,
                  fontSize: "11",
                  textAnchor: "end",
                  dy: 6,
                }}
              />
              <YAxis dataKey="totalcount" />
              <Tooltip content={<ClaimTooltip />} />
              <Legend />
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="100%">
                  <stop offset="0" stopColor="#f5234a" />
                  <stop offset="1" stopColor="#fa859a" />
                </linearGradient>
              </defs>
              <Bar
                dataKey="totalcount"
                barSize={30}
                fill="url(#colorUv)"
                radius={[10, 10, 0, 0]}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default Ageingchart;
