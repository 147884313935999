import React, { Component } from "react";
import axios from "axios";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Table, Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { ip } from "../../Api";
import loading from "../../Images/loader.gif";
class Ageing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      updatemodal: false,
      deletemodal: false,
      createmodal: false,
      payercategories: [],
      payervalue1: [],
      dataforupdate: null,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      assigclaim: "",
      agentname: "",
      status: "Online",
      role: "",
      claimId: "",
      email: "",
      completedclaim: "",
      pendingclaim: "",
      code: "",
      user: [],
      updateagentname: "",
      updateassigclaim: "",
      updateemail: "",
      updateclaimId: "",
      updaterole: "",
      updatecompletedclaim: "",
      updatependingclaim: "",
      updatestatus: null,
      updatecode: "",
      updateid: "",
      code: "",
      user: [],
    };
  }

  getpayercategory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(`${ip}/payercategory/all`, {
        headers: headers,
      })
      .then((res) => {
        let other = {
          category_name: "Others",
        };
        let data = res.data.payer;
        data.push(other);
        this.setState({ payercategories: data }, () => {});
      })

      .catch((err) => {});
  };

  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  // toogle modal for delete
  toggledelete = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  //toggle modal for update
  toggleupdate = () => {
    this.setState({ updatemodal: !this.state.updatemodal });
  };

  handleSubmit = async () => {
    const isValid = this.validate();

    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        agent_name: this.state.agentname,
        email: this.state.email,
        claimId: this.state.claimId,
        assigclaim: this.state.assigclaim,
        completedclaim: this.state.completedclaim,
        pendingclaim: this.state.pendingclaim,
        status: this.state.status,
      };
      // eslint-disable-next-line
       await axios
        // .post(`http://localhost:3002/users/create/user`, value)
        .then((res) => {
          alert("New User Created Successfully!");
          this.getusers();
          this.toggle();
        })
        .catch((err) => {
          // "country_code":this.state.code,
        });
    }
  };

  // fuction for prepopulate the particular data
  settingupdatestate = function () {
    this.setState(
      {
        updateagentname: this.state.dataforupdate.agent_name,
        updateemail: this.state.dataforupdate.email,
        updateclaimId: this.state.dataforupdate.claimId,
        updateassigclaim: this.state.dataforupdate.last_name,
        updatecompletedclaim: this.state.dataforupdate.completedclaim,
        updatependingclaim: this.state.dataforupdate.pendingclaim,
        updatestatus: this.state.dataforupdate.status,
      },
      (res) => {}
    );
  };

  putrequests = async (dataid) => {
    const isValid = this.updatevalidate();

    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const data = {
        agent_name: this.state.updateagentname,
        claim_id: this.state.updateclaimId,
        email: this.state.updateemail,
        assign_claim: this.state.updateassigclaim,
        completedclaim: this.state.updatecompletedclaim,
        pendingclaim: this.state.updatependingclaim,
        status: this.state.updatestatus,
      };
      // eslint-disable-next-line
       await axios
        .put(`${ip}/users/${dataid}`, data, {
          headers: headers,
        })
        .then((res) => {
          alert("User Data Updated Successfully!");
          this.toggle();
          this.getusers();
        })
        .catch((err) => {});
    }
  };

  deleterequests = async (DataID) => {
    //api call to delete requests
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/users/${DataID}`, { headers: headers })
      .then((req) => {
        alert("Clinical Staff Deleted Successfully");

        const requests = this.state.user.filter((data) => data._id !== DataID);
        this.setState({ requests }, () => {});
        this.toggle();
        this.getusers();
      })
      .catch((err) => {});
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //getusers
  getusers = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     axios
      .get(`${ip}/users/all`, { headers: headers })
      .then((res) => {
        this.setState({
          user: res.data.users,
        });
      })
      .catch((err) => {});
  };

  validate = () => {
    let hasErr = "true";
    let agentnameErr = "";
    let assigclaimErr = "";
    let claimIdErr = "";
    let emailErr = "";
    let completedclaimErr = "";
    let pendingclaimErr = "";
    let statusErr = "";

    if (this.state.agentname === "") {
      agentnameErr = "This field is required";
      this.setState({ agentnameErr });
      hasErr = false;
    }
    if (this.state.status === "") {
      statusErr = "This field is required";
      this.setState({ statusErr });
      hasErr = false;
    }
    if (this.state.assigclaim === "") {
      assigclaimErr = "This field is required";
      this.setState({ assigclaimErr });
      hasErr = false;
    }
    if (this.state.claimId === "") {
      claimIdErr = "This field is required";
      this.setState({ claimIdErr });
      hasErr = false;
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
        this.state.claimId
      )
    ) {
      claimIdErr = "Invalid phone number";
      this.setState({ claimIdErr });
      hasErr = false;
    }
    if (this.state.email === "") {
      emailErr = "This field is required";
      this.setState({ emailErr });
      hasErr = false;
    } else if (
      // eslint-disable-next-line
      !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
        this.state.email
      )
    ) {
      //var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      emailErr = "Invalid email address";
      this.setState({ emailErr });
      hasErr = false;
    }
    return hasErr;
  };

  updatevalidate = () => {
    let hasErr = "true";
    let agentnameErr = "";
    let assigclaimErr = "";
    let claimIdErr = "";
    let emailErr = "";
    let statusErr = "";

    if (this.state.updateagentname === "") {
      agentnameErr = "This field is required";
      this.setState({ agentnameErr });
      hasErr = false;
    }
    if (this.state.updatestatus === "") {
      statusErr = "This field is required";
      this.setState({ statusErr });
      hasErr = false;
    }
    if (this.state.updateassigclaim === "") {
      assigclaimErr = "This field is required";
      this.setState({ assigclaimErr });
      hasErr = false;
    }
    if (this.state.updateclaimId === "") {
      claimIdErr = "This field is required";
      this.setState({ claimIdErr });
      hasErr = false;
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
        this.state.updateclaimId
      )
    ) {
      claimIdErr = "Invalid phone number";
      this.setState({ claimIdErr });
      hasErr = false;
    }
    if (this.state.updateemail === "") {
      emailErr = "This field is required";
      this.setState({ emailErr });
      hasErr = false;
    } else if (
      // eslint-disable-next-line
      !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
        this.state.updateemail
      )
    ) {
      //var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      emailErr = "Invalid email address";
      this.setState({ emailErr });
      hasErr = false;
    }
    return hasErr;
  };

  componentDidMount() {
    this.getpayercategory();
  }
  render() {
    return (
      <div>
      <Newsidebar name="Ageing" />
      <div className="align-row" style={{overflow:"hidden"}}>
        <div className="align-column">
          <br></br>

          <br></br>
          <div className="align-row items" style={{ marginTop: "2%" }}>
            <button
              className="AddNew"
              onClick={this.toggle}
              style={{
                width: "200px",
                height: "59%",
                marginTop: "24px",
                borderRadius: "8px",
                color: "white",
                background: "black",
                fontWeight: "bold",
                marginLeft: "80%",
              }}
            >
              Create Allocation
            </button>
          </div>
          <div className="box" ref={(el) => (this.componentRef = el)}>
            <Table className="StandardTable" bordered>
              <thead>
                <tr>
                  <th>Agent/Mem.,Name</th>
                  <th>Email_ID</th>
                  <th>Claim ID Range</th>
                  <th>#Assigned Claim</th>
                  <th>Completed Claim</th>
                  <th>Pending Claim</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.user &&
                  this.state.user.map((data, index) => {
                    return (
                      <tr>
                        <td>{data.agent_name}</td>
                        <td>{data.email}</td>
                        <td>{data.claimId}</td>
                        <td>{data.assigclaim}</td>
                        <td>{data.completedclaim}</td>
                        <td>{data.pendingclaim}</td>
                        <td>{data.status}</td>
                        <td>
                          {" "}
                          <button
                            className="Edit"
                            onClick={() => {
                              this.setState({ dataforupdate: data }, () => {
                                this.settingupdatestate();
                                this.toggleupdate();
                              });
                            }}
                          >
                            Edit
                          </button>
                          &nbsp;
                          <button
                            className="Delete"
                            onClick={() => {
                              this.setState({ dataforupdate: data }, () => {
                                this.settingupdatestate();
                                this.toggleupdate();
                              });
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
          {this.state.modal && (
            <Modal
              style={{ width: "30%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              onExit={this.reset}
              centered
            >
              <ModalHeader toggle={this.toggle}>Create Allocation</ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Agent Name</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.agentname}
                          onChange={(e) =>
                            this.setState({
                              agentname: e.target.value,
                              agentnameErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.agentnameErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Email Address</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({
                              email: e.target.value,
                              emailErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.emailErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Claim_ID Range</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.claimId}
                          onChange={(e) =>
                            this.setState({
                              claimId: e.target.value,
                              claimIdErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.claimIdErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Assigned claim</Label>
                        <Input
                          type="number"
                          required
                          value={this.state.assigclaim}
                          onChange={(e) =>
                            this.setState({
                              assigclaim: e.target.value,
                              assigclaimErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.assigclaimErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md="6">
                      <label>Completed claim</label>
                      <Input
                        type="number"
                        required
                        value={this.state.completedclaim}
                        onChange={(e) =>
                          this.setState({
                            completedclaim: e.target.value,
                            completedclaimErr: "",
                          })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.completedclaimErr}
                      </div>
                    </Col>
                    <Col md="6">
                      <label>Pending Claim</label>
                      <Input
                        type="number"
                        required
                        value={this.state.pendingclaim}
                        onChange={(e) =>
                          this.setState({
                            pendingclaim: e.target.value,
                            pendingclaimErr: "",
                          })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.pendingclaimErr}
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label htmlFor="input" className="heading required">
                          Status
                        </label>
                        {/* <Input type="text" placeholder="Patient Name" /> */}
                        <Input
                          type="select"
                          className="Status"
                          value={this.state.status}
                          onChange={(e) =>
                            this.setState({
                              status: e.target.value,
                              statusErr: "",
                            })
                          }
                        >
                          <option value="Online">Online</option>
                          <option value="Offline">Offline</option>
                        </Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.statusErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                    //   this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.handleSubmit}
                >
                  Create
                </Button>
              </ModalFooter>
            </Modal>
          )}
          <Modal isOpen={this.state.createmodal}>
            <ModalBody>
              <img
                src={loading}
                style={{ width: "200px", height: "200px", marginLeft: "30%" }}
              />
            </ModalBody>
          </Modal>
          {this.state.updatemodal && this.state.dataforupdate && (
            <Modal
              style={{ width: "30%" }}
              isOpen={this.state.updatemodal}
              onHide={this.toggleupdate}
              onExit={this.reset}
              centered
            >
              <ModalHeader toggle={this.toggleupdate}>Update User</ModalHeader>
              <ModalBody>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Agent Name</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.updateagentname}
                          disabled
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.agentnameErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Last Name</Label>
                        <Input
                          type="text"
                          disabled
                          value={this.state.updateassigclaim}
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.assigclaimErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Email Address</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.updateemail}
                          onChange={(e) =>
                            this.setState({
                              updateemail: e.target.value,
                              emailErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.emailErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Mobile Number</Label>
                        <Row>
                          <Col>
                            <Input
                              type="alphanumeric"
                              required
                              value={this.state.updatecode}
                              onChange={(e) =>
                                this.setState({ updatecode: e.target.value })
                              }
                            />
                          </Col>
                          <Col>
                            <Input
                              type="number"
                              required
                              value={this.state.updateclaimId}
                              onChange={(e) =>
                                this.setState({
                                  updateclaimId: e.target.value,
                                  claimIdErr: "",
                                })
                              }
                            />
                          </Col>
                        </Row>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.claimIdErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Role</Label>
                        <Row>
                          <Col>
                            <Input
                              type="checkbox"
                              name="Admin"
                              required
                              value={"Admin"}
                              onChange={(e) =>
                                this.setState({
                                  updaterole: e.target.value,
                                  roleErr: "",
                                })
                              }
                            />

                            <Label> Admin</Label>
                          </Col>
                          <Col>
                            <Input
                              type="checkbox"
                              required
                              value={"Manager"}
                              onChange={(e) =>
                                this.setState({
                                  updaterole: e.target.value,
                                  roleErr: "",
                                })
                              }
                            />

                            <Label> Manager</Label>
                          </Col>
                          <Col>
                            <Input
                              type="checkbox"
                              required
                              value={"Team Lead"}
                              onChange={(e) =>
                                this.setState({
                                  updaterole: e.target.value,
                                  roleErr: "",
                                })
                              }
                            />

                            <Label> Team Lead</Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Input
                              type="checkbox"
                              required
                              value={"Team Manager"}
                              onChange={(e) =>
                                this.setState({
                                  updaterole: e.target.value,
                                  roleErr: "",
                                })
                              }
                            />

                            <Label> Team Member</Label>
                          </Col>
                        </Row>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.roleErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggleupdate();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.putrequests(this.state.updateid)}
                >
                  Update
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {this.state.deletemodal && (
            <Modal
              isOpen={this.state.deletemodal}
              onHide={this.toggledelete}
              // onExit={this.reloadpage}
              centered
            >
              <ModalHeader toggle={this.toggledelete}>Delete User</ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid #268da5",
                    color: "#268da5",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.toggledelete}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "#268da5" }}
                  onClick={() => {
                    this.deleterequests(this.state._id);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      </div>
      </div>
    );
  }
}
export default Ageing;
