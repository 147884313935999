import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

import { ip } from "../../../../Api";

const data2 = [
  {
    _id: "",
    totalamt: 800,
    totalclaims: 30,
    percent: 82,
  },
  {
    _id: "",
    totalamt: 800,
    totalclaims: 20,
    percent: 59,
  },
  {
    _id: "",
    totalamt: 400,
    totalclaims: 10,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 10,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 5,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 5,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 5,
    percent: 27,
  },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};

const WebCharts__Grid2_cell2 = ({ client, from, to, Location, agegrpwise__one }) => {
  const [payerAgeWise, setPayerAgeWise] = useState([]);
  const [accessToken, setAccessToken] = useState(
    sessionStorage.getItem("accesstoken")
  );
  const refreshToken = sessionStorage.getItem("refreshtoken");

  const renewAccessToken = async () => {
    try {
      const renewHeaders = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`,
      };
      const response = await axios.get(`${ip}/auth/renewAccessToken`, {
        headers: renewHeaders,
      });
      sessionStorage.setItem("accesstoken", response.data.accessToken);
      setAccessToken(response.data.accessToken);
    } catch (err) {
      console.error("Error renewing access token", err);
    }
  };

  const getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    let url = `${ip}/outcome/agewise`;
    if (client) {
      url += `?clientId=${client}`;
      if (Location) url += `&Location=${Location}`;
      if (from && to) url += `&start_date=${from}&end_date=${to}`;
    } else if (from && to) {
      url += `?start_date=${from}&end_date=${to}`;
    }

    try {
      const response = await axios.get(url, { headers });
      const data = response.data.age_wise.nis.length
        ? response.data.age_wise.nis.slice(0, 5)
        : data2;
      setPayerAgeWise(data);
    } catch (err) {
      console.error("Error fetching data", err);
    }
  };

  useEffect(() => {
    getDetails();
  }, [client, from, to, Location, accessToken]);

  return (
    <div>
      {agegrpwise__one === "$" && (
        <div>
          <BarChart
            width={1100}
            height={500}
            data={payerAgeWise}
            margin={{ top: 5, right: 10, left: 100, bottom: 5 }}
          >
            <XAxis
              dataKey="_id"
              height={120}
              interval={0}
              angle={"-45"}
              tick={{
                strokeWidth: 0,
                fontSize: "11",
                textAnchor: "end",
                dy: 6,
              }}
            />
            <YAxis dataKey="totalAmount" />
            <Tooltip
              cursor={{ fill: "transparent" }}
              content={<CustomTooltip />}
            />
            <Legend />
            <Bar
              dataKey="totalAmount"
              fill="#8884d8"
              barSize={35}
              name="Total Amount"
            />
          </BarChart>
        </div>
      )}
      {agegrpwise__one === "#" && (
        <div>
          <BarChart
            width={1100}
            height={500}
            data={payerAgeWise}
            margin={{ top: 5, right: 10, left: 100, bottom: 5 }}
          >
            <XAxis
              dataKey="_id"
              height={120}
              interval={0}
              angle={"-45"}
              tick={{
                strokeWidth: 0,
                fontSize: "11",
                textAnchor: "end",
                dy: 6,
              }}
            />
            <YAxis dataKey="totalClaims" />
            <Tooltip
              cursor={{ fill: "transparent" }}
              content={<ClaimTooltip />}
            />
            <Legend />
            <Bar
              dataKey="totalClaims"
              fill="#8884d8"
              barSize={35}
              name="Total Claims"
            />
          </BarChart>
        </div>
      )}
    </div>
  );
};

export default WebCharts__Grid2_cell2;
