import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import "../../../Styles/subclaim.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import { ip } from "../../../Api";
class Finalized extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: sessionStorage.getItem("queryurl"),
      Location: sessionStorage.getItem("queryLocation"),
      client: sessionStorage.getItem("queryclient"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
      subcategoryvalue: null,
      subcategoryvalue: null,
      show2: sessionStorage.getItem("queryclaimid"),
      claimtotalamt: "",
      claimtotalcnt: "",
      paymenttotalamt: "",
      paymenttotalcnt: "",
      show2: sessionStorage.getItem("queryclaimid"),
      revisedtotalamt: "",
      revisedtotalcnt: "",
      forwaredtotalamt: "",
      forwaredtotalcnt: "",
      notfortotalamt: "",
      notfortotalcnt: "",
      completetotalamt: "",
      completetotalcnt: "",
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getfinalizeddata = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryenddate && this.state.querystartdate) {
      url = `${ip}/claimstatus/subcategory?${this.state.value}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
    } else {
      url = `${ip}/claimstatus/subcategory?${this.state.value}`;
    }
    const res = await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ subcategoryvalue: res.data.data });
        if (res.data.data.length !== 0) {
          res.data.data.map((data, index) => {
            switch (data._id) {
              case "F0":
                return this.setState({
                  claimtotalamt: Math.round(data.totalamount).toLocaleString(),
                  claimtotalcnt: data.totalcount,
                });
              case "F1":
                return this.setState({
                  paymenttotalcnt: data.totalcount,
                  paymenttotalamt: Math.round(
                    data.totalamount
                  ).toLocaleString(),
                });
              case "F3":
                return this.setState({
                  revisedtotalcnt: data.totalcount,
                  revisedtotalamt: Math.round(
                    data.totalamount
                  ).toLocaleString(),
                });
              case "F3F":
                return this.setState({
                  forwaredtotalamt: Math.round(
                    data.totalamount
                  ).toLocaleString(),
                  forwaredtotalcnt: data.totalcount,
                });
              case "F3N":
                return this.setState({
                  notfortotalamt: Math.round(data.totalamount).toLocaleString(),
                  notfortotalcnt: data.totalcount,
                });
              case "F4":
                return this.setState({
                  completetotalamt: Math.round(
                    data.totalamount
                  ).toLocaleString(),
                  completetotalcnt: data.totalcount,
                });
            }
          });
        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    this.getfinalizeddata();
  }
  render() {
    return (
      <div className="align-row" style={{ overflow: "hidden" }}>
        <Newsidebar>
          <p>
            <Link
              to="/claim-management"
              style={{ textDecoration: "none", color: "white" }}
            >
              Claim Status Management
            </Link>
            &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Finalized
          </p>
        </Newsidebar>
        <div className="align-column">
          <br />
          <br />
          <div className="row" style={{ marginLeft: "1%" }}>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // The claim card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "The Claim");
                        sessionStorage.setItem("subclaimname", "finalizedf0");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F0&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F4");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "The Claim");
                        sessionStorage.setItem("subclaimname", "finalizedf0");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F0&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F0");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "The Claim");
                        sessionStorage.setItem("subclaimname", "finalizedf0");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F0&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F0");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "The Claim");
                        sessionStorage.setItem("subclaimname", "finalizedf0");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F0&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F0");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Finalized");
                      sessionStorage.setItem("querysub", "The Claim");
                      sessionStorage.setItem("subclaimname", "finalizedf0");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Finalized&statusCategoryCode=F0&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                      sessionStorage.setItem("queryCategoryCode", "F0");
                      sessionStorage.setItem("queryCategoryname", "Finalized");
                    } else {
                      sessionStorage.setItem("querycategory", "Finalized");
                      sessionStorage.setItem("querysub", "The Claim");
                      sessionStorage.setItem("subclaimname", "finalizedf0");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Finalized&statusCategoryCode=F0`
                      );
                      sessionStorage.setItem("queryCategoryCode", "F0");
                      sessionStorage.setItem("queryCategoryname", "Finalized");
                    }
                  }
                }}
              >
                <div class="card-header bg-white">
                  <div
                    style={{
                      background:
                        this.state.show2 === "F0"
                          ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)"
                          : "linear-gradient(to right, #002755 65%, #EB1A23)",
                      padding: "3px",
                      color: "white",
                    }}
                  >
                    <span class="d-inline" style={{ fontWeight: "bold" }}>
                      The Claim
                    </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter has completed the adjudication
                          cycle and no more action will be taken.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {this.state.claimtotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.claimtotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //Payment card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Payment");
                        sessionStorage.setItem("subclaimname", "finalizedf1");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F1&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F1");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Payment");
                        sessionStorage.setItem("subclaimname", "finalizedf1");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F1&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F1");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Payment");
                        sessionStorage.setItem("subclaimname", "finalizedf1");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F1&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F1");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Payment");
                        sessionStorage.setItem("subclaimname", "finalizedf1");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F1&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F1");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Finalized");
                      sessionStorage.setItem("querysub", "Payment");
                      sessionStorage.setItem("subclaimname", "finalizedf1");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Finalized&statusCategoryCode=F1&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                      sessionStorage.setItem("queryCategoryCode", "F1");
                      sessionStorage.setItem("queryCategoryname", "Finalized");
                    } else {
                      sessionStorage.setItem("querycategory", "Finalized");
                      sessionStorage.setItem("querysub", "Payment");
                      sessionStorage.setItem("subclaimname", "finalizedf1");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Finalized&statusCategoryCode=F1`
                      );
                      sessionStorage.setItem("queryCategoryCode", "F1");
                      sessionStorage.setItem("queryCategoryname", "Finalized");
                    }
                  }
                }}
              >
                <div class="card-header bg-white">
                  <div
                    style={{
                      background:
                        this.state.show2 === "F1"
                          ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)"
                          : "linear-gradient(to right, #002755 65%, #EB1A23)",
                      padding: "3px",
                      color: "white",
                    }}
                  >
                    <span class="d-inline" style={{ fontWeight: "bold" }}>
                      Payment
                    </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/line has been paid.
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {this.state.paymenttotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.paymenttotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //Revised card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Revised");
                        sessionStorage.setItem("subclaimname", "finalizedf2");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F3&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F3");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Revised");
                        sessionStorage.setItem("subclaimname", "finalizedf2");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F3&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F3");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Revised");
                        sessionStorage.setItem("subclaimname", "finalizedf2");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F3&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F3");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Revised");
                        sessionStorage.setItem("subclaimname", "finalizedf2");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F3&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F3");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Finalized");
                      sessionStorage.setItem("querysub", "Revised");
                      sessionStorage.setItem("subclaimname", "finalizedf2");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Finalized&statusCategoryCode=F3&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                      sessionStorage.setItem("queryCategoryCode", "F3");
                      sessionStorage.setItem("queryCategoryname", "Finalized");
                    } else {
                      sessionStorage.setItem("querycategory", "Finalized");
                      sessionStorage.setItem("querysub", "Revised");
                      sessionStorage.setItem("subclaimname", "finalizedf2");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Finalized&statusCategoryCode=F3`
                      );
                      sessionStorage.setItem("queryCategoryCode", "F3");
                      sessionStorage.setItem("queryCategoryname", "Finalized");
                    }
                  }
                }}
              >
                <div class="card-header bg-white">
                  <div
                    style={{
                      background:
                        this.state.show2 === "F2"
                          ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)"
                          : "linear-gradient(to right, #002755 65%, #EB1A23)",
                      padding: "3px",
                      color: "white",
                    }}
                  >
                    <span class="d-inline" style={{ fontWeight: "bold" }}>
                      Revised{" "}
                    </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          Adjudication information has been changed.
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {this.state.revisedtotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.revisedtotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //Forwared card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Forwarded");
                        sessionStorage.setItem("subclaimname", "finalizedf3");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F3F&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F3F");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Forwarded");
                        sessionStorage.setItem("subclaimname", "finalizedf3");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F3F&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F3F");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Forwarded");
                        sessionStorage.setItem("subclaimname", "finalizedf3");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F3F&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F3F");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Forwarded");
                        sessionStorage.setItem("subclaimname", "finalizedf3");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F3F&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F3F");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Finalized");
                      sessionStorage.setItem("querysub", "Forwarded");
                      sessionStorage.setItem("subclaimname", "finalizedf3");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Finalized&statusCategoryCode=F3F&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                      sessionStorage.setItem("queryCategoryCode", "F3F");
                      sessionStorage.setItem("queryCategoryname", "Finalized");
                    } else {
                      sessionStorage.setItem("querycategory", "Finalized");
                      sessionStorage.setItem("querysub", "Forwarded");
                      sessionStorage.setItem("subclaimname", "finalizedf3");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Finalized&statusCategoryCode=F3F`
                      );
                      sessionStorage.setItem("queryCategoryCode", "F3F");
                      sessionStorage.setItem("queryCategoryname", "Finalized");
                    }
                  }
                }}
              >
                <div class="card-header bg-white">
                  <div
                    style={{
                      background:
                        this.state.show2 === "F3"
                          ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)"
                          : "linear-gradient(to right, #002755 65%, #EB1A23)",
                      padding: "3px",
                      color: "white",
                    }}
                  >
                    <span class="d-inline" style={{ fontWeight: "bold" }}>
                      Forwarded{" "}
                    </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter has been forwarded to a subsequent
                          entity as identified on the original claim or in this
                          payer's records.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {this.state.forwaredtotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.forwaredtotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //Not Forwared card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Not Forwarded");
                        sessionStorage.setItem("subclaimname", "finalizedf4");
                        sessionStorage.setItem("queryCategoryCode", "F3N");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F3N&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Not Forwarded");
                        sessionStorage.setItem("subclaimname", "finalizedf4");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F3N&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F3N");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Not Forwarded");
                        sessionStorage.setItem("subclaimname", "finalizedf4");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F3N&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F3N");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem("querysub", "Not Forwarded");
                        sessionStorage.setItem("subclaimname", "finalizedf4");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F3N&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F3N");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Finalized");
                      sessionStorage.setItem("querysub", "Not Forwarded");
                      sessionStorage.setItem("subclaimname", "finalizedf4");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Finalized&statusCategoryCode=F3N&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                      sessionStorage.setItem("queryCategoryCode", "F3N");
                      sessionStorage.setItem("queryCategoryname", "Finalized");
                    } else {
                      sessionStorage.setItem("querycategory", "Finalized");
                      sessionStorage.setItem("querysub", "Not Forwarded");
                      sessionStorage.setItem("subclaimname", "finalizedf4");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Finalized&statusCategoryCode=F3N`
                      );
                      sessionStorage.setItem("queryCategoryCode", "F3N");
                      sessionStorage.setItem("queryCategoryname", "Finalized");
                    }
                  }
                }}
              >
                <div class="card-header bg-white">
                  <div
                    style={{
                      background:
                        this.state.show2 === "F4"
                          ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)"
                          : "linear-gradient(to right, #002755 65%, #EB1A23)",
                      padding: "3px",
                      color: "white",
                    }}
                  >
                    <span class="d-inline" style={{ fontWeight: "bold" }}>
                      Not Forwarded
                    </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter has NOT been forwarded to any
                          subsequent entity identified on the original claim.
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {this.state.notfortotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.notfortotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //Adjusted card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem(
                          "querysub",
                          "Adjudication Complete"
                        );
                        sessionStorage.setItem("subclaimname", "finalizedf5");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F4&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "F4");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem(
                          "querysub",
                          "Adjudication Complete"
                        );
                        sessionStorage.setItem("queryCategoryCode", "F4");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                        sessionStorage.setItem("subclaimname", "finalizedf5");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F4&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem(
                          "querysub",
                          "Adjudication Complete"
                        );
                        sessionStorage.setItem("queryCategoryCode", "F4");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                        sessionStorage.setItem("subclaimname", "finalizedf5");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F4&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Finalized");
                        sessionStorage.setItem(
                          "querysub",
                          "Adjudication Complete"
                        );
                        sessionStorage.setItem("queryCategoryCode", "F4");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Finalized"
                        );
                        sessionStorage.setItem("subclaimname", "finalizedf5");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Finalized&statusCategoryCode=F4&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Finalized");
                      sessionStorage.setItem(
                        "querysub",
                        "Adjudication Complete"
                      );
                      sessionStorage.setItem("queryCategoryCode", "F4");
                      sessionStorage.setItem("queryCategoryname", "Finalized");
                      sessionStorage.setItem("subclaimname", "finalizedf5");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Finalized&statusCategoryCode=F4&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem("querycategory", "Finalized");
                      sessionStorage.setItem(
                        "querysub",
                        "Adjudication Complete"
                      );
                      sessionStorage.setItem("queryCategoryCode", "F4");
                      sessionStorage.setItem("queryCategoryname", "Finalized");
                      sessionStorage.setItem("subclaimname", "finalizedf5");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Finalized&statusCategoryCode=F4`
                      );
                    }
                  }
                }}
              >
                <div class="card-header bg-white">
                  <div
                    style={{
                      background:
                        this.state.show2 === "F5"
                          ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)"
                          : "linear-gradient(to right, #002755 65%, #EB1A23)",
                      padding: "3px",
                      color: "white",
                    }}
                  >
                    <span class="d-inline" style={{ fontWeight: "bold" }}>
                      Adjudication Complete
                    </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter has been adjudicated and no
                          further payment is forthcoming.
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {this.state.completetotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.completetotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Finalized;
