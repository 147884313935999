import React, { useState, useEffect } from "react";
import { ip } from "../../../../Api";
import axios from "axios";
import { LineChart, Line, XAxis, YAxis, Tooltip } from "recharts";

const data2 = [
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
];
const COLORS = ["#00BFFF", "#FF0080", "#8C7380", "#992600", "#008080"];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};

const PayerCategoryCharts = ({ client, Location, from, to, categorywise }) => {
  const [deptWise, setDeptWise] = useState([]);
  const [accesstoken, setAccessToken] = useState(
    sessionStorage.getItem("accesstoken")
  );
  const refreshtoken = sessionStorage.getItem("refreshtoken");

  const renewAccessToken = async () => {
    const renewHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshtoken}`,
    };
    try {
      const response = await axios.get(`${ip}/auth/renewAccessToken`, {
        headers: renewHeaders,
      });
      sessionStorage.setItem("accesstoken", response.data.accessToken);
      setAccessToken(response.data.accessToken);
    } catch (error) {
      console.error("Failed to renew access token", error);
    }
  };

  const getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };

    let url = `${ip}/outcome/departmentwise`;
    if (client) {
      url += Location
        ? from && to
          ? `?start_date=${from}&end_date=${to}&clientId=${client}&Location=${Location}`
          : `?clientId=${client}&Location=${Location}`
        : from && to
        ? `?start_date=${from}&end_date=${to}&clientId=${client}`
        : `?clientId=${client}`;
    } else if (from && to) {
      url += `?start_date=${from}&end_date=${to}`;
    }

    try {
      const response = await axios.get(url, { headers });
      const data = response.data.department_wise.length
        ? response.data.department_wise.slice(0, 5)
        : data2;
      setDeptWise(data);
    } catch (error) {
      console.error("Failed to fetch details", error);
      setDeptWise(data2);
    }
  };

  useEffect(() => {
    getDetails();
  }, [client, Location, from, to, accesstoken]);

  return (
    <div>
      {categorywise === "$" && (
        <div>
          <LineChart
            width={500}
            height={400}
            data={deptWise}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey={"_id"}
              height={120}
              interval={0}
              angle={-45}
              minTickGap={6}
              tick={{
                strokeWidth: 0,
                fontSize: "11",
                textAnchor: "end",
                dy: 6,
              }}
            />
            <YAxis dataKey={"totalAmount"} />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey={"totalAmount"}
              stroke="#82ca9d"
              name="Total Amount"
            />
          </LineChart>
        </div>
      )}
      {categorywise === "#" && (
        <div>
          <LineChart
            width={500}
            height={400}
            data={deptWise}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey={"_id"}
              height={120}
              interval={0}
              angle={-45}
              minTickGap={6}
              tick={{
                strokeWidth: 0,
                fontSize: "11",
                textAnchor: "end",
                dy: 6,
              }}
            />
            <YAxis dataKey={"totalClaims"} />
            <Tooltip content={<ClaimTooltip />} />
            <Line
              type="monotone"
              dataKey={"totalClaims"}
              stroke="#82ca9d"
              name="Total Claims"
            />
          </LineChart>
        </div>
      )}
    </div>
  );
};

export default PayerCategoryCharts;
