import React, { Component } from "react";
import axios from "axios";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { AiOutlineDownload } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh
} from "react-icons/io";
import fileformat from "../../Files/denialmaster_sample.xlsx";
import { Table } from "react-bootstrap";
import { ip} from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loading from "../../Images/loader.gif";
import upload from "../../Images/upload.png";
import excel from "../../Images/excel.png";
import { IoMdClose } from "react-icons/io";

class Denialmaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      statuslist: [],
      updatemodal: false,
      createmodal: false,
      dataforupdate: null,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role_type: sessionStorage.getItem("role_type"),
      getalldenialdata: [],
      statusCategoryCode: "",
      statusCode: "",
      claimStatus: "",
      claimCategory: "",
      claimSubCategory: "",
      RankCategory: "",
      reason: "",
      Notes1: "",
      Notes2: "",
      Action1: "",
      Action2: "",
      Comments: "",
      payervalue1: [],
      Bucket_to_be_moved: "",
      RemarkCodeOrDesc: "",
      update_statusCategoryCode: "",
      update_statusCode: "",
      client: sessionStorage.getItem("clientid"),
      update_reason: "",
      update_claimCategory: "",
      update_claimSubCategory: "",
      update_RankCategory: "",
      update_claimStatus: "",
      update_Notes1: "",
      update_Notes2: "",
      update_Action1: "",
      update_Action2: "",
      update_Comments: "",
      update_Bucket_to_be_moved: "",
      update_RemarkCodeOrDesc: "",
      statusCategoryCodeErr: "",
      statusCodeErr: "",
      reasonErr: "",
      search__pg__count: 0,
      offsetSearch: 0,
      search__status__code: "",
      claimCategoryErr: "",
      claimSubCategoryErr: "",
      searchdataresponse1: [],
      searchclaimdata1: [],
      searchtext1: "",
      RankCategoryErr: "",
      Notes1Err: "",
      claimStatusErr: "",
      Action1Err: "",
      CommentsErr: "",
      Bucket_to_be_movedErr: "",
      RemarkCodeOrDescErr: "",
      payercategories: [],
      singleid: "",
      offset: 0,
      currentpage: 0,
      perPage: 10,
      searchClaimId: "",
      searchClaimStatus: [],
      serachCategory: [],
      claim__id: "",
      bulkdeletemodal:false,
      bulkmodal: false,
      file:null,
    };
    this.fileInput = React.createRef();
    this.onFileChange = this.onFileChange.bind(this);
    this.clearFile = this.clearFile.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  //toggle modal for update
  toggleupdate = () => {
    this.setState({ updatemodal: !this.state.updatemodal });
  };
  //toggle for bulk delete
  toggledeletebulk = () => {
    this.setState({ bulkdeletemodal: !this.state.bulkdeletemodal });
  }
  //bulk upload modal
  bulkmodal = () => {
    this.setState({
      bulkmodal: !this.state.bulkmodal,
    });
  };
  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };
  clearFile() {
    this.setState({ file: null });
  }
  onFileUpload = async (e) => {
    if (!this.state.file) {
      // Display an error message or handle the validation as needed
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    e.preventDefault();
    const value = new FormData();
    value.append("file", this.fileInput.current.files[0]);
    value.append("clientids", JSON.stringify(this.state.client));
    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    await axios({
      method: "post",
      url: `${ip}/denialmaster/bulk/upload`,
      data: value,
      headers: headers,
    })
      .then((response) => {
        //handle success
        if (response.status === 200) {
          toast.success("File uploaded successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
          this.getdenialmaster();
        }
      })
      .catch((err) => {
        //handle error
        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 400) {
            toast.error("Please include all the fields in excel data", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
//bulk delete api
  bulkdelete = async () => {
  //api call to delete requests
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${this.state.accesstoken}`,
  };
  // eslint-disable-next-line
  const req = axios
    .delete(`${ip}/denialmaster/bulk/delete?clientId=${this.state.client}`, { headers: headers })
    .then((req) => {
      this.toggledeletebulk();
      this.getdenialmaster();
      toast.error("Denial Master deleted", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      if (err.response) {
        this.getdenialmaster();
        this.toggledeletebulk();
        this.setState({ createmodal: !this.state.createmodal });
        if (err.response.data.statusCode === 500) {
          toast.error("Server Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        } else if (err.response.data.statusCode === 401) {
          toast.error("Please reload and try again", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        } else {
          toast.error("An unknown error occured", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      }
    });
  };
  getpayercategory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/payercategory/all`, {
        headers: headers,
      })
      .then((res) => {
        let other = {
          category_name: "Others",
        };
        let data = res.data.payer;
        data.push(other);
        this.setState({ payercategories: data }, () => {});
      })

      .catch((err) => {});
  };
  getClaimStatus = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = await axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        this.setState({
          statuslist: res.data.claimstatus,
        });
      })
      .catch((err) => {});
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getdenialmaster();
      }
    );
  };
  reset__one = (e) => {
    this.setState({
      claim__id: "",
      status3: [],
      payervalue1: [],
      searchClaimId: "",
      searchClaimStatus: [],
      serachCategory: [],
    });
  };
  handleSubmit = async () => {
    const isValid = this.validate();
    if (isValid) {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        statusCategoryCode: this.state.statusCategoryCode,
        statusCode: this.state.statusCode,
        reason: this.state.reason,
        claimCategory: this.state.claimCategory,
        claimSubCategory: this.state.claimSubCategory,
        claimStatus: this.state.claimStatus,
        RankCategory: this.state.RankCategory,
        Notes: this.state.Notes1,
        Notes2: this.state.Notes2,
        Action1: this.state.Action1,
        Action2: this.state.Action2,
        Comments: this.state.Comments,
        Bucket_to_be_moved: this.state.Bucket_to_be_moved,
        RemarkCodeOrDesc: this.state.RemarkCodeOrDesc,
      };
      // eslint-disable-next-line
      const res = await axios
        .post(`${ip}/denialmaster/create`, value, {
          headers: headers,
        })
        .then((res) => {
          if (res) {
            this.toggle();
            this.getdenialmaster();
            this.setState({ createmodal: !this.state.createmodal });
            toast.success("Denial Master created", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            this.toggle();
            this.getdenialmaster();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
      // }
    }
  };

  // fuction for prepopulate the particular data
  settingupdatestate = function () {
    this.setState({
      singleid: this.state.dataforupdate._id,
      update_statusCategoryCode: this.state.dataforupdate.statusCategoryCode,
      update_statusCode: this.state.dataforupdate.statusCode,
      update_reason: this.state.dataforupdate.reason,
      update_claimStatus: this.state.dataforupdate.claimStatus,
      update_claimCategory: this.state.dataforupdate.claimCategory,
      update_claimSubCategory: this.state.dataforupdate.claimSubCategory,
      update_RankCategory: this.state.dataforupdate.RankCategory,
      update_Notes: this.state.dataforupdate.Notes,
      // update_Action3: this.state.dataforupdate.Action3,
      // update_Action4: this.state.dataforupdate.Action4,
      update_Comments: this.state.dataforupdate.Comments,
      update_Bucket_to_be_moved: this.state.dataforupdate.Bucket_to_be_moved,
      update_RemarkCodeOrDesc: this.state.dataforupdate.RemarkCodeOrDesc,
    });
  };

  putrequests = async (dataid) => {
    const isValid = this.updatevalidate();

    if (isValid) {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        statusCategoryCode: this.state.update_statusCategoryCode,
        statusCode: this.state.update_statusCode,
        reason: this.state.update_reason,
        claimStatus: this.state.update_claimStatus,
        claimCategory: this.state.update_claimCategory,
        claimSubCategory: this.state.update_claimSubCategory,
        RankCategory: this.state.update_RankCategory,
        Notes: this.state.update_Notes,
        // Notes2: this.state.update_Notes2,
        // Notes3: this.state.update_Notes3,
        // Notes4: this.state.update_Notes4,
        // Action1: this.state.update_Action1,
        // Action2: this.state.update_Action2,
        // Action3: this.state.update_Action3,
        // Action4: this.state.update_Action4,
        Comments: this.state.update_Comments,
        Bucket_to_be_moved: this.state.update_Bucket_to_be_moved,
        RemarkCodeOrDesc: this.state.update_RemarkCodeOrDesc,
      };

      // eslint-disable-next-line
      const res = await axios
        .put(`${ip}/denialmaster/${dataid}`, value, {
          headers: headers,
        })
        .then((res) => {
          if (res) {
            this.toggleupdate();
            this.getdenialmaster();
            this.setState({ createmodal: !this.state.createmodal });
            toast.info("Denial Master updated", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            this.toggleupdate();
            this.getdenialmaster();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };
  reset = () => {
    this.setState({
      search__status__code: "",
      searchdataresponse1: [],
      searchclaimdata1: [],
      searchtext1: "",
    });
  };
  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        search__pg__count: selectedPage,
        offsetSearch: offset,
      },
      () => this.filtersearch1()
    );
  };
  filtersearch1 = (e) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.search__status__code !== "") {
      let url;
      if (this.state.client) {
        url = `${ip}/denialmaster/advancedmultiplefilter?statusCode=${this.state.search__status__code}&pageno=${this.state.search__pg__count}&clientId=${this.state.client}`;
      } else {
        url = `${ip}/denialmaster/advancedmultiplefilter?statusCode=${this.state.search__status__code}&pageno=${this.state.search__pg__count}`;
      }
      const res = axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          let filtereddata = res.data.denialmaster;
          if (filtereddata.length !== 0) {
            this.setState({ searchtext1: 1 });
            this.setState({
              searchdataresponse1: filtereddata,
              pageCount: Math.ceil(res.data.notescount / this.state.perPage),
            });
          } else if (filtereddata.length === 0) {
            this.setState({
              searchtext1: 1,
              searchdataresponse1: [],
              searchclaimdata1: [],
            });
          }
        })
        .catch((err) => {});
    } else {
      alert("Select data to search");
    }
  };
  //getusers
  getdenialmaster = () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(
        `${ip}/denialmaster/cpts?pageno=${
          this.state.currentpage + 1
        }&clientId=${this.state.client}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          pageCount: Math.ceil(res.data.cpt.cpts / this.state.perPage),
          getalldenialdata: res.data.cpt.count,
        });
      })
      .catch((err) => {});
  };

  validate = () => {
    let hasErr = "true";
    let statusCategoryCodeErr = "";
    let statusCodeErr = "";

    if (this.state.statusCategoryCode === "") {
      statusCategoryCodeErr = "This field is required";
      this.setState({ statusCategoryCodeErr });
      hasErr = false;
    }
    if (this.state.statusCode === "") {
      statusCodeErr = "This field is required";
      this.setState({ statusCodeErr });
      hasErr = false;
    }

    return hasErr;
  };

  updatevalidate = () => {
    let hasErr = "true";
    let statusCategoryCodeErr = "";
    let statusCodeErr = "";

    if (this.state.update_statusCategoryCode === "") {
      statusCategoryCodeErr = "This field is required";
      this.setState({ statusCategoryCodeErr });
      hasErr = false;
    }

    if (this.state.update_statusCode === "") {
      statusCodeErr = "This field is required";
      this.setState({ statusCodeErr });
      hasErr = false;
    }

    return hasErr;
  };
  handleStatusChange = (selected) => {
    if (selected.length > 0) {
      this.setState({ status: selected[0] });
    } else {
      this.setState({ status: "" });
    }
  };

  componentDidMount() {
    this.getdenialmaster();
    this.getClaimStatus();
    this.getpayercategory();
  }
  // doublt reset here so cmt this
  // reset = () => {
  //   this.setState({
  //     statusCategoryCode: "",
  //     statusCode: "",
  //     reason: "",
  //     claimCategory: "",
  //     claimSubCategory: "",
  //     RankCategory: "",
  //     Notes1: "",
  //     Notes2: "",
  //     Action1: "",
  //     Action1: "",
  //     Action1Err: "",
  //     Action2: "",
  //     Comments: "",
  //     reason: "",
  //     reasonErr: "",
  //     Bucket_to_be_moved: "",
  //     RemarkCodeOrDesc: "",
  //     statusCategoryCodeErr: "",
  //     statusCodeErr: "",
  //     RemarkcodeErr: "",
  //     claimCategoryErr: "",
  //     claimSubCategoryErr: "",
  //     RankCategoryErr: "",
  //     Notes1Err: "",
  //     ActionErr: "",
  //     CommentsErr: "",
  //     claimStatus: "",
  //     claimStatusErr: "",
  //     Bucket_to_be_movedErr: "",
  //     RemarkCodeOrDescErr: "",
  //   });
  // };
  render() {
    return (
      <div>
        <Newsidebar name="Denial Master" />
        <div className="align-row" style={{ overflow: "hidden", paddingTop:"1%" }}>
          <ToastContainer />

          <div>
          <Row style={{ alignItems: "center", marginLeft: "20px" }}>
          {/* Left side: Search Box */}
          <Col xs={6}>
            <Row 
            style={{
              background:"whitesmoke",
              padding:"6px",
              borderRadius:"20px",
            }}>
              <Col xs={6}>
                <FormGroup>
                  <Label>Status Code</Label>
                  <Input
                    value={this.state.search__status__code}
                    onChange={(e) => {
                      this.setState({ search__status__code: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <div
                  className="btn-group"
                  role="group"
                  style={{ marginRight: "220px" }}
                >
                  <Button
                    type="button"
                    className="btn"
                    style={{
                      width: "90%",
                      height: "59%",
                      marginTop: "18px",
                      borderRadius: "8px",
                      color: "white",
                      background: "#041C3C",
                      fontWeight: "bold",
                      marginRight: "10%",
                    }}
                    onClick={(e) => this.filtersearch1(e)}
                  >
                    Search
                  </Button>
                  <button
                    type="button"
                    className="btn"
                    style={{
                      width: "60%",
                      height: "59%",
                      marginTop: "18px",
                      border: "none",
                      color: "white",
                      background: "#7DD657",
                      fontWeight: "bold",
                      marginRight: "100%",
                    }}
                    onClick={this.reset}
                  >
                    <IoIosRefresh
                      style={{
                        color: "white",
                        fontSize: "25px",
                      }}
                    />
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        
          {/* Right side: Button Group */}
          <Col xs={6} style={{ textAlign: "right" }}>
            <div className="btn-group" role="group">
              <button
                type="button"
                className="btn"
                style={{ backgroundColor: "#041c3c", color: "#fff" }}
                onClick={this.toggle}
              >
                Add New Denialmaster
              </button>
              <button
                type="button"
                className="btn"
                style={{ backgroundColor: "#7DD657", color: "#fff" }}
                onClick={this.bulkmodal}
              >
                Bulk Insert
              </button>
              <a
              style={{ textDecoration: "none", color: "white" }}
              href={fileformat}
              download="Sample_Denial.xlsx"
              target="_blank"
            >
              <button
                type="button"
                className="btn"
                style={{ backgroundColor: "#041c3c", color: "#fff" }}
              
              >
              <AiOutlineDownload style={{ fontSize: "15px" }} />
              &nbsp;&nbsp;Sample File
              </button>
              </a>
              <button
                type="button"
                className="btn"
                style={{ backgroundColor: "#EB1A23", color: "#fff" }}
                onClick={this.toggledeletebulk}
              >
                Bulk Delete
              </button>
            </div>
          </Col>
        </Row>
        
          </div>

          <div style={{ height: "100%", width: "100%", marginTop: "2%" }}>
            {this.state.getalldenialdata.length === 0 ? (
              <div className="bot-ui">
              <div className="bot-head">
                <div className="eyes-container">
                  <div className="to-left eye"></div>
                  <div className="to-right eye"></div>
                </div>
              </div>
              <div className="bot-body">
                <div className="analyzer"></div>
              </div>
              <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                No Data Found
              </p>
            </div>
            ) : (
              <div>
                {this.state.searchtext1 === "" ? (
                  <div>
                    <div
                      style={{
                        marginLeft: "3%",
                        marginRight: "3%",
                        width: "91%",
                      }}
                    >
                      <Table responsive="sx" bordered className="StandardTable">
                        <thead>
                          <tr>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                                whiteSpace: "normal",
                              }}
                            >
                              Status Category Code
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Client ID
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Status Code
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Reason
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Claim Category
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Claim SubCategory
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Claim Status
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Rank Category
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Notes
                            </th>

                            {/* <th>Comments</th> */}
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                                whiteSpace: "normal",
                              }}
                            >
                              Bucket Moved
                            </th>
                            <th
                              style={{
                                backgroundColor: "#041c3c",
                                color: "white",
                                textAlign: "center",
                                whiteSpace: "normal",
                              }}
                            >
                              Remark Code ORDesc
                            </th>
                            {this.state.role_type === "Admin" && (
                              <th
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                  textAlign: "center",
                                }}
                              >
                                Action
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {rows.map((row) => ( */}
                          {this.state.getalldenialdata &&
                            this.state.getalldenialdata.map((data, index) => (
                              <tr key={data._id}>
                                <td
                                  component="th"
                                  scope="row"
                                  style={{ backgroundColor: "whitesmoke" }}
                                >
                                  {data.statusCategoryCode}
                                </td>
                                <td
                                  component="th"
                                  scope="row"
                                  style={{ backgroundColor: "whitesmoke" }}
                                >
                                  {data.clientId}
                                </td>
                                <td style={{ backgroundColor: "whitesmoke" }}>
                                  {data.statusCode}
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                    background: "whitesmoke",
                                  }}
                                >
                                  {data.reason}
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                    backgroundColor: "whitesmoke",
                                  }}
                                >
                                  {data.claimCategory}
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                    backgroundColor: "whitesmoke",
                                  }}
                                >
                                  {data.claimSubCategory}
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                    backgroundColor: "whitesmoke",
                                  }}
                                >
                                  {data.claimStatus}
                                </td>
                                <td style={{ backgroundColor: "whitesmoke" }}>
                                  {data.RankCategory}
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                    background: "whitesmoke",
                                  }}
                                >
                                  {data.Notes}
                                </td>

                                {/* <td>{data.Comments}</td> */}
                                <td style={{ backgroundColor: "whitesmoke" }}>
                                  {data.Bucket_to_be_moved}
                                </td>
                                <td style={{ backgroundColor: "whitesmoke" }}>
                                  {data.RemarkCodeOrDesc}
                                </td>

                                {this.state.role_type === "Admin" && (
                                  <td style={{ backgroundColor: "whitesmoke" }}>
                                    <button
                                      className="Edit"
                                      onClick={() => {
                                        this.setState(
                                          { dataforupdate: data },
                                          () => {
                                            this.settingupdatestate();
                                            this.toggleupdate();
                                          }
                                        );
                                      }}
                                    >
                                      <FaRegEdit />
                                    </button>
                                  </td>
                                )}
                              </tr>
                              // ))}
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    {this.state.searchdataresponse1.length === 0 ? (
                      <div className="bot-ui">
                        <div className="bot-head">
                          <div className="eyes-container">
                            <div className="to-left eye"></div>
                            <div className="to-right eye"></div>
                          </div>
                        </div>
                        <div className="bot-body">
                          <div className="analyzer"></div>
                        </div>
                        <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                          No Data Found
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div
                          style={{ marginLeft: "50px", marginRight: "75px" }}
                        >
                          <Table
                            // sx={{ minWidth: 700, marginLeft: "18px" }}
                            // aria-label="customized table"
                            responsive="sx"
                            bordered
                            className="StandardTable"
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  Status Category Code
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  Client ID
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  Status Code
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  Reason
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  Claim Category
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  Claim SubCategory
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  Claim Status
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  Rank Category
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  Notes
                                </th>

                                {/* <th>Comments</th> */}
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  Bucket Moved
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#041c3c",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  RemarkCodeORDesc
                                </th>
                                {this.state.role_type === "Admin" && (
                                  <th
                                    style={{
                                      backgroundColor: "#041c3c",
                                      color: "white",
                                      textAlign: "center",
                                    }}
                                  >
                                    Action
                                  </th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {/* {rows.map((row) => ( */}
                              {this.state.searchdataresponse1 &&
                                this.state.searchdataresponse1.map(
                                  (data, index) => (
                                    <tr key={data._id}>
                                      <td
                                        component="th"
                                        scope="row"
                                        style={{
                                          backgroundColor: "whitesmoke",
                                        }}
                                      >
                                        {data.statusCategoryCode}
                                      </td>
                                      <td
                                        component="th"
                                        scope="row"
                                        style={{
                                          backgroundColor: "whitesmoke",
                                        }}
                                      >
                                        {data.clientId}
                                      </td>
                                      <td
                                        style={{
                                          backgroundColor: "whitesmoke",
                                        }}
                                      >
                                        {data.statusCode}
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: "pre-wrap",
                                          wordWrap: "break-word",
                                          background: "whitesmoke",
                                        }}
                                      >
                                        {data.reason}
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: "pre-wrap",
                                          wordWrap: "break-word",
                                          backgroundColor: "whitesmoke",
                                        }}
                                      >
                                        {data.claimCategory}
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: "pre-wrap",
                                          wordWrap: "break-word",
                                          backgroundColor: "whitesmoke",
                                        }}
                                      >
                                        {data.claimSubCategory}
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: "pre-wrap",
                                          wordWrap: "break-word",
                                          backgroundColor: "whitesmoke",
                                        }}
                                      >
                                        {data.claimStatus}
                                      </td>
                                      <td
                                        style={{
                                          backgroundColor: "whitesmoke",
                                        }}
                                      >
                                        {data.RankCategory}
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: "pre-wrap",
                                          wordWrap: "break-word",
                                          background: "whitesmoke",
                                        }}
                                      >
                                        {data.Notes}
                                      </td>

                                      {/* <td>{data.Comments}</td> */}
                                      <td
                                        style={{
                                          backgroundColor: "whitesmoke",
                                        }}
                                      >
                                        {data.Bucket_to_be_moved}
                                      </td>
                                      <td
                                        style={{
                                          backgroundColor: "whitesmoke",
                                        }}
                                      >
                                        {data.RemarkCodeOrDesc}
                                      </td>

                                      {this.state.role_type === "Admin" && (
                                        <td
                                          style={{
                                            backgroundColor: "whitesmoke",
                                          }}
                                        >
                                          <button
                                            className="Edit"
                                            onClick={() => {
                                              this.setState(
                                                { dataforupdate: data },
                                                () => {
                                                  this.settingupdatestate();
                                                  this.toggleupdate();
                                                }
                                              );
                                            }}
                                          >
                                            <FaRegEdit />
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                    // ))}
                                  )
                                )}
                            </tbody>
                          </Table>
                        </div>
                        <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                          <ReactPaginate
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          {this.state.modal && (
            <Modal
              style={{ width: "100%", height: "90%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              onExit={this.reset}
              centered
              size="lg"
            >
              <ModalHeader toggle={this.toggle}>
                Create New DenialMaster
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="required">Status Category Code</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.statusCategoryCode}
                          onChange={(e) =>
                            this.setState({
                              statusCategoryCode: e.target.value,
                              statusCategoryCodeErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.statusCategoryCodeErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="required">Status Code</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.statusCode}
                          onChange={(e) =>
                            this.setState({
                              statusCode: e.target.value,
                              statusCodeErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.statusCodeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Reason</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.reason}
                          onChange={(e) =>
                            this.setState({
                              reason: e.target.value,
                              reasonErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.reasonErr}
                        </div>
                      </FormGroup>
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Claim Category</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.claimCategory}
                          onChange={(e) =>
                            this.setState({
                              claimCategory: e.target.value,
                              claimCategoryErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.claimCategoryErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Claim SubCategory</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.claimSubCategory}
                          onChange={(e) =>
                            this.setState({
                              claimSubCategory: e.target.value,
                              claimSubCategoryErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.claimSubCategoryErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col style={{ marginLeft: "0%" }}>
                      <FormGroup>
                        <Label>Claim Status</Label>
                        <br />
                        <Typeahead
                          id="myTypeahead" // Add a unique id for accessibility
                          clearButton={true}
                          options={this.state.statuslist.map(
                            (data) => data.claimStatus
                          )}
                          onChange={this.handleStatusChange}
                          placeholder="Select Claim Status..."
                          selected={
                            this.state.status ? [this.state.status] : []
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Rank Category</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.RankCategory}
                          style={{ resize: "none" }}
                          onChange={(e) =>
                            this.setState({
                              RankCategory: e.target.value,
                              RankCategoryErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.RankCategoryErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Bucket to be moved</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.Bucket_to_be_moved}
                          onChange={(e) =>
                            this.setState({
                              Bucket_to_be_moved: e.target.value,
                              Bucket_to_be_movedErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.Bucket_to_be_movedErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Notes</Label>
                        <Input
                          type="textarea"
                          value={this.state.Notes1}
                          style={{ resize: "none" }}
                          onChange={(e) =>
                            this.setState({
                              Notes1: e.target.value,
                              Notes1Err: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.Notes1Err}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>RemarkCode OR Desc</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.RemarkCodeOrDesc}
                          onChange={(e) =>
                            this.setState({
                              RemarkCodeOrDesc: e.target.value,
                              RemarkCodeOrDescErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.RemarkCodeOrDescErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.handleSubmit}
                >
                  Create
                </Button>
              </ModalFooter>
            </Modal>
          )}

          {this.state.updatemodal && (
            <Modal
              style={{ width: "100%", height: "90%" }}
              isOpen={this.state.updatemodal}
              onHide={this.toggleupdate}
              onExit={this.reset}
              centered
              size="lg"
            >
              <ModalHeader toggle={this.toggleupdate}>
                Update DenialMaster
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "scroll",
                }}
              >
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="required">Status Category Code</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.update_statusCategoryCode}
                          onChange={(e) =>
                            this.setState({
                              update_statusCategoryCode: e.target.value,
                              statusCategoryCodeErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.statusCategoryCodeErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="required">Status Code</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.update_statusCode}
                          onChange={(e) =>
                            this.setState({
                              update_statusCode: e.target.value,
                              statusCodeErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.statusCodeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Reason</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.update_reason}
                          onChange={(e) =>
                            this.setState({
                              update_reason: e.target.value,
                              reasonErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.reasonErr}
                        </div>
                      </FormGroup>
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Claim Category</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.update_claimCategory}
                          onChange={(e) =>
                            this.setState({
                              update_claimCategory: e.target.value,
                              claimCategoryErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.claimCategoryErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Claim SubCategory</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.update_claimSubCategory}
                          onChange={(e) =>
                            this.setState({
                              update_claimSubCategory: e.target.value,
                              claimSubCategoryErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.claimSubCategoryErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col style={{ marginLeft: "0%" }}>
                      <FormGroup>
                        <Label>Claim Status</Label>
                        <Input
                          className="form-control form-select"
                          type="select"
                          value={this.state.update_claimStatus}
                          onChange={(e) => {
                            this.setState({
                              update_claimStatus: e.target.value,
                            });
                          }}
                        >
                          <option value="" hidden id="select-placeholder">
                            Select Claim Status...
                          </option>
                          {this.state.statuslist.map((data, index) => (
                            <option
                              value={data.claimStatus}
                              selected={
                                data.claimStatus ===
                                this.state.update_claimStatus
                              }
                            >
                              {data.claimStatus}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Rank Category</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.update_RankCategory}
                          style={{ resize: "none" }}
                          onChange={(e) =>
                            this.setState({
                              update_RankCategory: e.target.value,
                              RankCategoryErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.RankCategoryErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Comments</Label>
                        <Input
                          type="text"
                          value={this.state.update_Comments}
                          onChange={(e) =>
                            this.setState({
                              update_Comments: e.target.value,
                              CommentsErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.CommentsErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Notes</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.update_Notes}
                          onChange={(e) =>
                            this.setState({
                              update_Notes: e.target.value,
                              Bucket_to_be_movedErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.Bucket_to_be_movedErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>RemarkCode OR Desc</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.update_RemarkCodeOrDesc}
                          onChange={(e) =>
                            this.setState({
                              update_RemarkCodeOrDesc: e.target.value,
                              RemarkCodeOrDescErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.RemarkCodeOrDescErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggleupdate();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    this.putrequests(this.state.singleid);
                  }}
                >
                  Update
                </Button>
              </ModalFooter>
            </Modal>
          )}

          {this.state.bulkdeletemodal && (
            <Modal
              isOpen={this.state.bulkdeletemodal}
              onHide={this.toggledeletebulk}
              centered
            >
              <ModalHeader toggle={this.toggledeletebulk}>
                Bulk Delete
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this Denial Master?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid gray",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.toggledeletebulk}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "rgb(25,25,25)" }}
                  onClick={() => {
                    this.bulkdelete();
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {this.state.bulkmodal && (
            <Modal
              isOpen={this.state.bulkmodal}
              onHide={this.bulkmodal}
              centered
              size="xl"
            >
              <ModalHeader toggle={this.bulkmodal}>Bulk Upload</ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
              <div>
            <input
              type="file"
              id="file"
              ref={this.fileInput}
              onChange={this.onFileChange}
              style={{ display: "none" }}
              accept=".xlsx"
            />
            {this.state.file ? (
              <div className="file-card-upload">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "5px" }}
                  >
                    <div className="file-card" style={{ position: "relative" }}>
                      <span>
                        <img
                          src={excel}
                          alt="excel"
                          style={{ width: "30px", height: "30px" }}
                        />
                      </span>
                      <div className="file-info">
                        <div style={{ flex: 1 }}>
                          <h6>{this.state.file.name}</h6>
                        </div>
                      </div>
                      <button
                        className="close-icon"
                        onClick={this.clearFile} // Clear file function
                      >
                        <IoMdClose />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="file-btn"
                onClick={() => this.fileInput.current.click()}
              >
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <img
                    src={upload}
                    alt="upload"
                    style={{ width: "90px", height: "90px" }}
                  />
                  <div>
                    <p style={{ margin: 0 }}>Click file to this area to upload</p>
                    <span style={{ color: "GrayText", fontSize: "11px" }}>
                      The given input should be in .xlsx format
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid gray",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.bulkmodal}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    border: "1px solid gray",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.onFileUpload}
                  className="uploadcss"
                >
                  Upload
                </Button>
              </ModalFooter>
            </Modal>
          )}
          <Modal isOpen={this.state.createmodal} centered>
            <ModalBody>
              <img
                src={loading}
                style={{ width: "200px", height: "200px", marginLeft: "30%" }}
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Denialmaster;
