import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import "../../../Styles/subclaim.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { SiAcclaim } from "react-icons/si";
import { SiCodesandbox } from "react-icons/si";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { MdOutlinePaid } from "react-icons/md";
import { GiHospitalCross } from "react-icons/gi";
import { MdPersonOutline } from "react-icons/md";
import { MdOutlinePersonPin } from "react-icons/md";
import axios from "axios";
import { ip } from "../../../Api";
class Machine1Error extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: sessionStorage.getItem("queryurl"),
      Location: sessionStorage.getItem("queryLocation"),
      client: sessionStorage.getItem("queryclient"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
      subcategoryvalue: null,
      subcategoryvalue: null,
      reserrortotalamt: "",
      show2: sessionStorage.getItem("queryclaimid"),
      reserrortotalcnt: "",
      ressystotalamt: "",
      ressystotalcnt: "",
      ihrtotalamt: "",
      ihrtotalcnt: "",
      correctionreqamt: "",
      correctionreqcnt: "",
      tpatotalamt: "",
      tpatotalcnt: "",
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  geterrorsdata = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryenddate && this.state.querystartdate) {
      url = `${ip}/claimstatus/subcategory?${this.state.value}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
    } else {
      url = `${ip}/claimstatus/subcategory?${this.state.value}`;
    }
    const res = await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ subcategoryvalue: res.data.data });
        if (res.data.data.length !== 0) {
          res.data.data.map((data, index) => {
            switch (data._id) {
              case "E0":
                return this.setState({
                  reserrortotalamt: data.totalamount.toLocaleString(),
                  reserrortotalcnt: data.totalcount,
                });
              case "E1":
                return this.setState({
                  ressystotalamt: data.totalamount.toLocaleString(),
                  ressystotalcnt: data.totalcount,
                });
              case "E2":
                return this.setState({
                  ihrtotalamt: data.totalamount.toLocaleString(),
                  ihrtotalcnt: data.totalcount,
                });
              case "E3":
                return this.setState({
                  correctionreqamt: data.totalamount.toLocaleString(),
                  correctionreqcnt: data.totalcount,
                });
              case "E4":
                return this.setState({
                  tpatotalamt: data.totalamount.toLocaleString(),
                  tpatotalcnt: data.totalcount,
                });
            }
          });
        }
        else{

        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    
    this.geterrorsdata();
  }
  render() {
    return (
      <div className="align-row" style={{ overflow: "hidden" }}>
        <Newsidebar>
        <p>
              <Link
                to="/claim-management"
                style={{ textDecoration: "none", color: "white",fontSize: "15px",
                fontWeight: 500 }}
              >
                Claim Status Management
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Machine 1
              Error
            </p>
        </Newsidebar>
        <div className="align-column">
          <br />
          <br />
          <div
            style={{ textAlign: "left", marginLeft: "5%", fontSize: "12px" }}
          >
            
          </div>
         <div className="row" style={{marginLeft:"3%"}}>
         <div className="col-md-4 mb-3">
         <div class="card">
         <div class="card-header bg-white">
         <div
         style={{
          background: this.state.show2 === "E0" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)": "linear-gradient(to right, #002755 65%, #EB1A23)",
          padding: "3px",
          color: "white"}}
         >
         <Row>
         <Col className="text" style={{color:"white"}}>
           Response not Possible - Error on Submitted Data{" "}
         </Col>
         <Col className="subclaim-icon">
           <div className="subclaimicon-green" style={{background:"white"}}>
             <SiAcclaim
               style={{
                 color: "green",
                 fontSize: "17px",
               }}
             >
               {" "}
             </SiAcclaim>
           </div>
         </Col>
       </Row>
       <Row>
         <Col className="subclaim-description" style={{color:"white"}}>
           An Error on submitted request data
         </Col>
       </Row>
         </div>
         <Link
         to={{
           pathname: "/claim/sub-category-details",
         }}
         style={{ textDecoration: "none" }}
       >
           <div class="card-text"
           onClick={() => {
            if (this.state.client) {
              if (this.state.querystartdate && this.state.queryenddate) {
                if (this.state.Location) {
                  sessionStorage.setItem("querycategory", "Machine1 Error");
                  sessionStorage.setItem(
                    "querysub",
                    " Response not Possible"
                  );
                  sessionStorage.setItem(
                    "claimurl",
                    `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E0&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                  );
                  sessionStorage.setItem("queryCategoryCode", "E0");
                  sessionStorage.setItem("queryCategoryname", "error");
                  sessionStorage.setItem("subclaimname", "errore0");
                } else {
                  sessionStorage.setItem("querycategory", "Machine1 Error");
                  sessionStorage.setItem(
                    "querysub",
                    " Response not Possible"
                  );
                  sessionStorage.setItem("queryCategoryCode", "E0");
                  sessionStorage.setItem("queryCategoryname", "error");
                  sessionStorage.setItem(
                    "claimurl",
                    `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E0&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                  );
                  sessionStorage.setItem("subclaimname", "errore0");
                }
              } else {
                if (this.state.Location) {
                  sessionStorage.setItem("querycategory", "Machine1 Error");
                  sessionStorage.setItem(
                    "querysub",
                    " Response not Possible"
                  );
                  sessionStorage.setItem("queryCategoryCode", "E0");
                  sessionStorage.setItem("queryCategoryname", "error");
                  sessionStorage.setItem(
                    "claimurl",
                    `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E0&clientId=${this.state.client}&Location=${this.state.Location}`
                  );
                  sessionStorage.setItem("subclaimname", "errore0");
                } else {
                  sessionStorage.setItem("querycategory", "Machine1 Error");
                  sessionStorage.setItem(
                    "querysub",
                    " Response not Possible"
                  );
                  sessionStorage.setItem("queryCategoryCode", "E0");
                  sessionStorage.setItem("queryCategoryname", "error");
                  sessionStorage.setItem(
                    "claimurl",
                    `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E0&clientId=${this.state.client}`
                  );
                  sessionStorage.setItem("subclaimname", "errore0");
                }
              }
            } else {
              if (this.state.querystartdate && this.state.queryenddate) {
                sessionStorage.setItem("querycategory", "Machine1 Error");
                sessionStorage.setItem(
                  "querysub",
                  "Response not Possible"
                );
                sessionStorage.setItem("queryCategoryCode", "E0");
                sessionStorage.setItem("queryCategoryname", "error");
                sessionStorage.setItem("subclaimname", "errore0");
                sessionStorage.setItem(
                  "claimurl",
                  `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E0&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                );
              } else {
                sessionStorage.setItem("querycategory", "Machine1 Error");
                sessionStorage.setItem(
                  "querysub",
                  " Response not Possible"
                );
                sessionStorage.setItem("queryCategoryCode", "E0");
                sessionStorage.setItem("queryCategoryname", "error");
                sessionStorage.setItem("subclaimname", "errore0");
                sessionStorage.setItem(
                  "claimurl",
                  `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E0`
                );
              }
            }
          }}
           >
           <Row>
           <Col className="sub-totalclaim">
             Total Claims:
             <span>
               <div
                 className="to"
                 style={{
                   color: "black",
                   fontWeight: "600",
                   fontSize: "19px",
                 }}
               >
                 {this.state.reserrortotalcnt}
               </div>
             </span>
           </Col>
           <Col className="sub-total">
             Grand Total:
             <span>
               {" "}
               <div
                 style={{
                   color: "black",
                   fontSize: "19px",
                   fontWeight: "600",
                 }}
               >
                 ${" "}
        
                 {this.state.reserrortotalamt}
               </div>
             </span>
           </Col>
         </Row>
           </div>
           </Link>
         </div>

       </div>
         </div>
         <div className="col-md-4 mb-3">
         <div class="card">
         <div class="card-header bg-white"
          >
          <div
          style={{background: this.state.show2 === "E1" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)": "linear-gradient(to right, #002755 65%, #EB1A23)"}}
          >
          <Row>
          <Col className="text" style={{color:"white"}}>
            Response not Possible - System Status
          </Col>
        <Col className="subclaim-icon">
            <div className="subclaimicon-blue" style={{background:"white"}}>
              <SiCodesandbox
                style={{
                  color: "blue",
                  fontSize: "19px",
                  marginTop:"20%"
                }}
              >
                {" "}
              </SiCodesandbox>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="subclaim-description" style={{color:"white"}}>System Status</Col>
        </Row>
          </div>
          <Link
          to={{
            pathname: "/claim/sub-category-details",
          }}
          style={{ textDecoration: "none" }}
        >
            <div class="card-text"
            onClick={() => {
             if (this.state.client) {
               if (this.state.querystartdate && this.state.queryenddate) {
                 if (this.state.Location) {
                   sessionStorage.setItem("querycategory", "Machine1 Error");
                   sessionStorage.setItem(
                     "querysub",
                     " Response not Possible"
                   );
                   sessionStorage.setItem("queryCategoryCode", "E1");
                   sessionStorage.setItem("queryCategoryname", "error");
                   sessionStorage.setItem(
                     "claimurl",
                     `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E1&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                   );
                   sessionStorage.setItem("subclaimname", "errore1");
                 } else {
                   sessionStorage.setItem("querycategory", "Machine1 Error");
                   sessionStorage.setItem(
                     "querysub",
                     " Response not Possible"
                   );
                   sessionStorage.setItem("queryCategoryCode", "E1");
                   sessionStorage.setItem("queryCategoryname", "error");
                   sessionStorage.setItem(
                     "claimurl",
                     `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E1&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                   );
                   sessionStorage.setItem("subclaimname", "errore1");
                 }
               } else {
                 if (this.state.Location) {
                   sessionStorage.setItem("querycategory", "Machine1 Error");
                   sessionStorage.setItem(
                     "querysub",
                     " Response not Possible"
                   );
                   sessionStorage.setItem("queryCategoryCode", "E1");
                   sessionStorage.setItem("queryCategoryname", "error");
                   sessionStorage.setItem(
                     "claimurl",
                     `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E1&clientId=${this.state.client}&Location=${this.state.Location}`
                   );
                   sessionStorage.setItem("subclaimname", "errore1");
                 } else {
                   sessionStorage.setItem("querycategory", "Machine1 Error");
                   sessionStorage.setItem(
                     "querysub",
                     " Response not Possible"
                   );
                   sessionStorage.setItem("queryCategoryCode", "E1");
                   sessionStorage.setItem("queryCategoryname", "error");
                   sessionStorage.setItem(
                     "claimurl",
                     `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E1&clientId=${this.state.client}`
                   );
                   sessionStorage.setItem("subclaimname", "errore1");
                 }
               }
             } else {
               if (this.state.querystartdate && this.state.queryenddate) {
                 sessionStorage.setItem("querycategory", "Machine1 Error");
                 sessionStorage.setItem(
                   "querysub",
                   "Response not Possible"
                 );
                 sessionStorage.setItem("queryCategoryCode", "E1");
                 sessionStorage.setItem("queryCategoryname", "error");
                 sessionStorage.setItem("subclaimname", "errore1");
                 sessionStorage.setItem(
                   "claimurl",
                   `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E1&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                 );
               } else {
                 sessionStorage.setItem("querycategory", "Machine1 Error");
                 sessionStorage.setItem(
                   "querysub",
                   " Response not Possible"
                 );
                 sessionStorage.setItem("queryCategoryCode", "E1");
                 sessionStorage.setItem("queryCategoryname", "error");
                 sessionStorage.setItem("subclaimname", "errore1");
                 sessionStorage.setItem(
                   "claimurl",
                   `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E1`
                 );
               }
             }
           }}
            >
            <Row>
            <Col className="sub-totalclaim">
              Total Claims:
              <span>
                <div
                  className="to"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    fontSize: "19px",
                  }}
                >
                  {/* {this.state.subcategoryvalue &&
                    this.state.subcategoryvalue[1].totalcount} */}
                  {this.state.ressystotalcnt}
                </div>
              </span>
            </Col>
            <Col className="sub-total">
              Grand Total:
              <span>
                {" "}
                <div
                  style={{
                    color: "black",
                    fontSize: "19px",
                    fontWeight: "600",
                  }}
                >
                  ${" "}
                  {/* {this.state.subcategoryvalue &&
                    this.state.subcategoryvalue[1].totalamount} */}
                  {this.state.ressystotalamt}
                </div>
              </span>
            </Col>
          </Row>
 
            </div>
            </Link>
         </div>
   
       </div>
         </div>
         <div className="col-md-4 mb-3">
         <div class="card">
         <div class="card-header bg-white"
         >
         <div
         style={{background: this.state.show2 === "E2" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)": "linear-gradient(to right, #002755 65%, #EB1A23)"}}
         >
         <Row>
         <Col className="text" style={{color:"white"}}>
           Information Holder is not Responding
         </Col>
         <Col className="subclaim-icon">
           <div className="subclaimicon-purple" style={{background:"white"}}>
             <MdOutlineIntegrationInstructions
               style={{
                 color: "purple",
                 fontSize: "21px",
                 marginTop: "20%",
               }}
             >
               {" "}
             </MdOutlineIntegrationInstructions>
           </div>
         </Col>
       </Row>
       <Row>
         <Col className="subclaim-description" style={{color:"white"}}>
           Resubmit at a later time.
         </Col>
       </Row>
       </div>
       <Link
       to={{
         pathname: "/claim/sub-category-details",
       }}
       style={{ textDecoration: "none" }}
     >
         <div class="card-text"
         onClick={() => {
          if (this.state.client) {
            if (this.state.querystartdate && this.state.queryenddate) {
              if (this.state.Location) {
                sessionStorage.setItem("querycategory", "Machine1 Error");
                sessionStorage.setItem(
                  "querysub",
                  "Information Holder is not Responding"
                );
                sessionStorage.setItem(
                  "claimurl",
                  `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E2&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                );
                sessionStorage.setItem("queryCategoryCode", "E2");
                sessionStorage.setItem("queryCategoryname", "error");
                sessionStorage.setItem("subclaimname", "errore2");
              } else {
                sessionStorage.setItem("querycategory", "Machine1 Error");
                sessionStorage.setItem(
                  "querysub",
                  "Information Holder is not Responding"
                );
                sessionStorage.setItem("queryCategoryCode", "E2");
                sessionStorage.setItem("queryCategoryname", "error");
                sessionStorage.setItem(
                  "claimurl",
                  `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E2&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                );
                sessionStorage.setItem("subclaimname", "errore2");
              }
            } else {
              if (this.state.Location) {
                sessionStorage.setItem("querycategory", "Machine1 Error");
                sessionStorage.setItem(
                  "querysub",
                  "Information Holder is not Responding"
                );
                sessionStorage.setItem("queryCategoryCode", "E2");
                sessionStorage.setItem("queryCategoryname", "error");
                sessionStorage.setItem(
                  "claimurl",
                  `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E2&clientId=${this.state.client}&Location=${this.state.Location}`
                );
                sessionStorage.setItem("subclaimname", "errore2");
              } else {
                sessionStorage.setItem("querycategory", "Machine1 Error");
                sessionStorage.setItem(
                  "querysub",
                  "Information Holder is not Responding"
                );
                sessionStorage.setItem("queryCategoryCode", "E2");
                sessionStorage.setItem("queryCategoryname", "error");
                sessionStorage.setItem(
                  "claimurl",
                  `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E2&clientId=${this.state.client}`
                );
                sessionStorage.setItem("subclaimname", "errore2");
              }
            }
          } else {
            if (this.state.querystartdate && this.state.queryenddate) {
              sessionStorage.setItem("querycategory", "Machine1 Error");
              sessionStorage.setItem(
                "querysub",
                "Information Holder is not Responding"
              );
              sessionStorage.setItem("queryCategoryCode", "E2");
              sessionStorage.setItem("queryCategoryname", "error");
              sessionStorage.setItem("subclaimname", "errore2");
              sessionStorage.setItem(
                "claimurl",
                `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E2&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
              );
            } else {
              sessionStorage.setItem("querycategory", "Machine1 Error");
              sessionStorage.setItem(
                "querysub",
                "Information Holder is not Responding"
              );
              sessionStorage.setItem("queryCategoryCode", "E2");
              sessionStorage.setItem("queryCategoryname", "error");
              sessionStorage.setItem("subclaimname", "errore2");
              sessionStorage.setItem(
                "claimurl",
                `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E2`
              );
            }
          }
        }}
         >
         <Row>
         <Col className="sub-totalclaim">
           Total Claims:
           <span>
             <div
               className="to"
               style={{
                 color: "black",
                 fontWeight: "600",
                 fontSize: "19px",
               }}
             >
               {this.state.ihrtotalcnt}
             </div>
           </span>
         </Col>
         <Col className="sub-total">
           Grand Total:
           <span>
             {" "}
             <div
               style={{
                 color: "black",
                 fontSize: "19px",
                 fontWeight: "600",
               }}
             >
               ${" "}
               {this.state.ihrtotalamt}
             </div>
           </span>
         </Col>
       </Row>
         </div>
         </Link>
         </div>
  
       </div>
         </div>
         </div>
         <div className="row" style={{marginLeft:"3%"}}>
         <div className="col-md-4 mb-3">
         <div class="card">
         <div class="card-header bg-white"
         >
         <div
         style={{background: this.state.show2 === "E3" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)": "linear-gradient(to right, #002755 65%, #EB1A23)"}}
         >
         <Row>
         <Col className="text" style={{color:"white"}}>Correction Required</Col>

         <Col className="subclaim-icon">
           <div className="subclaimicon-pink" style={{background:"white"}}>
             <MdOutlinePaid
               style={{
                 color: "#e60028",
                 fontSize: "22px",
                 marginTop: "18%",
               }}
             >
               {" "}
             </MdOutlinePaid>
           </div>
         </Col>
       </Row>
       <Row>
         <Col className="subclaim-description" style={{color:"white"}}>
           Relational fields in error
         </Col>
       </Row>
       </div>
       <Link
       to={{
         pathname: "/claim/sub-category-details",
       }}
       style={{ textDecoration: "none" }}
     >
         <div class="card-text"
         onClick={() => {
          if (this.state.client) {
            if (this.state.querystartdate && this.state.queryenddate) {
              if (this.state.Location) {
                sessionStorage.setItem("querycategory", "Machine1 Error");
                sessionStorage.setItem(
                  "querysub",
                  "Correction Required"
                );
                sessionStorage.setItem("queryCategoryCode", "E3");
                sessionStorage.setItem("queryCategoryname", "error");
                sessionStorage.setItem(
                  "claimurl",
                  `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E3&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                );
                sessionStorage.setItem("subclaimname", "errore3");
              } else {
                sessionStorage.setItem("querycategory", "Machine1 Error");
                sessionStorage.setItem(
                  "querysub",
                  "Correction Required"
                );
                sessionStorage.setItem("queryCategoryCode", "E3");
                sessionStorage.setItem("queryCategoryname", "error");
                sessionStorage.setItem(
                  "claimurl",
                  `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E3&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                );
                sessionStorage.setItem("subclaimname", "errore3");
              }
            } else {
              if (this.state.Location) {
                sessionStorage.setItem("querycategory", "Machine1 Error");
                sessionStorage.setItem(
                  "querysub",
                  "Correction Required"
                );
                sessionStorage.setItem("queryCategoryCode", "E3");
                sessionStorage.setItem("queryCategoryname", "error");
                sessionStorage.setItem(
                  "claimurl",
                  `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E3&clientId=${this.state.client}&Location=${this.state.Location}`
                );
                sessionStorage.setItem("subclaimname", "errore3");
              } else {
                sessionStorage.setItem("querycategory", "Machine1 Error");
                sessionStorage.setItem(
                  "querysub",
                  "Correction Required"
                );
                sessionStorage.setItem(
                  "claimurl",
                  `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E3&clientId=${this.state.client}`
                );
                sessionStorage.setItem("queryCategoryCode", "E3");
                sessionStorage.setItem("queryCategoryname", "error");
                sessionStorage.setItem("subclaimname", "errore3");
              }
            }
          } else {
            if (this.state.querystartdate && this.state.queryenddate) {
              sessionStorage.setItem("querycategory", "Machine1 Error");
              sessionStorage.setItem("querysub", "Correction Required");
              sessionStorage.setItem("subclaimname", "errore3");
              sessionStorage.setItem(
                "claimurl",
                `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E3&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
              );
              sessionStorage.setItem("queryCategoryCode", "E3");
              sessionStorage.setItem("queryCategoryname", "error");
            } else {
              sessionStorage.setItem("querycategory", "Machine1 Error");
              sessionStorage.setItem("querysub", "Correction Required");
              sessionStorage.setItem("subclaimname", "errore3");
              sessionStorage.setItem(
                "claimurl",
                `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E3`
              );
              sessionStorage.setItem("queryCategoryCode", "E3");
              sessionStorage.setItem("queryCategoryname", "error");
            }
          }
        }}
         >
         <Row>
         <Col className="sub-totalclaim">
           Total Claims:
           <span>
             <div
               className="to"
               style={{
                 color: "black",
                 fontWeight: "600",
                 fontSize: "19px",
               }}
             >
               {/* {this.state.subcategoryvalue &&
                 this.state.subcategoryvalue[3].totalcount} */}
               {this.state.correctionreqcnt}
             </div>
           </span>
         </Col>
         <Col className="sub-total">
           Grand Total:
           <span>
             {" "}
             <div
               style={{
                 color: "black",
                 fontSize: "19px",
                 fontWeight: "600",
               }}
             >
               ${" "}
               {/* {this.state.subcategoryvalue &&
                 this.state.subcategoryvalue[3].totalamount} */}
               {this.state.correctionreqamt}
             </div>
           </span>
         </Col>
       </Row>
         </div>
         </Link>
         </div>
      
       </div>
         </div>
         <div className="col-md-4 mb-3">
         <div class="card">
         
         <div class="card-header bg-white"
         >
         <div
         style={{background: this.state.show2 === "E4" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)": "linear-gradient(to right, #002755 65%, #EB1A23)"}}
         >
         <Row>
                    <Col className="text" style={{color:"white"}}>
                      Trading Partner Agreement Specific Requirement not Met
                    </Col>
                    <Col className="subclaim-icon">
                      <div className="subclaimicon-black" style={{background:"white"}}>
                        <GiHospitalCross
                          style={{
                            color: "black",
                            fontSize: "21px",
                            marginTop: "20%",
                          }}
                        >
                          {" "}
                        </GiHospitalCross>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="subclaim-description" style={{color:"white"}}>
                      Data correction required.
                    </Col>
                  </Row>
                  </div>
                  <Link
                  to={{
                    pathname: "/claim/sub-category-details",
                  }}
                  style={{ textDecoration: "none" }}
                >
            
                    <div class="card-text"
                    onClick={() => {
                     if (this.state.client) {
                       if (this.state.querystartdate && this.state.queryenddate) {
                         if (this.state.Location) {
                           sessionStorage.setItem("querycategory", "Machine1 Error");
                           sessionStorage.setItem(
                             "querysub",
                             "Trading Partner Agreement Specific Requirement not Met"
                           );
                           sessionStorage.setItem("queryCategoryCode", "E4");
                           sessionStorage.setItem("queryCategoryname", "error");
                           sessionStorage.setItem(
                             "claimurl",
                             `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E4&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                           );
                           sessionStorage.setItem("subclaimname", "errore4");
                         } else {
                           sessionStorage.setItem("querycategory", "Machine1 Error");
                           sessionStorage.setItem(
                             "querysub",
                             "Trading Partner Agreement Specific Requirement not Met"
                           );
                           sessionStorage.setItem("queryCategoryCode", "E4");
                           sessionStorage.setItem("queryCategoryname", "error");
                           sessionStorage.setItem(
                             "claimurl",
                             `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E4&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                           );
                           sessionStorage.setItem("subclaimname", "errore4");
                         }
                       } else {
                         if (this.state.Location) {
                           sessionStorage.setItem("querycategory", "Machine1 Error");
                           sessionStorage.setItem(
                             "querysub",
                             "Trading Partner Agreement Specific Requirement not Met"
                           );
                           sessionStorage.setItem("queryCategoryCode", "E4");
                           sessionStorage.setItem("queryCategoryname", "error");
                           sessionStorage.setItem(
                             "claimurl",
                             `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E4&clientId=${this.state.client}&Location=${this.state.Location}`
                           );
                           sessionStorage.setItem("subclaimname", "errore4");
                         } else {
                           sessionStorage.setItem("querycategory", "Machine1 Error");
                           sessionStorage.setItem(
                             "querysub",
                             "Trading Partner Agreement Specific Requirement not Met"
                           );
                           sessionStorage.setItem("queryCategoryCode", "E4");
                           sessionStorage.setItem("queryCategoryname", "error");
                           sessionStorage.setItem(
                             "claimurl",
                             `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E4&clientId=${this.state.client}`
                           );
                           sessionStorage.setItem("subclaimname", "errore4");
                         }
                       }
                     } else {
                       if (this.state.querystartdate && this.state.queryenddate) {
                         sessionStorage.setItem("querycategory", "Machine1 Error");
                         sessionStorage.setItem(
                           "querysub",
                           "Trading Partner Agreement Specific Requirement not Met"
                         );
                         sessionStorage.setItem("queryCategoryCode", "E4");
                         sessionStorage.setItem("queryCategoryname", "error");
                         sessionStorage.setItem("subclaimname", "errore4");
                         sessionStorage.setItem(
                           "claimurl",
                           `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E4&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                         );
                       } else {
                         sessionStorage.setItem("querycategory", "Machine1 Error");
                         sessionStorage.setItem(
                           "querysub",
                           "Trading Partner Agreement Specific Requirement not Met"
                         );
                         sessionStorage.setItem("queryCategoryCode", "E4");
                         sessionStorage.setItem("queryCategoryname", "error");
                         sessionStorage.setItem("subclaimname", "errore4");
                         sessionStorage.setItem(
                           "claimurl",
                           `ClaimCategory=error&Machine=Changehealthcare&statusCategoryCode=E4`
                         );
                       }
                     }
                   }}
                    >
                    <Row>
                    <Col className="sub-totalclaim">
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "19px",
                          }}
                        >
                          {/* {this.state.subcategoryvalue &&
                            this.state.subcategoryvalue[4].totalcount} */}
                          {this.state.tpatotalcnt}
                        </div>
                      </span>
                    </Col>
                    <Col className="sub-total">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "19px",
                            fontWeight: "600",
                          }}
                        >
                          ${" "}
                          {/* {this.state.subcategoryvalue &&
                            this.state.subcategoryvalue[4].totalamount} */}
                          {this.state.tpatotalamt}
                        </div>
                      </span>
                    </Col>
                  </Row>
                    </div>
                    </Link>
         </div>

       </div>
         </div>
         </div>
        </div>
      </div>
    );
  }
}
export default Machine1Error;
