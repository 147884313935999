import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { ip } from "../../../Api";

const getPath = (x, y, width, height) =>
  `M${x},${y + height}
  C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
    x + width / 2
  }, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
    x + width
  }, ${y + height}
  Z`;

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const defaultData = [
  { _id: "", totalamount: 0, totalcount: 0 },
  { _id: "", totalamount: 0, totalcount: 0 },
  { _id: "", totalamount: 0, totalcount: 0 },
  { _id: "", totalamount: 0, totalcount: 0 },
  { _id: "", totalamount: 0, totalcount: 0 },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};

const ARbyCPT = ({ client, Location, startdate, enddate, cptwise }) => {
  const [cptwiseamount, setCptwiseAmount] = useState(defaultData);
  const [cptwisecount, setCptwiseCount] = useState(defaultData);

  const refreshtoken = sessionStorage.getItem("refreshtoken");
  const accesstoken = sessionStorage.getItem("accesstoken");

  const renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshtoken}`,
    };
    try {
      const renew = await axios.get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      });
      sessionStorage.setItem("accesstoken", renew.data.accessToken);
    } catch (error) {
      console.error(error);
    }
  };

  const getInventory = useCallback(async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    let url = null;

    if (client) {
      if (Location) {
        if (startdate && enddate) {
          url = `${ip}/inventory/cptwise?start_date=${startdate}&end_date=${enddate}&clientId=${client}&Location=${Location}`;
        } else {
          url = `${ip}/inventory/cptwise?clientId=${client}&Location=${Location}`;
        }
      } else {
        if (startdate && enddate) {
          url = `${ip}/inventory/cptwise?start_date=${startdate}&end_date=${enddate}&clientId=${client}`;
        } else {
          url = `${ip}/inventory/cptwise?clientId=${client}`;
        }
      }
    } else {
      if (startdate && enddate) {
        url = `${ip}/inventory/cptwise?start_date=${startdate}&end_date=${enddate}`;
      } else {
        url = `${ip}/inventory/cptwise`;
      }
    }

    try {
      const res = await axios.get(url, { headers });
      if (res.data.inventory.length !== 0) {
        setCptwiseAmount(res.data.inventory);
        setCptwiseCount(res.data.inventorycount);
      } else {
        setCptwiseAmount(defaultData);
        setCptwiseCount(defaultData);
      }
    } catch (error) {
      console.error(error);
    }
  }, [client, Location, startdate, enddate, accesstoken]);

  useEffect(() => {
    getInventory();
  }, [getInventory]);

  return (
    <div>
      {cptwise === "$" && (
        <ResponsiveContainer width={"99%"} height={400}>
          <BarChart
            data={cptwiseamount}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis
              dataKey="_id"
              height={120}
              interval={0}
              angle={"-45"}
              minTickGap={6}
              tick={{
                strokeWidth: 0,
                fontSize: "11",
                textAnchor: "end",
                dy: 6,
              }}
            />
            <YAxis hide />
            <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
            <Legend />
            <Bar
              name="Total Amount"
              dataKey={"totalamount"}
              shape={<TriangleBar />}
              fill="#00a0fc"
            />
          </BarChart>
        </ResponsiveContainer>
      )}

      {cptwise === "#" && (
        <ResponsiveContainer width={"99%"} height={400}>
          <BarChart
            data={cptwisecount}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis
              dataKey="_id"
              height={120}
              interval={0}
              angle={"-45"}
              minTickGap={6}
              tick={{
                strokeWidth: 0,
                fontSize: "11",
                textAnchor: "end",
                dy: 6,
              }}
            />
            <YAxis hide />
            <Tooltip content={<ClaimTooltip />} cursor={{ fill: "transparent" }} />
            <Legend />
            <Bar
              name="Total Claims"
              dataKey="totalcount"
              shape={<TriangleBar />}
              fill="#00a0fc"
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default ARbyCPT;
