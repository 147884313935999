import React, { useState, useEffect } from "react";
import axios from "axios";
import { ip } from "../../../Api";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from "react-icons/bs";
import moment from "moment-timezone";
import { Row, Col, Input, Button } from "reactstrap";
import { Spinner } from "react-bootstrap";
import { DatePicker } from "antd";

const Arlogs = () => {
  const [fetchingArlog, setFetchingArlog] = useState(true);
  const [accessToken, setAccessToken] = useState(sessionStorage.getItem("accesstoken"));
  const [client, setClient] = useState(sessionStorage.getItem("clientid"));
  const [arlogwise, setArlogwise] = useState([]);
  const [countofarlog, setCountofarlog] = useState("");
  const [currentPage3, setCurrentPage3] = useState(0);
  const [perPage3] = useState(10);
  const [buttonSize, setButtonSize] = useState(0);
  const [download, setDownload] = useState(false);
  const [loading, setLoading] = useState(true);
  const { RangePicker } = DatePicker;
  const [filterstatus, setFilterStatus] = useState("custom");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const toggleDownload = () => {
    setDownload(!download);
  };

  const getDownloadButton = () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    let url;
    let filename;
    if (startdate && enddate) {
      filename = `Arlogs_${startdate}_${enddate}`;
      url = `${ip}/report/arlogs?clientId=${client}&start_date=${startdate}&end_date=${enddate}&button=${buttonSize}`;
    } else {
      filename = `Arlogs`;
      url = `${ip}/report/arlogs?clientId=${client}&button=${buttonSize}`;
    }

    fetch(url, { headers }).then((response) => {
      response.blob().then((blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.setAttribute("download", filename);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        setLoading(false);
      });
    });
  };

  const getArlogwiseReports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    let url;
    if (startdate && enddate) {
      url = `${ip}/report/arlogs?clientId=${client}&start_date=${startdate}&end_date=${enddate}&pageno=${currentPage3}`;
    } else {
      url = `${ip}/report/arlogs?clientId=${client}&pageno=${currentPage3}`;
    }

    axios
      .get(url, { headers })
      .then((res) => {
        setArlogwise(res.data.data);
        setFetchingArlog(false);
      })
      .catch((err) => console.error(err));
  };

  const getCount = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    let url;
    if (startdate && enddate) {
      url = `${ip}/report/getcounts?clientId=${client}&feature=arlogs&start_date=${startdate}&end_date=${enddate}`;
    } else {
      url = `${ip}/report/getcounts?clientId=${client}&feature=arlogs`;
    }

    axios
      .get(url, { headers })
      .then((res) => {
        setCountofarlog(Math.ceil(res.data.count / 20000));
      })
      .catch((err) => console.error(err));
  };

  const increment = () => {
    if (buttonSize < countofarlog - 1) {
      setButtonSize(buttonSize + 1);
    }
  };

  const decrement = () => {
    if (buttonSize > 0) {
      setButtonSize(buttonSize - 1);
    } else {
      alert("No data here so please click next arrow");
    }
  };

  const handlePageClick3 = (e) => {
    const selectedPage = e.selected;
    setCurrentPage3(selectedPage);
  };

  useEffect(() => {
    getArlogwiseReports();
    getCount();
  }, [currentPage3, startdate, enddate]);
//value of the date picker
const valuedate = (option, e) => {
  if (option === "yesterday") {
    setStartDate(e.target.value);
    setEndDate(moment().subtract(1, "days").format("MM-DD-YYYY"));
  } else {
    setStartDate(e.target.value);
    setEndDate(moment().format("MM-DD-YYYY"));
  }
};
  return (
    <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
    <Row className="align-items-center">
      <Col md={6} xs={12} className="d-flex">
                  <Input
                  type="select"
                  style={{ width: "20%", marginTop: "1%" }}
                  className="form-control form-select"
                  onChange={(e) => {
                    const index = e.target.selectedIndex;
                    const el = e.target.childNodes[index];
                    const option = el.getAttribute("title");
              
                    if (option === "custom") {
                      setFilterStatus("custom");
                    } else {
                      setFilterStatus("");
                    }
              
                    valuedate(option, e); 
                  }}
                >
                    <option value="" title="custom">
                      Custom
                    </option>
                    <option value={moment().format("MM-DD-YYYY")} title="">
                      Today
                    </option>
                    <option
                      value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                      title="yesterday"
                    >
                      Yesterday
                    </option>
                    <option
                      value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 3 days
                    </option>
                    <option
                      value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 7 days
                    </option>
                    <option
                      value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 30 days
                    </option>
                    <option
                      value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 365 days
                    </option>
                  </Input> &nbsp;&nbsp;
                  {filterstatus === "custom" && (
                    <RangePicker
                      format="MM-DD-YYYY"
                      onChange={(dates) => {
                        if (dates) {
                          setStartDate(dates[0].format("MM-DD-YYYY"));
                          setEndDate(dates[1].format("MM-DD-YYYY"));
                        } else {
                          setStartDate("");
                          setEndDate("");
                        }
                      }}
                      width="200%"
                      style={{ borderColor: "gray",  marginTop: "1%" }}
                    />
                  )}
                </Col>
                <Col md={6} xs={12} className="text-md-right mt-2 mt-md-0">
                <Button
                style={{
                  background:"#002755",
                  color:"#fff"
               }}
                onClick={() => {
                  toggleDownload();
                  getDownloadButton();
                }}
              >
                Generate Report
              </Button>
      </Col>
      </Row>
      <div class="claim-details">
      <div class="row">
          <div class="cell label-cell">statusCategoryCode</div>
          <div class="cell description-cell">The primary category code representing the claim’s overall status in the system.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">clientId</div>
          <div class="cell description-cell">Unique identifier for the client associated with the claim, used internally.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">claimSubCategory</div>
          <div class="cell description-cell">Sub-category indicating a more specific classification within the broader claim type.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">claimStatus</div>
          <div class="cell description-cell">Current status of the claim, indicating its processing phase within the company.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">CPTCode</div>
          <div class="cell description-cell">CPT (Current Procedural Terminology) code, specifying the medical procedure or service rendered.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">Statuscode</div>
          <div class="cell description-cell">Detailed status code describing the current claim position or action needed.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">Actioncode</div>
          <div class="cell description-cell">Code that represents the next action required in the claim process.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">workedDate</div>
          <div class="cell description-cell">Date the claim was last reviewed or worked on by a user within company.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">NextFollowupDate</div>
          <div class="cell description-cell">Scheduled date for the next follow-up or action on the claim.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">UserId</div>
          <div class="cell description-cell">Unique identifier for the user or agent handling the claim within the company’s system.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">ClaimID</div>
          <div class="cell description-cell">Unique identifier for the insurance claim used across the system.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">Notes</div>
          <div class="cell description-cell">Additional notes or comments regarding the claim’s status, actions, or details.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">OverallServiceLevelStatus</div>
          <div class="cell description-cell">The overall status indicating if the claim processing meets service level expectations.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">Module</div>
          <div class="cell description-cell">System module or area where the claim is currently being processed or reviewed.</div>
      </div>
      <div class="row">
          <div class="cell label-cell">Eventdate</div>
          <div class="cell description-cell">Date of a significant event or milestone within the claim lifecycle.</div>
      </div>
  </div>
      {download && (
        <Modal
          style={{ width: "30%" }}
          isOpen={download}
          toggle={toggleDownload}
          size="md"
          centered
        >
          <ModalHeader toggle={toggleDownload}>Download</ModalHeader>
          <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
            <Row style={{ margin: "0px" }}>
              <Col>
                <div id="container">
                  {loading ? (
                    <BsFillArrowLeftCircleFill
                      style={{ fontSize: "183%" }}
                      disabled
                      onClick={decrement}
                    />
                  ) : (
                    <BsFillArrowLeftCircleFill style={{ fontSize: "183%" }} onClick={decrement} />
                  )}
                  {loading ? (
                    <button
                      style={{
                        background: "green",
                        backgroundColor: "green",
                        borderRadius: "6px",
                        border: "3px solid green",
                        cursor: "pointer",
                        color: "#ffffff",
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "-4px 24px",
                        textDecoration: "none",
                        width: "45%",
                        whiteSpace: "nowrap",
                        marginLeft: "20%",
                        textShadow: "0px 1px 0px #9b14b3",
                      }}
                    >
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      &nbsp;&nbsp;Loading data...{buttonSize + 1}
                    </button>
                  ) : (
                    <button
                      style={{
                        background: "Black",
                        backgroundColor: "Black",
                        borderRadius: "6px",
                        border: "3px solid Black",
                        cursor: "pointer",
                        color: "#ffffff",
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "-4px 24px",
                        textDecoration: "none",
                        width: "42%",
                        marginLeft: "20%",
                        textShadow: "0px 1px 0px #9b14b3",
                      }}
                      onClick={getDownloadButton}
                    >
                      Report {buttonSize + 1}
                    </button>
                  )}
                  {loading ? (
                    <BsFillArrowRightCircleFill
                      disabled
                      style={{ marginLeft: "49px", fontSize: "183%" }}
                      onClick={increment}
                    />
                  ) : (
                    <BsFillArrowRightCircleFill
                      style={{ marginLeft: "49px", fontSize: "183%" }}
                      onClick={increment}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default Arlogs;
