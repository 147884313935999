import React, { useState, useEffect } from "react";
import axios from "axios";
import { ip } from "../../../Api";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Spinner } from "react-bootstrap";
import { Row, Col, Input, Button } from "reactstrap";
import { DatePicker } from "antd";
import moment from "moment-timezone";

const ClosedCpt = () => {
  const [fetchingCpt, setFetchingCpt] = useState(true);
  const [refreshToken] = useState(sessionStorage.getItem("refreshtoken"));
  const [accessToken, setAccessToken] = useState(sessionStorage.getItem("accesstoken"));
  const [client] = useState(sessionStorage.getItem("clientid"));
  const [cptwise, setCptwise] = useState([]);
  const [currentpage2, setCurrentpage2] = useState(0);
  const [perPage2] = useState(10);
  const [loading, setLoading] = useState(true);
  const [download, setDownload] = useState(false);
  const [countOfArlog, setCountOfArlog] = useState("");
  const [buttonsize, setButtonsize] = useState(0);
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [filterstatus, setFilterStatus] = useState("custom");
  const { RangePicker } = DatePicker;

  const downloaddata = () => {
    setDownload(!download);
  };


  const getclosedcptwisereports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    let url = startdate && enddate 
      ? `${ip}/report/closedcpts?clientId=${client}&start_date=${startdate}&end_date=${enddate}&pageno=${currentpage2}`
      : `${ip}/report/closedcpts?clientId=${client}&pageno=${currentpage2}`;

    axios.get(url, { headers })
      .then((res) => {
        setCptwise(res.data.data);
        setFetchingCpt(false);
      });
  };

  const getdownloadbutton = () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    let filename = startdate && enddate 
      ? `Closed_CPTs_${startdate}_${enddate}`
      : "Closed_CPTs";
    
    let url = startdate && enddate 
      ? `${ip}/report/closedcpts?clientId=${client}&start_date=${startdate}&end_date=${enddate}&button=${buttonsize}`
      : `${ip}/report/closedcpts?clientId=${client}&button=${buttonsize}`;

    fetch(url, { headers })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        a.click();
        URL.revokeObjectURL(url);
        setLoading(false);
      });
  };

  const getcount = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    let url = startdate && enddate
      ? `${ip}/report/getcounts?clientId=${client}&feature=cpts&start_date=${startdate}&end_date=${enddate}`
      : `${ip}/report/getcounts?clientId=${client}&feature=cpts`;

    axios.get(url, { headers })
      .then((res) => {
        setCountOfArlog(Math.ceil(res.data.count / 5000));
      });
  };
  const increment = () => {
    if (buttonsize < countOfArlog - 1) {
      setButtonsize(buttonsize + 1);
    }
  };

  const decrement = () => {
    if (buttonsize > 0) {
      setButtonsize(buttonsize - 1);
    }
  };
  //value of the date picker
  const valuedate = (option, e) => {
    if (option === "yesterday") {
      setStartDate(e.target.value);
      setEndDate(moment().subtract(1, "days").format("MM-DD-YYYY"));
    } else {
      setStartDate(e.target.value);
      setEndDate(moment().format("MM-DD-YYYY"));
    }
  };
  useEffect(() => {
    getclosedcptwisereports();
    getcount();
  }, [startdate, enddate]);

  return (
    <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
    <Row className="align-items-center">
      <Col md={6} xs={12} className="d-flex">
                  <Input
                  type="select"
                  style={{ width: "20%", marginTop: "1%" }}
                  className="form-control form-select"
                  onChange={(e) => {
                    const index = e.target.selectedIndex;
                    const el = e.target.childNodes[index];
                    const option = el.getAttribute("title");
              
                    if (option === "custom") {
                      setFilterStatus("custom");
                    } else {
                      setFilterStatus("");
                    }
              
                    valuedate(option, e); 
                  }}
                >
                    <option value="" title="custom">
                      Custom
                    </option>
                    <option value={moment().format("MM-DD-YYYY")} title="">
                      Today
                    </option>
                    <option
                      value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                      title="yesterday"
                    >
                      Yesterday
                    </option>
                    <option
                      value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 3 days
                    </option>
                    <option
                      value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 7 days
                    </option>
                    <option
                      value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 30 days
                    </option>
                    <option
                      value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 365 days
                    </option>
                  </Input> &nbsp;&nbsp;
                  {filterstatus === "custom" && (
                    <RangePicker
                      format="MM-DD-YYYY"
                      onChange={(dates) => {
                        if (dates) {
                          setStartDate(dates[0].format("MM-DD-YYYY"));
                          setEndDate(dates[1].format("MM-DD-YYYY"));
                        } else {
                          setStartDate("");
                          setEndDate("");
                        }
                      }}
                      width="200%"
                      style={{ borderColor: "gray",  marginTop: "1%" }}
                    />
                  )}
                </Col>
                <Col md={6} xs={12} className="text-md-right mt-2 mt-md-0">
                <Button
                style={{
                  background:"#002755",
                  color:"#fff"
               }}
                onClick={() => {
                  downloaddata();
                  getdownloadbutton();
                }}
              >
                Generate Report
              </Button>
      </Col>
      </Row>

      {download && (
        <Modal isOpen={download} onHide={downloaddata} size="md" centered>
          <ModalHeader toggle={downloaddata}>Download</ModalHeader>
          <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
            <Row>
              <Col>
                <div id="container">
                  <BsFillArrowLeftCircleFill
                    style={{ fontSize: "183%" }}
                    onClick={decrement}
                  />
                  {loading ? (
                    <button
                      style={{
                        background: "green",
                        borderRadius: "6px",
                        border: "3px solid green",
                        color: "#fff",
                        fontSize: "15px",
                        padding: "-4px 24px",
                        width: "45%",
                        marginLeft: "20%",
                      }}
                    >
                      <Spinner as="span" animation="border" size="sm" />
                      &nbsp;&nbsp;Loading data...{buttonsize + 1}
                    </button>
                  ) : (
                    <button
                      style={{
                        background: "Black",
                        borderRadius: "6px",
                        border: "3px solid Black",
                        color: "#fff",
                        fontSize: "15px",
                        padding: "-4px 24px",
                        width: "42%",
                        marginLeft: "20%",
                      }}
                      onClick={getdownloadbutton}
                    >
                      Report {buttonsize + 1}
                    </button>
                  )}
                  <BsFillArrowRightCircleFill
                    style={{ marginLeft: "49px", fontSize: "183%" }}
                    onClick={increment}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default ClosedCpt;
