import React, { useState, useEffect } from "react";
import { ip } from "../../../Api";
import axios from "axios";
import {
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from "recharts";

const data1 = [
  { _id: "", totalamount: 0 },
  { _id: "", totalamount: 0 },
  { _id: "", totalamount: 0 },
  { _id: "", totalamount: 0 },
  { _id: "", totalamount: 0 },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const AverageTouches = ({ client, Location, touchStart, touchEnd }) => {
  const [touch, setTouch] = useState([]);
  const [tokens, setTokens] = useState({
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
  });

  const renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokens.refreshtoken}`,
    };
    try {
      const renew = await axios.get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      });
      sessionStorage.setItem("accesstoken", renew.data.accessToken);
      setTokens((prevTokens) => ({
        ...prevTokens,
        accesstoken: renew.data.accessToken,
      }));
    } catch (err) {
      console.error("Error renewing access token:", err);
    }
  };

  const getTouches = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokens.accesstoken}`,
    };
    let url = `${ip}/inventory/avgnooftouches`;
    if (client) {
      url += `?clientId=${client}`;
      if (Location) url += `&Location=${Location}`;
      if (touchStart && touchEnd) url += `&start_date=${touchStart}&end_date=${touchEnd}`;
    } else if (touchStart && touchEnd) {
      url += `?start_date=${touchStart}&end_date=${touchEnd}`;
    }

    try {
      const req = await axios.get(url, { headers });
      setTouch(req.data.touch.length ? req.data.touch : data1);
    } catch (err) {
      console.error("Error fetching touches:", err);
      setTouch(data1);
    }
  };

  useEffect(() => {
    getTouches();
  }, [client, Location, touchStart, touchEnd]);

  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          width={450}
          height={350}
          data={touch}
          margin={{
            top: 13,
            right: 30,
            left: 10,
            bottom: 5,
          }}
        >
          <XAxis dataKey="_id" interval={0} fontSize="10" />
          <YAxis dataKey="totalamount" name="$" fontSize="10" />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            name="Total Amount"
            dataKey="totalamount"
            barSize={20}
            fill="#eb349b"
            radius={[100, -30, 0, 0]}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AverageTouches;
