import  { useEffect, useState } from "react";
import {  Row, Col, Input , Button} from "reactstrap";
import { Spinner } from "react-bootstrap";
import { ip } from "../../../Api";
import axios from "axios";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import moment from "moment";
import { DatePicker } from 'antd';

const DailyReport = () => {
  const [fetchingdailyreport, setFetchingDailyReport] = useState(true);
  const [refreshtoken] = useState(sessionStorage.getItem("refreshtoken"));
  const [accesstoken] = useState(sessionStorage.getItem("accesstoken"));
  const [client] = useState(sessionStorage.getItem("clientid"));
  const [currentpage , setCurrentPage] = useState(0);
  const [perPage, setPerpage] = useState(10);
  const [dailyreport, setDailyReport] = useState([]);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [download, setDownload] = useState(false);
  const [buttonsize, setButtonSize] = useState(0);
  const [countofarlog, setCounttofarlog] = useState("");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [filterstatus, setFilterStatus] = useState("custom");

  const { RangePicker } = DatePicker;

  const getDownloadButton = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    let url;
    let filename;
    let date1 = moment().subtract(7, "days").format().substring(0, 16);
    let date2 = moment().format().substring(0, 16);
    if (startdate && enddate) {
      filename = `Daily_Report_${startdate}_${enddate}`;
      url = `${ip}/report/dailyreport?start_date=${startdate}&end_date=${enddate}&clientId=${client}&button=${buttonsize}`;
    } else {
      filename = `Daily_Report_${moment(date1).format("MM/DD/YYYY")}_${moment(date2).format("MM/DD/YYYY")}`;
      url = `${ip}/report/dailyreport?clientId=${client}&start_date=${date1}&end_date=${date2}&button=${buttonsize}`;
    }
    fetch(url, { headers: headers }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        a.click();
        URL.revokeObjectURL(a);
        setLoading(false);
      });
    });
  };
  const increment = () => {
    if (buttonsize < countofarlog - 1) {
      setButtonSize(buttonsize+1)
      if (buttonsize - 1 < 0) {
        alert("No data here so please click next arrow");
        setButtonSize(0);
      }}
  };
  const decrement = () => {
    setButtonSize(buttonsize-1);
    if (buttonsize - 1 < 0) {
      alert("No data here so please click next arrow");
      setButtonSize(0);
    }};
  const getcount = () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accesstoken}`,
      };
      let url;
      if (startdate && enddate) {
        url = `${ip}/report/getcounts?clientId=${client}&feature=dailyproductionreport&start_date=${startdate}&end_date=${enddate}`;
      } else {
        url = `${ip}/report/getcounts?clientId=${client}&feature=dailyproductionreport`;
      }
      const res = axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          setCounttofarlog(Math.ceil(res.data.count / 500))
        });
    };
  const  toggleDownload = () => {
      setDownload(!download)
    }; 
  const  getDailyreports = () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accesstoken}`,
      };
      let url;
      let date1 = moment().subtract(7, "days").format().substring(0, 16);
      let date2 = moment().format().substring(0, 16);
      if (startdate && enddate) {
        url = `${ip}/report/dailyreport?start_date=${startdate}&end_date=${enddate}&clientId=${client}&pageno=${currentpage}`;
      } else {
        url = `${ip}/report/dailyreport?clientId=${client}&pageno=${currentpage}&start_date=${date1}&end_date=${date2}`;
      }
      const res = axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
           setDailyReport(res.data.data);
           setFetchingDailyReport(false);
        });};
  const valuedate = (option, e) => {
    if (option === "yesterday") {
        setStartDate(e.target.value);
        setEndDate(moment().subtract(1, "days").format().substring(0, 16));
    } else {
            setStartDate(e.target.value);
            setEndDate(moment().format().substring(0, 16));
          }
        };
      
    useEffect(()=> {
      getDailyreports();
      getcount();
    },[startdate, enddate])        
     
    return (
      <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
      <Row className="align-items-center">
      <Col md={6} xs={12} className="d-flex">
                  <Input
                  type="select"
                  style={{ width: "30%", marginTop: "1%" }}
                  className="form-control form-select"
                  onChange={(e) => {
                    const index = e.target.selectedIndex;
                    const el = e.target.childNodes[index];
                    const option = el.getAttribute("title");
              
                    if (option === "custom") {
                      setFilterStatus("custom");
                    } else {
                      setFilterStatus("");
                    }
              
                    valuedate(option, e); 
                  }}
                >
                    <option value="" title="custom">
                      Custom
                    </option>
                    <option value={moment().format().substring(0, 16)} title="">
                      Today
                    </option>
                    <option
                      value={moment().subtract(1, "days").format().substring(0, 16)}
                      title="yesterday"
                    >
                      Yesterday
                    </option>
                    <option
                      value={moment().subtract(3, "days").format().substring(0, 16)}
                      title=""
                    >
                      Last 3 days
                    </option>
                    <option
                      value={moment().subtract(7, "days").format().substring(0, 16)}
                      title=""
                    >
                      Last 7 days
                    </option>
                    <option
                      value={moment().subtract(30, "days").format().substring(0, 16)}
                      title=""
                    >
                      Last 30 days
                    </option>
                    <option
                      value={moment().subtract(365, "days").format().substring(0, 16)}
                      title=""
                    >
                      Last 365 days
                    </option>
                  </Input> &nbsp;&nbsp;
                  {filterstatus === "custom" && (
                    <RangePicker
                    showTime={{
                      format: 'HH:mm',
                    }}
                    format="YYYY-MM-DD HH:mm"
                      onChange={(dates) => {
                        if (dates) {
                          setStartDate(dates[0].format().substring(0, 16));
                          setEndDate(dates[1].format().substring(0, 16));
                        } else {
                          setStartDate("");
                          setEndDate("");
                        }
                      }}
                      width="200%"
                      style={{ borderColor: "gray",  marginTop: "1%" }}
                    />
                  )}
                </Col>
                <Col md={6} xs={12} className="text-md-right mt-2 mt-md-0">
                <Button
                style={{
                  background:"#002755",
                  color:"#fff"
               }}
                onClick={(e) => {
                  toggleDownload();
                  getDownloadButton();
                }}
              >
                Generate Report
              </Button>
      </Col>
      </Row>
        {download && (
          <Modal
            style={{ width: "30%" }}
            isOpen={download}
            onHide={toggleDownload}
            size="md"
            centered
          >
            <ModalHeader toggle={toggleDownload}>Download</ModalHeader>
            <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <Row style={{ margin: "0px" }}>
                <Col>
                  <div id="container">
                    {loading ? (
                      <BsFillArrowLeftCircleFill
                        style={{ fontSize: "183%" }}
                        disabled
                        onClick={(e) => {
                          this.decrement();
                        }}
                      >
                        left
                      </BsFillArrowLeftCircleFill>
                    ) : (
                      <BsFillArrowLeftCircleFill
                        style={{ fontSize: "183%" }}
                        onClick={(e) => {
                          this.decrement();
                        }}
                      >
                        left
                      </BsFillArrowLeftCircleFill>
                    )}
                    {loading ? (
                      <button
                        style={{
                          background: "green",
                          backgroundColor: "green",
                          borderRadius: "6px",
                          border: "3px solid green",
                          display: "inline-block",
                          cursor: "pointer",
                          color: "#ffffff",
                          fontSize: "15px",
                          fontWeight: "bold",
                          padding: "-4px 24px",
                          textDecoration: "none",
                          width: "45%",
                          whiteSpace: "nowrap",
                          marginLeft: "20%",
                          textShadow: "0px 1px 0px #9b14b3",
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp;&nbsp;Loading data...{buttonsize + 1}
                      </button>
                    ) : (
                      <button
                        style={{
                          background: "Black",
                          backgroundColor: "Black",
                          borderRadius: "6px",
                          border: "3px solid Black",
                          display: "inline-block",
                          cursor: "pointer",
                          color: "#ffffff",
                          fontSize: "15px",
                          fontWeight: "bold",
                          padding: "-4px 24px",
                          textDecoration: "none",
                          width: "42%",
                          marginLeft: "20%",
                          textShadow: "0px 1px 0px #9b14b3",
                        }}
                        onClick={(e) => {
                          this.getDownloadButton();
                        }}
                      >
                        Report {buttonsize + 1}
                      </button>
                    )}

                    {loading ? (
                      <BsFillArrowRightCircleFill
                        disabled
                        style={{ marginLeft: "49px", fontSize: "183%" }}
                        onClick={(e) => {
                          increment();
                        }}
                      >
                        right
                      </BsFillArrowRightCircleFill>
                    ) : (
                      <BsFillArrowRightCircleFill
                        style={{ marginLeft: "49px", fontSize: "183%" }}
                        onClick={(e) => {
                          increment();
                        }}
                      >
                        right
                      </BsFillArrowRightCircleFill>
                    )}
                  </div>
                </Col>
                <br />
                <br />
              </Row>

              <br />
            </ModalBody>
          </Modal>
        )}
        </div>
    );
      };

export default DailyReport;
