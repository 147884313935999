import React, { useState } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import Switch from "react-switch";
import { ip } from "../../Api";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";

const GeneralSettings = () => {
  const [refreshtoken] = useState(sessionStorage.getItem("refreshtoken"));
  const [accesstoken, setAccessToken] = useState(sessionStorage.getItem("accesstoken"));
  const [userid] = useState(sessionStorage.getItem("user_id"));
  const [checked, setChecked] = useState(sessionStorage.getItem("pre-toggle") === "true");
  const [checkedNotes, setCheckedNotes] = useState(sessionStorage.getItem("with_notes") === "true");
  const [obj_id] = useState(sessionStorage.getItem("object_id"));
  const [perPage, setPerPage] = useState(sessionStorage.getItem("Default_pagination"));
  const [logoffmodal, setLogoffModal] = useState(false);
  const [client] = useState(sessionStorage.getItem("clientid"));

  const handleChange = () => {
    setChecked(!checked, updateCPt);
  };

  const handleChange1 = () => {
    setCheckedNotes(!checkedNotes, updateNotes);
  };

  const updateCPt = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    const value = {
      closedcpt: checked,
    };
    axios
      .put(`${ip}/clientonboard/${obj_id}`, value, { headers })
      .catch((err) => {});
  };

  const updatePagecount = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    const value = {
      paginationCount: parseInt(perPage),
      clientId: parseInt(client),
    };
    axios
      .put(`${ip}/users/${userid}`, value, { headers })
      .then(() => {
        alert("Page count updated in all pages");
      })
      .catch((err) => {});
  };

  const updateNotes = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    const value = {
      combainedReportNotes: checkedNotes,
    };
    axios
      .put(`${ip}/clientonboard/${obj_id}`, value, { headers })
      .catch((err) => {});
  };

  const renewAccessToken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshtoken}`,
    };
    await axios
      .get(`${ip}/auth/renewAccessToken`, { headers: renewheaders })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
        setAccessToken(renew.data.accessToken);
      })
      .catch((err) => {});
  };

  const openLogoffModal = () => {
    setLogoffModal(!logoffmodal);
  };

  return (
    <div>
      <Newsidebar name="General Settings" />
      <div style={{ paddingTop: "1%", width: "60%", marginLeft: "15%", marginRight: "15%" }}>
        <Table className="StandardTable" bordered>
          <thead>
            <tr>
              <th>Title</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Bring all CPTs</td>
              <td>
                <Switch onChange={handleChange} checked={checked} />
              </td>
            </tr>
            <tr>
              <td>Select page count</td>
              <td>
                <select
                  id="pageCount"
                  style={{ height: "35px", width: "60px" }}
                  value={perPage}
                  onChange={(e) => setPerPage(e.target.value, updatePagecount)}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>With Notes</td>
              <td>
                <Switch onChange={handleChange1} checked={checkedNotes} />
              </td>
            </tr>
          </tbody>
        </Table>
        <Button style={{ backgroundColor: "#041c3c", color: "#fff" }} onClick={openLogoffModal}>
          OK
        </Button>
      </div>
      <Modal show={logoffmodal} onHide={openLogoffModal} centered>
        <Modal.Body>The system is about to log off. Please confirm by selecting 'OK'.</Modal.Body>
        <Modal.Footer>
          <Link to="/">
            <Button style={{ border: "1px solid grey", color: "black", backgroundColor: "#fff" }}>
              OK
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GeneralSettings;
