import React, { useState, useRef } from "react";
import "../../Styles/DataConversionOCR.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Spinner, Button } from "react-bootstrap";
import CsvDownload from "react-json-to-csv";
import Tabs, { Tab } from "react-best-tabs";
import Aging from "../../Files/Aging.PDF";
import summary from "../../Files/summary.PDF";
import { Link } from "react-router-dom";
import { ip } from "../../Api1";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import excel from "../../Images/excel.png";
import { MdOutlineFileUpload } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

import {
  RiNumber1,
  RiNumber2,
  RiNumber3,
  RiNumber4,
  RiNumber5,
  RiNumber6,
} from "react-icons/ri";
const DataConversionOCR = () => {
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileUploaded1, setFileUploaded1] = useState(false);
  const [reports, setReports] = useState([]);
  const [reports1, setReports1] = useState([]);
  const fileInput = useRef(null);
  const fileInput1 = useRef(null);

  const onFileChange = (e) => setFile(e.target.files[0]);
  const onFileChange1 = (e) => setFile1(e.target.files[0]);

  const uploadFile = async (file, setReports, setFileUploaded, url) => {
    if (!file) {
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    setFileUploaded(true); // Indicate file upload in progress

    try {
      const response = await axios.post(url, formData);
      if (response.status === 200) {
        console.log("res ocr", response.data)
        setReports(response.data.aging);
        setFileUploaded(false);
        toast.success("File uploaded successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    } catch (err) {
      const errorMessage =
        err.response?.status === 500
          ? "Server Error"
          : err.response?.status === 401
          ? "Please reload and try again"
          : err.response?.status === 400
          ? "Please include all the fields in correct data format"
          : "An unknown error occurred";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } finally {
      setFileUploaded(false);
    }
  };
  const uploadFile1 = async (file1, setReports1, setFileUploaded1, url) => {
    if (!file1) {
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return;
    }
    const formData = new FormData();
    formData.append("file", file1);
    setFileUploaded1(true); // Indicate file upload in progress

    try {
      const response = await axios.post(url, formData);
      if (response.status === 200) {
        setReports1(response.data.patientsummary);
        setFileUploaded1(false);
        toast.success("File uploaded successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    } catch (err) {
      const errorMessage =
        err.response?.status === 500
          ? "Server Error"
          : err.response?.status === 401
          ? "Please reload and try again"
          : err.response?.status === 400
          ? "Please include all the fields in correct data format"
          : "An unknown error occurred";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } finally {
      setFileUploaded1(false);
    }
  };
  const onFileUpload = (e) => {
    e.preventDefault();
    uploadFile(file, setReports, setFileUploaded, `${ip}/aging`);
  };

  const onFileUpload1 = (e) => {
    e.preventDefault();
    uploadFile1(file1, setReports1, setFileUploaded1, `${ip}/patientsummary`);
  };

  return (
    <div>
      <Newsidebar name="Data Conversion OCR" />
      <ToastContainer />
      <Tabs activityClassName="text-secondary" style={{ margin: "5%" }}>
        <Tab title="Aging">
          <div className="upload_process_container">
            <div className="upload_container">
              <input
                ref={fileInput}
                type="file"
                id="file"
                onChange={onFileChange}
                style={{ display: "none" }}
                accept=".pdf,.csv,.xlsx,.doc,.docx,.txt,"
              />
              {file ? (
                <div className="file-card">
                  <span>
                    <img
                      src={excel}
                      alt="excel"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </span>
                  <div className="file-info">
                    <div style={{ flex: 1 }}>
                      <h6>{file.name}</h6>
                    </div>
                  </div>
                  <button
                  className="close-icon"
                  onClick={() => setFile(null)} 
                > 
                <IoMdClose />
                </button>
                </div>
              ) : (
                <button
                  className="file-btn"
                  onClick={() => fileInput.current.click()}
                >
                  <span className="file-circle">
                    <MdOutlineFileUpload />
                  </span>
                  Click file to this area to upload <br />
                  <span style={{ color: "GrayText", fontSize: "11px" }}>
                    The given input should be in .xlsx format
                  </span>
                </button>
              )}
            </div>
            <div className="process_againg">
              <div className="align-column">
                <ul className="data-cleaning-ul">
                  <li style={{ "--accent-color": "#EB1A23" }}>
                    <div className="icon">
                      <RiNumber1 />
                    </div>
                    <Button
                      style={{
                        fontSize: "13px",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                      }}
                    >
                      data extraction
                    </Button>
                  </li>
                  <li style={{ "--accent-color": "#EB1A23" }}>
                    <div className="icon">
                      <RiNumber2 />
                    </div>
                    <Button
                      style={{
                        fontSize: "13px",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                      }}
                    >
                      unstructured data
                    </Button>
                  </li>
                  <li style={{ "--accent-color": "#EB1A23" }}>
                    <div className="icon">
                      <RiNumber3 />
                    </div>
                    <Button
                      style={{
                        fontSize: "13px",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                          whiteSpace:"nowrap"
                      }}
                    >
                      processing unstructured data
                    </Button>
                  </li>
                  <li style={{ "--accent-color": "#EB1A23" }}>
                    <div className="icon">
                      <RiNumber4 />
                    </div>
                    <Button
                      style={{
                        fontSize: "13px",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                      }}
                    >
                      structure data
                    </Button>
                  </li>
                  <li style={{ "--accent-color": "#EB1A23" }}>
                    <div className="icon">
                      <RiNumber5 />
                    </div>
                    <Button
                      style={{
                        fontSize: "13px",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                          whiteSpace:"nowrap"
                      }}
                    >
                      Accuracy average 98.9%
                    </Button>
                  </li>
                  <li style={{ "--accent-color": "#EB1A23" }}>
                    <div className="icon">
                      <RiNumber6 />
                    </div>
                    <Button
                      style={{
                        fontSize: "13px",
                        background:
                          "linear-gradient(to right, #002755 65%, #EB1A23)",
                      }}
                    >
                      OCR
                    </Button>
                  </li>
                </ul>
              </div>
            </div>

          </div>
          <div className="upload_container1">
            <div className="upload_head">
              <div className="vertical" />
              <span style={{ fontWeight: "800", fontSize: "18px" }}>
                Aging History
              </span>
            </div>
            <div
              className="btn-group"
              role="group"
              style={{ boxShadow: "0px 0px 6px rgb(124 119 119 / 50%)" }}
            >
              <Link
                style={{ textDecoration: "none", color: "#a8adad" }}
                to={Aging}
                download="Aging.pdf"
                target="_blank"
              >
                <button
                  type="button"
                  className="btn btn-danger"
                >
                  <AiOutlineDownload style={{ fontSize: "15px" }} />
                  &nbsp;&nbsp;Sample
                </button>
              </Link>
              <button type="button" className="btn" onClick={onFileUpload}>
                &nbsp;&nbsp; Upload
              </button>
              {fileUploaded ? (
                <button style={{ backgroundColor: "green", color:"white" }}>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading data...
                </button>
              ) : (
                <button>
                <CsvDownload
                data={Array.isArray(reports) ? reports : []}
                filename="Aging.csv"
                style={{
                  backgroundColor: "black",
                  color: "#ffffff",
                  padding: "6px 24px",
                  float: "right",
                }}
              >
                Download file
              </CsvDownload>
              
                </button>
              )}
            </div>
          </div>
          <hr style={{ margin: "1%" }} />
          <div className="table-wrapper">
            <table bordered="true">
              <thead>
                <tr className="sticky_upload">
                  <th className="sticky_upload">File Name</th>
                  <th className="sticky_upload">Uploaded At</th>
                  <th className="sticky_upload">Character-Level Accuracy</th>
                  <th className="sticky_upload">Word-Level Accuracy</th>
                  <th className="sticky_upload">
                    Edit Distance (Levenshtein Distance)
                  </th>
                  <th className="sticky_upload">BLEU Score</th>
                  <th className="sticky_upload">Aging Accuracy</th>
                  <th className="sticky_upload">Conversion Status</th>
                  <th className="sticky_upload">Action</th>
                </tr>
              </thead>
            </table>
          </div>
        </Tab>
        <Tab title="Patientsummary">
        <div className="upload_process_container">
          <div className="upload_container">
          <input
          ref={fileInput1} 
          type="file"
          id="file1"
          onChange={onFileChange1}
          style={{ display: "none" }}
          accept=".pdf,.csv,.xlsx,.doc,.docx,.txt"
        />
            {file1 ? (
              <div className="file-card">
                <span>
                  <img
                    src={excel}
                    alt="excel"
                    style={{ width: "30px", height: "30px" }}
                  />
                </span>
                <div className="file-info">
                  <div style={{ flex: 1 }}>
                    <h6>{file1.name}</h6>
                  </div>
                </div>
                <button
                className="close-icon"
                onClick={() => setFile1(null)} 
              > 
              <IoMdClose />
              </button>
              </div>
            ) : (
              <button
              className="file-btn"
              onClick={() => fileInput1.current.click()} 
            >
                <span className="file-circle">
                  <MdOutlineFileUpload />
                </span>
                Click file to this area to upload <br />
                <span style={{ color: "GrayText", fontSize: "11px" }}>
                  The given input should be in .xlsx format
                </span>
              </button>
            )}
          </div>
          <div className="process_againg">
          <div className="align-column">
            <ul className="data-cleaning-ul">
              <li style={{ "--accent-color": "#EB1A23" }}>
                <div className="icon">
                  <RiNumber1 />
                </div>
                <Button
                  style={{
                    fontSize: "13px",
                    background:
                      "linear-gradient(to right, #002755 65%, #EB1A23)",
                  }}
                >
                  data extraction
                </Button>
              </li>
              <li style={{ "--accent-color": "#EB1A23" }}>
                <div className="icon">
                  <RiNumber2 />
                </div>
                <Button
                  style={{
                    fontSize: "13px",
                    background:
                      "linear-gradient(to right, #002755 65%, #EB1A23)",
                  }}
                >
                  unstructured data
                </Button>
              </li>
              <li style={{ "--accent-color": "#EB1A23" }}>
                <div className="icon">
                  <RiNumber3 />
                </div>
                <Button
                  style={{
                    fontSize: "13px",
                    background:
                      "linear-gradient(to right, #002755 65%, #EB1A23)",
                      whiteSpace:"nowrap"
                  }}
                >
                  processing unstructured data
                </Button>
              </li>
              <li style={{ "--accent-color": "#EB1A23" }}>
                <div className="icon">
                  <RiNumber4 />
                </div>
                <Button
                  style={{
                    fontSize: "13px",
                    background:
                      "linear-gradient(to right, #002755 65%, #EB1A23)",
                  }}
                >
                  structure data
                </Button>
              </li>
              <li style={{ "--accent-color": "#EB1A23" }}>
                <div className="icon">
                  <RiNumber5 />
                </div>
                <Button
                  style={{
                    fontSize: "13px",
                    background:
                      "linear-gradient(to right, #002755 65%, #EB1A23)",
                      whiteSpace:"nowrap"
                  }}
                >
                  Accuracy average 98.9%
                </Button>
              </li>
              <li style={{ "--accent-color": "#EB1A23" }}>
                <div className="icon">
                  <RiNumber6 />
                </div>
                <Button
                  style={{
                    fontSize: "13px",
                    background:
                      "linear-gradient(to right, #002755 65%, #EB1A23)",
                  }}
                >
                  OCR
                </Button>
              </li>
            </ul>
          </div>
        </div>
        </div>
          <div className="upload_container1">
            <div className="upload_head">
              <div className="vertical" />
              <span style={{ fontWeight: "800", fontSize: "18px" }}>
                Patient summary History
              </span>
            </div>
            <div
              className="btn-group"
              role="group"
              style={{ boxShadow: "0px 0px 6px rgb(124 119 119 / 50%)" }}
            >
              <Link
                style={{ textDecoration: "none", color: "#a8adad" }}
                to={summary}
                download="patientsummary.pdf"
                target="_blank"
              >
                <button
                  type="button"
                  className="btn btn-danger"
                >
                  <AiOutlineDownload style={{ fontSize: "15px" }} />
                  &nbsp;&nbsp;Sample
                </button>
              </Link>
              <button type="button" className="btn" onClick={onFileUpload1}>
                &nbsp;&nbsp; Upload
              </button>
              {fileUploaded1 ? (
                <button
                  style={{
                    backgroundColor: "green",
                    color:"white"
                  }}
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading data...
                </button>
              ) : (
                <button>
                <CsvDownload
                data={Array.isArray(reports1) ? reports1 : []}
                filename="Patient_Summary.csv"
                style={{
                  backgroundColor: "black",
                  color: "#ffffff",
                  padding: "6px 24px",
                  float: "right",
                }}
              >
                Download file
              </CsvDownload>
                </button>
              )}
            </div>
          </div>
          <hr style={{ margin: "1%" }} />
          <div className="table-wrapper">
            <table bordered="true">
              <thead>
                <tr className="sticky_upload">
                  <th className="sticky_upload">File Name</th>
                  <th className="sticky_upload">Uploaded At</th>
                  <th className="sticky_upload">Character-Level Accuracy</th>
                  <th className="sticky_upload">Word-Level Accuracy</th>
                  <th className="sticky_upload">
                    Edit Distance (Levenshtein Distance)
                  </th>
                  <th className="sticky_upload">BLEU Score</th>
                  <th className="sticky_upload">patient summary Accuracy</th>
                  <th className="sticky_upload">Conversion Status</th>
                  <th className="sticky_upload">Action</th>
                </tr>
              </thead>
            </table>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};
export default DataConversionOCR;
