import React, { useState, useEffect } from "react";
import axios from "axios";
import Newsidebar from "../Dashboard/Newsidebar";
import { ip } from "../../Api";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "../../Styles/databasesync.css";

const ApiSync = () => {
  const [refreshtoken] = useState(sessionStorage.getItem("refreshtoken"));
  const [accesstoken, setAccesstoken] = useState(
    sessionStorage.getItem("accesstoken")
  );

  // Renew access token
  const renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshtoken}`,
    };
    try {
      const renew = await axios.get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      });
      sessionStorage.setItem("accesstoken", renew.data.accessToken);
      setAccesstoken(renew.data.accessToken);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // Add any componentDidMount logic here if needed
  }, []);

  return (
    <div>
      <Newsidebar name="API Sync" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column apisync">
          <br />
          <br />
          <br />
          <br />
          <div
            className="card apisync-card"
            style={{
              alignItems: "center",
              border: "1px solid skyblue",
              width: "90%",
              padding: "10px",
            }}
          >
            <div
              className="row"
              style={{ alignItems: "center", justifyContent: "center", margin: "10px" }}
            >
              <div className="col-md-4 mb-3">
                <div className="apisync-container">
                  <div className="card-body">
                    <Link to="/apisync/advancedmd" style={{ textDecoration: "none" }}>
                      <Row>
                        <Col className="apisync-text">Advanced MD</Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="apisync-container">
                  <div className="card-body">
                    <Link to="/apisync/advancedmd" style={{ textDecoration: "none" }}>
                      <Row>
                        <Col className="apisync-text">EPIC</Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="apisync-container">
                  <div className="card-body">
                    <Link to="/apisync/advancedmd" style={{ textDecoration: "none" }}>
                      <Row>
                        <Col className="apisync-text">ECW</Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiSync;
