import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import "../../../Styles/subclaim.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import { ip } from "../../../Api";

class AdditionalInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: sessionStorage.getItem("queryurl"),
      client: sessionStorage.getItem("queryclient"),
      searchidkeyindicator: sessionStorage.getItem("searchidkeyindicator"),
      Location: sessionStorage.getItem("queryLocation"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
      subcategoryvalue: null,
      subcategoryvalue: null,
      errortotalamt: "",
      errortotalcnt: "",
      reqtotalamt: "",
      reqtotalcnt: "",
      show2: sessionStorage.getItem("queryclaimid"),
      claimtotalamt: "",
      claimtotalcnt: "",
      docreqtotalamt: "",
      docreqtotalcnt: "",
      msdtotalamt: "",
      msdtotalcnt: "",
      regreqtotalamt: "",
      regreqtotalcnt: "",
      ccctotalcmt: "",
      ccctotalcnt: "",
      pcetotalamt: "",
      pcetotalcnt: "",
      dmntotalamt: "",
      dmntotalcnt: "",
      sfgatotalcnt: "",
      sfgatotalamt: "",
      prctotalamt: "",
      prctotalcnt: "",
      spctotalamt: "",
      spctotalcnt: "",
      odsntotalamt: "",
      odsntotalcnt: "",
      odrrtotalamt: "",
      odrrtotalcnt: "",
      wccdtotalamt: "",
      wccdtotalcnt: "",
      eligibilitytotalamt: "",
      eligibilitytotalcnt: "",
      replacementtotalamt: "",
      replacementtotalcnt: "",
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getrequestdata = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line\
    let url;
    if (this.state.queryenddate && this.state.querystartdate) {
      url = `${ip}/claimstatus/subcategory?${this.state.value}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
    } else {
      url = `${ip}/claimstatus/subcategory?${this.state.value}`;
    }
    const res = await axios
      .get(url, {
        headers: headers,
      })

      .then((res) => {
        this.setState({ subcategoryvalue: res.data.data });
        if (res.data.data.length !== 0) {
          res.data.data.map((data, index) => {
            switch (data._id) {
              case "R0":
                return this.setState({
                  errortotalamt: Math.round(data.totalamount).toLocaleString(),
                  errortotalcnt: data.totalcount,
                });
              case "R1":
                return this.setState({
                  reqtotalamt: Math.round(data.totalamount).toLocaleString(),
                  reqtotalcnt: data.totalcount,
                });
              case "R3":
                return this.setState({
                  claimtotalamt: Math.round(data.totalamount).toLocaleString(),
                  claimtotalcnt: data.totalcount,
                });
              case "R4":
                return this.setState({
                  docreqtotalamt: Math.round(data.totalamount).toLocaleString(),
                  docreqtotalcnt: data.totalcount,
                });
              case "R5":
                return this.setState({
                  msdtotalcnt: data.totalcount,
                  msdtotalamt: Math.round(data.totalamount).toLocaleString(),
                });
              case "R6":
                return this.setState({
                  regreqtotalamt: Math.round(data.totalamount).toLocaleString(),
                  regreqtotalcnt: data.totalcount,
                });
              case "R7":
                return this.setState({
                  ccctotalcmt: Math.round(data.totalamount).toLocaleString(),
                  ccctotalcnt: data.totalcount,
                });
              case "R8":
                return this.setState({
                  pcetotalamt: Math.round(data.totalamount).toLocaleString(),
                  pcetotalcnt: data.totalcount,
                });
              case "R9":
                return this.setState({
                  dmntotalamt: Math.round(data.totalamount).toLocaleString(),
                  dmntotalcnt: data.totalcount,
                });
              case "R10":
                return this.setState({
                  sfgatotalamt: Math.round(data.totalamount).toLocaleString(),
                  sfgatotalcnt: data.totalcount,
                });
              case "R11":
                return this.setState({
                  prctotalamt: Math.round(data.totalamount).toLocaleString(),
                  prctotalcnt: data.totalcount,
                });
              case "R12":
                return this.setState({
                  spctotalamt: Math.round(data.totalamount).toLocaleString(),
                  spctotalcnt: data.totalcount,
                });
              case "R13":
                return this.setState({
                  odsntotalamt: Math.round(data.totalamount).toLocaleString(),
                  odsntotalcnt: data.totalcount,
                });
              case "R14":
                return this.setState({
                  odrrtotalamt: Math.round(data.totalamount).toLocaleString(),
                  odrrtotalcnt: data.totalcount,
                });
              case "R15":
                return this.setState({
                  wccdtotalamt: Math.round(data.totalamount).toLocaleString(),
                  wccdtotalcnt: data.totalcount,
                });
              case "R16":
                return this.setState({
                  eligibilitytotalamt: Math.round(
                    data.totalamount
                  ).toLocaleString(),
                  eligibilitytotalcnt: data.totalcount,
                });
              case "R17":
                return this.setState({
                  replacementtotalamt: Math.round(
                    data.totalamount
                  ).toLocaleString(),
                  replacementtotalcnt: data.totalcount,
                });
            }
          });
        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    this.getrequestdata();
  }
  render() {
    return (
      <div className="align-row" style={{ overflowX: "hidden" }}>
        <Newsidebar>
        <p>
        <Link
          to="/claim-management"
          style={{ textDecoration: "none", color: "white",fontSize: "15px",
          fontWeight: 500 }}
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Requests
        for Additional Information
      </p>
        </Newsidebar>
        <div className="align-column">
          <br />
          <div className="row" style={{ marginLeft: "1%" }}>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // general request card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R0");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr0");
                        sessionStorage.setItem("querysub", "General Requests");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R0&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R0");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr0");
                        sessionStorage.setItem("querysub", "General Requests");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R0&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R0");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr0");
                        sessionStorage.setItem("querysub", "General Requests");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R0&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R0");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr0");
                        sessionStorage.setItem("querysub", "General Requests");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R0&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R0");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem("querysub", "General Requests");
                      sessionStorage.setItem("subclaimname", "requestr0");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R0&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R0");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem("querysub", "General Requests");
                      sessionStorage.setItem("subclaimname", "requestr0");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R0`
                      );
                    }
                  }
                }}
              >
                <div
                  class="card-header bg-white">
                <div
                style={{background: this.state.show2 === "R0" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
                >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    General Requests
                  </span>
                  </div>
                  <div class="card-text">
                  <Link
                    to={{
                      pathname: "/claim/sub-category-details",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Row>
                      <Col className="subclaim-description">
                        Requests that don't fall into other R-type categories.
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col className="sub-totalclaim">
                        Total Claims:
                        <span>
                          <div
                            className="to"
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "19px",
                            }}
                          >
                            {" "}
                            {this.state.errortotalcnt}
                          </div>
                        </span>
                      </Col>
                      <Col className="sub-total">
                        Grand Total:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontSize: "19px",
                              fontWeight: "600",
                            }}
                          >
                            $ {this.state.errortotalamt}
                          </div>
                        </span>
                      </Col>
                    </Row>
                  </Link>
                </div>
                </div>
  
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // entity request card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R1");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr1");
                        sessionStorage.setItem("querysub", "Entity Requests");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R1&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R1");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr1");
                        sessionStorage.setItem("querysub", "Entity Requests");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R1&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R1");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr1");
                        sessionStorage.setItem("querysub", "Entity Requests");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R1&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R1");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr1");
                        sessionStorage.setItem("querysub", "Entity Requests");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R1&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R1");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem("querysub", "Entity Requests");
                      sessionStorage.setItem("subclaimname", "requestr1");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R1&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R1");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem("querysub", "Entity Requests");
                      sessionStorage.setItem("subclaimname", "requestr1");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R1`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R1" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Entity Requests
                  </span>
                  </div>
                  <div class="card-text">
                  <Link
                    to={{
                      pathname: "/claim/sub-category-details",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Row>
                      <Col className="subclaim-description">
                        Requests for information about specific entities
                        (subscribers, patients, various providers).
                      </Col>
                    </Row>
                    <Row>
                      <Col className="sub-totalclaim">
                        Total Claims:
                        <span>
                          <div
                            className="to"
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "19px",
                            }}
                          >
                            {" "}
                            {this.state.reqtotalcnt}
                          </div>
                        </span>
                      </Col>
                      <Col className="sub-total">
                        Grand Total:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontSize: "19px",
                              fontWeight: "600",
                            }}
                          >
                            $ {this.state.reqtotalamt}
                          </div>
                        </span>
                      </Col>
                    </Row>
                  </Link>
                </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // claim card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R3");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr2");
                        sessionStorage.setItem("querysub", "Claim");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R3&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R3");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr2");
                        sessionStorage.setItem("querysub", "Claim");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R3&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R3");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr2");
                        sessionStorage.setItem("querysub", "Claim");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R3&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R3");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr2");
                        sessionStorage.setItem("querysub", "Claim");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R3&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R3");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem("querysub", "Claim");
                      sessionStorage.setItem("subclaimname", "requestr2");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R3&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R3");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem("querysub", "Claim");
                      sessionStorage.setItem("subclaimname", "requestr2");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R3`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R2" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Claim
                  </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          Requests for information that could normally be
                          submitted on a claim.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.claimtotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.claimtotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // documentation requested card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("subclaimname", "requestr3");
                        sessionStorage.setItem(
                          "querysub",
                          "Documentation Requests"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R4");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R4&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R4");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr3");
                        sessionStorage.setItem(
                          "querysub",
                          "Documentation Requests"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R4&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R4");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr3");
                        sessionStorage.setItem(
                          "querysub",
                          "Documentation Requests"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R4&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R4");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr3");
                        sessionStorage.setItem(
                          "querysub",
                          "Documentation Requests"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R4&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R4");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Documentation Requests"
                      );
                      sessionStorage.setItem("subclaimname", "requestr3");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R4&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R4");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Documentation Requests"
                      );
                      sessionStorage.setItem("subclaimname", "requestr3");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R4`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R3" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Documentation Requests
                  </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                        Requests for additional supporting documentation, such as certification, x-rays, or notes
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.docreqtotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.docreqtotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // more specific detail card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R5");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr4");
                        sessionStorage.setItem(
                          "querysub",
                          "More Specific Detail"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R5&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R5");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr4");
                        sessionStorage.setItem(
                          "querysub",
                          "More Specific Detail"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R5&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R5");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr4");
                        sessionStorage.setItem(
                          "querysub",
                          "More Specific Detail"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R5&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R5");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr4");
                        sessionStorage.setItem(
                          "querysub",
                          "More Specific Detail"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R5&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R5");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "More Specific Detail"
                      );
                      sessionStorage.setItem("subclaimname", "requestr4");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R5&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem(
                        "querysub",
                        "More Specific Detail"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R5");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem("subclaimname", "requestr4");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R5`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R4" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    More Specific Detail
                  </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                        More information is needed to follow up on a prior request
                        </Col>
                      </Row>
                    <br/>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {/* {this.state.subcategoryvalue &&
                     this.state.subcategoryvalue[12].totalcount} */}
                              {this.state.msdtotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              ${" "}
                              {/* {this.state.subcategoryvalue &&
                     this.state.subcategoryvalue[12].totalamount} */}
                              {this.state.msdtotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // regularity requirements card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R6");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr5");
                        sessionStorage.setItem(
                          "querysub",
                          "Regulatory Requirements"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R6&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R6");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr5");
                        sessionStorage.setItem(
                          "querysub",
                          "Regulatory Requirements"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R6&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R6");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr5");
                        sessionStorage.setItem(
                          "querysub",
                          "Regulatory Requirements"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R6&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R6");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr5");
                        sessionStorage.setItem(
                          "querysub",
                          "Regulatory Requirements"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R6&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R6");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Regulatory Requirements"
                      );
                      sessionStorage.setItem("subclaimname", "requestr5");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R6&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R6");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Regulatory Requirements"
                      );
                      sessionStorage.setItem("subclaimname", "requestr5");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R6`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R5" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Regulatory Requirements
                  </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <br />
                      <br />
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.regreqtotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.regreqtotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // confirm care is consistenet with health plan policy card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("subclaimname", "requestr6");
                        sessionStorage.setItem(
                          "querysub",
                          "Confirm Care is Consistent with Health Plan Policy Coverage"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R7");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R7&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R7");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr6");
                        sessionStorage.setItem(
                          "querysub",
                          "Confirm Care is Consistent with Health Plan Policy Coverage"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R7&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R7");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr6");
                        sessionStorage.setItem(
                          "querysub",
                          "Confirm Care is Consistent with Health Plan Policy Coverage"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R7&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R7");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr6");
                        sessionStorage.setItem(
                          "querysub",
                          "Confirm Care is Consistent with Health Plan Policy Coverage"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R7&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R7");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Confirm Care is Consistent with Health Plan Policy Coverage"
                      );
                      sessionStorage.setItem("subclaimname", "requestr6");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R7&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R7");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Confirm Care is Consistent with Health Plan Policy Coverage"
                      );
                      sessionStorage.setItem("subclaimname", "requestr6");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R7`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R6" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Confirm Care is Consistent with Health Plan Policy Coverage
                  </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.ccctotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.ccctotalcmt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // confirm care is consistenet with health plan coverage expectations card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R8");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr7");
                        sessionStorage.setItem(
                          "querysub",
                          "Confirm Care is Consistent with Health Plan Policy Exceptions"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R8&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R8");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr7");
                        sessionStorage.setItem(
                          "querysub",
                          "Confirm Care is Consistent with Health Plan Policy Exceptions"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R8&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R8");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr7");
                        sessionStorage.setItem(
                          "querysub",
                          "Confirm Care is Consistent with Health Plan Policy Exceptions"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R8&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R8");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr7");
                        sessionStorage.setItem(
                          "querysub",
                          "Confirm Care is Consistent with Health Plan Policy Exceptions"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R8&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R8");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Confirm Care is Consistent with Health Plan Policy Exceptions"
                      );
                      sessionStorage.setItem("subclaimname", "requestr7");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R8&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R8");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Confirm Care is Consistent with Health Plan Policy Exceptions"
                      );
                      sessionStorage.setItem("subclaimname", "requestr7");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R8`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R7" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Confirm Care is Consistent with Health Plan Coverage
                    Exceptions
                  </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
               
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.pcetotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.pcetotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // determination of medical necessities card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("subclaimname", "requestr8");
                        sessionStorage.setItem(
                          "querysub",
                          "Determination of Medical Necessity"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R9");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R9&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R9");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr8");
                        sessionStorage.setItem(
                          "querysub",
                          "Determination of Medical Necessity"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R9&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R9");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr8");
                        sessionStorage.setItem(
                          "querysub",
                          "Determination of Medical Necessity"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R9&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R9");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr8");
                        sessionStorage.setItem(
                          "querysub",
                          "Determination of Medical Necessity"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R9&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R9");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Determination of Medical Necessity"
                      );
                      sessionStorage.setItem("subclaimname", "requestr8");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R9&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R9");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Determination of Medical Necessity"
                      );
                      sessionStorage.setItem("subclaimname", "requestr8");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R9`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R8" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Determination of Medical Necessity
                  </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.dmntotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.dmntotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Support a Filed Grievance or Appeal card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("subclaimname", "requestr9");
                        sessionStorage.setItem(
                          "querysub",
                          "Support a Filed Grievance or Appeal"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R10");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R10&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R10");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr9");
                        sessionStorage.setItem(
                          "querysub",
                          "Support a Filed Grievance or Appeal"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R10&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R10");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr9");
                        sessionStorage.setItem(
                          "querysub",
                          "Support a Filed Grievance or Appeal"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R10&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R10");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr9");
                        sessionStorage.setItem(
                          "querysub",
                          "Support a Filed Grievance or Appeal"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R10&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R10");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Support a Filed Grievance or Appeal"
                      );
                      sessionStorage.setItem("subclaimname", "requestr9");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R10&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R10");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Support a Filed Grievance or Appeal"
                      );
                      sessionStorage.setItem("subclaimname", "requestr9");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R10`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R9" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Support a Filed Grievance or Appeal
                  </span></div>
           
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
               
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.sfgatotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.sfgatotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // pre payment review of claims card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R11");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr10");
                        sessionStorage.setItem(
                          "querysub",
                          "Pre-Payment Review of Claims"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R11&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R11");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr10");
                        sessionStorage.setItem(
                          "querysub",
                          "Pre-Payment Review of Claims"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R11&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R11");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr10");
                        sessionStorage.setItem(
                          "querysub",
                          "Pre-Payment Review of Claims"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R11&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R11");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr10");
                        sessionStorage.setItem(
                          "querysub",
                          "Pre-Payment Review of Claims"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R11&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R11");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Pre-Payment Review of Claims"
                      );
                      sessionStorage.setItem("subclaimname", "requestr10");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R11&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R11");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Pre-Payment Review of Claims"
                      );
                      sessionStorage.setItem("subclaimname", "requestr10");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R11`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R10" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Pre-Payment Review of Claims
                  </span>
                </div>
            
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
              
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.prctotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.prctotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //justification or clairification card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R12");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr11");
                        sessionStorage.setItem(
                          "querysub",
                          "Clarification or Justification of Use for Specified Procedure Code"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R12&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R12");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr11");
                        sessionStorage.setItem(
                          "querysub",
                          "Clarification or Justification of Use for Specified Procedure Code"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R12&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R12");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr11");
                        sessionStorage.setItem(
                          "querysub",
                          "Clarification or Justification of Use for Specified Procedure Code"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R12&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R12");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr11");
                        sessionStorage.setItem(
                          "querysub",
                          "Clarification or Justification of Use for Specified Procedure Code"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R12&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R12");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Clarification or Justification of Use for Specified Procedure Code"
                      );
                      sessionStorage.setItem("subclaimname", "requestr11");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R12&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R12");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Clarification or Justification of Use for Specified Procedure Code"
                      );
                      sessionStorage.setItem("subclaimname", "requestr11");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R12`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R11" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Clarification or Justification of Use for Specified
                    Procedure Code
                  </span>
                  </div>

                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >

                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.spctotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.spctotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //original documents submitted are readable card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R13");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr12");
                        sessionStorage.setItem(
                          "querysub",
                          "Original Documents Submitted are not Readable"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R13&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R13");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr12");
                        sessionStorage.setItem(
                          "querysub",
                          "Original Documents Submitted are not Readable"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R13&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R13");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr12");
                        sessionStorage.setItem(
                          "querysub",
                          "Original Documents Submitted are not Readable"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R13&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R13");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr12");
                        sessionStorage.setItem(
                          "querysub",
                          "Original Documents Submitted are not Readable"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R13&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R13");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Original Documents Submitted are not Readable"
                      );
                      sessionStorage.setItem("subclaimname", "requestr12");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R13&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R13");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Original Documents Submitted are not Readable"
                      );
                      sessionStorage.setItem("subclaimname", "requestr12");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R13`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R12" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Original Documents Submitted are not Readable
                  </span>
                  </div>
         
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                 
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.odsntotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.odsntotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //original documents received or not received what was wrong card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R14");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr13");
                        sessionStorage.setItem(
                          "querysub",
                          "Original Documents Received are not What was Requested"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R14&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R14");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr13");
                        sessionStorage.setItem(
                          "querysub",
                          "Original Documents Received are not What was Requested"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R14&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R14");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr13");
                        sessionStorage.setItem(
                          "querysub",
                          "Original Documents Received are not What was Requested"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R14&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R14");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr13");
                        sessionStorage.setItem(
                          "querysub",
                          "Original Documents Received are not What was Requested"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R14&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R14");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Original Documents Received are not What was Requested"
                      );
                      sessionStorage.setItem("subclaimname", "requestr13");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R14&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R14");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Original Documents Received are not What was Requested"
                      );
                      sessionStorage.setItem("subclaimname", "requestr13");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R14`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R13" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Original Documents Received are not What was Requested
                  </span>
                  </div>
        
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
              
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.odrrtotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.odrrtotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //workeres compensastion coverage determination card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("subclaimname", "requestr14");
                        sessionStorage.setItem(
                          "querysub",
                          "Workers Compensation Coverage Determination"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R15");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R15&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R15");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr14");
                        sessionStorage.setItem(
                          "querysub",
                          "Workers Compensation Coverage Determination"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R15&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R15");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr14");
                        sessionStorage.setItem(
                          "querysub",
                          "Workers Compensation Coverage Determination"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R15&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R15");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr14");
                        sessionStorage.setItem(
                          "querysub",
                          "Workers Compensation Coverage Determination"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R15&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R15");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Workers Compensation Coverage Determination"
                      );
                      sessionStorage.setItem("subclaimname", "requestr14");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R15&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R15");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Workers Compensation Coverage Determination"
                      );
                      sessionStorage.setItem("subclaimname", "requestr14");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R15`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R14" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Workers Compensation Coverage Determination
                  </span>
                  </div>
         
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
              
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.wccdtotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.wccdtotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //eligibility determination card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R16");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr15");
                        sessionStorage.setItem(
                          "querysub",
                          "Eligibility Determination"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R16&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R16");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr15");
                        sessionStorage.setItem(
                          "querysub",
                          "Eligibility Determination"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R16&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R16");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr15");
                        sessionStorage.setItem(
                          "querysub",
                          "Eligibility Determination"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R16&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R16");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr15");
                        sessionStorage.setItem(
                          "querysub",
                          "Eligibility Determination"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R16&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R16");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Eligibility Determination"
                      );
                      sessionStorage.setItem("subclaimname", "requestr15");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R16&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R16");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Eligibility Determination"
                      );
                      sessionStorage.setItem("subclaimname", "requestr15");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R16`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R15" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Eligibility Determination
                  </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <br />
                      <br />
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.eligibilitytotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.eligibilitytotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //Replacement of a prior request card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R17");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr16");
                        sessionStorage.setItem(
                          "querysub",
                          "Replacement of a Prior Request"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R17&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R17");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr16");
                        sessionStorage.setItem(
                          "querysub",
                          "Replacement of a Prior Request"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R17&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R17");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr16");
                        sessionStorage.setItem(
                          "querysub",
                          "Replacement of a Prior Request"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R17&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Requests for Additional Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "R17");
                        sessionStorage.setItem("queryCategoryname", "Request");
                        sessionStorage.setItem("subclaimname", "requestr16");
                        sessionStorage.setItem(
                          "querysub",
                          "Replacement of a Prior Request"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Request&statusCategoryCode=R17&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R17");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem(
                        "querysub",
                        "Replacement of a Prior Request"
                      );
                      sessionStorage.setItem("subclaimname", "requestr16");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R17&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Requests for Additional Information"
                      );
                      sessionStorage.setItem(
                        "querysub",
                        "Replacement of a Prior Request"
                      );
                      sessionStorage.setItem("queryCategoryCode", "R17");
                      sessionStorage.setItem("queryCategoryname", "Request");
                      sessionStorage.setItem("subclaimname", "requestr16");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Request&statusCategoryCode=R17`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "R16" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                  Replacement of a Prior Request
                  </span>
                  </div>
       
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                        This indicates that the current attachment request supersedes a previous one
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.replacementtotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.replacementtotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdditionalInformation;
