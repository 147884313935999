import { Component } from "react";
import { Row, Col, Button, CardHeader, CardBody } from "reactstrap";
import { Modal } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Newsidebar from "../../Dashboard/Newsidebar";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import "./../../../Styles/eligibility.css";
import { ip } from "../../../Api";
import axios from "axios";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

class Eligibility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ClaimID: sessionStorage.getItem("ClaimID"),
      ChartID: sessionStorage.getItem("ChartID"),
      MRN: sessionStorage.getItem("MRN"),
      Patient: sessionStorage.getItem("Patient"),
      Pripayer: sessionStorage.getItem("Pripayer"),
      DateOfService: sessionStorage.getItem("DateOfService"),
      ClaimDate: sessionStorage.getItem("ClaimDate"),
      PatientDOB: sessionStorage.getItem("PatientDOB"),
      reportmodal: false,
      Eligibilitydata: [],
      subscriberFirstname: "",
      subscriberLastname: "",
      subscriberGender: "",
      new: [],
      subscriberDOB: "",
      subscriberMemberId: "",
      subscriberAddress: "",
      subscriberGroup: "",
      subscriberType: "",
      submitterNpi: "",
      submittername: "",
      submittertype: "",
      planNumber: "",
      planStatus: "",
      benefitamount: "",
      remaining: "",
      planGroupNumber: "",
      Patientservicename: "",
      insurancetype: "",
      coverge: "",
      status: "",
      servicetypes: "",
      planDate: "",
      PlanEligibility: "",
      planServiceType: "",
      planCoverageLevel: "",
      planService: "",

      plan: "",
      name: "",
      entitytype: "",
      identifiercode: "",
      address1: "",
      telephone: "",
      url: "",
      benefit: "",
      checkmodal: false,
      updateEndDOS: "",
      updatePatientDOB: "",
      updateLastName: "",
      updateFirstName: "",
      updateBeginingDOS: "",
      updateControlNo: "",
      updateTrackingNumber: "",
      updateGender: "",
      updateNpi: "",
      updateType: "",
      planbegin: "",
      updateServiceOrganizationName: "",
      tradingPartnerServiceId: "",
      updatePrimaryPolicyNo: "",
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      modulename: sessionStorage.getItem("Module"),
      subscriberAddress: "",
      subscriberdob: "",
      subscribergroup: "",
      subscribergender: "",
      coverage: "",
    };
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  EligibilityPost = async () => {
    // alert('Enter')
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      controlNumber: this.state.updateControlNo,
      tradingPartnerServiceId: this.state.tradingPartnerServiceId,
      organizationName: this.state.updateServiceOrganizationName,
      npi: this.state.updateNpi,
      memberId: this.state.updatePrimaryPolicyNo,
      firstName: this.state.updateFirstName,
      lastName: this.state.updateLastName,
      gender: this.state.updateGender,
      dateOfBirth: this.state.updatePatientDOB,
      beginningDateOfService: this.state.updateBeginingDOS,
      endDateOfService: this.state.updateEndDOS,
    };
    const res = await axios
      .post(`${ip}/eligibility/report?claimid=${this.state.ClaimID}`, value, {
        headers: headers,
      })
      .then((res) => {
        this.getclaims();
        this.setState({ checkmodal: !this.state.checkmodal });
      })
      .catch((err) => {});
  };
  getClaimDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = await axios
      .get(`${ip}/claim/viewclaim?claimid=${this.state.ClaimID}`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          updateEndDOS: res.data.Claim[0].EndDOS,

          updatePatientDOB: res.data.Claim[0].PatientDOB,
          updateLastName: res.data.Claim[0].PatientLastName,
          updateFirstName: res.data.Claim[0].PatientFirstName,

          updateGender: res.data.Claim[0].Gender,
          updateNpi: res.data.Claim[0].Npi,

          updateControlNo: res.data.Claim[0].controlNumber,

          updateBeginingDOS: res.data.Claim[0].BeginingDOS,
          updateType: res.data.Claim[0].type,
          updateServiceOrganizationName:
            res.data.Claim[0].ServiceOrganizationName,
          updatePrimaryPolicyNo: res.data.Claim[0].PrimaryPolicyNo,
          tradingPartnerServiceId: res.data.Claim[0].PayerID,
        });
      })
      .catch((err) => {});
  };
  getclaims = async () => {
    // api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/eligibility/all?claimid=${this.state.ClaimID}`, {
        headers: headers,
      })
      .then((res) => {
        alert("Eligibility report will be uploaded soon");

        if (res.data.eligibility[0].data.subscriber.firstName !== 0) {
          this.setState({
            subscriberFirstname:
              res.data.eligibility[0].data.subscriber.firstName,
          });
        } else {
          this.setState({
            subscriberFirstname: "No data",
          });
        }
        if (res.data.eligibility[0].data.subscriber.lastName !== 0) {
          this.setState({
            subscriberLastname:
              res.data.eligibility[0].data.subscriber.lastName,
          });
        } else {
          this.setState({
            subscriberLastname: "No data",
          });
        }
        if (res.data.eligibility[0].data.subscriber.dateOfBirth !== 0) {
          this.setState({
            subscriberdob: res.data.eligibility[0].data.subscriber.dateOfBirth,
          });
        } else {
          this.setState({
            subscriberdob: "No data",
          });
        }
        if (res.data.eligibility[0].data.subscriber.gender !== 0) {
          this.setState({
            subscribergender: res.data.eligibility[0].data.subscriber.gender,
          });
        } else {
          this.setState({
            subscribergender: "No data",
          });
        }
        if (res.data.eligibility[0].data.subscriber.groupNumber !== 0) {
          this.setState({
            subscribergroup:
              res.data.eligibility[0].data.subscriber.groupNumber,
          });
        } else {
          this.setState({
            subscribergroup: "No data",
          });
        }
        if (res.data.eligibility[0].data.subscriber.memberId !== 0) {
          this.setState({
            subscriberMemberId:
              res.data.eligibility[0].data.subscriber.memberId,
          });
        } else {
          this.setState({
            subscriberMemberId: "No data",
          });
        }
        if (res.data.eligibility[0].data.provider.providerName !== 0) {
          this.setState({
            submittername: res.data.eligibility[0].data.provider.providerName,
          });
        } else {
          this.setState({
            submittername: "No data",
          });
        }
        if (res.data.eligibility[0].data.provider.npi !== 0) {
          this.setState({
            submitterNpi: res.data.eligibility[0].data.provider.npi,
          });
        } else {
          this.setState({
            submitterNpi: "No data",
          });
        }
        if (res.data.eligibility[0].data.provider.entityIdentifier !== 0) {
          this.setState({
            submittertype:
              res.data.eligibility[0].data.provider.entityIdentifier,
          });
        } else {
          this.setState({
            submittertype: "No data",
          });
        }
        if (
          res.data.eligibility[0].data.subscriber.address.address1 +
            res.data.eligibility[0].data.subscriber.address.city +
            res.data.eligibility[0].data.subscriber.address.state +
            res.data.eligibility[0].data.subscriber.address.postalCode !==
          0
        ) {
          this.setState({
            subscriberAddress:
              res.data.eligibility[0].data.subscriber.address.address1 +
              res.data.eligibility[0].data.subscriber.address.city +
              res.data.eligibility[0].data.subscriber.address.state +
              res.data.eligibility[0].data.subscriber.address.postalCode,
          });
        } else {
          this.setState({
            subscriberAddress: "No data",
          });
        }
        if (
          res.data.eligibility[0].data.planStatus[0].serviceTypeCodes[0] !== 0
        ) {
          this.setState({
            planServiceType:
              res.data.eligibility[0].data.planStatus[0].serviceTypeCodes[0],
          });
        } else {
          this.setState({
            planServiceType: "No data",
          });
        }
        if (res.data.eligibility[0].data.planStatus[0].status !== 0) {
          this.setState({
            planStatus: res.data.eligibility[0].data.planStatus[0].status,
          });
        } else {
          this.setState({
            planStatus: "No data",
          });
        }
        if (res.data.eligibility[0].data.planStatus[0].status !== 0) {
          this.setState({
            planCoverageLevel:
              res.data.eligibility[0].data.planStatus[0].status,
          });
        } else {
          this.setState({
            planCoverageLevel: "No data",
          });
        }
        if (
          res.data.eligibility[0].data.benefitsInformation[0]
            .serviceTypes[0] !== 0
        ) {
          this.setState({
            subscriberType:
              res.data.eligibility[0].data.benefitsInformation[0]
                .serviceTypes[0],
          });
        } else {
          this.setState({
            subscriberType: "No data",
          });
        }
        if (res.data.eligibility[0].data.planInformation.idCardNumber !== 0) {
          this.setState({
            planGroupNumber:
              res.data.eligibility[0].data.planInformation.idCardNumber,
          });
        } else {
          this.setState({
            planGroupNumber: "No data",
          });
        }
        if (res.data.eligibility[0].data.planStatus[0].planDetails !== 0) {
          this.setState({
            planNumber: res.data.eligibility[0].data.planStatus[0].planDetails,
          });
        } else {
          this.setState({
            planNumber: "No data",
          });
        }
        if (res.data.eligibility[0].data.planStatus[0].status !== 0) {
          this.setState({
            status: res.data.eligibility[0].data.planStatus[0].status,
          });
        } else {
          this.setState({
            status: "No data",
          });
        }
        if (
          res.data.eligibility[0].data.planStatus[0].serviceTypeCodes[0] !== 0
        ) {
          this.setState({
            servicetypes:
              res.data.eligibility[0].data.planStatus[0].serviceTypeCodes[0],
          });
        } else {
          this.setState({
            servicetypes: "No data",
          });
        }
        if (res.data.eligibility[0].data.planDateInformation.planBegin !== 0) {
          this.setState({
            planbegin:
              res.data.eligibility[0].data.planDateInformation.planBegin,
          });
        } else {
          this.setState({
            planbegin: "No data",
          });
        }
        if (
          res.data.eligibility[0].data.benefitsInformation[0].benefitAmount !==
          0
        ) {
          this.setState({
            benefitamount:
              res.data.eligibility[0].data.benefitsInformation[0].benefitAmount,
          });
        } else {
          this.setState({
            benefitamount: "No data",
          });
        }
        if (
          res.data.eligibility[0].data.benefitsInformation[0]
            .insuranceTypeCode !== 0
        ) {
          this.setState({
            insurancetype:
              res.data.eligibility[0].data.benefitsInformation[0]
                .insuranceTypeCode,
          });
        } else {
          this.setState({
            insurancetype: "No data",
          });
        }
        if (res.data.eligibility[0].data.planStatus[0].planDetails !== 0) {
          this.setState({
            plan: res.data.eligibility[0].data.planStatus[0].planDetails,
          });
        } else {
          this.setState({
            plan: "No data",
          });
        }
        if (res.data.eligibility[0].data.benefitsInformation[0].name !== 0) {
          this.setState({
            name: res.data.eligibility[0].data.benefitsInformation[0].name,
          });
        } else {
          this.setState({
            name: "No data",
          });
        }
        if (
          res.data.eligibility[0].data.benefitsInformation[0]
            .benefitsRelatedEntity.entityType !== 0
        ) {
          this.setState({
            entitytype:
              res.data.eligibility[0].data.benefitsInformation[0]
                .benefitsRelatedEntity.entityType,
          });
        } else {
          this.setState({
            entitytype: "No data",
          });
        }
        if (
          res.data.eligibility[0].data.benefitsInformation[0]
            .headerLoopIdentifierCode !== 0
        ) {
          this.setState({
            identifiercode:
              res.data.eligibility[0].data.benefitsInformation[0]
                .headerLoopIdentifierCode,
          });
        } else {
          this.setState({
            identifiercode: "No data",
          });
        }
        if (
          res.data.eligibility[0].data.subscriber.address.address1 +
            res.data.eligibility[0].data.subscriber.address.city +
            res.data.eligibility[0].data.subscriber.address.state +
            res.data.eligibility[0].data.subscriber.address.postalCode !==
          0
        ) {
          this.setState({
            address1:
              res.data.eligibility[0].data.subscriber.address.address1 +
              res.data.eligibility[0].data.subscriber.address.city +
              res.data.eligibility[0].data.subscriber.address.state +
              res.data.eligibility[0].data.subscriber.address.postalCode,
          });
        } else {
          this.setState({
            address1: "No data",
          });
        }
      })

      .catch((err) => {});
  };

  componentDidMount() {
    this.getClaimDetails();

    if (this.state.modulename === "Allocation")
      this.setState({ redirecturl: "/allocation-buckets" });
    else if (this.state.modulename === "Denial Management")
      this.setState({ redirecturl: "/denial-management" });
    else if (this.state.modulename === "Claim Status Management")
      this.setState({ redirecturl: "/claim-management" });
    else if (this.state.modulename === "Web Claims")
      this.setState({ redirecturl: "/claims/Web" });
    else if (this.state.modulename === "IVR Claims")
      this.setState({ redirecturl: "/claims/IVR" });
    else if (this.state.modulename === "Manual Claims")
      this.setState({ redirecturl: "/claims/Manual" });
    else if (this.state.modulename === "Rejection")
      this.setState({ redirecturl: "/allocate/rejection" });
    // this.getclaims();
  }
  togglereport = () => {
    this.setState({ reportmodal: !this.state.reportmodal });
  };
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
        width: "50%",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        width: "50%",
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:last-child th, &:last-child tr": {
        border: "1px solid rgba(224, 224, 224, 1)",
        //border:0,
      },
    }));
    const StyledTableRow1 = styled(TableRow)(({ theme }) => ({
      "&:nth-child(even) th, &:nth-child(even) td, &:nth-child(odd) th, &:nth-child(odd) td":
        {
          border: "1px solid rgba(224, 224, 224, 1)",
        },
    }));
    return (
      <div className="align-row" style={{ overflow: "hidden" }}>
        <Newsidebar name="Eligibility" />
        <div className="align-column">
          <br></br>
          <div
            style={{ textAlign: "left", marginLeft: "2%", fontSize: "14px" }}
          >
            <p>
              <Link
                to={this.state.redirecturl}
                style={{ textDecoration: "none", color: "black" }}
              >
                {this.state.modulename}
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>
              &nbsp;Eligibility Report
            </p>
          </div>
          <br></br>
          <ToastContainer></ToastContainer>
          <div>
            {" "}
            <h5
              style={{ textAlign: "left", marginLeft: "2%", fontWeight: "600" }}
            >
              View Claim
            </h5>
            {this.state.planServiceType == 30 ? (
              <button
                className="e-button"
                style={{
                  float: "right",
                  marginRight: "5%",
                  backgroundColor: "whitesmoke",
                  color: "black",
                  borderRadius: "20px",
                  border: "2px solid green",
                  padding: "12px 14px",
                }}
              >
                Eligibility
              </button>
            ) : (
              <button
                style={{
                  float: "right",
                  marginRight: "5%",
                  border: "2px solid red",
                  backgroundColor: "whitesmoke",
                  color: "black",
                  borderRadius: "20px",
                  padding: "12px 14px",
                }}
              >
                Eligibility
              </button>
            )}
          </div>

          <div
            style={{
              height: "100%",
              width: "100%",
              marginTop: "2%",
              marginLeft: "1%",
            }}
          >
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>ClaimID</StyledTableCell>
                    <StyledTableCell>ChartID</StyledTableCell>
                    <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                    Claim No/Account Number
                    </StyledTableCell>
                    <StyledTableCell>PatientName</StyledTableCell>
                    <StyledTableCell>PatientDOB</StyledTableCell>
                    <StyledTableCell>Pripayer</StyledTableCell>
                    <StyledTableCell>Phone#</StyledTableCell>
                    {/* <StyledTableCell>Address</StyledTableCell> */}
                    <StyledTableCell>ClaimDate</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableCell>{this.state.ClaimID}</StyledTableCell>
                    {/* <StyledTableCell component="th" scope="row">
               {this.state.ChartID}
             </StyledTableCell> */}
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>{this.state.MRN}</StyledTableCell>
                    <StyledTableCell>
                      {this.state.updateFirstName}
                      <br />
                      {this.state.updateLastName}
                    </StyledTableCell>
                    <StyledTableCell>{moment(this.state.PatientDOB).format("MM/DD/YYYY")}</StyledTableCell>
                    <StyledTableCell>{this.state.Pripayer}</StyledTableCell>
                    {/* <StyledTableCell>{this.state.PatientDOB}</StyledTableCell> */}
                    <StyledTableCell>{this.state.ClaimID}</StyledTableCell>
                    {/* <StyledTableCell>{this.state.ClaimID}</StyledTableCell> */}
                    <StyledTableCell>{this.state.ClaimDate}</StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <br />
          <div>
            {" "}
            <p
              style={{
                float: "left",
                paddingLeft: "70%",
                fontWeight: "400",
                marginLeft: "10%",
              }}
            >
              Total Amount:
            </p>{" "}
            &nbsp;
            <p style={{ float: "left", paddingLeft: "1%", fontWeight: "600" }}>
              $ 1,500.00
            </p>
          </div>

          <div>
            <h3 align="left" style={{ marginLeft: "2%" }}>
              <b>Eligibility Report</b>
            </h3>{" "}
            <button
              style={{
                width: "100px",
                marginLeft: "80%",
                backgroundColor: "black",
                color: "white",
                borderRadius: "20px",
              }}
              onClick={() =>
                this.setState({ checkmodal: !this.state.checkmodal })
              }
            >
              Check
            </button>
            <br />
            <br />
            <Row>
              <Col md={4}>
                <Card style={{ boxShadow: "1px 3px 1px #9E9E9E" }}>
                  <CardHeader>
                    <Row className="plan_head">Patient Details</Row>
                  </CardHeader>

                  <CardBody>
                    <Row>
                      <Col>
                        <p>Patient First Name</p>
                      </Col>
                      <Col>
                        <p>{this.state.subscriberFirstname} </p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />
                    <Row>
                      <Col>
                        <p>Patient Last Name</p>
                      </Col>
                      <Col>
                        <p>{this.state.subscriberLastname} </p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />
                    <Row>
                      <Col>
                        <p>Type</p>
                      </Col>
                      <Col>
                        <p>{this.state.subscriberType} </p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />
                    <Row>
                      <Col>
                        <p>Member ID</p>
                      </Col>
                      <Col>
                        <p>{this.state.subscriberMemberId} </p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p>Group</p>
                      </Col>
                      <Col>
                        <p>{this.state.subscribergroup} </p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p>Address</p>
                      </Col>
                      <Col>
                        <p>{this.state.subscriberAddress}</p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p>DOB</p>
                      </Col>
                      <Col>
                        <p>{this.state.subscriberdob} </p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p>Gender</p>
                      </Col>
                      <Col>
                        <p>{this.state.subscribergender} </p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p>Adm. Communication</p>
                      </Col>
                      <Col>
                        <p></p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p> Service</p>
                      </Col>
                      <Col>
                        <p>{this.state.Patientservicename}</p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p>Enrollment</p>
                      </Col>
                      <Col>
                        <p> </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col md={8}>
                <div>
                  <Card
                    style={{
                      boxShadow: "1px 3px 1px #9E9E9E",
                      marginLeft: "2px",
                    }}
                  >
                    <CardHeader>
                      <Row className="plan_head">Submitter</Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <p style={{ marginRight: "64%" }}> Submitter</p>
                        </Col>
                        <Col>
                          <p> {this.state.submittername}</p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p style={{ marginRight: "64%" }}>Submitter Type</p>
                        </Col>
                        <Col>
                          <p>{this.state.submittertype}</p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p style={{ marginRight: "64%" }}>
                            {" "}
                            Service Provider#
                          </p>
                        </Col>
                        <Col>
                          <p>{this.state.submitterNpi}</p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <br />
                <div>
                  <Card style={{ boxShadow: "1px 3px 1px #9E9E9E" }}>
                    <CardHeader>
                      <Row className="plan_head">Plan Details</Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <p style={{ marginRight: "64%" }}> Plan</p>
                        </Col>
                        <Col>
                          <p>{this.state.planNumber}</p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p style={{ marginRight: "64%" }}>Status</p>
                        </Col>
                        <Col>
                          <p>{this.state.planStatus}</p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p style={{ marginRight: "64%" }}>Coverage Level</p>
                        </Col>
                        <Col>
                          <p>MEDICARE PART-A</p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p style={{ marginRight: "64%" }}>Service Type</p>
                        </Col>
                        <Col>
                          <p style={{ marginRight: "35%" }}>
                            {this.state.planServiceType}
                          </p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p style={{ marginRight: "64%" }}>Plan</p>
                        </Col>
                        <Col>
                          <p>
                            {this.state.planDate}-{this.state.planService}
                          </p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p style={{ marginRight: "64%" }}>
                            Group or Policy Number
                          </p>
                        </Col>
                        <Col>
                          <p>{this.state.planGroupNumber}</p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p style={{ marginRight: "64%" }}>Eligibility</p>
                        </Col>
                        <Col>
                          <p>Service</p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <br />
            <Card
              style={{
                boxShadow: "1px 3px 1px #9E9E9E",
                width: "97%",
                marginLeft: "20px",
              }}
            >
              <CardHeader>
                <Row className="plan_status">Plan Status</Row>
              </CardHeader>
              <CardBody>
                <div>
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>Coverage</p>
                    </Col>

                    <Col>
                      <p>{this.state.planGroupNumber}</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>Status</p>
                    </Col>
                    <Col>
                      <p>{this.state.status}</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>Service Types</p>
                    </Col>
                    <Col>
                      <p style={{ marginRight: "54%" }}>
                        {this.state.servicetypes}
                      </p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                </div>
              </CardBody>
            </Card>
            <br />
            <Card
              style={{
                boxShadow: "1px 3px 1px #9E9E9E",
                width: "97%",
                marginLeft: "20px",
              }}
            >
              <CardBody>
                <div>
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>Plan Effective Date</p>
                    </Col>
                    <Col>
                      <p>{this.state.planbegin}</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />

                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>
                        <span style={{ color: "blue" }}>Deductible</span>
                        <br />
                        Health Benefit Plan Coverage (30)
                        (20210101-20211231)-Calendar Year-Amount:
                      </p>
                    </Col>
                    <Col>
                      <p>{this.state.benefitamount}</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%", color: "red" }}>
                        Remaining
                      </p>
                    </Col>
                    <Col>
                      <p>{this.state.benefitamount}</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                </div>
              </CardBody>
            </Card>
            <br />
            <Card
              style={{
                boxShadow: "1px 3px 1px #9E9E9E",
                width: "97%",
                marginLeft: "20px",
              }}
            >
              <CardBody>
                <div>
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>
                        <span style={{ color: "blue" }}>Deductible</span>
                        <br />
                        Home Health Care(42),Smoking
                        Cessation(67),Alcoholism(AJ) (20210101-
                        20211231)-Calendar Year-Amount: $ 0
                      </p>
                    </Col>
                    <Col>
                      <p></p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%", color: "red" }}>
                        Remaining
                      </p>
                    </Col>
                    <Col>
                      <p>{this.state.benefitamount}</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                </div>
              </CardBody>
            </Card>
            <br />
            <Card
              style={{
                boxShadow: "1px 3px 1px #9E9E9E",
                width: "97%",
                marginLeft: "20px",
              }}
            >
              <CardBody>
                <div>
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>
                        <span style={{ color: "blue" }}>CO Insurance</span>
                        <br />
                        Health Benefit Plan Coverage (30)
                        (20210101-20211231)-Calendar Year-Amount: NA Per
                        Visit(27)=20.0 % (0.2)
                      </p>
                    </Col>
                    <Col>
                      <p></p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />

                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                </div>
              </CardBody>
            </Card>
            <br />
            <Card
              style={{
                boxShadow: "1px 3px 1px #9E9E9E",
                width: "97%",
                marginLeft: "20px",
              }}
            >
              <CardBody>
                <div>
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>
                        <span style={{ color: "blue" }}>
                          Limitations Notcovered
                        </span>
                        <br />
                        Routine (Preventive) Dental(41),Long Term Care(54)
                      </p>
                    </Col>
                    <Col>
                      <p> {this.state.status} </p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                </div>
              </CardBody>
            </Card>
            <Card
              style={{
                boxShadow: "1px 3px 1px #9E9E9E",
                width: "97%",
                marginLeft: "20px",
              }}
            >
              <CardHeader>
                <Row className="plan_status">Pharmacy</Row>
              </CardHeader>
              <CardBody>
                <div>
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>Insurance Type Code</p>
                    </Col>
                    <Col>
                      <p>{this.state.insurancetype}</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>Plan</p>
                    </Col>
                    <Col>
                      <p>{this.state.plan}</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>Name</p>
                    </Col>
                    <Col>
                      <p>{this.state.name}</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>Entity Type</p>
                    </Col>
                    <Col>
                      <p>{this.state.entitytype}</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>Identifier Code</p>
                    </Col>
                    <Col>
                      <p>{this.state.identifiercode}</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>Address</p>
                    </Col>
                    <Col>
                      <p>{this.state.subscriberAddress}</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>Telephone</p>
                    </Col>
                    <Col>
                      <p>{this.state.telephone}</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>
                        Uniform Resource Locator (URL)
                      </p>
                    </Col>
                    <Col>
                      <p>http://localhost/www.Express- ScriptsMedicare.com</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                  <Row>
                    <Col>
                      <p style={{ marginRight: "64%" }}>Benefit</p>
                    </Col>
                    <Col>
                      <p>{this.state.benefit}</p>
                    </Col>
                  </Row>
                  <hr style={{ width: "100%", marginLeft: "-10px" }} />
                </div>
              </CardBody>
            </Card>
            <br />
          </div>

          <Modal show={this.state.checkmodal} centered>
            <Modal.Header
              closeButton
              closeLabel=""
              onClick={() =>
                this.setState({ checkmodal: !this.state.checkmodal })
              }
            >
              <Modal.Title>Check Eligibility Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure want to check eligibility report?
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.EligibilityPost}>Yes</Button>
              <Button
                onClick={() =>
                  this.setState({ checkmodal: !this.state.checkmodal })
                }
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}
export default Eligibility;
