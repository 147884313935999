import { Component } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Newsidebar from "./../../Dashboard/Newsidebar.js";
import { formatCurrency } from "./utils.js";
import { ip } from "../../../Api.js";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
const getPath = (x, y, width, height) => `M${x},${y + height}
  C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}Z`;
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;};
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const ClaimTooltip = ({ active, payload }) => {
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          {payload[0].payload._id}
          <br />
          <p style={{ color: payload[0].color }}>
            {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
          </p >
          <p style={{ color: payload[1].color }}>
            {payload[1].name}:&nbsp;${payload[1].value.toLocaleString()}
          </p>
        </div>
      );
    }
    return null;
  };
class BillingPayment extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
    dataarray:[],
    client:sessionStorage.getItem("clientid")
   };
   renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {   
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getData = async () =>{
    const headers = {
      "Content-Type" : "application/json",
      Authorization : `bearer ${this.state.accesstoken}`
    };

    let url;
    if(this.state.client) {
      url = `${ip}/trends/billingpayment?clientId=${this.state.client}`
    }  
    else {
      url = `${ip}/trends/billingpayment`
    }
     axios
    .get(url,{headers:headers})
    .then((res) =>{
      this.setState({
        dataarray:res.data.data
      })
    })
    .catch((err)=>{})
  }

  componentDidMount() {
    
    this.getData()
  };
  render() {
    return (
      <div className="align-row">
        <Newsidebar />
        <div className="align-column">

          <div>
            <div
              style={{
                textAlign: "left",
                marginLeft: "3%",
                marginTop: "2%",
                fontSize: "14px",
              }}
            >
              <p>
                <Link
                  to="/management-trends-patterns"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Trends & Patterns
                </Link>
                &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Billing
                and Payments
              </p>
            </div>
            <br />
            <ResponsiveContainer width={"99%"} height={590}>
              <LineChart
                width={500}
                height={380}
                data={this.state.dataarray}
                margin={{
                  top: 10,
                  right: 30,
                  left: 40,
                  bottom: 10,
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis
                  dataKey="_id"
                  height={80}
                  angle="-90"
                  interval={0}
                  tick={{
                    strokeWidth: 0,
                    fontSize: "12",
                    top: 400,
                    textAnchor: "end",
                    dy: 6,
                  }}
                />
                <YAxis
                  domain={["auto", "auto"]}
                  /*domain={[0.00,200000.00]}*/ interval={0}
                  minTickGap="1"
                />
                <Tooltip cursor={true} content={<ClaimTooltip/>}/>
                
                <Line
                  shape={<TriangleBar />}
                  name="TotalBilledAmount"
                  type="monotone"
                  dataKey="TotalBilledAmount"
                  stroke="rgb(5, 101, 191)"
                  fill="rgb(5, 101, 191)"
                  formatter = {formatCurrency}
                />
                <Line
                 shape={<TriangleBar />}
                  name="TotalAmountPaid"
                  type="monotone"
                  dataKey="TotalAmountPaid"
                  stroke="rgb(247, 73, 105)"
                  fill="rgb(247, 73, 105)"
                  formatter = {formatCurrency}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }
}
export default BillingPayment;
