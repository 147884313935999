import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import {
  Input,
  Col,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import { ip} from "../../Api";
import { Table } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";
export default class ClaimCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getquery: [],
      getdata1: [],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      getdataTable: false,
      lastName: "",
      firstName: "",
      middleName: "",
      suffix: "",
      providerlastName: "",
      providerfirstName: "",
      providermiddleName: "",
      providersuffix: "",
      providernpi: "",
      providertaxid: "",
      subscribermemberId: "",
      subscriberlastName: "",
      subscriberfirstName: "",
      subscribermiddleName: "",
      subscribersuffix: "",
      patientlastName: "",
      patientfirstName: "",
      patientmiddleName: "",
      patientsuffix: "",
      patientbirthDate: "",
      patientgenderCode: "",
      startdate: "",
      enddate: "",
      patientaccountNumber: "",
      patientsubscriberRelationshipCode: "",
      getquery1: [],
      disabled: true,
      subfirstnameErr: "",
      sublastnameErr: "",
      submidnameErr: "",
      providerlastnameErr: "",
      providernpiErr: "",
      providertaxErr: "",
      subsmemidErr: "",
      subsfirstnameErr: "",
      subslastnameErr: "",
      patientfirstnameErr: "",
      patientlastnameErr: "",
      patientbirthErr: "",
      patientgenderErr: "",
      patientaccnoErr: "",
      patientrelationcodeErr: "",
      startdateErr: "",
      ClaimID: "",
      enddateErr: "",
      ClaimIDErr: "",
      clientId: sessionStorage.getItem("clientid"),
      //new values for payerid
      payerAssignedProviderId: "",
      payeridload: false,
      payeridarray: [],
      payeridErr: "",
      inputValue: "", // Initialize inputValue
    };
  }
  getidinfo = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    try {
      const res = await axios.get(
        `${ip}/payermaster/all?clientId=${this.state.clientId}`,
        { headers }
      );
      const filteredPayerArray = res.data.payer.filter(
        (payer) => !payer.payer_id.includes("-")
      ); // Filter out entries with hyphens
      this.setState({
        payeridarray: filteredPayerArray,
        payeridload: false,
      });
    } catch (err) {
      console.error(err); // Optionally handle errors
    }
  };
  handleSubmitForm = () => {
    const isValid = this.formValidate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const form = {
        firstName: this.state.firstName,
        middleName: this.state.middleName,
        lastName: this.state.lastName,
        fromDate: this.state.startdate,
        patientfirstName: this.state.patientfirstName,
        patientlastName: this.state.patientlastName,
        patientmiddleName: this.state.patientmiddleName,
        patientbirthDate: this.state.patientbirthDate,
        patientgenderCode: this.state.patientgenderCode,
        patientaccountNumber: this.state.patientaccountNumber,
        patientsubscriberRelationshipCode:
          this.state.patientsubscriberRelationshipCode,
        PayerID: this.state.payerAssignedProviderId,
        providerfirstName: this.state.providerfirstName,
        providermiddleName: this.state.providermiddleName,
        providerlastName: this.state.providerlastName,
        providernpi: this.state.providernpi,
        providertaxid: this.state.providertaxid,
        subscribermemberId: this.state.subscribermemberId,
        subscriberfirstName: this.state.subscriberfirstName,
        subscriberlastName: this.state.subscriberlastName,
        toDate: this.state.enddate,
        // ClaimID: this.state.ClaimID,
        clientId: parseInt(this.state.clientId),
      };
      const res = axios
        .post(`${ip}/uploadquickcheck/checkstatus`, form, {
          headers: headers,
        })
        .then((res) => {
          this.setState({
            getdataTable: true,
            getdata1: res.data.claims,
            disabled: true,
          });
          alert("Data added");
        })
        .catch((err) => {});
    }
  };
  formValidate = () => {
    let hasErr = "true";
    // let ClaimIDErr = "";
    let payeridErr = "";
    let subfirstnameErr = "";
    let sublastnameErr = "";
    let submidnameErr = "";
    let providerlastnameErr = "";
    let providernpiErr = "";
    let providertaxErr = "";
    let subsmemidErr = "";
    let subsfirstnameErr = "";
    let subslastnameErr = "";
    let patientfirstnameErr = "";
    let patientlastnameErr = "";
    let patientbirthErr = "";
    let patientgenderErr = "";
    let patientaccnoErr = "";
    let patientrelationcodeErr = "";
    let startdateErr = "";
    let enddateErr = "";
    if (
      this.state.payerAssignedProviderId === "" ||
      this.state.payerAssignedProviderId === null
    ) {
      payeridErr = "This field is required";
      this.setState({ payeridErr });
      hasErr = false;
    }
    if (this.state.firstName === "" || this.state.firstName === null) {
      subfirstnameErr = "This field is required";
      this.setState({ subfirstnameErr });
      hasErr = false;
    }
    if (this.state.middleName === "" || this.state.middleName === null) {
      submidnameErr = "This field is required";
      this.setState({ submidnameErr });
      hasErr = false;
    }
    if (this.state.lastName === "" || this.state.lastName === null) {
      sublastnameErr = "This field is required";
      this.setState({ sublastnameErr });
      hasErr = false;
    }
    // if (this.state.providerfirstName === "" || this.state.providerfirstName === null) {
    //   providerfirstnameErr = "This field is required";
    //   this.setState({ providerfirstnameErr });
    //   hasErr = false;
    // }
    if (
      this.state.providerlastName === "" ||
      this.state.providerlastName === null
    ) {
      providerlastnameErr = "This field is required";
      this.setState({ providerlastnameErr });
      hasErr = false;
    }
    if (this.state.providernpi === "" || this.state.providernpi === null) {
      providernpiErr = "This field is required";
      this.setState({ providernpiErr });
      hasErr = false;
    }
    if (this.state.providertaxid === "" || this.state.providertaxid === null) {
      providertaxErr = "This field is required";
      this.setState({ providertaxErr });
      hasErr = false;
    }
    // if (this.state.ClaimID === "" || this.state.ClaimID === null) {
    //   ClaimIDErr = "This field is required";
    //   this.setState({ ClaimIDErr });
    //   hasErr = false;
    // }
    if (
      this.state.subscribermemberId === "" ||
      this.state.subscribermemberId === null
    ) {
      subsmemidErr = "This field is required";
      this.setState({ subsmemidErr });
      hasErr = false;
    }
    if (
      this.state.subscriberfirstName === "" ||
      this.state.subscriberfirstName === null
    ) {
      subsfirstnameErr = "This field is required";
      this.setState({ subsfirstnameErr });
      hasErr = false;
    }
    if (
      this.state.subscriberlastName === "" ||
      this.state.subscriberlastName === null
    ) {
      subslastnameErr = "This field is required";
      this.setState({ subslastnameErr });
      hasErr = false;
    }
    if (
      this.state.patientfirstName === "" ||
      this.state.patientfirstName === null
    ) {
      patientfirstnameErr = "This field is required";
      this.setState({ patientfirstnameErr });
      hasErr = false;
    }
    if (
      this.state.patientlastName === "" ||
      this.state.patientlastName === null
    ) {
      patientlastnameErr = "This field is required";
      this.setState({ patientlastnameErr });
      hasErr = false;
    }
    if (
      this.state.patientbirthDate === "" ||
      this.state.patientbirthDate === null
    ) {
      patientbirthErr = "This field is required";
      this.setState({ patientbirthErr });
      hasErr = false;
    }
    if (
      this.state.patientgenderCode === "" ||
      this.state.patientgenderCode === null
    ) {
      patientgenderErr = "This field is required";
      this.setState({ patientgenderErr });
      hasErr = false;
    }
    if (
      this.state.patientaccountNumber === "" ||
      this.state.patientaccountNumber === null
    ) {
      patientaccnoErr = "This field is required";
      this.setState({ patientaccnoErr });
      hasErr = false;
    }
    if (
      this.state.patientsubscriberRelationshipCode === "" ||
      this.state.patientsubscriberRelationshipCode === null
    ) {
      patientrelationcodeErr = "This field is required";
      this.setState({ patientrelationcodeErr });
      hasErr = false;
    }
    if (this.state.startdate === "" || this.state.startdate === null) {
      startdateErr = "This field is required";
      this.setState({ startdateErr });
      hasErr = false;
    }
    if (this.state.enddate === "" || this.state.enddate === null) {
      enddateErr = "This field is required";
      this.setState({ enddateErr });
      hasErr = false;
    }
    return hasErr;
  };
  handleresetForm = () => {
    this.setState({
      getquery: [],
      getdata1: [],
      getdataTable: false,
      payerAssignedProviderId: "",
      lastName: "",
      firstName: "",
      middleName: "",
      suffix: "",
      providerlastName: "",
      providerfirstName: "",
      providermiddleName: "",
      providersuffix: "",
      providernpi: "",
      providertaxid: "",
      subscribermemberId: "",
      subscriberlastName: "",
      subscriberfirstName: "",
      subscribermiddleName: "",
      subscribersuffix: "",
      patientlastName: "",
      patientfirstName: "",
      patientmiddleName: "",
      patientsuffix: "",
      patientbirthDate: "",
      patientgenderCode: "",
      patientaccountNumber: "",
      isSubscriberEdited: false, // flag to check if subscriber fields are manually edited
      startdate: "",
      enddate: "",
      getquery1: [],
      patientsubscriberRelationshipCode: "",
      disabled: true,
      payeridErr: "",
      subfirstnameErr: "",
      sublastnameErr: "",
      submidnameErr: "",
      providerfirstnameErr: "",
      providerlastnameErr: "",
      providernpiErr: "",
      providertaxErr: "",
      subsmemidErr: "",
      subsfirstnameErr: "",
      subslastnameErr: "",
      patientfirstnameErr: "",
      patientlastnameErr: "",
      patientbirthErr: "",
      patientgenderErr: "",
      patientaccnoErr: "",
      patientrelationcodeErr: "",
      startdateErr: "",
      enddateErr: "",
      ClaimID: "",
      ClaimIDErr: "",
    });
  };
  handlePatientFirstNameChange = (data) => {
    const value = data.target.value;
    this.setState((prevState) => ({
      patientfirstName: value,
      subscriberfirstName: prevState.isSubscriberEdited
        ? prevState.subscriberfirstName
        : value,
    }));
  };
  handlePatientLastNameChange = (data) => {
    const value = data.target.value;
    this.setState((prevState) => ({
      patientlastName: value,
      subscriberlastName: prevState.isSubscriberEdited
        ? prevState.subscriberlastName
        : value,
    }));
  };
  handleSubscriberFirstNameChange = (data) => {
    this.setState({
      subscriberfirstName: data.target.value,
      isSubscriberEdited: true,
    });
  };
  handleSubscriberLastNameChange = (data) => {
    this.setState({
      subscriberlastName: data.target.value,
      isSubscriberEdited: true,
    });
  };
  componentDidMount() {
    this.getidinfo();
  }
  render() {
    return (
      <div style={{ paddingTop: "1%", overflow: "hidden" }}>
        <Newsidebar name="Claim Quick Check" />
        <div
          className="claim_grid"
          style={{ fontSize: "12px", overflow: "hidden" }}
        >
          <div className="nosql1">
            <div
              className="card"
              style={{
                border: "none",
                background: "whitesmoke",
              }}
            >
              <div
                className="card-header"
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  fontWeight: "800",
                  background: "#07326c",
                  color: "white",
                }}
              >
                PAYERID
              </div>
              <div className="card-body">
                <Row>
                  <Col md={6}>
                    <Label>PayerAssignedProviderId</Label>
                  </Col>
                  <Col md={6}>
                    <Typeahead
                      id="claimcheck-typeahead"
                      style={{ fontSize: "12px" }}
                      size="13px"
                      isLoading={this.state.payeridload}
                      clearButton={true}
                      options={this.state.payeridarray}
                      labelKey={(option) =>
                        `${option.payer_id} - ${option.payerName}`
                      }
                      placeholder="Select..."
                      selected={
                        this.state.payerAssignedProviderId
                          ? [
                              {
                                payer_id: this.state.payerAssignedProviderId,
                                payerName: this.state.selectedPayerName,
                              },
                            ]
                          : []
                      }
                      inputProps={{ value: this.state.displayValue }}
                      onChange={(selected) => {
                        const selectedValue =
                          selected.length > 0 ? selected[0].payer_id : "";
                        const selectedPayerName =
                          selected.length > 0 ? selected[0].payerName : "";
                        const displayValue =
                          selected.length > 0
                            ? `${selected[0].payer_id} - ${selected[0].payerName}`
                            : "";
                        this.setState({
                          payerAssignedProviderId: selectedValue,
                          selectedPayerName: selectedPayerName,
                          displayValue: displayValue,
                        });
                      }}
                      onInputChange={(text, event) => {
                        this.setState({
                          inputValue: text,
                          payerAssignedProviderId: "",
                          selectedPayerName: "",
                          displayValue: text,
                        });
                      }}
                    />

                    <div style={{ fontSize: 16, color: "red" }}>
                      {this.state.payeridErr}
                    </div>
                  </Col>
                </Row>

                <br />
              </div>
            </div>
            <div className="sub-grid-claim">
              <div
                className="card"
                style={{
                  border: "none",
                  background: "whitesmoke",
                }}
              >
                <div
                  className="card-header"
                  style={{
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: "800",
                    background: "#07326c",
                    color: "white",
                  }}
                >
                  SUBMITTER
                </div>
                <div className="card-body">
                  <Row>
                    <Col md={6}>
                      <Label className="required">First Name</Label>
                    </Col>
                    <Col md={6}>
                      <Input
                        style={{
                          border: "1px solid #ddd",
                        }}
                        type="text"
                        value={this.state.firstName}
                        onChange={(data) => {
                          this.setState({
                            firstName: data.target.value,
                          });
                        }}
                      />
                      <div
                        style={{
                          fontSize: 16,
                          color: "red",
                          marginLeft: "11.5%",
                        }}
                      >
                        {this.state.subfirstnameErr}
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <Label className="required">MiddleName</Label>
                    </Col>
                    <Col md={6}>
                      <Input
                        style={{
                          border: "1px solid #ddd",
                        }}
                        type="text"
                        value={this.state.middleName}
                        onChange={(data) => {
                          this.setState({ middleName: data.target.value });
                        }}
                      />
                      <div
                        style={{
                          fontSize: 16,
                          color: "red",
                          marginLeft: "11.5%",
                        }}
                      >
                        {this.state.submidnameErr}
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <Label className="required">LastName</Label>
                    </Col>
                    <Col md={6}>
                      <Input
                        style={{
                          border: "1px solid #ddd",
                        }}
                        type="text"
                        value={this.state.lastName}
                        onChange={(data) => {
                          this.setState({ lastName: data.target.value });
                        }}
                      />
                      <div
                        style={{
                          fontSize: 16,
                          color: "red",
                          marginLeft: "11.5%",
                        }}
                      >
                        {this.state.sublastnameErr}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div
                className="card"
                style={{
                  border: "none",
                  background: "whitesmoke",
                }}
              >
                <div
                  className="card-header"
                  style={{
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: "800",
                    background: "#07326c",
                    color: "white",
                  }}
                >
                  PROVIDER
                </div>
                <div className="card-body">
                  <Row>
                    <Col md={6}>
                      <Label>FirstName</Label>
                    </Col>
                    <Col md={6}>
                      <Input
                        style={{
                          border: "1px solid #ddd",
                        }}
                        type="text"
                        value={this.state.providerfirstName}
                        onChange={(data) => {
                          this.setState({
                            providerfirstName: data.target.value,
                          });
                        }}
                      />
                      <div
                        style={{
                          fontSize: 16,
                          color: "red",
                          marginLeft: "11.5%",
                        }}
                      >
                        {this.state.providerfirstnameErr}
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <Label className="required">
                        LastName/
                        <br />
                        FacilityName
                      </Label>
                    </Col>
                    <Col md={6}>
                      <Input
                        style={{
                          border: "1px solid #ddd",
                        }}
                        type="text"
                        value={this.state.providerlastName}
                        onChange={(data) => {
                          this.setState({
                            providerlastName: data.target.value,
                          });
                        }}
                      />
                      <div
                        style={{
                          fontSize: 16,
                          color: "red",
                          marginLeft: "11.5%",
                        }}
                      >
                        {this.state.providerlastnameErr}
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <Label className="required">Npi</Label>
                    </Col>
                    <Col md={6}>
                      <Input
                        style={{
                          border: "1px solid #ddd",
                        }}
                        type="text"
                        value={this.state.providernpi}
                        onChange={(data) => {
                          this.setState({ providernpi: data.target.value });
                        }}
                      />
                      <div
                        style={{
                          fontSize: 16,
                          color: "red",
                          marginLeft: "11.5%",
                        }}
                      >
                        {this.state.providernpiErr}
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <Label className="required">TaxID</Label>
                    </Col>
                    <Col md={6}>
                      <Input
                        style={{
                          border: "1px solid #ddd",
                        }}
                        type="text"
                        value={this.state.providertaxid}
                        onChange={(data) => {
                          this.setState({ providertaxid: data.target.value });
                        }}
                      />
                      <div
                        style={{
                          fontSize: 16,
                          color: "red",
                          marginLeft: "11.5%",
                        }}
                      >
                        {this.state.providertaxErr}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className="card"
              style={{
                border: "none",
                background: "whitesmoke",
              }}
            >
              <div
                className="card-header"
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  fontWeight: "800",
                  background: "#07326c",
                  color: "white",
                }}
              >
                PATIENT
              </div>
              <br />
              <div className="card-body">
                <Row>
                  <Col md={6}>
                    <Label className="required">MemberID</Label>
                  </Col>
                  <Col md={6}>
                    <Input
                      style={{
                        border: "1px solid #ddd",
                      }}
                      type="text"
                      value={this.state.subscribermemberId}
                      onChange={(data) => {
                        this.setState({
                          subscribermemberId: data.target.value,
                        });
                      }}
                    />
                    <div
                      style={{ fontSize: 16, color: "red", marginLeft: "25%" }}
                    >
                      {this.state.subsmemidErr}
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={6}>
                    <Label className="required">FirstName</Label>
                  </Col>
                  <Col md={6}>
                    <Input
                      style={{
                        border: "1px solid #ddd",
                      }}
                      type="text"
                      value={this.state.patientfirstName}
                      onChange={this.handlePatientFirstNameChange}
                    />
                    <div
                      style={{ fontSize: 16, color: "red", marginLeft: "25%" }}
                    >
                      {this.state.patientfirstnameErr}
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={6}>
                    <Label className="required">LastName</Label>
                  </Col>
                  <Col md={6}>
                    <Input
                      style={{
                        border: "1px solid #ddd",
                      }}
                      type="text"
                      value={this.state.patientlastName}
                      onChange={this.handlePatientLastNameChange}
                    />
                    <div
                      style={{ fontSize: 16, color: "red", marginLeft: "25%" }}
                    >
                      {this.state.patientlastnameErr}
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={6}>
                    <Label className="required">BirthDate</Label>
                  </Col>
                  <Col md={6}>
                    <Input
                      placeholder="YYYYMMDD"
                      style={{
                        border: "1px solid #ddd",
                      }}
                      type="text"
                      value={this.state.patientbirthDate}
                      onChange={(data) => {
                        this.setState({ patientbirthDate: data.target.value });
                      }}
                    />
                    <div
                      style={{ fontSize: 16, color: "red", marginLeft: "25%" }}
                    >
                      {this.state.patientbirthErr}
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={6}>
                    <Label className="required">Gender</Label>
                  </Col>
                  <Col md={6}>
                    <Input
                      style={{
                        border: "1px solid #ddd",
                      }}
                      type="text"
                      value={this.state.patientgenderCode}
                      onChange={(data) => {
                        this.setState({ patientgenderCode: data.target.value });
                      }}
                    />
                    <div
                      style={{ fontSize: 16, color: "red", marginLeft: "25%" }}
                    >
                      {this.state.patientgenderErr}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div>
            <div
              className="card"
              style={{
                border: "none",
                background: "whitesmoke",
              }}
            >
              <div
                className="card-header"
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  fontWeight: "800",
                  background: "#07326c",
                  color: "white",
                }}
              >
                SUBSCRIBER
              </div>
              <div className="card-body">
                <Row>
                  <Col md={6}>
                    <Label className="required">FirstName</Label>
                  </Col>
                  <Col md={6}>
                    <Input
                      style={{
                        border: "1px solid #ddd",
                      }}
                      type="text"
                      value={this.state.subscriberfirstName}
                      onChange={this.handleSubscriberFirstNameChange}
                    />
                    <div
                      style={{ fontSize: 16, color: "red", marginLeft: "25%" }}
                    >
                      {this.state.subsfirstnameErr}
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={6}>
                    <Label className="required">LastName</Label>
                  </Col>
                  <Col md={6}>
                    <Input
                      style={{
                        border: "1px solid #ddd",
                      }}
                      type="text"
                      value={this.state.subscriberlastName}
                      onChange={this.handleSubscriberLastNameChange}
                    />
                    <div
                      style={{ fontSize: 16, color: "red", marginLeft: "25%" }}
                    >
                      {this.state.subslastnameErr}
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={6}>
                    <Label className="required">AccountNumber</Label>
                  </Col>
                  <Col md={6}>
                    <Input
                      style={{
                        border: "1px solid #ddd",
                      }}
                      type="text"
                      value={this.state.patientaccountNumber}
                      onChange={(data) => {
                        this.setState({
                          patientaccountNumber: data.target.value,
                        });
                      }}
                    />
                    <div
                      style={{ fontSize: 16, color: "red", marginLeft: "25%" }}
                    >
                      {this.state.patientaccnoErr}
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={6}>
                    <Label className="required">Relationship Code</Label>
                  </Col>
                  <Col md={6}>
                    <Input
                      style={{
                        border: "1px solid #ddd",
                      }}
                      type="text"
                      value={this.state.patientsubscriberRelationshipCode}
                      onChange={(data) => {
                        this.setState({
                          patientsubscriberRelationshipCode: data.target.value,
                        });
                      }}
                    />
                    <div
                      style={{ fontSize: 16, color: "red", marginLeft: "25%" }}
                    >
                      {this.state.patientrelationcodeErr}
                    </div>
                  </Col>
                </Row>
              </div>
              <br />
              <div
                className="card-footer"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  border: "none",
                }}
              >
                <div>
                  <div className="datepicker">
                    <div>
                      <label className="required">Start Date</label>
                      <input
                        type="required"
                        value={this.state.startdate}
                        onChange={(data) => {
                          this.setState({ startdate: data.target.value });
                        }}
                        className="startdate"
                        id="formGroupExampleInput"
                        placeholder="YYYYMMDD"
                      />
                      <div
                        style={{
                          fontSize: 16,
                          color: "red",
                          marginLeft: "31%",
                        }}
                      >
                        {this.state.startdateErr}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="datepicker">
                    <div>
                      <label className="required">End Date</label>
                      <input
                        type="required"
                        value={this.state.enddate}
                        onChange={(data) => {
                          this.setState({ enddate: data.target.value });
                        }}
                        className="enddate"
                        id="formGroupExampleInput"
                        placeholder="YYYYMMDD"
                      />
                      <div
                        style={{
                          fontSize: 16,
                          color: "red",
                          marginLeft: "31%",
                        }}
                      >
                        {this.state.enddateErr}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <button
              className="button"
              style={{ float: "right" }}
              onClick={() => {
                if (this.state.disabled) {
                  this.setState({ disabled: false, getdata1: [] }, () => {
                    this.handleSubmitForm();
                    console.log("value", this.state.disabled);
                  });
                }
              }}
              disabled={!this.state.disabled}
            >
              Save
            </button>
            <button
              className="button"
              style={{
                float: "right",
                background: "#EB1A23",
                marginRight: "8%",
              }}
              onClick={this.handleresetForm}
            >
              Reset
            </button>
            <br />
          </div>
        </div>
        {this.state.getdataTable && (
          <Table className="StandardTable" responsive bordered>
            <thead>
              <tr align="center">
                <th>ClaimStatusType</th>
                <th>uniqueID</th>
                <th>fromDate</th>
                <th>toDate</th>
                <th>Channel</th>
                <th>PayerId</th>
                <th>Insurance Name</th>
                <th>Submitter Lastname</th>
                <th>Provider TaxId</th>
                <th>Provider Lastname</th>
                <th>Provider NPI</th>
                <th>Subscriber MemberID</th>
                <th>Patient Firstname</th>
                <th>Patient Lastname</th>
                <th>Patient BirthDate</th>
                <th>Gender</th>
                <th>Subscriber Lastname</th>
                <th>Subscriber Firstname</th>
                <th>Status</th>
                <th>FinalizeDate</th>
                <th>Claim Number</th>
                <th>CPT</th>
                <th>Claim Amount</th>
                <th>Paid Amount</th>
                <th>Check Number</th>
                <th>Status Category Code</th>
                <th>Status Code</th>
                <th>Status Code Description</th>
              </tr>
            </thead>
            <tbody>
            {this.state.getdata1 &&
              this.state.getdata1.map((data, index) => (
                <React.Fragment key={index}>
                  {/* Claim Row */}
                  <tr>
                    <td>{"Claim"}</td>
                    <td>{data?.ClaimID ?? "-"}</td>
                    <td>{data?.BeginingDOS ?? "-"}</td>
                    <td>{data?.EndDOS ?? "-"}</td>
                    <td>{"Machine - 2"}</td>
                    <td>{data?.PayerID ?? "-"}</td>
                    <td>{data?.InsuranceName ?? "-"}</td>
                    <td>{data?.BillingOrganizationName ?? "-"}</td>
                    <td>{data?.TaxID ?? "-"}</td>
                    <td>{data?.BillingOrganizationName ?? "-"}</td>
                    <td>{data?.Npi ?? "-"}</td>
                    <td>{data?.PrimaryPolicyNo ?? "-"}</td>
                    <td>{data?.PatientFirstName ?? "-"}</td>
                    <td>{data?.PatientLastName ?? "-"}</td>
                    <td>{data?.PatientDOB ?? "-"}</td>
                    <td>{data?.Gender ?? "-"}</td>
                    <td>{data?.PatientLastName ?? "-"}</td>
                    <td>{data?.PatientFirstName ?? "-"}</td>
                    <td>{data?.ClaimStatus ?? "-"}</td>
                    <td>{data?.processDate ?? "-"}</td>
                    <td>{data?.tradingPartnerClaimNumber ?? "-"}</td>
                    <td>{/* CPTs for claim row would be blank */}</td>
                    <td>{data?.TotalBilledAmount ?? "-"}</td>
                    <td>{data?.TotalAmountPaid ?? "-"}</td>
                    <td>{data?.checkNumber ?? "-"}</td>
                    <td>{data?.statusCategoryCode ?? "-"}</td>
                    <td>{data?.statusCode ?? "-"}</td>
                    <td>{data?.statusCodeValue ?? "-"}</td>
                  </tr>
          
                  {/* Check if data.cpt is present and is an array with at least one element */}
                  {data.cpt && data.cpt.length > 0 ? (
                    data.cpt.map((cpt, cptIndex) => (
                      <tr key={cptIndex}>
                        <td>{"CPT"}</td>
                        <td>{cpt?.ClaimID ?? "-"}</td>
                        <td>{data?.BeginingDOS ?? "-"}</td>
                        <td>{data?.EndDOS ?? "-"}</td>
                        <td>{"Machine - 2"}</td>
                        <td>{cpt?.PayerID ?? "-"}</td>
                        <td>{data?.InsuranceName ?? "-"}</td>
                        <td>{data?.BillingOrganizationName ?? "-"}</td>
                        <td>{data?.TaxID ?? "-"}</td>
                        <td>{data?.BillingOrganizationName ?? "-"}</td>
                        <td>{data?.Npi ?? "-"}</td>
                        <td>{data?.PrimaryPolicyNo ?? "-"}</td>
                        <td>{data?.PatientFirstName ?? "-"}</td>
                        <td>{data?.PatientLastName ?? "-"}</td>
                        <td>{data?.PatientDOB ?? "-"}</td>
                        <td>{data?.Gender ?? "-"}</td>
                        <td>{data?.PatientLastName ?? "-"}</td>
                        <td>{data?.PatientFirstName ?? "-"}</td>
                        <td>{cpt?.ClaimStatus ?? "-"}</td>
                        <td>{cpt?.processDate ?? "-"}</td>
                        <td>{cpt?.ICN ?? "-"}</td>
                        <td>{cpt?.CPTCode ?? "-"}</td>
                        <td>{cpt?.submittedAmount ?? "-"}</td>
                        <td>{cpt?.amountPaid ?? "-"}</td>
                        <td>{cpt?.check_no ?? "-"}</td>
                        <td>{cpt?.statusCategoryCode ?? "-"}</td>
                        <td>{cpt?.statusCode ?? "-"}</td>
                        <td>{cpt?.statusCodeValue ?? "-"}</td>
                      </tr>
                    ))
                  ) : (
                    // If no CPT data is present, show a "No CPTs" message
                    <tr>
                      <td colSpan="28" style={{ textAlign: "center" }}>
                        No CPTs available for this claim
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>     
          </Table>
        )}
      </div>
    );
  }
}
