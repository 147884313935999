import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import "../../Styles/admin.css";
import "../../Styles/configure.css";
import "../../Styles/PayerMaster.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import loading from "../../Images/loader.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { MdOutlineSearch } from "react-icons/md";
import { Table } from "react-bootstrap";
import { ip } from "../../Api";
import "../../Styles/typetext.css";

const PayerCategory = () => {
  const numbers = Array.from({ length: 100 }, (_, index) => index + 1);
  const [pageCount, setpageCount] = useState(null);
  const [search_result, setsearch_result] = useState(null);
  const [modal, setmodal] = useState(false);
  const [updatemodal, setupdatemodal] = useState(false);
  const [deletemodal, setdeletemodel] = useState(false);
  const [dataforupdate, setdataforupdate] = useState(null);
  const refreshtoken = sessionStorage.getItem("refreshtoken");
  const accesstoken = sessionStorage.getItem("accesstoken");
  const role_type = sessionStorage.getItem("role_type");
  const [payer, setpayer] = useState([]);
  const [searchclaimdata, setsearchclaimdata] = useState([]);
  const [searchdataresponse, setsearchdataresponse] = useState([]);
  const [updatedayscount, setupdatedayscount] = useState("");
  const [categoryId, setcategoryId] = useState("");
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [searchBy, setsearchBy] = useState("category_id");
  const [querySearch, setquerySearch] = useState("");
  const [searchtext, setsearchtext] = useState("");
  const [provider, setprovider] = useState("");
  const [insurance, setinsurance] = useState("");
  const [authorization, setauthorization] = useState("");
  const [write, setwrite] = useState("");
  const [status, setstatus] = useState("");
  const [updatecategoryId, setupdatecategoryId] = useState("");
  const [updatename, setupdatename] = useState("");
  const [updatedescription, setupdatedescription] = useState("");
  const [updateprovider, setupdateprovider] = useState("");
  const [updateinsurance, setupdateinsurance] = useState("");
  const [updateauthorization, setupdateauthorization] = useState("Yes");
  const [updatewrite, setupdatewrite] = useState("");
  const [updatestatus, setupdatestatus] = useState("Enabled");
  const [categoryIdErr, setcategoryIdErr] = useState("");
  const [nameErr, setnameErr] = useState("");
  const [descriptionErr, setdescriptionErr] = useState("");
  const [providerErr, setproviderErr] = useState("");
  const [insuranceErr, setinsuranceErr] = useState("");
  const [dayscount, setdayscount] = useState("");
  const [writeErr, setwriteErr] = useState("");
  const [singleid, setsingleid] = useState("");
  const [offset, setoffset] = useState(0);
  const [currentpage, setcurrentpage] = useState(0);
  const [perPage, setperPage] = useState(10);
  const [createmodal, setcreatemodal] = useState(false);
  const [statusErr, setstatusErr] = useState("");
  const [authErr, setauthErr] = useState("");
  const [idErr, setidErr] = useState("");
  const [daysErr, setdaysErr] = useState("");
  const clientid = sessionStorage.getItem("clientid");
  useEffect(() => {
    getpayer();
  },[]);
  const toggle = () => {
    setmodal(!modal);
    setcategoryId("");
    setstatus("");
    setauthorization("");
    setwrite("");
    setinsurance("");
    setprovider("");
    setdescription("");
    setdayscount("");
    setname("");
    setnameErr("");
    setauthErr("");
    setidErr("");
    setstatusErr("");
    setdaysErr("");
  }

  const toggledelete = () => {
    setdeletemodel(!deletemodal);
  };

  const toggleupdate = () => {
    setupdatemodal(!updatemodal);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;
    setcurrentpage(selectedPage); 
    setoffset(offset); 
    getpayer(selectedPage); 
  };
  const handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;
    setcurrentpage(selectedPage);
    setoffset(offset);
    handleSearch();
  };
  const handleSubmit = async () => {
    const isValid = validate();
    if (isValid) {
      setcreatemodal(!createmodal);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accesstoken}`,
      };
      const value = {
        category_id: categoryId,
        category_name: name,
        description: description,
        billing_provider: provider,
        insurance_adjustment: insurance,
        preauthorization: authorization,
        write_off: write,
        status: status,
        claimFollowupDays: parseInt(dayscount),
        clientId: clientid,
      };
      // eslint-disable-next-line
      const res = await axios
        .post(`${ip}/payercategory/create`, value, {
          headers: headers,
        })
        .then((res) => {
          if (res) {
            toggle();
            setcreatemodal(false);
            getpayer();
            toast.success("Payer category created", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            toggle();
            getpayer();
            setcreatemodal(!createmodal);
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };

  const settingupdatestate = function () {
    setupdatecategoryId(dataforupdate.category_id);
    setupdatename(dataforupdate.category_name);
    setupdatedescription(dataforupdate.description);
    setupdateprovider(dataforupdate.billing_provider);
    setupdateinsurance(dataforupdate.insurance_adjustment);
    setupdatewrite(dataforupdate.write_off);
    setupdateauthorization(dataforupdate.preauthorization);
    setupdatestatus(dataforupdate.status);
    setsingleid(dataforupdate._id);
    setupdatedayscount(dataforupdate.claimFollowupDays);
  };

  const putrequests = async (dataid) => {
    // const isValid = this.updatevalidate();

    // if (isValid) {
    setcreatemodal(!createmodal);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    const value = {
      category_id: updatecategoryId,
      category_name: updatename,
      description: updatedescription,
      billing_provider: updateprovider,
      insurance_adjustment: updateinsurance,
      preauthorization: updateauthorization,
      write_off: updatewrite,
      status: updatestatus,
      claimFollowupDays: parseInt(updatedayscount),
      clientId: clientid,
    };
    // eslint-disable-next-line
    const res = await axios
      .put(`${ip}/payercategory/${dataid}`, value, {
        headers: headers,
      })
      .then((res) => {
        if (res) {
          setcreatemodal(!createmodal);
          toggleupdate();
          getpayer();
          toast.info("Payer category updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          getpayer();
          toggleupdate();
          setcreatemodal(!createmodal);
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  const deleterequests = async (DataID) => {
    //api call to delete requests
    setcreatemodal(!createmodal);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/payercategory/${DataID}`, {
        headers: headers,
      })
      .then((req) => {
        if (req) {
          setcreatemodal(!createmodal);
          toggledelete();
          getpayer();
          toast.error("Payer category deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          getpayer();
          toggledelete();
          setcreatemodal(!createmodal);
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  const renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshtoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => { });
  };

  //filter by  Payer category
  const handleSearch = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    // eslint-disable-next-line
    if (querySearch === "") {
      toast.error("Please fill the search value!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const res = await axios
        .get(
          `${ip}/payercategory/advancedFilter?${searchBy}=${querySearch}&clientId=${clientid}`,
          { headers: headers }
        )
        .then((res) => {

          if (res) {
            toast.success("Searching completed!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.warning("Fetching data", {
              position: "top-right",
              hideProgressBar: false,
              pauseOnHover: true,
              progress: true,
            });
          }
          setsearchtext(1);
          setsearch_result(res.data.payer);
          const slice = res.data.payer .slice(
            offset,
            offset + perPage
          );
          setsearchdataresponse(res.data.payer);
          setpageCount(Math.ceil(res.data.payer.length / perPage));
          setsearchclaimdata(slice);
        })
        .catch((err) => {});
    }
  };

  const getpayer = async (page = currentpage) => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (clientid) {
      url = `${ip}/payercategory/all?clientId=${clientid}&pageno=${page}`
    } else {
      url = `${ip}/payercategory/all&pageno=${page}`
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        setpageCount(Math.ceil(res.data.count / perPage));
        setpayer(res.data.payer);
      })
      .catch((err) => {});
  };

  const validate = () => {
    let hasErr = "true";
    let nameErr = "";
    let statusErr = "";
    let authErr = "";
    let idErr = "";
    let daysErr = "";
    if (name === "") {
      nameErr = "This field is required";
      setnameErr(nameErr);
      hasErr = false;
    }
    if (status === "") {
      statusErr = "This field is required";
      setstatusErr(statusErr);
      hasErr = false;
    }
    if (authorization === "") {
      authErr = "This field is required";
      setauthErr(authErr);
      hasErr = false;
    }
    if (categoryId === "") {
      idErr = "This field is required";
      setidErr(idErr);
      hasErr = false;
    }
    if (dayscount === "") {
      daysErr = "This field is required";
      setdaysErr(daysErr);
      hasErr = false;
    }
    return hasErr;
  };

  const updatevalidate = () => {
    let hasErr = "true";
    let categoryIdErr = "";
    let nameErr = "";
    let providerErr = "";
    let insuranceErr = "";
    let writeErr = "";
    let descriptionErr = "";

    if (updatecategoryId === "") {
      categoryIdErr = "This field is required";
      setcategoryIdErr(categoryIdErr);
      hasErr = false;
    }
    if (updatename === "") {
      nameErr = "This field is required";
      setnameErr(nameErr);
      hasErr = false;
    }
    if (updatedescription === "") {
      descriptionErr = "This field is required";
      setdescriptionErr(descriptionErr);
      hasErr = false;
    }
    if (updateprovider === "") {
      providerErr = "This field is required";
      setproviderErr(providerErr);
      hasErr = false;
    }
    if (updateinsurance === "") {
      insuranceErr = "This field is required";
      setinsuranceErr(insuranceErr);
      hasErr = false;
    }
    if (updatewrite === "") {
      writeErr = "This field is required";
      setwriteErr(writeErr);
      hasErr = false;
    }

    return hasErr;
  };

  const reset1 = () => {
    setcategoryId("");
    setname("");
    setdescription("");
    setprovider("");
    setinsurance("");
    setauthorization("Yes");
    setwrite("");
    setstatus("Enabled");
    setcategoryIdErr("");
    setnameErr("");
    setdescriptionErr("");
    setproviderErr("");
    setinsuranceErr("");
    setwriteErr("");
    setsearchBy("category_id");
    getpayer();
  };
  const resetSearch = () => {
    toast.info("All values displayed now!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });
    setsearchBy("payer_id");
    setquerySearch("");
    setsearchdataresponse([]);
    setsearchclaimdata([]);
    setsearchtext("");
  } 

  const reset2 = () => {
    setcategoryId("");
    setname("");
    setdescription("");
    setprovider("");
    setinsurance("");
    setauthorization("Yes");
    setwrite("");
    setstatus("Enabled");
    setcategoryIdErr("");
    setnameErr("");
    setdescriptionErr("");
    setproviderErr("");
    setinsuranceErr("");
    setwriteErr("");
  };

  const reset3 = () => {
    setsearchBy("category_id");
    setquerySearch("");
    setsearchdataresponse([]);
    setsearchclaimdata([]);
    setsearchtext("");
    getpayer();
  };

  return (
    <div>
      <Newsidebar name="Payer Category" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column">
          <ToastContainer></ToastContainer>
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="top__search">
              <div className="form__group">
                <select
                  className="drop-down__one"
                  value={searchBy}
                  onChange={(e) => setsearchBy(e.target.value)}
                >
                  <option value="category_id">Category ID</option>
                  <option value="category_name">Payer Category</option>
                </select>
                <div className="main__search">
                  <input
                    type="text"
                    className="search__input"
                    value={querySearch}
                    onChange={(e) => {
                      setquerySearch(e.target.value);
                    }}
                  ></input>
                </div>
                <button
                  style={{
                    border: "none",
                    padding: "3px",
                    backgroundColor: "#041c3c"
                  }}
                  onClick={handleSearch}>
                  <MdOutlineSearch
                    style={{
                      color: "#fff",
                      fontSize: "25px",
                    }}
                  />
                </button>
                <button
                  onClick={resetSearch}>
                  <IoIosRefresh
                    style={{
                      color: "#041c3c",
                      fontSize: "25px",
                    }}
                  ></IoIosRefresh>
                </button>
              </div>
            </div>
          
          <div>
            <div>
            <button type="button"
              className="btn"
              style={{
                backgroundColor: "#7DD657",
                color: "#fff",
                fontSize: "12px",
              }} onClick={toggle}>
              Add New Category
            </button>
            </div>
          </div>
          </div>
        
      
      <br />
      <div style={{ height: "100%", width: "100%" }}>
        {payer.length !== 0 ? (
          <div>
            {searchtext === "" ? (
              <div
                style={{
                  marginLeft: "1%",
                  marginRight: "4%",
                }}
              >
                <Table bordered
                  className="StandardTable"
                >
                  <thead>
                    <tr
                      className="table-primary"
                      style={{ textAlign: "center" }}
                    >
                      <th className="text-center">Category ID</th>
                      <th>Claim Followup Days</th>
                      <th>Category Name</th>
                      <th>Description</th>
                      <th>Billing Provider</th>
                      <th>Adjustment%</th>
                      <th>Write Off%</th>
                      <th>Pre Auth</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {rows.map((row) => ( */}
                    {payer &&
                      payer.map((data, index) => (
                        <tr key={data._id}>
                          <td
                            className="text-center"
                            component="th"
                            scope="row"
                          >
                            {data.category_id}
                          </td>
                          <td
                            className="text-center"
                            component="th"
                            scope="row"
                          >
                            {data.claimFollowupDays}
                          </td>
                          <td className="text-center">
                            {data.category_name}
                          </td>
                          <td className="text-center">
                            {data.description}
                          </td>
                          <td className="text-center">
                            {data.billing_provider}
                          </td>
                          <td className="text-center">
                            {data.insurance_adjustment}
                          </td>
                          <td className="text-center">{data.write_off}</td>
                          <td className="text-center">
                            {data.preauthorization}
                          </td>
                          <td className="text-center">{data.status}</td>
                          <td className="text-center">
                            <button
                              className="Edit"
                              onClick={() => {
                                { setdataforupdate(data); }
                                settingupdatestate();
                                toggleupdate();
                              }}
                            >
                              Edit
                            </button>
                            &nbsp;
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                  <ReactPaginate
                    previousLabel={<IoIosArrowBack />}
                    nextLabel={<IoIosArrowForward />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            ) : (
              <div>
                {searchdataresponse.length === 0 ? (
                  <div>
                    <p align="center">No data found</p>
                  </div>
                ) : (
                  <div
                    style={{
                      marginLeft: "4%",
                      marginRight: "4%",
                    }}
                  >
                    <Table bordered striped>
                      <thead>
                        <tr className="table-primary">
                          <td>Category ID</td>
                          <td>Category Name</td>
                          <td>Description</td>
                          <td>Billing Provider</td>
                          <td>Adjustment%</td>
                          <td>Write Off%</td>
                          <td>Pre Auth</td>
                          <td>Status</td>
                          <td>Action</td>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {rows.map((row) => ( */}
                        {searchclaimdata &&
                          searchclaimdata.map((data, index) => (
                            <tr key={data._id}>
                              <td component="th" scope="row">
                                {data.category_id}
                              </td>
                              <td>{data.category_name}</td>
                              <td>{data.description}</td>
                              <td>{data.billing_provider}</td>
                              <td>{data.insurance_adjustment}</td>
                              <td>{data.write_off}</td>
                              <td>{data.preauthorization}</td>
                              <td>{data.status}</td>
                              <td>
                                <button
                                  className="Edit"
                                  onClick={() => {
                                    { setdataforupdate(data); }
                                    settingupdatestate();
                                    toggleupdate();
                                  }}
                                >
                                  Edit
                                </button>
                                &nbsp;
                                <button
                                  className="Delete"
                                  onClick={() => {
                                    { setsingleid(data._id) }
                                    toggledelete();
                                  }}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                            // ))}
                          ))}
                      </tbody>
                    </Table>
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick1}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
        )}
      </div>
      {modal && (
        <Modal
          style={{ width: "50%" }}
          isOpen={modal}
          onHide={toggle}
          onExit={reset1}
          size="lg"
          centered
        >
          <ModalHeader toggle={toggle}>
            Create New Payer Category
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="input" className="required">Category ID</Label>
                    <Input
                      type="text"
                      required
                      value={categoryId}
                      onChange={(e) => {
                        { setcategoryId(e.target.value) }
                        { setidErr("") }
                      }}
                    />
                    <div style={{ fontSize: 16, color: "red" }}>
                      {idErr}
                    </div>
                    {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.categoryIdErr}
                        </div> */}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="required">Category Name</Label>
                    <Input
                      type="text"
                      required
                      value={name}
                      onChange={(e) => {
                        { setname(e.target.value) }
                        { setnameErr("") }
                      }
                      }
                    />
                    <div style={{ fontSize: 16, color: "red" }}>
                      {nameErr}
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <div>
                    <FormGroup>
                      <Label htmlFor="numberDropdown" className="required">
                        Claim Followup Days
                      </Label>
                      <Input

                        type="select"
                        id="numberDropdown"
                        value={dayscount}
                        onChange={(e) => {
                          { setdayscount(e.target.value) }
                          { setdaysErr("") }
                        }
                        }
                      >
                        <option value="" disabled selected>
                          -- Select --
                        </option>
                        {numbers.map((number) => (
                          <option key={number} value={number}>
                            {number}
                          </option>
                        ))}
                      </Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {daysErr}
                      </div>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Description</Label>
                    <Input
                      type="text"
                      required
                      value={description}
                      onChange={(e) => {
                        { setdescription(e.target.value) }
                        { setdescriptionErr("") }
                      }
                      }
                    />
                    {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.descriptionErr}
                        </div> */}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Billing Provider</Label>
                    <Input
                      type="text"
                      required
                      value={provider}
                      onChange={(e) => {
                        { setprovider(e.target.value) }
                        { setproviderErr("") }
                      }
                      }
                    />
                    {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.providerErr}
                        </div> */}
                  </FormGroup>
                </Col>
              </Row>
              <br />

              <Row>
                <Col>
                  <FormGroup>
                    <Label>Adjustment</Label>
                    <Input
                      className="arrowchange"
                      type="number"
                      required
                      value={insurance}
                      onChange={(e) => {
                        { setinsurance(e.target.value) }
                        { setinsuranceErr("") }
                      }
                      }
                    />
                    {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.insuranceErr}
                        </div> */}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Write Off</Label>
                    <Input
                      type="number"
                      required
                      value={write}
                      onChange={(e) => {
                        { setwrite(e.target.value) }
                        { setwriteErr("") }
                      }
                      }
                    />
                    {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.writeErr}
                        </div> */}
                  </FormGroup>
                </Col>
              </Row>
              <br />

              <Row>
                <Col>
                  <FormGroup>
                    <Label className="required">Preauthorization</Label>

                    <Input
                      type="select"
                      value={authorization}
                      onChange={(e) => {
                        { setauthorization(e.target.value) }
                        { setauthErr("") }
                      }
                      }
                    >
                      <option value="" disabled selected>
                        --select--
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Input>
                    <div style={{ fontSize: 16, color: "red" }}>
                      {authErr}
                    </div>
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <Label className="required">Status</Label>
                    <Input
                      type="select"
                      className="form-control form-select"
                      value={status}
                      onChange={(e) => {
                        { setstatus(e.target.value) }
                        { setstatusErr("") }
                      }
                      }
                    >
                      <option value="" disabled selected>
                        --select--
                      </option>
                      <option value="Enabled">Enabled</option>
                      <option value="Disabled">Disabled</option>
                    </Input>
                    <div style={{ fontSize: 16, color: "red" }}>
                      {statusErr}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                toggle();
                reset1();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={handleSubmit}
            >
              Create
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {updatemodal && (
        <Modal
          style={{ width: "50%" }}
          isOpen={updatemodal}
          onHide={toggleupdate}
          onExit={reset1}
          size="lg"
          centered
        >
          <ModalHeader toggle={toggleupdate}>
            Update Category
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="required">Category ID</Label>
                    <Input
                      disabled
                      type="text"
                      required
                      value={updatecategoryId}
                      onChange={(e) => {
                        { setupdatecategoryId(e.target.value) }
                        { setidErr("") }
                      }
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Category Name</Label>
                    <Input
                      type="text"
                      required
                      value={updatename}
                      onChange={(e) => {
                        { setupdatename(e.target.value) }
                        { setnameErr("") }
                      }
                      }
                    />
                    <div style={{ fontSize: 16, color: "red" }}>
                      {nameErr}
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <div>
                    <FormGroup>
                      <Label htmlFor="numberDropdown" className="required">
                        Claim Followup Days
                      </Label>
                      <Input
                        type="select"
                        id="numberDropdown"
                        value={updatedayscount}
                        // defaultValue={this.state.updatedayscount}
                        onChange={(e) => {
                          { setupdatedayscount(e.target.value) }
                          { setdaysErr("") }
                        }
                        }
                      >
                        <option value="" disabled selected>
                          -- Select --
                        </option>
                        {numbers.map((number) => (
                          <option key={number} value={number}>
                            {number}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              <br />

              <Row>
                <Col>
                  <FormGroup>
                    <Label>Description</Label>
                    <Input
                      type="text"
                      required
                      value={updatedescription}
                      onChange={(e) => {
                        { setupdatedescription(e.target.value) }
                      }
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Billing Provider</Label>
                    <Input
                      type="text"
                      required
                      value={updateprovider}
                      onChange={(e) => {
                        { setupdateprovider(e.target.value) }
                      }
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />

              <Row>
                <Col>
                  <FormGroup>
                    <Label>Adjustment</Label>
                    <Input
                      type="number"
                      required
                      value={updateinsurance}
                      onChange={(e) => {
                        { setupdateinsurance(e.target.value) }
                        { setinsuranceErr("") }
                      }
                      }
                    />
                    <div style={{ fontSize: 16, color: "red" }}>
                      {insuranceErr}
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Write Off</Label>
                    <Input
                      type="number"
                      required
                      value={updatewrite}
                      onChange={(e) => {
                        { setupdatewrite(e.target.value) }
                        { setwriteErr("") }
                      }
                      }
                    />
                    <div style={{ fontSize: 16, color: "red" }}>
                      {writeErr}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <br />

              <Row>
                <Col>
                  <FormGroup>
                    <Label className="required">Preauthorization</Label>

                    <Input
                      type="select"
                      value={updateauthorization}
                      onChange={(e) => {
                        { setauthorization(e.target.value) }
                        { setauthErr("") }
                      }
                      }
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <Label className="required">Status</Label>
                    <Input
                      type="select"
                      className="form-control form-select"
                      value={updatestatus}
                      onChange={(e) => {
                        { setupdatestatus(e.target.value) }
                        { setstatusErr("") }
                      }
                      }
                    >
                      <option value="Enabled">Enabled</option>
                      <option value="Disabled">Disabled</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                toggleupdate();
                reset1();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={() => {
                putrequests(singleid);
              }}
            >
              Update
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <Modal isOpen={createmodal} centered>
        <ModalBody>
          <img
            src={loading}
            style={{ width: "200px", height: "200px", marginLeft: "30%" }}
          />
        </ModalBody>
      </Modal>
      {deletemodal && (
            <Modal
              isOpen={deletemodal}
              onHide={toggledelete}
              // onExit={this.reloadpage}
              centered
            >
              <ModalHeader toggle={toggledelete}>
                Delete Payer Category
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={toggledelete}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    deleterequests(singleid);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
    </div>
    </div>
    </div>
  );
}
export default PayerCategory;