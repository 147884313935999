import React, { useState, useEffect } from "react";
import { ip } from "../../../../Api";
import axios from "axios";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

const data2 = [
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};

const PayerCategoryCharts = (props) => {
  const [deptWise, setDeptWise] = useState([]);
  const [accessToken, setAccessToken] = useState(
    sessionStorage.getItem("accesstoken")
  );
  const refreshToken = sessionStorage.getItem("refreshtoken");

  const renewAccessToken = async () => {
    try {
      const renewHeaders = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`,
      };
      const response = await axios.get(`${ip}/auth/renewAccessToken`, {
        headers: renewHeaders,
      });
      sessionStorage.setItem("accesstoken", response.data.accessToken);
      setAccessToken(response.data.accessToken);
    } catch (error) {
      console.error("Error renewing access token:", error);
    }
  };

  const getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    let url = "";
    if (props.client) {
      if (props.Location) {
        if (props.from && props.to) {
          url = `${ip}/outcome/payerwise?start_date=${props.from}&end_date=${props.to}&clientId=${props.client}&Location=${props.Location}`;
        } else {
          url = `${ip}/outcome/payerwise?clientId=${props.client}&Location=${props.Location}`;
        }
      } else {
        if (props.from && props.to) {
          url = `${ip}/outcome/payerwise?start_date=${props.from}&end_date=${props.to}&clientId=${props.client}`;
        } else {
          url = `${ip}/outcome/payerwise?clientId=${props.client}`;
        }
      }
    } else {
      if (props.from && props.to) {
        url = `${ip}/outcome/payerwise?start_date=${props.from}&end_date=${props.to}`;
      } else {
        url = `${ip}/outcome/payerwise`;
      }
    }

    try {
      const req = await axios.get(url, { headers: headers });
      let data = [];
      if (req.data.payer_wise.denied.length !== 0) {
        for (let i = 0; i < 5; i++) {
          data.push(req.data.payer_wise.denied[i]);
        }
        setDeptWise(data);
      } else {
        setDeptWise([]); 
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getDetails();
  }, [props.from, props.to, props.client, props.Location, accessToken]);

  return (
    <div>
      {props.payerwise === "$" && (
        <div>
          <ComposedChart
            width={700}
            height={400}
            data={deptWise}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          >
            <XAxis
              dataKey={"_id"}
              height={120}
              interval={0}
              angle={"-45"}
              minTickGap={6}
              tick={{
                strokeWidth: 0,
                fontSize: "11",
                top: 400,
                textAnchor: "end",
                dy: 6,
              }}
            />
            <YAxis dataKey={"totalAmount"} />
            <Tooltip content={<CustomTooltip />} />
            <Area
              name="Total Amount"
              type="monotone"
              dataKey={"totalAmount"}
              fill="#8884d8"
              stroke="#8884d8"
            />
            <Bar dataKey={"totalAmount"} barSize={20} fill="#04C671" />
            <Line type="monotone" dataKey={"_id"} stroke="#ff7300" />
          </ComposedChart>
        </div>
      )}
      {props.payerwise === "#" && (
        <div>
          <ComposedChart
            width={700}
            height={400}
            data={deptWise}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          >
            <XAxis
              dataKey={"_id"}
              height={120}
              interval={0}
              angle={"-45"}
              minTickGap={6}
              tick={{
                strokeWidth: 0,
                fontSize: "11",
                top: 400,
                textAnchor: "end",
                dy: 6,
              }}
            />
            <YAxis dataKey={"totalClaims"} />
            <Tooltip content={<ClaimTooltip />} />
            <Area
              name="Total Claims"
              type="monotone"
              dataKey={"totalClaims"}
              fill="#8884d8"
              stroke="#8884d8"
            />
            <Bar dataKey={"totalClaims"} barSize={20} fill="#04C671" />
            <Line type="monotone" dataKey={"_id"} stroke="#ff7300" />
          </ComposedChart>
        </div>
      )}
    </div>
  );
};

export default PayerCategoryCharts;
