import React, { useState } from 'react';
import Newsidebar from '../Dashboard/Newsidebar';
import Arlogs from './Reports/Arlogs';
import Claim from './Reports/Claim';
import Cpt from './Reports/Cpt';
import ClaimwiseProduction from './Reports/ClaimwiseProduction';
import DailyReport from './Reports/DailyReport';
import OpenedClaims from './Reports/OpenedClaims';
import ClosedCpt from './Reports/ClosedCpt';
import CombinedReport from './Reports/CombinedReport';
import '../../Styles/Reports.css';
import documents from "../../Images/documents.png";

const Reports = () => {
  const roleAccess = JSON.parse(sessionStorage.getItem("role_access")) || {};

  const [folders] = useState([
    {
      name: 'Reports',
      subfolders: [
        { name: 'Claimwise Report', component: <Claim />, displayName: 'Claimwise Report', accessKey: 'claimwiseReport' },
        { name: 'Cptwise Report', component: <Cpt />, displayName: 'CPTwise Report', accessKey: 'cptwiseReport' },
        { name: 'Arlogwise Report', component: <Arlogs />, displayName: 'Arlogwise Report', accessKey: 'arlogwiseReport' },
        { name: 'DailyProduction Report', component: <DailyReport />, displayName: 'Daily Production Report', accessKey: 'dailyProductionReport' },
        { name: 'OpenClaims Report', component: <OpenedClaims />, displayName: 'Open Claims Report', accessKey: 'openClaimsReport' },
        { name: 'Claimwise Production', component: <ClaimwiseProduction />, displayName: 'Claimwise Production Report', accessKey: 'claimwiseProductionReport' },
        { name: 'ClosedCpt Report', component: <ClosedCpt />, displayName: 'Closed Cpt Report', accessKey: 'closedCPT' },
        { name: 'Combined Report', component: <CombinedReport />, displayName: 'Combined Report', accessKey: 'combinedReport' },
      ]
    }
  ]);

  const [selectedContent, setSelectedContent] = useState(null);
  const [selectedName, setSelectedName] = useState('');

  const displayContent = (content, name) => {
    setSelectedContent(content);
    setSelectedName(name);
  };

  return (
    <div>
      <Newsidebar name="Generate Reports" />
      <div className='report-layout'>
        <div className='report-sidebar'>
          {folders.map((folder, folderIndex) => (
            <div key={folderIndex}>
              <div className='folder-header'>
                <span>{'📂'}</span>
                <span>{folder.name}</span>
              </div>

              <div className='subfolder-container'>
                {folder.subfolders.map((subfolder, subfolderIndex) => {
                  // Check if the user has access to this subfolder
                  const hasAccess = roleAccess[subfolder.accessKey];
                  return (
                    hasAccess && ( // Render subfolder only if user has access
                      <div key={subfolderIndex}>
                        <div
                          onClick={() => displayContent(subfolder.component, subfolder.displayName)}
                          className='subfolder-header'
                        >
                        <span><img src={documents} alt='doc' width="25px" height="25px" /></span> &nbsp;&nbsp;&nbsp;
                          <span>{subfolder.name}</span>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          ))}
        </div>

        <div className='content-area'>
          {selectedContent ? (
            <div>
              <h3>{selectedName}</h3>
              {selectedContent}
            </div>
          ) : (
            <div>
              <h3>Select a report to view its content</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;
