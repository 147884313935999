import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import "../../Styles/Authorization.css";
import { Row, Col, Card } from "react-bootstrap";
import { Button, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { ip } from "../../Api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auth from "../../Files/Authorization.xlsx";
import upload from "../../Images/upload.png";
import excel from "../../Images/excel.png";
import { IoMdClose } from "react-icons/io";
export default class Authorization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      file: null,
      type: "",
      upload: [],
      fileUploaded: false,
    };
    this.fileInput = React.createRef();
    this.onFileChange = this.onFileChange.bind(this);
    this.clearFile = this.clearFile.bind(this);
  }
  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };
  clearFile() {
    this.setState({ file: null });
  }
  onFileUpload = async (e) => {
    if (!this.state.file) {
      // Display an error message or handle the validation as needed
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    this.setState({ fileUploaded: !this.state.fileUploaded });
    e.preventDefault();
    const value = new FormData();
    value.append("file", this.fileInput.current.files[0]);
    value.append("clientids", JSON.stringify(this.state.client));
    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    await axios({
      method: "post",
      url: `${ip}/authorization/upload`,
      data: value,
      headers: headers,
    })
      .then((response) => {
        //handle success
        if (response.status === 200) {
          this.setState({ fileUploaded: !this.state.fileUploaded });
          toast.success("File uploaded successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        //handle error
        this.setState({ fileUploaded: !this.state.fileUploaded });
        this.getfiles();
        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 400) {
            toast.error("Please include all the fields in excel data", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  
  render() {
    return (
      <div>
      <Newsidebar name="Authorization" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column">
        <ToastContainer></ToastContainer>
          <div>
          <div>
          <input
            type="file"
            id="file"
            ref={this.fileInput}
            onChange={this.onFileChange}
            style={{ display: "none" }}
            accept=".xlsx"
          />
          {this.state.file ? (
            <div className="file-card-upload">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <div className="file-card" style={{ position: "relative" }}>
                    <span>
                      <img
                        src={excel}
                        alt="excel"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </span>
                    <div className="file-info">
                      <div style={{ flex: 1 }}>
                        <h6>{this.state.file.name}</h6>
                      </div>
                    </div>
                    <button
                      className="close-icon"
                      onClick={this.clearFile} // Clear file function
                    >
                      <IoMdClose />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="file-btn"
              onClick={() => this.fileInput.current.click()}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <img
                  src={upload}
                  alt="upload"
                  style={{ width: "90px", height: "90px" }}
                />
                <div>
                  <p style={{ margin: 0 }}>
                    Click file to this area to upload
                  </p>
                  <span style={{ color: "GrayText", fontSize: "11px" }}>
                    The given input should be in .xlsx format
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="upload_container1">
            <div className="upload_head">
              <div className="vertical" />
              <span style={{ fontWeight: "800", fontSize: "18px" }}>
                Bulk Upload History
              </span>
            </div>
            <div
              className="btn-group"
              role="group"
              style={{ boxShadow: "0px 0px 6px rgb(124 119 119 / 50%)" }}
            >
            <Link
              style={{ textDecoration: "none", color: "#a8adad" }}
              download="Authorization.xlsx"
              target="_blank"
              to={Auth}
            >
            <button
            outline
            color="secondary"
            size="sm"
            className="btn btn-danger"
          >
              {" "}
              Sample
          </button>
          </Link>

          <button
          outline
          color="secondary"
          size="sm"
          onClick={this.onFileUpload}
         className="btn"
        >
          Upload
        </button>
            </div>
          </div>
          <hr />
          </div>
        </div>
      </div>
      </div>
    );
  }
}
