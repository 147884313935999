import { Component } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Newsidebar from "./../../Dashboard/Newsidebar.js";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ip } from "../../../Api.js";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const ClaimTooltip = ({ active, payload }) => {
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          {payload[0].payload._id}
          <br />
          <p style={{ color: payload[0].color }}>
            {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
          </p >
          <p style={{ color: payload[1].color }}>
            {payload[1].name}:&nbsp;${payload[1].value.toLocaleString()}
          </p>
          <p style={{ color: payload[2].color }}>
            {payload[2].name}:&nbsp;${payload[2].value.toLocaleString()}
          </p>
        </div>
      );
    }
    return null;
  };
class ARPrimaryRisk extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
    dataarray:[],
    client:sessionStorage.getItem("clientid"),
    denialarray:[],
    ararray:[],
    noararray:[],
    maxarray:[]
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getData= async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
    url = `${ip}/trends/arrisk?clientId=${this.state.client}`
    }
     else {
        url = `${ip}/trends/arrisk`;
      }
    
    const req = axios
      .get(url, { headers: headers })
      
     
      .then((req) => {
        let deniedpayarray=0
        for(let i=0;i<req.data.data.length;i++){
          deniedpayarray=deniedpayarray+req.data.data[i].deniedpayment;
        }
        let ararray = 0
        for(let i=0;i<req.data.data.length;i++){
          ararray=ararray+req.data.data[i].AR
        }
        let noarray = 0
        for(let i=0;i<req.data.data.length;i++){
          noarray=noarray+req.data.data[i].noAR
        }
        let maxarray = [];
        maxarray.push(parseInt(deniedpayarray),parseInt(ararray),parseInt(noarray));
        let maxvalue=Math.max(...maxarray)
        let maxvalue2=Math.ceil(maxvalue/10000)*10000
        this.setState({
          dataarray:req.data.data,
          ararray:ararray,
          noarray:noarray,
          denialarray:deniedpayarray,
          maxarray:maxvalue
        }
        )
      })

      .catch((err) => {});
  };
  componentDidMount(){
    
    this.getData()
  }
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:last-child td, &:last-child th,": {
        border: 1,
      },
    }));
    return (
      <div className="align-row">
        <Newsidebar />
        <div className="align-column">
          <div>
            <div
              style={{
                textAlign: "left",
                marginLeft: "3%",
                marginTop: "2%",
                fontSize: "14px",
              }}
            >
              <p>
                <Link
                  to="/management-trends-patterns"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Trends & Patterns
                </Link>
                &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;AR Risk
                (Primary AR Only)
              </p>
            </div>
            <br />
            <ResponsiveContainer width={"99%"} height={400}>
              <LineChart
                width={1000}
                height={380}
                data={this.state.dataarray}
                margin={{
                  top: 8,
                  right: 20,
                  left: 25,
                  bottom: 5,
                }}
              >
                <XAxis
                  dataKey="_id"
                  padding={{ right: 30 }} 
                />
                <YAxis interval={
                    0
                  }
                  minTickGap="1"
                />
                <Tooltip cursor={true} content={<ClaimTooltip/>}/>
                <Legend
                 wrapperStyle={{
                    top: 390,
                    left: 380,
                    bottom: "300",
                    width: "26vw",
                    border: "1px solid gray",
                  }}
                />

                <Line
                  name="Denied"
                  type="monotone"
                  dataKey="deniedpayment"
                  stroke="rgb(5, 101, 191)"
                  fill="rgb(5, 101, 191)"
                />
                <Line
                  name="No AR Follow-Up"
                  type="monotone"
                  dataKey="noAR"
                  stroke="rgb(247, 73, 105)"
                  fill="rgb(247, 73, 105)"
                />
                <Line
                  name="AR"
                  type="monotone"
                  dataKey="AR"
                  stroke="rgb(84 205 26)"
                  fill="rgb(84 205 26)"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }
}
export default ARPrimaryRisk;
