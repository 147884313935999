import React, { PureComponent } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";
import { ip } from "../../../Api";
import axios from "axios";
let tooltip;
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};
const getPath = (x, y, width, height) => `M${x},${y + height}
    C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
  x + width / 2
}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
  x + width
}, ${y + height}
    Z`;

const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
export default class AvgTouches extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      touch_wise: [],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      UserId: sessionStorage.getItem("user_id"),
      firstname: sessionStorage.getItem("firstname"),
      lastname: sessionStorage.getItem("lastname"),
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    let name = this.state.firstname + " " + this.state.lastname;
    if (this.props.start !== "" && this.props.end !== "") {
      url = `${ip}/userdashboard/avgtouches?claimunder=${name}&start_date=${this.props.start}&end_date=${this.props.end}`;
    } else {
      url = `${ip}/userdashboard/avgtouches?claimunder=${name}`;
    }
    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        this.setState({ touch_wise: req.data.userdetails});
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getDetails();
  }
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.start === prevProps.start && this.props.end === prevProps.end
      )
    ) {
      this.getDetails();
    }
  }
  render() {
    return (
      <div onMouseOver={() => (tooltip = "totalamount")}>
        <BarChart
          width={550}
          height={300}
          data={this.state.touch_wise}
        >
          <XAxis dataKey={"_id"} />
          <YAxis dataKey={"totalamount"} />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={<CustomTooltip />}
          />
          <Bar
            dataKey={"totalamount"}
            shape={<TriangleBar />}
            radius={[16, 16, 16, 16]}
            fill="rgb(197, 56, 233)"
            name="Total Amount"
          />
        </BarChart>
      </div>
    );
  }
}
