import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import "../../../Styles/subdenial.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import { ip } from "../../../Api";
class SubCategory1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.location.state.current,
      pastvalue: this.props.location.state.past,
      searchid: sessionStorage.getItem("searchid"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
      denialsub: sessionStorage.getItem("denialsub"),
      subcategorydenial: this.props.location.state.subcategorydenial,
      subcategorywisedata: null,
      grandtotal1: "",
      grandtotal2: "",
      grandtotal3: "",
      idof1: "",
      idof2: "",
      idof3: "",
    };
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getdata = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryenddate && this.state.querystartdate) {
      url = `${ip}/denial/subcategory/value?${this.state.value}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
    } else {
      url = `${ip}/denial/subcategory/value?${this.state.value}`;
    }
     await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          subcategorywisedata: res.data.data,
          idof1: res.data.data[0]._id,
          idof2: res.data.data[1]._id,
          idof3: res.data.data[2]._id,
          grandtotal1: res.data.data[0].grandcount,
          grandtotal2: res.data.data[1].grandcount,
          grandtotal3: res.data.data[2].grandcount,
        });
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getdata();
  }
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
        padding: "none",
        fontSize: 14,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));
    return (
      <div className="align-row" style={{overflow:"hidden"}}>
        <Newsidebar>
        <p>
        <Link
          to="/denial-management"
          style={{ textDecoration: "none", color: "white" ,fontSize: "15px",
          fontWeight: 500}}
        >
          Denial Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
        <Link
          to="/denial/category"
          style={{ textDecoration: "none", color: "white" ,fontSize: "15px",
          fontWeight: 500}}
        >
          Category
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Sub
        Category
      </p>
        </Newsidebar>
        <div className="align-column">

           <br/>
          <div
            style={{
              height: "100%",
              width: "100%",
              marginTop: "2%",
              marginLeft: "1%",
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Authorization</StyledTableCell>
                    <StyledTableCell>a)0-30 Days</StyledTableCell>
                    <StyledTableCell>c)31-60 Days</StyledTableCell>
                    <StyledTableCell>d)61-90 Days</StyledTableCell>
                    <StyledTableCell>e)91-120 Days</StyledTableCell>
                    <StyledTableCell>f)121-180 Days</StyledTableCell>
                    <StyledTableCell>g)181-365 Days</StyledTableCell>
                    <StyledTableCell>h)Above 1yr</StyledTableCell>
                    <StyledTableCell>Grand Total</StyledTableCell>
                  </TableRow>
                </TableHead>
                {(this.state.grandtotal1 ||
                  this.state.grandtotal2 ||
                  this.state.grandtotal3) === 0 ? (
                  <TableBody>
                    <div class="bot-ui">
                      <div class="bot-head">
                        <div class="eyes-container">
                          <div class="to-left eye"></div>
                          <div class="to-right eye"></div>
                        </div>
                      </div>
                      <div class="bot-body">
                        <div class="analyzer"></div>
                      </div>
                      <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                        No Data Found
                      </p>
                    </div>
                  </TableBody>
                ) : (
                  <TableBody>
                    {this.state.grandtotal1 === 0 ? (
                      ""
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[0]._id}
                        </StyledTableCell>

                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof1 &&
                              this.state.denialsub === "0-30days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                    <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof1 &&
                              this.state.denialsub === "31-60days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof1 &&
                              this.state.denialsub === "61-90days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>

                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof1 &&
                              this.state.denialsub === "91-120days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof1 &&
                              this.state.denialsub === "121-180days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof1 &&
                              this.state.denialsub === "181-365days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof1 &&
                              this.state.denialsub === "above 1year"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[0].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal2 === 0 ? (
                      ""
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[1]._id}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof2 &&
                              this.state.denialsub === "0-30days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[1]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof2 &&
                              this.state.denialsub === "31-60days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof2 &&
                              this.state.denialsub === "61-90days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof2 &&
                              this.state.denialsub === "91-120days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof2 &&
                              this.state.denialsub === "121-180days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof2 &&
                              this.state.denialsub === "181-365days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof2 &&
                              this.state.denialsub === "above 1year"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[1].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal3 === 0 ? (
                      ""
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[2]._id}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof3 &&
                              this.state.denialsub === "0-30days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof3 &&
                              this.state.denialsub === "31-60days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof3 &&
                              this.state.denialsub === "61-90days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof3 &&
                              this.state.denialsub === "91-120days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof3 &&
                              this.state.denialsub === "121-180days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof3 &&
                              this.state.denialsub === "181-365days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof3 &&
                              this.state.denialsub === "above 1year"
                                ? "e-card"
                                : "thirdcard"
                            }
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[2].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }
}
export default SubCategory1;
