import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody,Input , Button} from "reactstrap";
import { Spinner } from "react-bootstrap";
import { ip } from "../../../Api";
import axios from "axios";
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from "react-icons/bs";
import moment from "moment-timezone";
import { DatePicker } from 'antd';


const ClaimwiseProduction = () => {
  const [fetchingclaimwise, setFetchingClaimwise] = useState(true);
  const [refreshtoken, setRefreshToken] = useState(sessionStorage.getItem("refreshtoken"));
  const [accesstoken, setAccessToken] = useState(sessionStorage.getItem("accesstoken"));
  const [client] = useState(sessionStorage.getItem("clientid"));
  const [currentpage4, setCurrentPage4] = useState(0);
  const [perPage4] = useState(10);
  const [claimwise, setClaimwise] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonsize, setButtonSize] = useState(0);
  const [download, setDownload] = useState(false);
  const [countofarlog, setCountOfArlog] = useState("");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [filterstatus, setFilterStatus] = useState("custom");
  const downloaddata = () => setDownload(!download);
  const { RangePicker } = DatePicker;

  const getcount = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    let url;
    if (startdate && enddate) {
      url = `${ip}/report/getcounts?clientId=${client}&feature=dailyproductionreportclaimwise&start_date=${startdate}&end_date=${enddate}`;
    } else {
      url = `${ip}/report/getcounts?clientId=${client}&feature=dailyproductionreportclaimwise`;
    }

    axios.get(url, { headers }).then((res) => {
      setCountOfArlog(Math.ceil(res.data.count / 500));
    });
  }, [accesstoken, client, startdate, enddate]);

  const getdownloadbutton = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    let url;
    let filename;
    if (startdate && enddate) {
      filename = `Claimwise_Production_${moment(startdate).format("MM/DD/YYYY")}_${moment(enddate).format("MM/DD/YYYY")}`;
      url = `${ip}/report/dailyreportclaimwise?start_date=${startdate}&end_date=${enddate}&clientId=${client}&button=${buttonsize}`;
    } else {
      filename = "Claimwise_Production";
      url = `${ip}/report/dailyreportclaimwise?clientId=${client}&button=${buttonsize}`;
    }

    fetch(url, { headers }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        a.click();
        URL.revokeObjectURL(a);
        setLoading(false);
      });
    });
  }, [accesstoken, client, startdate, enddate, buttonsize]);

  const increment = () => {
    if (buttonsize < countofarlog - 1) {
      setButtonSize(buttonsize + 1);
      getdownloadbutton();
    }
  };

  const decrement = () => {
    if (buttonsize - 1 >= 0) {
      setButtonSize(buttonsize - 1);
    } else {
      alert("No data here so please click next arrow");
      setButtonSize(0);
    }
  };

  const getclaimwise = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    let url;
    if (startdate && enddate) {
      url = `${ip}/report/dailyreportclaimwise?start_date=${startdate}&end_date=${enddate}&clientId=${client}&pageno=${currentpage4}`;
    } else {
      url = `${ip}/report/dailyreportclaimwise?clientId=${client}&pageno=${currentpage4}`;
    }

    axios.get(url, { headers }).then((res) => {
      setClaimwise(res.data.data);
      setFetchingClaimwise(false);
    });
  }, [accesstoken, client, currentpage4, startdate, enddate]);

  useEffect(() => {
    getclaimwise();
    getcount();
  }, [getclaimwise, getcount]);
  const valuedate = (option, e) => {
    if (option === "yesterday") {
        setStartDate(e.target.value);
        setEndDate(moment().subtract(1, "days").format().substring(0, 16));
    } else {
            setStartDate(e.target.value);
            setEndDate(moment().format().substring(0, 16));
          }
        };
  useEffect(() => {
    getclaimwise();
  }, [startdate,enddate, getclaimwise]);

  return (
    <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
    <Row className="align-items-center">
    <Col md={6} xs={12} className="d-flex">
                <Input
                type="select"
                style={{ width: "30%", marginTop: "1%" }}
                className="form-control form-select"
                onChange={(e) => {
                  const index = e.target.selectedIndex;
                  const el = e.target.childNodes[index];
                  const option = el.getAttribute("title");
            
                  if (option === "custom") {
                    setFilterStatus("custom");
                  } else {
                    setFilterStatus("");
                  }
            
                  valuedate(option, e); 
                }}
              >
                  <option value="" title="custom">
                    Custom
                  </option>
                  <option value={moment().format().substring(0, 16)} title="">
                    Today
                  </option>
                  <option
                    value={moment().subtract(1, "days").format().substring(0, 16)}
                    title="yesterday"
                  >
                    Yesterday
                  </option>
                  <option
                    value={moment().subtract(3, "days").format().substring(0, 16)}
                    title=""
                  >
                    Last 3 days
                  </option>
                  <option
                    value={moment().subtract(7, "days").format().substring(0, 16)}
                    title=""
                  >
                    Last 7 days
                  </option>
                  <option
                    value={moment().subtract(30, "days").format().substring(0, 16)}
                    title=""
                  >
                    Last 30 days
                  </option>
                  <option
                    value={moment().subtract(365, "days").format().substring(0, 16)}
                    title=""
                  >
                    Last 365 days
                  </option>
                </Input> &nbsp;&nbsp;
                {filterstatus === "custom" && (
                  <RangePicker
                  showTime={{
                    format: 'HH:mm',
                  }}
                  format="YYYY-MM-DD HH:mm"
                    onChange={(dates) => {
                      if (dates) {
                        setStartDate(dates[0].format().substring(0, 16));
                        setEndDate(dates[1].format().substring(0, 16));
                      } else {
                        setStartDate("");
                        setEndDate("");
                      }
                    }}
                    width="200%"
                    style={{ borderColor: "gray",  marginTop: "1%" }}
                  />
                )}
              </Col>
              <Col md={6} xs={12} className="text-md-right mt-2 mt-md-0">
              <Button
              style={{
                background:"#002755",
                color:"#fff"
             }}
              onClick={() => {
                downloaddata();
                getdownloadbutton();
              }}
            >
              Generate Report
            </Button>
    </Col>
    </Row>
      {download && (
        <Modal style={{ width: "30%" }} isOpen={download} onHide={downloaddata} size="md" centered>
          <ModalHeader toggle={downloaddata}>Download</ModalHeader>
          <ModalBody style={{ "max-height": "calc(100vh - 210px)", "overflow-y": "auto" }}>
            <Row style={{ margin: "0px" }}>
              <Col>
                <div id="container">
                  <BsFillArrowLeftCircleFill
                    style={{ fontSize: "183%" }}
                    onClick={loading ? null : decrement}
                  />
                  {loading ? (
                    <button
                      style={{
                        background: "green",
                        backgroundColor: "green",
                        borderRadius: "6px",
                        border: "3px solid green",
                        display: "inline-block",
                        cursor: "pointer",
                        color: "#ffffff",
                        fontSize: "15px",
                        fontWeight: "bold",
                        textDecoration: "none",
                        width: "45%",
                        marginLeft: "20%",
                      }}
                    >
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      &nbsp;&nbsp;Loading data...{buttonsize + 1}
                    </button>
                  ) : (
                    <button
                      style={{
                        background: "Black",
                        backgroundColor: "Black",
                        borderRadius: "6px",
                        border: "3px solid Black",
                        display: "inline-block",
                        cursor: "pointer",
                        color: "#ffffff",
                        fontSize: "15px",
                        fontWeight: "bold",
                        width: "42%",
                        marginLeft: "20%",
                      }}
                      onClick={increment}
                    >
                      Report {buttonsize + 1}
                    </button>
                  )}
                  <BsFillArrowRightCircleFill
                    style={{ marginLeft: "49px", fontSize: "183%" }}
                    onClick={loading ? null : increment}
                  />
                </div>
              </Col>
            </Row>
            <br />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default ClaimwiseProduction;
