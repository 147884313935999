import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ip } from "../../../../Api";
import axios from "axios";

const data2 = [
  { _id: "", totalAmount: 0, totalClaims: 0 },
  { _id: "", totalAmount: 0, totalClaims: 0 },
  { _id: "", totalAmount: 0, totalClaims: 0 },
  { _id: "", totalAmount: 0, totalClaims: 0 },
  { _id: "", totalAmount: 0, totalClaims: 0 },
];

const getPath = (x, y, width, height) => `M${x},${y + height}
  C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
  x + width / 2
}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
  x + width
}, ${y + height}
  Z`;

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};

const DeptCharts = ({ client, Location, from, to, deptwise }) => {
  const [payerClassWise, setPayerClassWise] = useState([]);
  const [accesstoken, setAccessToken] = useState(sessionStorage.getItem("accesstoken"));
  const refreshtoken = sessionStorage.getItem("refreshtoken");

  const renewAccessToken = async () => {
    const renewHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshtoken}`,
    };
    try {
      const response = await axios.get(`${ip}/auth/renewAccessToken`, {
        headers: renewHeaders,
      });
      sessionStorage.setItem("accesstoken", response.data.accessToken);
      setAccessToken(response.data.accessToken);
    } catch (error) {
      console.error("Failed to renew access token", error);
    }
  };

  const getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };

    let url = `${ip}/outcome/payerclasswise`;
    if (client) {
      url += Location
        ? from && to
          ? `?start_date=${from}&end_date=${to}&clientId=${client}&Location=${Location}`
          : `?clientId=${client}&Location=${Location}`
        : from && to
        ? `?start_date=${from}&end_date=${to}&clientId=${client}`
        : `?clientId=${client}`;
    } else if (from && to) {
      url += `?start_date=${from}&end_date=${to}`;
    }

    try {
      const response = await axios.get(url, { headers });
      const data = response.data.payerclass_wise.denied.length
        ? response.data.payerclass_wise.denied.slice(0, 5)
        : data2;
      setPayerClassWise(data);
    } catch (error) {
      console.error("Failed to fetch details", error);
      setPayerClassWise(data2);
    }
  };

  useEffect(() => {
    getDetails();
  }, [client, Location, from, to, accesstoken]);

  return (
    <div>
      {deptwise === "$" && (
        <div>
          <BarChart
            width={700}
            height={400}
            data={payerClassWise}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="_id"
              height={120}
              interval={0}
              angle={-45}
              minTickGap={6}
              tick={{ strokeWidth: 0, fontSize: "11", textAnchor: "end", dy: 6 }}
            />
            <YAxis dataKey="totalAmount" />
            <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
            <Bar
              name="Total Amount"
              dataKey="totalAmount"
              shape={<TriangleBar />}
              radius={[16, 16, 16, 16]}
              fill="#00A0FC"
              barSize={30}
            />
          </BarChart>
        </div>
      )}
      {deptwise === "#" && (
        <div>
          <BarChart
            width={700}
            height={400}
            data={payerClassWise}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="_id"
              height={120}
              interval={0}
              angle={-45}
              minTickGap={6}
              tick={{ strokeWidth: 0, fontSize: "11", textAnchor: "end", dy: 6 }}
            />
            <YAxis dataKey="totalClaims" />
            <Tooltip content={<ClaimTooltip />} cursor={{ fill: "transparent" }} />
            <Bar
              name="Total Claims"
              dataKey="totalClaims"
              shape={<TriangleBar />}
              radius={[16, 16, 16, 16]}
              fill="#00A0FC"
              barSize={30}
            />
          </BarChart>
        </div>
      )}
    </div>
  );
};

export default DeptCharts;
