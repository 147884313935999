import React, { Component } from "react";
import axios from "axios";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import Tooltip from "@mui/material/Tooltip";
import { Icon } from "@iconify/react";
import ReactPaginate from "react-paginate";
import { AiOutlineSearch } from "react-icons/ai";
import { Typeahead } from "react-bootstrap-typeahead";
import eicon from "../../Images/eicon.jpg";
import "../../Styles/loader.scss";
import { ToastContainer, toast } from "react-toastify";
import { DatePicker } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { BiDownload } from "react-icons/bi";
import moment from "moment";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { IoRefreshOutline } from "react-icons/io5";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
import searchresult from "../../Images/searchresult.jpg";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "react-toastify/dist/ReactToastify.css";
import { Col, Input } from "reactstrap";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { Card, Row } from "react-bootstrap";
import { ip } from "../../Api";
import Viewmoreeligibility from "./Viewmoreeligibility";
const { RangePicker } = DatePicker;
const Option = (props) => {};

const options = [
  { value: "PatientFirstName", label: "First Name" },
  { value: "PatientLastName", label: "Last Name" },
  { value: "patientAccountNumber", label: "Account Number" },
  { value: "PatientDOB", label: "Date Of Birth" },
  { value: "PrimaryPolicyNo", label: "Primary Policy No" },
  { value: "InsuranceName", label: "Insurance Name" },
  { value: "type", label: "type" },
  { value: "status", label: "status" },
];
class OutcomeData extends Component {
  constructor(props) {
    super(props);
    this.typeaheadRef = React.createRef();
    this.state = {
      optionsarray: [
        { value: "PatientFirstName", label: "First Name" },
        { value: "PatientLastName", label: "Last Name" },
        { value: "patientAccountNumber", label: "Account Number" },
        { value: "PatientDOB", label: "Date Of Birth" },
        { value: "PrimaryPolicyNo", label: "Primary Policy No" },
        { value: "InsuranceName", label: "Insurance Name" },
        { value: "type", label: "Type" },
        { value: "status", label: "Status" },
      ],
      modal: false,
      modalunallocate: false,
      servicetypevalue: "",
      payerselect: "",
      billing: "",
      doioptionSelected: [],
      modalunallocate1: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      PatientFirstName: "",
      PatientLastName: "",
      claimsdata: [],
      reports: [],
      fetching: true,
      pasteligibilityworked: "",
      planstatus: "",
      pasteligistatus: "",
      ar: [],
      loading: true,
      any: [],
      typefor: "",
      statuslist: [],
      ServiceType: [],
      tabindex: 1,
      PatientFirstName: [],
      PatientLastName: [],
      selectuserErr: "",
      selectuserErr2: "",
      selectuserErr3: "",
      patientdobnew: moment().format("YYYY-MM-DD"),
      patientAccountNumber: "",
      searchtext: "",
      searchclaimdata: [],
      activeTab: "Web",
      cptdata: [],
      status: [],
      optionSelected: [],
      username: "",
      claimfield: "",
      user: "",
      claimvalue: "",
      Ivrdata: [],
      claimtype: "",
      coveragestatus: "",
      firstnamevalue: "",
      Eligibilityclaimid: "",
      lastnamevalue: "",
      payervalue: [],
      cptvalue: [],
      userdetails: [],
      query: [],
      outcomedata: false,
      Types: "Web",
      loadingextensive: true,
      manualupdate: false,
      Realtime: false,
      Calling: false,
      manual: false,
      ids: [],
      offset: 0,
      currentpage: 0,
      currentpagesearch: 0,
      perPage: 20,
      checkedAll: false,
      alldataresponse: [],
      searchdataresponse: [],
      selecteduser: [],
      users: [],
      user_id: sessionStorage.getItem("user_id"),
      role: sessionStorage.getItem("role_type"),
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
      clientslist: [],
      opendropdown: false,
      Location: "",
      locationslist: [],
      count: 10,
      agegrplist: [
        "0-30days",
        "31-60days",
        "61-90days",
        "91-120days",
        "121-180days",
        "181-365days",
        "above 1year",
      ],
      extensive: [],
      anchorEl: null,
      download: false,
      doiagegrplist: [
        "0-30days",
        "31-60days",
        "61-90days",
        "91-120days",
        "121-180days",
        "181-365days",
        "above 1year",
      ],
      userstatuslist: [],
      userstatus: "",
      agegrp: "",
      loadingpast: true,
      doiagegrp: "",
      unallocateid: "",
      sortstate: "ASC",
      openicon: false,
      claimids: [],
      pasteligibility: [],
      payerid: "",
      orgname: "",
      npi: "",
      memberid: "",
      loadingtable: true,
      firstname: "",
      lastname: "",
      gender: "",
      dob: "",
      dos: "",
      beginingdos: "",
      enddos: "",
      clientideli: "",
      insurname: "",
      _idvalue: "",
      status: "",
      claimfieldarray: [],
      planBeginfromInNetwork: "",
      planBegintoInNetwork: "",
      copayInNetwork: "",
      coinsuranceInNetwork: "",
      annualDeductibleTotalInNetwork: "",
      annualDeductibleMetInNetwork: "",
      annualDeductibleOpenInNetwork: "",
      unitsTotalInNetwork: "",
      unitsMetInNetwork: "",
      unitsOpenInNetwork: "",
      serviceAllowedAmountTotalInNetwork: "",
      serviceAllowedAmountMetInNetwork: "",
      serviceAllowedAmountOpenInNetwork: "",
      planBeginfromOutofNetwork: "",
      planBegintoOutofNetwork: "",
      copayOutofNetwork: "",
      coinsuranceOutofNetwork: "",
      annualDeductibleTotalOutofNetwork: "",
      annualDeductibleMetOutofNetwork: "",
      annualDeductibleOpenOutofNetwork: "",
      unitsTotalOutofNetwork: "",
      unitsMetOutofNetwork: "",
      unitsOpenOutofNetwork: "",
      serviceAllowedAmountTotalOutofNetwork: "",
      serviceAllowedAmountMetOutofNetwork: "",
      serviceAllowedAmountOpenOutofNetwork: "",
      plancode: "",
      loc: "",
      physician: "",
      mrn: "",
      patientno: "",
      filterstatus: "custom",
      valueOfInput1: "",
      valueOfInput2: "",
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.reset = this.reset.bind(this);
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  toggle1 = () => {
    const { modalunallocate } = this.state;
    this.setState({ modalunallocate: !modalunallocate });
  };
  downloaddata = () => {
    const { download } = this.state;
    this.setState({ download: !download });
  };
  toggle2 = () => {
    const { modalunallocate1 } = this.state;
    this.setState({ modalunallocate1: !modalunallocate1 });
  };
  toggle3 = () => {
    this.setState(() => {
      this.setState({ outcomedata: !this.state.outcomedata });
    });
  };
  toggle4 = () => {
    this.setState(() => {
      this.setState({ manualupdate: !this.state.manualupdate });
    });
  };
  toggleweb = () => {
    this.setState(() => {
      this.setState({ Realtime: !this.state.Realtime });
    });
  };
  toggleivr = () => {
    this.setState(() => {
      this.setState({ Calling: !this.state.Calling });
    });
  };
  togglemanual = () => {
    this.setState(() => {
      this.setState({ manual: !this.state.manual });
    });
  };

  handleChange = (selected) => {
    this.setState({
      optionSelected: selected,
    });
  };
  doihandleChange = (selected) => {
    this.setState({
      doioptionSelected: selected,
    });
  };
  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null });
  handleClose1 = () => this.setState({ anchorEl: null });

  toggledropdown = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };

  getserviceid = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = await axios
      .get(`${ip}/servicetype/all`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          ServiceType: res.data.ServiceType,
        });
      })
      .catch((err) => {});
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.gettable();
      }
    );
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //get Location
  getLocation = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      url = `${ip}/location/activelocations?clientId=${this.state.client}`;
    } else {
      url = `${ip}/location/activelocations`;
    }
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        let data = res.data.location;
        data = data.filter((item) => {
          return item.clientId == this.state.client;
        });
        data.sort((a, b) => {
          return a.Location < b.Location ? -1 : 1;
        });

        this.setState({ locationslist: data });
      })

      .catch((err) => {});
  };
  handleTabClick = (type) => {
    this.setState({ activeTab: type }, () => {
      this.gettable();
    });
  };
  gettable = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // let name = this.state.firstname + " " + this.state.lastname;
    // let dobdate = moment(this.state.patientdob).format("MM/DD/YYYY");
    const res = axios
      .get(
        `${ip}/neweligibility/all?type=${this.state.activeTab}&clientId=${this.state.client}&pageno=${this.state.currentpage}&count=${this.state.perPage}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState(
          {
            Ivrdata: res.data.eligibility,
            lastnamevalue: res.data.eligibility,
            firstnamevalue: res.data.eligibility,
            fetching: false,
            loadingtable: false,
            pageCount: Math.ceil(res.data.count / this.state.perPage),
          }
        );
      })
      .catch((err) => {});
  };

  getdownloadfile = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    fetch(
      `${ip}/neweligibility/all?type=${this.state.activeTab}&clientId=${this.state.client}`,
      { headers: headers }
    ).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "Eligibility.csv";
        a.click();
        URL.revokeObjectURL(a);
      });
    });
  };

  getpasteligibility = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // let name = this.state.firstname + " " + this.state.lastname;
    const res = axios
      .get(
        `${ip}/neweligibility/pasteligibility?PatientLastName=${this.state.lastnamevalue}&PatientFirstName=${this.state.firstnamevalue}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          pasteligibility: res.data.eligibility,
          pageCount: Math.ceil(res.data.count / this.state.perPage),

          loadingpast: false,
        });
        if (res.data.eligibility[0].data.benefitsInformation[0].length !== 0) {
          this.setState({
            pasteligibilityworked:
              res.data.eligibility[0].data.benefitsInformation[0]
                .serviceTypeCodes,
          });
        } else {
          this.setState({ pasteligibilityworked: 0 });
        }

        if (res.data.eligibility[0].data.benefitsInformation[0].length !== 0) {
          this.setState({
            pasteligistatus:
              res.data.eligibility[0].data.benefitsInformation[0].serviceTypes,
          });
        } else {
          this.setState({ pasteligistatus: 0 });
        }
      })
      .catch((err) => {});
  };

  checkeligibility = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const Checkdata = {
      PayerID: this.state.payerid,
      organizationName: this.state.orgname,
      Npi: this.state.npi,
      MemberID: this.state.memberid,
      FirstName: this.state.firstname,
      LastName: this.state.lastname,
      Gender: this.state.gender,
      DOB: this.state.dob,
      DOS: this.state.dos,
      BeginingDOS: this.state.beginingdos,
      EndDOS: this.state.enddos,
      clientId: this.state.clientideli,
      InsuranceName: this.state.insurname,
      PlanCode: this.state.plancode,
      Location: this.state.loc,
      Physician: this.state.physician,
      MRN: this.state.mrn,
      Patientnumber: this.state.patientno,
    };
    const res = await axios
      .post(`${ip}/neweligibility/neweligibilitycheck`, Checkdata, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          alert(" Eligibility Successfully Checked");
        }
      })

      .catch((err) => {});
  };

  editmanualupdate = async () => {
    const isValid = this.validate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };

      const value = {
        Npi: this.state.npi,
        BillingOrganizationName: this.state.orgname,
        DateOfService: this.state.dos,
        PrimaryPolicyNo: this.state.memberid,
        Gender: this.state.gender,
        PatientDOB: this.state.dob,
        PatientFirstName: this.state.firstname,
        status: this.state.status,
        planBeginfromInNetwork: this.state.planBeginfromInNetwork,
        planBegintoInNetwork: this.state.planBegintoInNetwork,
        copayInNetwork: this.state.copayInNetwork,
        coinsuranceInNetwork: this.state.coinsuranceInNetwork,
        annualDeductibleTotalInNetwork:
          this.state.annualDeductibleTotalInNetwork,
        annualDeductibleMetInNetwork: this.state.annualDeductibleMetInNetwork,
        annualDeductibleOpenInNetwork: this.state.annualDeductibleOpenInNetwork,
        unitsTotalInNetwork: this.state.unitsTotalInNetwork,
        unitsMetInNetwork: this.state.unitsMetInNetwork,
        unitsOpenInNetwork: this.state.unitsOpenInNetwork,
        serviceAllowedAmountTotalInNetwork:
          this.state.serviceAllowedAmountTotalInNetwork,
        serviceAllowedAmountMetInNetwork:
          this.state.serviceAllowedAmountMetInNetwork,
        serviceAllowedAmountOpenInNetwork:
          this.state.serviceAllowedAmountOpenInNetwork,
        planBeginfromOutofNetwork: this.state.planBeginfromOutofNetwork,
        planBegintoOutofNetwork: this.state.planBegintoOutofNetwork,
        copayOutofNetwork: this.state.copayOutofNetwork,
        coinsuranceOutofNetwork: this.state.coinsuranceOutofNetwork,
        annualDeductibleTotalOutofNetwork:
          this.state.annualDeductibleTotalOutofNetwork,
        annualDeductibleMetOutofNetwork:
          this.state.annualDeductibleMetOutofNetwork,
        annualDeductibleOpenOutofNetwork:
          this.state.annualDeductibleOpenOutofNetwork,
        unitsTotalOutofNetwork: this.state.unitsTotalOutofNetwork,
        unitsMetOutofNetwork: this.state.unitsMetOutofNetwork,
        unitsOpenOutofNetwork: this.state.unitsOpenOutofNetwork,
        serviceAllowedAmountTotalOutofNetwork:
          this.state.serviceAllowedAmountTotalOutofNetwork,
        serviceAllowedAmountMetOutofNetwork:
          this.state.serviceAllowedAmountMetOutofNetwork,
        serviceAllowedAmountOpenOutofNetwork:
          this.state.serviceAllowedAmountOpenOutofNetwork,
      };
      const res = await axios
        .put(
          `${ip}/neweligibility/manuallyupdate/${this.state._idvalue}`,
          value,
          {
            headers: headers,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            alert("Manually updated Successfully");
            this.setState({
              manualupdate: !this.state.manualupdate,
            });
            this.gettable();
          }
        })
        .catch((err) => {});
    }
  };

  getcpt = async () => {
    //api call to get all cpt data
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/cptmaster/all`, { headers: headers })
      .then((res) => {
        this.setState({
          cptdata: res.data.cpt,
        });
      })
      .catch((err) => {});
  };
  getextensivedownload = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    this.setState({
      loadingextensive: true,
    });
    fetch(`${ip}/eligibility/get`, { headers: headers })
      .then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Extensive.csv";
          a.click();
          URL.revokeObjectURL(a);
        });

        toast.success("Your file is downloading Please wait", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  getquery = () => {
    var query_o;
    // var query_final;
    let query = [];

    if (this.state.payervalue.length !== 0) {
      if (this.state.payervalue[0].category_name !== "") {
        query_o = `Payercategory=${this.state.payervalue[0].category_name}`;
        query.push(query_o);
      }
    }
    if (this.state.claimids.length !== 0) {
      let claimlist = [];
      let output = this.state.claimids.split(",");
      claimlist = output.map((str) => '"' + str + '"');
      const withCommasInBetween = claimlist.join(",");
      query_o = `ClaimID=[${withCommasInBetween}]`;
      query.push(query_o);
    }
    if (this.state.username) {
      query_o = `Claimunder=${this.state.username}`;
      query.push(query_o);
    }
    if (this.state.claimtype) {
      query_o = `type=${this.state.claimtype}`;
      query.push(query_o);
    }
    if (this.state.coveragestatus) {
      query_o = `status=${this.state.coveragestatus}`;
      query.push(query_o);
    }

    if (this.state.optionSelected.length !== 0) {
      let agegrp = [];
      let agelist;
      this.state.optionSelected.map((ele) => {
        agegrp.push(ele.value);
      });
      agelist = agegrp.map((str) => '"' + str + '"');
      query_o = `AgeGrp=[${agelist}]`;
      query.push(query_o);
    }
    if (this.state.doioptionSelected.length !== 0) {
      let agegrp = [];
      let temp;
      let agelist;
      this.state.doioptionSelected.map((ele) => {
        agegrp.push(ele.value);
      });
      agelist = agegrp.map((str) => '"' + str + '"');
      query_o = `DOIAgeGrp=[${agelist}]`;
      query.push(query_o);
    }
    if (this.state.userstatus.length !== 0) {
      query_o = `OverallClaimStatus=${this.state.userstatus}`;
      query.push(query_o);
    }

    if (this.state.claimfield && this.state.claimvalue !== "") {
      query_o = `${this.state.claimfield}=${this.state.claimvalue}`;
      query.push(query_o);
    }
    if (query.length === 1) {
      this.state.query.push(`${query[0]}`);
    }
    if (query.length === 2) {
      this.state.query.push(`${query[0]}&${query[1]}`);
    }
    if (query.length === 3) {
      this.state.query.push(`${query[0]}&${query[1]}&${query[2]}`);
    }
    if (query.length === 4) {
      this.state.query.push(`${query[0]}&${query[1]}&${query[2]}&${query[3]}`);
    }
    if (query.length === 5) {
      this.state.query.push(
        `${query[0]}&${query[1]}&${query[2]}&${query[3]}&${query[4]}`
      );
    }
    if (query.length === 6) {
      this.state.query.push(
        `${query[0]}&${query[1]}&${query[2]}&${query[3]}&${query[4]}&${query[5]}`
      );
    }
    if (query.length === 7) {
      this.state.query.push(
        `${query[0]}&${query[1]}&${query[2]}&${query[3]}&${query[4]}&${query[5]}&${query[6]}`
      );
    }
  };

  filtersearch = () => {
    this.setState({ query: [] });
    this.getquery();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.query.length !== 0) {
      let url;
      if (this.state.client) {
        if (this.state.Location) {
          if (this.state.valueOfInput1 && this.state.valueOfInput2) {
            url = `${ip}/neweligibility/advancedmultiplefilter?pageno=${this.state.currentpagesearch}&count=${this.state.perPage}&${this.state.query}&Location=${this.state.Location}&start_date=${this.state.valueOfInput1}&end_date=${this.state.valueOfInput2}`;
          } else {
            url = `${ip}/neweligibility/advancedmultiplefilter?pageno=${this.state.currentpagesearch}&count=${this.state.perPage}&${this.state.query}&Location=${this.state.Location}`;
          }
        } else {
          if (this.state.valueOfInput1 && this.state.valueOfInput2) {
            url = `${ip}/neweligibility/advancedmultiplefilter?pageno=${this.state.currentpagesearch}&count=${this.state.perPage}&${this.state.query}&start_date=${this.state.valueOfInput1}&end_date=${this.state.valueOfInput2}`;
          } else {
            url = `${ip}/neweligibility/advancedmultiplefilter?pageno=${this.state.currentpagesearch}&count=${this.state.perPage}&${this.state.query}`;
          }
        }
      } else {
        if (this.state.valueOfInput1 && this.state.valueOfInput2) {
          url = `${ip}/neweligibility/advancedmultiplefilter?pageno=${this.state.currentpagesearch}&count=${this.state.perPage}&${this.state.query}&start_date=${this.state.valueOfInput1}&end_date=${this.state.valueOfInput2}`;
        } else {
          url = `${ip}/neweligibility/advancedmultiplefilter?pageno=${this.state.currentpagesearch}&count=${this.state.perPage}&${this.state.query}`;
        }
      }
      const res = axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          if(res){
            toast.success("Searching completed!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
          else{
            toast.warning("Fetching data", {
              position: "top-right",
              hideProgressBar: false,
              pauseOnHover: true,
              progress: true,
            }); 
          }
          this.setState({ searchtext: 1 });
          this.setState({
            searchdataresponse: res.data.eligibility,
            searchclaimdata: res.data.eligibility,
          });
        })
        .catch((err) => {});
    } else {
      alert("Select data to search");
    }
  };
  sorting = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.Ivrdata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ Ivrdata: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.Ivrdata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ Ivrdata: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  sortingfilter = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.searchclaimdata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ searchclaimdata: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.searchclaimdata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ searchclaimdata: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };

  getusers = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/users/all?clientId=${this.state.client}`, { headers: headers })
      .then((res) => {
        let data = res.data.user;
        if(this.state.client){
          data = data.filter((item) => {
            return (
              item.role === "Team Member" 
            );
          });
        }
        else{
          data = data.filter((item) => {
            return (
              item.role === "Admin" || item.role === "SuperAdmin"
            );
          });
        }
        

        this.setState({ users: data });
      })
      .catch((err) => {});
  };
  resetuser = () => {
    this.setState({
      ids: [],
    });
  };
  reset = () => {
    if (this.typeaheadRef.current) {
      this.typeaheadRef.current.clear(); 
    }
    this.setState({
      claimfield: "",
      claimids: [],
      claimvalue: "",
      username: "",
      userstatus: [],
      servicetypevalue: "",
      payerselect: "",
      billing: "",
      serviceType: "",
      coveragestatus: "",
      plancode: "",
      loc: "",
      physician: "",
      mrn: "",
      patientno: "",
      user: "",
      ar: [],
      claimtype: "",
      agegrp: "",
      payervalue: [],
      cptvalue: [],
      valueOfInput1: "",
      valueOfInput2: "",
      searchclaimdata: [],
      searchtext: "",
      currentpage: 0,
      currentpagesearch: 0,
      searchdataresponse: [],
      perPage: 10,
      doiagegrp: "",
      optionSelected: [],
      doioptionSelected: [],
    });
    // if(this.state.claimfield !== "" || this.state.claimfield !== null){
    //   this.typeahead.current.state.selected= []
    // }
  };

  componentDidMount() {
    this.getcpt();
    this.gettable();
    this.getserviceid();
    this.getusers();
    this.getLocation();
  }

  validate = () => {
    let hasErr = "true";
    let selectuserErr = "";
    let selectuserErr2 = "";
    let selectuserErr3 = "";

    if (this.state.status === "" || this.state.status === null) {
      selectuserErr = "This field is required";
      this.setState({ selectuserErr });
      hasErr = false;
    }
    if (
      this.state.planBeginfromInNetwork === "" ||
      this.state.planBeginfromInNetwork === null
    ) {
      selectuserErr2 = "This field is required";
      this.setState({ selectuserErr2 });
      hasErr = false;
    }
    if (
      this.state.planBeginfromOutofNetwork === "" ||
      this.state.planBeginfromOutofNetwork === null
    ) {
      selectuserErr3 = "This field is required";
      this.setState({ selectuserErr3 });
      hasErr = false;
    }
    return hasErr;
  };
  render() {
    const { activeTab } = this.state;
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));
    const { anchorEl } = this.state;

    const checkboxstyle = {
      marginTop: "15px",
    };

    return (
      <div>
      <Newsidebar name="Eligibility Tracker" />
      <div className="align-row" style={{overflow:"hidden", paddingTop:"10px"}}>
        <div className="align-column">
          <ToastContainer />
          <div style={{ width: "98%", overflow: "hidden", fontSize:"12px" }}>
            <div>
              <div>
                  <Form style={{
                        border: "3px solid #FBFBFB",
                        padding: "8px",
                        borderRadius: "16px",
                        background: "#FBFBFB",
                      }}>
                    <Row>
                      <Label
                        style={{ fontWeight: "600", textAlign: "start" }}
                      ></Label>

                      <Col style={{ display: "flex" }} md="6">
                        {this.state.claimfield ? (
                          <Typeahead
                            ref={this.typeaheadRef}
                            clearButton={true}
                            id="outcomeData-admin-typeahead"  
                            style={{ width: "30em" }}
                            options={this.state.optionsarray}
                            labelKey={(option) => `${option.label}`}
                            placeholder="What are you looking for?"
                            onChange={(option) => {
                              if (option && option.length > 0) {
                                this.setState({
                                  claimfield: option[0].value, // Set the selected value, not the label
                                });
                              } else {
                                this.setState({
                                  claimfield: "",
                                });
                              }
                            }}
                          />
                        ) : (
                          <Typeahead
                          id="outcomeData-admin-typeahead-claimfield"  
                            style={{ width: "30em" }}
                            options={this.state.optionsarray}
                            labelKey={(option) => `${option.label}`}
                            placeholder="What are you looking for?"
                            onChange={(option) => {
                              if (option && option.length > 0) {
                                this.setState({
                                  claimfield: option[0].value, // Set the selected value, not the label
                                });
                              } else {
                                this.setState({
                                  claimfield: "",
                                });
                              }
                            }}
                          />
                        )}

                        {this.state.claimfield === "type" ? (
                          <Typeahead
                          id="outcomeData-admin-typeahead-claimvalue"  
                            style={{ width: "100%" , marginLeft:"3%"}}
                            clearButton={true}
                            options={["Realtime", "Calling/Portal", "Manual"]}
                            placeholder="Select Type..."
                            selected={
                              this.state.claimvalue
                                ? [this.state.claimvalue]
                                : []
                            }
                            onChange={(selected) => {
                              if (selected && selected.length > 0) {
                                this.setState({
                                  claimvalue: selected[0],
                                });
                              } else {
                                this.setState({
                                  claimvalue: "",
                                });
                              }
                            }}
                          />
                        ) : this.state.claimfield === "status" ? (
                          <Typeahead
                          id="outcomeData-admin-typeahead-status-claimvalue"  
                            clearButton={true}
                            style={{ width: "100%" , marginLeft:"3%"}}
                            options={["Active", "Inactive", "Error"]}
                            placeholder="Select Type..."
                            selected={
                              this.state.claimvalue
                                ? [this.state.claimvalue]
                                : []
                            }
                            onChange={(selected) => {
                              if (selected && selected.length > 0) {
                                this.setState({
                                  claimvalue: selected[0],
                                });
                              } else {
                                this.setState({
                                  claimvalue: "",
                                });
                              }
                            }}
                          />
                        ) : (
                          <Input
                            className="header__searchInput"
                            type="text"
                            style={{ width: "100%", marginLeft:"3%" }}
                            placeholder="Enter text"
                            value={this.state.claimvalue}
                            onChange={(e) =>
                              this.setState({
                                claimvalue: e.target.value,
                              })
                            }
                          />
                        )}
                      </Col>
                      <Col>
                        <RangePicker
                          format="MM-DD-YYYY"
                          onChange={(dates, datestring) => {
                            this.setState({
                              valueOfInput1: datestring[0],
                              valueOfInput2: datestring[1],
                            });
                          }}
                          height="50%"
                          style={{
                            borderColor: "gray",
                            width: "150%",
                            right: "1%",
                          }}
                        />
                      </Col>
                      <Col style={{ marginLeft: "4%" }}>
                        <div className="button-group">
                          <Button
                            style={{
                              width: "20%",
                              borderRadius: "8px",
                              color: "white",
                              padding: "4px",
                              background: "#041c3c",
                              borderRadius: "4px",
                              fontWeight: "bold",
                              // marginLeft:"3%",
                              cursor: "pointer",
                            }}
                            onClick={this.filtersearch}
                          >
                            <AiOutlineSearch />
                          </Button>
                          <Button
                            style={{
                              width: "20%",
                              borderRadius: "8px",
                              color: "black",
                              padding: "4px",
                              background: "#f8f8ff",
                              fontWeight: "bold",
                              // marginLeft:"3%",
                              borderRadius: "4px",
                              cursor: "pointer",
                            }}
                            onClick={this.reset}
                          >
                            <IoRefreshOutline></IoRefreshOutline>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
              </div>
            </div>
            <br />
            {this.state.download && (
              <Modal
                style={{ width: "30%", fontSize:"13px" }}
                isOpen={this.state.download}
                onHide={this.downloaddata}
                onExit={this.reset}
                size="md"
                centered
              >
                <ModalHeader toggle={this.downloaddata}>
                  Download Report
                </ModalHeader>
                <ModalBody
                  style={{
                    "max-height": "calc(100vh - 210px)",
                    "overflow-y": "auto",
                  }}
                >
                  <Row style={{ margin: "0px" }}>
                    <Col>
                      <div>
                        <button
                          style={{
                            boxShadow: "inset 0px 1px 0px 0px #e184f3",
                            background: "black",
                            backgroundColor: "black",
                            borderRadius: "6px",
                            border: "1px solid black",
                            display: "inline-block",
                            cursor: "pointer",
                            color: "#ffffff",
                            fontSize: "15px",
                            fontWeight: "bold",
                            padding: "2px 24px",
                            textDecoration: "none",

                            marginLeft: "-10%",
                            textShadow: "0px 1px 0px #9b14b3",
                          }}
                          onClick={this.getextensivedownload}
                        >
                          Extensive Download
                        </button>
                      </div>
                    </Col>
                    <br />
                    <br />
                    <Col>
                      <div>
                        <button
                          style={{
                            boxShadow: "inset 0px 1px 0px 0px #E184F3",
                            background: "black",
                            backgroundColor: "black",
                            borderRadius: "6px",
                            border: "1px solid black",
                            display: "inline-block",
                            cursor: "pointer",
                            color: "#FFFFFF",
                            fontSize: "15px",
                            fontWeight: "bold",
                            padding: "2px 24px",
                            textDecoration: "none",
                            textShadow: "0px 1px 0px #9B14B3",
                            float: "right",
                          }}
                          onClick={this.getdownloadfile}
                        >
                          Generate Report
                        </button>
                      </div>
                    </Col>
                  </Row>

                  <br />
                </ModalBody>
              </Modal>
            )}
            <div>
              <Row>
                <Col>
                  <button
                    className={`Outcomedata1 ${
                      activeTab === "Web" ? "active-out" : ""
                    }`}
                    style={{ border: "none" }}
                    onClick={() => this.handleTabClick("Web")}
                  >
                    RealTime
                  </button>
                </Col>

                <Col>
                  <button
                    className={`Outcomedata2 ${
                      activeTab === "IVR" ? "active-out" : ""
                    }`}
                    style={{ border: "none" }}
                    onClick={() => this.handleTabClick("IVR")}
                  >
                    Calling/Portal
                  </button>
                </Col>
                <Col>
                  <button
                    className={`Outcomedata3 ${
                      activeTab === "Manual" ? "active-out" : ""
                    }`}
                    style={{ border: "none" }}
                    onClick={() => this.handleTabClick("Manual")}
                  >
                    Manual
                  </button>
                </Col>
                <Col>
                  <button
                    style={{
                      backgroundColor: "#081342",
                      color: "white",
                      border: "none",
                      width: "20%",
                      float: "right",
                    }}
                    onClick={(e) => {
                      this.downloaddata();
                    }}
                  >
                    <BiDownload />
                  </button>
                </Col>
              </Row>
            </div>
            <br />
            <div>
              {this.state.fetching ? (
                <div>
                  <p
                    style={{
                      fontSize: "13px",
                      marginTop: "10px",
                      color: "#000",
                    }}
                  >
                    <span>Loading...</span>
                    <span>Please Wait...</span>
                  </p>
                  <div className="boxes body__loader dribbble">
                    <div className="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div className="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div className="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div className="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              ) : this.state.Ivrdata.length === 0 ? (
                <div className="bot-ui" style={{ marginTop: "13%" }}>
                  <div className="bot-head">
                    <div className="eyes-container">
                      <div className="to-left eye"></div>
                      <div className="to-right eye"></div>
                    </div>
                  </div>
                  <div className="bot-body">
                    <div className="analyzer"></div>
                  </div>
                  <p style={{ fontSize: "13px", fontStyle: "italic" }}>
                    No Data Found
                  </p>
                </div>
              ) : (
                <div>
                  {this.state.searchtext === "" ? (
                    <div style={{ fontSize: "13px" }}>
                      <TableContainer style={{ fontSize: "13px" }}>
                        <Table
                          // sx={{ minWidth: 700 }}
                          style={{ fontSize: "13px" }}
                          // className="table__card"
                          // aria-label="customized table"
                        >
                          <TableHead style={{ fontSize: "13px" }}>
                            <TableRow style={{ fontSize: "13px" }}>
                             <StyledTableCell></StyledTableCell>
                              <StyledTableCell
                                onClick={() => this.sorting("claimid")}
                                style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                              >
                                ClaimID
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={() =>
                                  this.sorting("patientAccountNumber")
                                }
                                style={{ whiteSpace: "nowrap" , fontSize:"13px"}}
                              >
                                Account No{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={() => this.sorting("PatientFirstName")}
                                style={{ whiteSpace: "nowrap" , fontSize:"13px"}}
                              >
                                FirstName{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={() => this.sorting("PatientLastName")}
                                style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                              >
                                LastName{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={() => this.sorting("DateOfService")}
                                style={{ whiteSpace: "nowrap" , fontSize:"13px"}}
                              >
                                DOS{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </StyledTableCell>

                              <StyledTableCell
                                onClick={() => this.sorting("PatientDOB")}
                                style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                              >
                                DOB{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </StyledTableCell>

                              <StyledTableCell
                                onClick={() => this.sorting("Pripayer")}
                                style={{ whiteSpace: "nowrap" , fontSize:"13px"}}
                              >
                                Insurance Name{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </StyledTableCell>

                              <StyledTableCell
                                onClick={() => this.sorting("PrimaryPolicyNo")}
                                style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                              >
                                Policy No{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={() => this.sorting("Physician")}
                                style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                              >
                                Physician Name{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </StyledTableCell>

                              <StyledTableCell
                                onClick={() => this.sorting("type")}
                                style={{ whiteSpace: "nowrap" , fontSize:"13px"}}
                              >
                                Type{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody style={{fontSize:"13px"}}>
                            {this.state.Ivrdata &&
                              this.state.Ivrdata.map((data, index) => (
                                <TableRow key={data._id}>
                             
                                    <StyledTableCell
                                      onClick={() =>
                                        this.setState(
                                          {
                                            Eligibilityclaimid: data.ClaimID,
                                            firstnamevalue:
                                              data.PatientFirstName,
                                            lastnamevalue: data.PatientLastName,
                                            payerid: data.PayerID,
                                            orgname:
                                              data.BillingOrganizationName,
                                            npi: data.Npi,
                                            memberid: data.PrimaryPolicyNo,
                                            firstname: data.PatientFirstName,
                                            lastname: data.PatientLastName,
                                            gender: data.Gender,
                                            dob: data.dob,
                                            dos: data.DateOfService,
                                            beginingdos: data.BeginingDOS,
                                            enddos: data.EndDOS,
                                            clientideli: data.clientId,
                                            _idvalue: data._id,
                                            insurname: data.InsuranceName,
                                            plancode: data.PlanCode,
                                            loc: data.Location,
                                            physician: data.Physician,
                                            mrn: data.MRN,
                                            patientno:
                                              data.patientAccountNumber,
                                            status: data.status,
                                            planBeginfromInNetwork:
                                              data.planBeginfromInNetwork,
                                            planBegintoInNetwork:
                                              data.planBegintoInNetwork,
                                            copayInNetwork: data.copayInNetwork,
                                            coinsuranceInNetwork:
                                              data.coinsuranceInNetwork,
                                            annualDeductibleTotalInNetwork:
                                              data.annualDeductibleTotalInNetwork,
                                            annualDeductibleMetInNetwork:
                                              data.annualDeductibleMetInNetwork,
                                            annualDeductibleOpenInNetwork:
                                              data.annualDeductibleOpenInNetwork,
                                            typefor: data.type,
                                            unitsTotalInNetwork:
                                              data.unitsTotalInNetwork,
                                            unitsMetInNetwork:
                                              data.unitsMetInNetwork,
                                            unitsOpenInNetwork:
                                              data.unitsOpenInNetwork,
                                            serviceAllowedAmountTotalInNetwork:
                                              data.serviceAllowedAmountTotalInNetwork,
                                            serviceAllowedAmountMetInNetwork:
                                              data.serviceAllowedAmountMetInNetwork,
                                            serviceAllowedAmountOpenInNetwork:
                                              data.serviceAllowedAmountOpenInNetwork,
                                            planBeginfromOutofNetwork:
                                              data.planBeginfromOutofNetwork,
                                            planBegintoOutofNetwork:
                                              data.planBegintoOutofNetwork,
                                            copayOutofNetwork:
                                              data.copayOutofNetwork,
                                            coinsuranceOutofNetwork:
                                              data.coinsuranceOutofNetwork,
                                            annualDeductibleTotalOutofNetwork:
                                              data.annualDeductibleTotalOutofNetwork,
                                            annualDeductibleMetOutofNetwork:
                                              data.annualDeductibleMetOutofNetwork,
                                            annualDeductibleOpenOutofNetwork:
                                              data.annualDeductibleOpenOutofNetwork,
                                            unitsTotalOutofNetwork:
                                              data.unitsTotalOutofNetwork,
                                            unitsMetOutofNetwork:
                                              data.unitsMetOutofNetwork,
                                            unitsOpenOutofNetwork:
                                              data.unitsOpenOutofNetwork,
                                            serviceAllowedAmountTotalOutofNetwork:
                                              data.serviceAllowedAmountTotalOutofNetwork,
                                            serviceAllowedAmountMetOutofNetwork:
                                              data.serviceAllowedAmountMetOutofNetwork,
                                            serviceAllowedAmountOpenOutofNetwork:
                                              data.serviceAllowedAmountOpenOutofNetwork,
                                          },
                                          () => this.getpasteligibility(),
                                          this.gettable()
                                        )
                                      }
                                    >
                                  <Link
                                    style={{ color: "black" }}
                                    to={{
                                      pathname: "/viewmoreeligibility",
                                    }}
                                    onClick={() => (
                                      <Viewmoreeligibility
                                        value={
                                          sessionStorage.setItem(
                                            "Eligibilityclaimid",
                                            data.ClaimID
                                          ) ||
                                          sessionStorage.setItem(
                                            "modulename",
                                            "Eligibility Tracker"
                                          )
                                        }
                                      ></Viewmoreeligibility>
                                    )}
                                  >
                                    
                                      {this.state.activeTab === "Web" &&
                                        (data.status === "Active Coverage" ? (
                                          <Tooltip
                                            title="Active"
                                            placement="top"
                                          >
                                            <Icon
                                              icon="dashicons:yes-alt"
                                              color="green"
                                              fontSize="22px"
                                            />
                                          </Tooltip>
                                        ) : data.status === "Inactive" || data.status === "200" || data.status === 200 ?(
                                            <Tooltip
                                              title="Inactive"
                                              placement="top"
                                            >
                                              <Icon
                                                icon="gridicons:cross-circle"
                                                color="red"
                                                fontSize="22px"
                                              />
                                            </Tooltip>
                                        )
                                        //   ) :
                                        //   data.status === "200" || data.status === 200 ?
                                        //   <Tooltip
                                        //   title="Error"
                                        //   placement="top"
                                        // >
                                        //   <Icon
                                        //     icon="gridicons:cross-circle"
                                        //     color="red"
                                        //     fontSize="22px"
                                        //   />
                                        // </Tooltip>
                                         :null )}
                                      {this.state.activeTab === "Web" &&
                                        (data.status === "Error" ? (
                                          <Tooltip
                                            title="Error"
                                            placement="top"
                                          >
                                            <Icon
                                              icon="ic:outline-error"
                                              color="yellow"
                                              fontSize="22px"
                                            />
                                          </Tooltip>
                                        ) : null)}

                                      {this.state.activeTab === "IVR" && (
                                        <div>
                                          <img
                                            src={eicon}
                                            alt="notfound"
                                            className="ivreicon"
                                            onClick={(e) => {
                                              this.toggle3();
                                            }}
                                          ></img>
                                        </div>
                                      )}

                                      {this.state.activeTab === "Manual" && (
                                        <div>
                                          <img
                                            src={eicon}
                                            className="ivreicon"
                                            alt="notfound"
                                            onClick={(e) => {
                                              this.toggle3();
                                            }}
                                          ></img>
                                        </div>
                                      )}
                                   
                                  </Link>
                                  </StyledTableCell>
                                  <StyledTableCell   style={{fontSize:"13px"}}>
                                    {data.ClaimID}
                                  </StyledTableCell>
                                  <StyledTableCell   style={{fontSize:"13px"}}>
                                    {data.patientAccountNumber
                                      ? data.patientAccountNumber
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell   style={{fontSize:"13px"}}>
                                    {data.PatientFirstName}
                                  </StyledTableCell>
                                  <StyledTableCell   style={{fontSize:"13px"}}>
                                    {data.PatientLastName}
                                  </StyledTableCell>
                                  <StyledTableCell   style={{fontSize:"13px"}}>
                                    {data.DateOfService
                                      ? moment(data.DateOfService).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell   style={{fontSize:"13px"}}>
                                    {data.PatientDOB ? data.PatientDOB : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell   style={{fontSize:"13px", whiteSpace:"normal"}}>
                                    {data.InsuranceName
                                      ? data.InsuranceName
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell   style={{fontSize:"13px"}}>
                                    {data.PrimaryPolicyNo
                                      ? data.PrimaryPolicyNo
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell   style={{fontSize:"13px"}}>
                                    {data.Physician ? data.Physician : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell   style={{fontSize:"13px"}}>{data.type}</StyledTableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      {this.state.searchdataresponse.length === 0 ? (
                        <div>
                          {/* <Row style={{ height: "100%" }} className="m-lg-4">
                            <Card
                              style={{
                                height: "98%",
                                marginTop: "1%",
                                marginLeft: "6px",
                                width: "97%",
                                paddingBottom: "3%",
                              }}
                            >
                              <Form>
                                <Row style={{ marginTop: "1.5%" }}>
                                  <Col md="3">
                                    <Label style={{ fontWeight: "600" }}>
                                      What are you looking for?
                                    </Label>
                                    <div
                                      className="header__search"
                                      style={{ width: "143%" }}
                                    >
                                      <Input
                                        type="select"
                                        style={{ width: "100%" }}
                                        value={this.state.claimfield}
                                        onChange={(e) =>
                                          this.setState({
                                            claimfield: e.target.value,
                                          })
                                        }
                                      >
                                        {" "}
                                        <option>Select...</option>
                                        {options.length > 0 &&
                                          options.map((data, i) => {
                                            return (
                                              <option
                                                key={i}
                                                value={data.label}
                                              >
                                                {data.label}
                                              </option>
                                            );
                                          })}
                                      </Input>
                                      <Input
                                        className="header__searchInput"
                                        type="text"
                                        placeholder="Enter text"
                                        value={this.state.claimvalue}
                                        onChange={(e) =>
                                          this.setState({
                                            claimvalue: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </Col>

                                  <Col>
                                    <FormGroup
                                      style={{
                                        marginLeft: "36%",
                                        width: "47%",
                                      }}
                                    >
                                      <Label
                                        style={{
                                          fontWeight: "600",
                                          marginLeft: "10%",
                                        }}
                                      >
                                        Type
                                      </Label>
                                      <Input
                                        style={{ marginleft: "25%" }}
                                        type="select"
                                        className="form-control form-select"
                                        value={this.state.claimtype}
                                        onChange={(e) =>
                                          this.setState({
                                            claimtype: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="" hidden>
                                          Select Type...
                                        </option>
                                        <option value="Web">Realtime</option>
                                        <option value="IVR">
                                          Calling/Portal
                                        </option>
                                        <option value="Manual">Manual</option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <br />

                                <Row>
                                  <Col
                                    style={{ marginLeft: "1%", width: "30%" }}
                                    md="2"
                                  >
                                    <FormGroup style={{ width: "100%" }}>
                                      <Label style={{ fontWeight: "600" }}>
                                        Coverage Status
                                      </Label>
                                      <Input
                                        className="form-control form-select"
                                        type="select"
                                        value={this.state.coveragestatus}
                                        onChange={(e) => {
                                          this.setState({
                                            coveragestatus: e.target.value,
                                          });
                                        }}
                                      >
                                        <option value="" hidden>
                                          Select Type...
                                        </option>
                                        <option value="Active Coverage">
                                          Active
                                        </option>
                                        <option value="Inactive">
                                          Inactive
                                        </option>
                                        <option value="Error">Error</option>
                                      </Input>
                                    </FormGroup>
                                  </Col>

                                  <Col>
                                    <FormGroup>
                                      <Label
                                        style={{
                                          fontWeight: "600",
                                          width: "72%",
                                        }}
                                      >
                                        Authorization Status
                                      </Label>
                                      <Input
                                        style={{ width: "145%" }}
                                        className="form-control form-select"
                                        type="select"
                                        value={this.state.userstatus}
                                        onChange={(e) => {
                                          this.setState({
                                            userstatus: e.target.value,
                                          });
                                        }}
                                      >
                                        <option
                                          value=""
                                          hidden
                                          id="select-placeholder"
                                        >
                                          Select Status...
                                        </option>
                                        {this.state.userstatuslist.map(
                                          (data, index) => (
                                            <option value={data}>{data}</option>
                                          )
                                        )}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col style={{ marginLeft: "7%" }}>
                                    <Button
                                      style={{
                                        width: "60%",
                                        height: "59%",
                                        marginTop: "23.3px",
                                        borderRadius: "8px",
                                        color: "white",
                                        background: "black",
                                        fontWeight: "bold",
                                      }}
                                      onClick={this.filtersearch}
                                    >
                                      Search
                                    </Button>
                                  </Col>

                                  <Col>
                                    <Button
                                      style={{
                                        width: "50%",
                                        height: "59%",
                                        marginTop: "23.3px",
                                        border: "none",
                                        color: "white",
                                        background: "white",
                                        fontWeight: "bold",
                                        marginLeft: "40%",
                                      }}
                                      onClick={this.reset}
                                    >
                                      <IoIosRefresh
                                        style={{
                                          color: "blue",
                                          fontSize: "25px",
                                        }}
                                      ></IoIosRefresh>
                                    </Button>
                                  </Col>
                                </Row>
                                <br />
                                <Row>
                                  <Col>
                                    <Button
                                      className="Outcomedata1"
                                      onClick={() => {
                                        this.setState(
                                          {
                                            Types: "Web",
                                          },
                                          () => this.gettable()
                                        );
                                      }}
                                    >
                                      RealTime
                                    </Button>
                                  </Col>

                                  <Col>
                                    <Button
                                      className="Outcomedata2"
                                      onClick={() => {
                                        this.setState(
                                          {
                                            Types: "IVR",
                                          },
                                          () => this.gettable()
                                        );
                                      }}
                                    >
                                      Calling/Portal
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Button
                                      className="Outcomedata3"
                                      onClick={() => {
                                        this.setState(
                                          {
                                            Types: "Manual",
                                          },
                                          () => this.gettable()
                                        );
                                      }}
                                    >
                                      Manual
                                    </Button>
                                  </Col>
                                </Row>
                              </Form>
                            </Card>
                          </Row>*/}
                          <p>
                            <img src={searchresult} />
                          </p>
                        </div>
                      ) : (
                        <div>
                          {this.state.searchclaimdata &&
                            this.state.activeTab === "Web" && (
                              <div style={{ fontSize: "13px" }}>
                                <TableContainer style={{ fontSize: "13px" }}>
                                  <Table
                                  // sx={{ minWidth: 700 }}
                                  // className="table__card"
                                  // aria-label="customized table"
                                  >
                                    <TableHead style={{fontSize:"13px"}}>
                                      <TableRow style={{fontSize:"13px"}}>
                                
                                        <StyledTableCell style={{fontSize:"13px"}}>
                                          
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("claimid")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          ClaimID
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("patientAccountNumber")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Account No{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("PatientFirstName")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          FirstName{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("PatientLastName")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          LastName{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("DateOfService")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          DOS{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("PatientDOB")
                                          }
                                          style={{ whiteSpace: "nowrap" , fontSize:"13px"}}
                                        >
                                          DOB{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("InsuranceName")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Insurance Name{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("PrimaryPolicyNo")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Policy No{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("Physician")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Physician Name{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() => this.sorting("type")}
                                          style={{ whiteSpace: "nowrap" , fontSize:"13px"}}
                                        >
                                          Type{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("ClaimStatus")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Status
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.searchclaimdata &&
                                        this.state.searchclaimdata.map(
                                          (data, index) => (
                                            <TableRow key={data._id} style={{fontSize:"13px"}}>
                                       
                                              <Link
                                                style={{ color: "black" }}
                                                to={{
                                                  pathname:
                                                    "/viewmoreeligibility",
                                                }}
                                                onClick={() => (
                                                  <Viewmoreeligibility
                                                    value={
                                                      sessionStorage.setItem(
                                                        "Eligibilityclaimid",
                                                        data.ClaimID
                                                      ) ||
                                                      sessionStorage.setItem(
                                                        "modulename",
                                                        "Eligibility Tracker"
                                                      )
                                                    }
                                                  ></Viewmoreeligibility>
                                                )}
                                              >
                                                <StyledTableCell
                                                  onClick={() =>
                                                    this.setState(
                                                      {
                                                        Eligibilityclaimid:
                                                          data.ClaimID,
                                                        firstnamevalue:
                                                          data.PatientFirstName,
                                                        lastnamevalue:
                                                          data.PatientLastName,
                                                        payerid: data.PayerID,
                                                        orgname:
                                                          data.BillingOrganizationName,
                                                        npi: data.Npi,
                                                        memberid:
                                                          data.PrimaryPolicyNo,
                                                        firstname:
                                                          data.PatientFirstName,
                                                        lastname:
                                                          data.PatientLastName,
                                                        gender: data.Gender,
                                                        dob: data.dob,
                                                        dos: data.DateOfService,
                                                        beginingdos:
                                                          data.BeginingDOS,
                                                        enddos: data.EndDOS,
                                                        clientideli:
                                                          data.clientId,
                                                        _idvalue: data._id,
                                                        insurname:
                                                          data.InsuranceName,
                                                        plancode: data.PlanCode,
                                                        loc: data.Location,
                                                        physician:
                                                          data.Physician,
                                                        mrn: data.MRN,
                                                        patientno:
                                                          data.patientAccountNumber,
                                                        status: data.status,
                                                        planBeginfromInNetwork:
                                                          data.planBeginfromInNetwork,
                                                        planBegintoInNetwork:
                                                          data.planBegintoInNetwork,
                                                        copayInNetwork:
                                                          data.copayInNetwork,
                                                        coinsuranceInNetwork:
                                                          data.coinsuranceInNetwork,
                                                        annualDeductibleTotalInNetwork:
                                                          data.annualDeductibleTotalInNetwork,
                                                        annualDeductibleMetInNetwork:
                                                          data.annualDeductibleMetInNetwork,
                                                        annualDeductibleOpenInNetwork:
                                                          data.annualDeductibleOpenInNetwork,
                                                        unitsTotalInNetwork:
                                                          data.unitsTotalInNetwork,
                                                        unitsMetInNetwork:
                                                          data.unitsMetInNetwork,
                                                        unitsOpenInNetwork:
                                                          data.unitsOpenInNetwork,
                                                        serviceAllowedAmountTotalInNetwork:
                                                          data.serviceAllowedAmountTotalInNetwork,
                                                        serviceAllowedAmountMetInNetwork:
                                                          data.serviceAllowedAmountMetInNetwork,
                                                        serviceAllowedAmountOpenInNetwork:
                                                          data.serviceAllowedAmountOpenInNetwork,
                                                        planBeginfromOutofNetwork:
                                                          data.planBeginfromOutofNetwork,
                                                        planBegintoOutofNetwork:
                                                          data.planBegintoOutofNetwork,
                                                        copayOutofNetwork:
                                                          data.copayOutofNetwork,
                                                        coinsuranceOutofNetwork:
                                                          data.coinsuranceOutofNetwork,
                                                        annualDeductibleTotalOutofNetwork:
                                                          data.annualDeductibleTotalOutofNetwork,
                                                        typefor: data.type,
                                                        annualDeductibleMetOutofNetwork:
                                                          data.annualDeductibleMetOutofNetwork,
                                                        annualDeductibleOpenOutofNetwork:
                                                          data.annualDeductibleOpenOutofNetwork,
                                                        unitsTotalOutofNetwork:
                                                          data.unitsTotalOutofNetwork,
                                                        unitsMetOutofNetwork:
                                                          data.unitsMetOutofNetwork,
                                                        unitsOpenOutofNetwork:
                                                          data.unitsOpenOutofNetwork,
                                                        serviceAllowedAmountTotalOutofNetwork:
                                                          data.serviceAllowedAmountTotalOutofNetwork,
                                                        serviceAllowedAmountMetOutofNetwork:
                                                          data.serviceAllowedAmountMetOutofNetwork,
                                                        serviceAllowedAmountOpenOutofNetwork:
                                                          data.serviceAllowedAmountOpenOutofNetwork,
                                                      },
                                                      () =>
                                                        this.getpasteligibility(),
                                                      this.gettable()
                                                    )
                                                  }
                                                >
                                                  {data.type === "Web" &&
                                                  data.status ===
                                                    "Active Coverage" ? (
                                                    <Tooltip
                                                      title="Active"
                                                      placement="top"
                                                    >
                                                      <Icon
                                                        icon="dashicons:yes-alt"
                                                        color="green"
                                                        fontSize="22px"
                                                      />
                                                    </Tooltip>
                                                  ) : (
                                                    data.type === "Web" &&
                                                    data.status ===
                                                      "Inactive" && (
                                                      <Tooltip
                                                        title="Inactive"
                                                        placement="top"
                                                      >
                                                        <Icon
                                                          icon="gridicons:cross-circle"
                                                          color="red"
                                                          fontSize="22px"
                                                        />
                                                      </Tooltip>
                                                    )
                                                  )}
                                                  {data.type === "Web" &&
                                                    data.status === "Error" && (
                                                      <Tooltip
                                                        title="Error"
                                                        placement="top"
                                                      >
                                                        <Icon
                                                          icon="ic:outline-error"
                                                          color="yellow"
                                                          fontSize="22px"
                                                        />
                                                      </Tooltip>
                                                    )}
                                                  {data.type === "IVR" && (
                                                    <img
                                                      src={eicon}
                                                      className="ivreicon"
                                                      alt="notfound"
                                                      onClick={(e) => {
                                                        this.toggle3();
                                                      }}
                                                    ></img>
                                                  )}
                                                  {data.type === "Manual" && (
                                                    <img
                                                      src={eicon}
                                                      className="ivreicon"
                                                      alt="notfound"
                                                      onClick={(e) => {
                                                        this.toggle3();
                                                      }}
                                                    ></img>
                                                  )}
                                                  {/* :(
                                                
                                                <Tooltip
                                                  title="Error"
                                                  placement="top"
                                                >
                                                  <Icon
                                                    icon="ic:outline-error"
                                                    color="yellow"
                                                    fontSize="22px"
                                                  />
                                                </Tooltip>
                                                )  */}

                                                  {/* <div>
                                                  <img
                                                    src={eicon}
                                                    className="ivreicon"
                                                    alt="notfound"
                                                    onClick={(e) => {
                                                      this.toggle3();
                                                    }}
                                                  ></img>
                                                </div>
                                                <div>
                                                  <img
                                                    src={eicon}
                                                    className="outcomeeicon"
                                                    alt="notfound"
                                                    onClick={(e) => {
                                                      this.toggle3();
                                                    }}
                                                  ></img>
                                                </div> */}
                                                </StyledTableCell>
                                              </Link>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.ClaimID}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.patientAccountNumber
                                                  ? data.patientAccountNumber
                                                  : "-"}
                                              </StyledTableCell>

                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.PatientFirstName}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.PatientLastName}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.DateOfService
                                                  ? moment(
                                                      data.DateOfService
                                                    ).format("MM/DD/YYYY")
                                                  : "-"}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.PatientDOB
                                                  ? data.PatientDOB
                                                  : "-"}
                                              </StyledTableCell>

                                              <StyledTableCell>
                                                {data.InsuranceName
                                                  ? data.InsuranceName
                                                  : "-"}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.PrimaryPolicyNo}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.Physician}
                                              </StyledTableCell>

                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.type}
                                              </StyledTableCell>
                                            </TableRow>
                                          )
                                        )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <div
                                  style={{
                                    marginLeft: "3%",
                                    marginTop: "20px",
                                  }}
                                >
                                  <ReactPaginate
                                    previousLabel={<IoIosArrowBack />}
                                    nextLabel={<IoIosArrowForward />}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                  />
                                </div>
                              </div>
                            )}
                          {this.state.searchclaimdata &&
                            this.state.activeTab === "IVR" && (
                              <div style={{ fontSize: "13px" }}>
                                <TableContainer style={{ fontSize: "13px" }}>
                                  <Table
                                  // sx={{ minWidth: 700 }}
                                  // className="table__card"
                                  // aria-label="customized table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                
                                        <StyledTableCell>
                                          
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("claimid")
                                          }
                                          style={{ whiteSpace: "nowrap" , fontSize:"13px"}}
                                        >
                                          ClaimID
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("patientAccountNumber")
                                          }
                                          style={{ whiteSpace: "nowrap" , fontSize:"13px"}}
                                        >
                                          Account No{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("PatientFirstName")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          FirstName{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("PatientLastName")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          LastName{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("DateOfService")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          DOS{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("PatientDOB")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          DOB{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("InsuranceName")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Insurance Name{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("PrimaryPolicyNo")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Policy No{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("Physician")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Physician Name{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() => this.sorting("type")}
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Type{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("ClaimStatus")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Status
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.searchclaimdata &&
                                        this.state.searchclaimdata.map(
                                          (data, index) => (
                                            <TableRow key={data._id} style={{fontSize:"13px"}}>
                                                     <StyledTableCell
                                                  onClick={() =>
                                                    this.setState(
                                                      {
                                                        Eligibilityclaimid:
                                                          data.ClaimID,
                                                        firstnamevalue:
                                                          data.PatientFirstName,
                                                        lastnamevalue:
                                                          data.PatientLastName,
                                                        payerid: data.PayerID,
                                                        orgname:
                                                          data.BillingOrganizationName,
                                                        npi: data.Npi,
                                                        memberid:
                                                          data.PrimaryPolicyNo,
                                                        firstname:
                                                          data.PatientFirstName,
                                                        lastname:
                                                          data.PatientLastName,
                                                        gender: data.Gender,
                                                        dob: data.dob,
                                                        dos: data.DateOfService,
                                                        beginingdos:
                                                          data.BeginingDOS,
                                                        enddos: data.EndDOS,
                                                        clientideli:
                                                          data.clientId,
                                                        _idvalue: data._id,
                                                        insurname:
                                                          data.InsuranceName,
                                                        plancode: data.PlanCode,
                                                        loc: data.Location,
                                                        physician:
                                                          data.Physician,
                                                        mrn: data.MRN,
                                                        patientno:
                                                          data.patientAccountNumber,
                                                        status: data.status,
                                                        planBeginfromInNetwork:
                                                          data.planBeginfromInNetwork,
                                                        planBegintoInNetwork:
                                                          data.planBegintoInNetwork,
                                                        copayInNetwork:
                                                          data.copayInNetwork,
                                                        coinsuranceInNetwork:
                                                          data.coinsuranceInNetwork,
                                                        annualDeductibleTotalInNetwork:
                                                          data.annualDeductibleTotalInNetwork,
                                                        annualDeductibleMetInNetwork:
                                                          data.annualDeductibleMetInNetwork,
                                                        annualDeductibleOpenInNetwork:
                                                          data.annualDeductibleOpenInNetwork,
                                                        unitsTotalInNetwork:
                                                          data.unitsTotalInNetwork,
                                                        unitsMetInNetwork:
                                                          data.unitsMetInNetwork,
                                                        unitsOpenInNetwork:
                                                          data.unitsOpenInNetwork,
                                                        serviceAllowedAmountTotalInNetwork:
                                                          data.serviceAllowedAmountTotalInNetwork,
                                                        serviceAllowedAmountMetInNetwork:
                                                          data.serviceAllowedAmountMetInNetwork,
                                                        serviceAllowedAmountOpenInNetwork:
                                                          data.serviceAllowedAmountOpenInNetwork,
                                                        planBeginfromOutofNetwork:
                                                          data.planBeginfromOutofNetwork,
                                                        planBegintoOutofNetwork:
                                                          data.planBegintoOutofNetwork,
                                                        copayOutofNetwork:
                                                          data.copayOutofNetwork,
                                                        coinsuranceOutofNetwork:
                                                          data.coinsuranceOutofNetwork,
                                                        annualDeductibleTotalOutofNetwork:
                                                          data.annualDeductibleTotalOutofNetwork,
                                                        typefor: data.type,
                                                        annualDeductibleMetOutofNetwork:
                                                          data.annualDeductibleMetOutofNetwork,
                                                        annualDeductibleOpenOutofNetwork:
                                                          data.annualDeductibleOpenOutofNetwork,
                                                        unitsTotalOutofNetwork:
                                                          data.unitsTotalOutofNetwork,
                                                        unitsMetOutofNetwork:
                                                          data.unitsMetOutofNetwork,
                                                        unitsOpenOutofNetwork:
                                                          data.unitsOpenOutofNetwork,
                                                        serviceAllowedAmountTotalOutofNetwork:
                                                          data.serviceAllowedAmountTotalOutofNetwork,
                                                        serviceAllowedAmountMetOutofNetwork:
                                                          data.serviceAllowedAmountMetOutofNetwork,
                                                        serviceAllowedAmountOpenOutofNetwork:
                                                          data.serviceAllowedAmountOpenOutofNetwork,
                                                      },
                                                      () =>
                                                        this.getpasteligibility(),
                                                      this.gettable()
                                                    )
                                                  }
                                                >
                                              <Link
                                                style={{ color: "black" }}
                                                to={{
                                                  pathname:
                                                    "/viewmoreeligibility",
                                                }}
                                                onClick={() => (
                                                  <Viewmoreeligibility
                                                    value={
                                                      sessionStorage.setItem(
                                                        "Eligibilityclaimid",
                                                        data.ClaimID
                                                      ) ||
                                                      sessionStorage.setItem(
                                                        "modulename",
                                                        "Eligibility Tracker"
                                                      )
                                                    }
                                                  ></Viewmoreeligibility>
                                                )}
                                              >
                                        
                                                  {this.state.activeTab ===
                                                    "Web" &&
                                                    (data.status ===
                                                    "Active Coverage" ? (
                                                      <Tooltip
                                                        title="Active"
                                                        placement="top"
                                                      >
                                                        <Icon
                                                          icon="dashicons:yes-alt"
                                                          color="green"
                                                          fontSize="22px"
                                                        />
                                                      </Tooltip>
                                                    ) : (
                                                      data.status ===
                                                        "Inactive" && (
                                                        <Tooltip
                                                          title="Inactive"
                                                          placement="top"
                                                        >
                                                          <Icon
                                                            icon="gridicons:cross-circle"
                                                            color="red"
                                                            fontSize="22px"
                                                          />
                                                        </Tooltip>
                                                      )
                                                    ))}

                                                  {this.state.activeTab ===
                                                    "Web" &&
                                                    (data.status === "Error" ? (
                                                      <Tooltip
                                                        title="Error"
                                                        placement="top"
                                                      >
                                                        <Icon
                                                          icon="ic:outline-error"
                                                          color="yellow"
                                                          fontSize="22px"
                                                        />
                                                      </Tooltip>
                                                    ) : null)}
                                                  {this.state.activeTab ===
                                                    "IVR" && (
                                                    <div>
                                                      <img
                                                        src={eicon}
                                                        className="ivreicon"
                                                        alt="notfound"
                                                        onClick={(e) => {
                                                          this.toggle3();
                                                        }}
                                                      ></img>
                                                    </div>
                                                  )}
                                                  {this.state.activeTab ===
                                                    "Manual" && (
                                                    <div>
                                                      <img
                                                        src={eicon}
                                                        className="outcomeeicon"
                                                        alt="notfound"
                                                        onClick={(e) => {
                                                          this.toggle3();
                                                        }}
                                                      ></img>
                                                    </div>
                                                  )}
                                              </Link>
                                              </StyledTableCell>

                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.ClaimID}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.patientAccountNumber
                                                  ? data.patientAccountNumber
                                                  : "-"}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.PatientFirstName}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.PatientLastName}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.DateOfService
                                                  ? moment(
                                                      data.DateOfService
                                                    ).format("MM/DD/YYYY")
                                                  : "-"}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.PatientDOB
                                                  ? data.PatientDOB
                                                  : "-"}
                                              </StyledTableCell>

                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.InsuranceName
                                                  ? data.InsuranceName
                                                  : "-"}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.PrimaryPolicyNo}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.Physician}
                                              </StyledTableCell>

                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.type}
                                              </StyledTableCell>
                                            </TableRow>
                                          )
                                        )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <div
                                  style={{
                                    marginLeft: "3%",
                                    marginTop: "20px",
                                  }}
                                >
                                  <ReactPaginate
                                    previousLabel={<IoIosArrowBack />}
                                    nextLabel={<IoIosArrowForward />}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                  />
                                </div>
                              </div>
                            )}
                          {this.state.searchclaimdata &&
                            this.state.activeTab === "Manual" && (
                              <div style={{ fontSize: "13px" }}>
                                <TableContainer style={{ fontSize: "13px" }}>
                                  <Table
                                  // sx={{ minWidth: 700 }}
                                  // className="table__card"
                                  // aria-label="customized table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                     
                                        <StyledTableCell>
                                        
                                        </StyledTableCell>
                                        <StyledTableCell
                                        
                                          onClick={() =>
                                            this.sorting("claimid")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          ClaimID
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("patientAccountNumber")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Account No{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("PatientFirstName")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                           FirstName{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("PatientLastName")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          LastName{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("DateOfService")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          DOS{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("PatientDOB")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          DOB{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("InsuranceName")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Insurance Name{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("PrimaryPolicyNo")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Policy No{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("Physician")
                                          }
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Physician Name{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() => this.sorting("type")}
                                          style={{ whiteSpace: "nowrap", fontSize:"13px" }}
                                        >
                                          Type{" "}
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          onClick={() =>
                                            this.sorting("ClaimStatus")
                                          }
                                          style={{ whiteSpace: "nowrap" , fontSize:"13px"}}
                                        >
                                          Status
                                          {this.state.openicon ? (
                                            <BiUpArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BiDownArrowAlt
                                              onClick={() =>
                                                this.setState({
                                                  openicon: true,
                                                })
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody style={{fontSize:"13px"}}>
                                      {this.state.searchclaimdata &&
                                        this.state.searchclaimdata.map(
                                          (data, index) => (
                                            <TableRow key={data._id} style={{fontSize:"13px"}}>
                                              <StyledTableCell
                                                  onClick={() =>
                                                    this.setState(
                                                      {
                                                        Eligibilityclaimid:
                                                          data.ClaimID,
                                                        firstnamevalue:
                                                          data.PatientFirstName,
                                                        lastnamevalue:
                                                          data.PatientLastName,
                                                        payerid: data.PayerID,
                                                        orgname:
                                                          data.BillingOrganizationName,
                                                        npi: data.Npi,
                                                        memberid:
                                                          data.PrimaryPolicyNo,
                                                        firstname:
                                                          data.PatientFirstName,
                                                        lastname:
                                                          data.PatientLastName,
                                                        gender: data.Gender,
                                                        dob: data.dob,
                                                        dos: data.DateOfService,
                                                        beginingdos:
                                                          data.BeginingDOS,
                                                        enddos: data.EndDOS,
                                                        clientideli:
                                                          data.clientId,
                                                        _idvalue: data._id,
                                                        insurname:
                                                          data.InsuranceName,
                                                        plancode: data.PlanCode,
                                                        loc: data.Location,
                                                        physician:
                                                          data.Physician,
                                                        mrn: data.MRN,
                                                        patientno:
                                                          data.patientAccountNumber,
                                                        status: data.status,
                                                        planBeginfromInNetwork:
                                                          data.planBeginfromInNetwork,
                                                        planBegintoInNetwork:
                                                          data.planBegintoInNetwork,
                                                        copayInNetwork:
                                                          data.copayInNetwork,
                                                        coinsuranceInNetwork:
                                                          data.coinsuranceInNetwork,
                                                        annualDeductibleTotalInNetwork:
                                                          data.annualDeductibleTotalInNetwork,
                                                        annualDeductibleMetInNetwork:
                                                          data.annualDeductibleMetInNetwork,
                                                        annualDeductibleOpenInNetwork:
                                                          data.annualDeductibleOpenInNetwork,
                                                        unitsTotalInNetwork:
                                                          data.unitsTotalInNetwork,
                                                        unitsMetInNetwork:
                                                          data.unitsMetInNetwork,
                                                        unitsOpenInNetwork:
                                                          data.unitsOpenInNetwork,
                                                        serviceAllowedAmountTotalInNetwork:
                                                          data.serviceAllowedAmountTotalInNetwork,
                                                        serviceAllowedAmountMetInNetwork:
                                                          data.serviceAllowedAmountMetInNetwork,
                                                        serviceAllowedAmountOpenInNetwork:
                                                          data.serviceAllowedAmountOpenInNetwork,
                                                        planBeginfromOutofNetwork:
                                                          data.planBeginfromOutofNetwork,
                                                        planBegintoOutofNetwork:
                                                          data.planBegintoOutofNetwork,
                                                        copayOutofNetwork:
                                                          data.copayOutofNetwork,
                                                        coinsuranceOutofNetwork:
                                                          data.coinsuranceOutofNetwork,
                                                        annualDeductibleTotalOutofNetwork:
                                                          data.annualDeductibleTotalOutofNetwork,
                                                        typefor: data.type,
                                                        annualDeductibleMetOutofNetwork:
                                                          data.annualDeductibleMetOutofNetwork,
                                                        annualDeductibleOpenOutofNetwork:
                                                          data.annualDeductibleOpenOutofNetwork,
                                                        unitsTotalOutofNetwork:
                                                          data.unitsTotalOutofNetwork,
                                                        unitsMetOutofNetwork:
                                                          data.unitsMetOutofNetwork,
                                                        unitsOpenOutofNetwork:
                                                          data.unitsOpenOutofNetwork,
                                                        serviceAllowedAmountTotalOutofNetwork:
                                                          data.serviceAllowedAmountTotalOutofNetwork,
                                                        serviceAllowedAmountMetOutofNetwork:
                                                          data.serviceAllowedAmountMetOutofNetwork,
                                                        serviceAllowedAmountOpenOutofNetwork:
                                                          data.serviceAllowedAmountOpenOutofNetwork,
                                                      },
                                                      () =>
                                                        this.getpasteligibility(),
                                                      this.gettable()
                                                    )
                                                  }
                                                >
                                                  {this.state.activeTab ===
                                                    "Web" &&
                                                    (data.status ===
                                                    "Active Coverage" ? (
                                                      <Tooltip
                                                        title="Active"
                                                        placement="top"
                                                      >
                                                        <Icon
                                                          icon="dashicons:yes-alt"
                                                          color="green"
                                                          fontSize="22px"
                                                        />
                                                      </Tooltip>
                                                    ) : (
                                                      data.status ===
                                                        "Inactive" && (
                                                        <Tooltip
                                                          title="Inactive"
                                                          placement="top"
                                                        >
                                                          <Icon
                                                            icon="gridicons:cross-circle"
                                                            color="red"
                                                            fontSize="22px"
                                                          />
                                                        </Tooltip>
                                                      )
                                                    ))}

                                                  {this.state.activeTab ===
                                                    "Web" &&
                                                    (data.status === "Error" ? (
                                                      <Tooltip
                                                        title="Error"
                                                        placement="top"
                                                      >
                                                        <Icon
                                                          icon="ic:outline-error"
                                                          color="yellow"
                                                          fontSize="22px"
                                                        />
                                                      </Tooltip>
                                                    ) : null)}
                                                  {this.state.activeTab ===
                                                    "IVR" && (
                                                    <div>
                                                      <img
                                                        src={eicon}
                                                        className="ivreicon"
                                                        alt="notfound"
                                                        onClick={(e) => {
                                                          this.toggle3();
                                                        }}
                                                      ></img>
                                                    </div>
                                                  )}
                                                  {this.state.activeTab ===
                                                    "Manual" && (
                                                    <div>
                                                      <img
                                                        src={eicon}
                                                        className="ivreicon"
                                                        alt="notfound"
                                                        onClick={(e) => {
                                                          this.toggle3();
                                                        }}
                                                      ></img>
                                                    </div>
                                                  )}
                                            
                                              <Link
                                                style={{ color: "black" }}
                                                to={{
                                                  pathname:
                                                    "/viewmoreeligibility",
                                                }}
                                                onClick={() => (
                                                  <Viewmoreeligibility
                                                    value={
                                                      sessionStorage.setItem(
                                                        "Eligibilityclaimid",
                                                        data.ClaimID
                                                      ) ||
                                                      sessionStorage.setItem(
                                                        "modulename",
                                                        "Eligibility Tracker"
                                                      )
                                                    }
                                                  ></Viewmoreeligibility>
                                                )}
                                              >
                                            
                                              </Link>
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.ClaimID}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.patientAccountNumber
                                                  ? data.patientAccountNumber
                                                  : "-"}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.PatientFirstName}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.PatientLastName}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.DateOfService
                                                  ? moment(
                                                      data.DateOfService
                                                    ).format("MM/DD/YYYY")
                                                  : "-"}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.PatientDOB
                                                  ? data.PatientDOB
                                                  : "-"}
                                              </StyledTableCell>

                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.InsuranceName
                                                  ? data.InsuranceName
                                                  : "-"}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.PrimaryPolicyNo}
                                              </StyledTableCell>
                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.Physician}
                                              </StyledTableCell>

                                              <StyledTableCell style={{fontSize:"13px"}}>
                                                {data.type}
                                              </StyledTableCell>
                                            </TableRow>
                                          )
                                        )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <div
                                  style={{
                                    marginLeft: "3%",
                                    marginTop: "20px",
                                  }}
                                >
                                  <ReactPaginate
                                    previousLabel={<IoIosArrowBack />}
                                    nextLabel={<IoIosArrowForward />}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal
          // // style={{ width: "30%" }}
          isOpen={this.state.outcomedata}
          onHide={this.toggle3}
          onClose={this.handleClose}
          onExit={this.reset}
          size="lg"
          centered
          style={{fontSize:"13px"}}
        >
          <ModalHeader>
            <button
              className="Neweligibility"
              onClick={(event, tab) => {
                this.setState({ tabindex: 1 });
              }}
            >
              New Eligibility
            </button>

            <button
              className="Pasteligibility"
              onClick={(event, tab) => {
                this.setState({ tabindex: 2 });
              }}
            >
              Past Eligibility
            </button>

            <button
              className="Authorization"
              // onClick={(event, tab) => {
              //   this.setState({ tabindex: 3 });
              // }}
            >
              Authorization
            </button>
          </ModalHeader>

          {this.state.tabindex === 1 && (
            <div>
              <ModalBody>
                <Row style={{ margin: "0px" }}>
                  <div style={{ width: "100%" }}>
                    <Row>
                      <Col> Service Date</Col>
                      <Col md="6">
                        <Input
                          type="date"
                          defaultValue={this.state.patientdobnew}
                          onChange={(e) => {
                            this.setState({
                              patientdobnew: e.target.value,
                            });
                          }}
                        ></Input>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>Payer</Col>
                      <Col md="6">
                        <Input
                          type="text"
                          value={this.state.insurname}
                          onChange={(e) => {
                            this.setState({ insurname: e.target.value });
                          }}
                        ></Input>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>Service Type</Col>
                      <Col md="6">
                        <Input
                          type="select"
                          style={{ width: "100%" }}
                          className="form-control form-select"
                          value={this.state.servicetypevalue}
                          onChange={(e) =>
                            this.setState({
                              servicetypevalue: e.target.value,
                            })
                          }
                        >
                          <option value="" hidden>
                            Select Service type...
                          </option>
                          {this.state.ServiceType.map((data, index) => (
                            <option selected={data.serviceType}>
                              {data.serviceType}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>Billing Provider</Col>
                      <Col md="6">
                        <Input
                          type="text"
                          value={this.state.orgname + this.state.npi}
                        ></Input>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Col>
                  <button
                    className="manualupdate"
                    onClick={(e) => {
                      this.toggle4();
                      this.handleClose();
                    }}
                  >
                    Manually Update
                  </button>
                </Col>
                <Col>
                  <button className="check" onClick={this.checkeligibility}>
                    Check
                  </button>
                </Col>
                <Col>
                  <button
                    className="closebtton"
                    onClick={() => {
                      this.toggle3();
                    }}
                  >
                    Close
                  </button>
                </Col>
              </ModalFooter>
            </div>
          )}

          {this.state.tabindex === 2 && (
            <div>
              <ModalBody style={{ marginTop: "-3%" }}>
                {this.state.loadingpast ? (
                  <div>
                    <p
                      style={{
                        fontSize: "23px",
                        marginTop: "10px",
                        color: "#000",
                      }}
                    >
                      <span>Loading...</span>
                      <span>Please Wait...</span>
                    </p>
                    <div className="boxes body__loader dribbble">
                      <div className="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                ) : this.state.pasteligibility.length === 0 ? (
                  /* <img src={nodata} alt="" className="nodata" /> */
                  <div className="bot-ui">
                    <div className="bot-head">
                      <div className="eyes-container">
                        <div className="to-left eye"></div>
                        <div className="to-right eye"></div>
                      </div>
                    </div>
                    <div className="bot-body">
                      <div className="analyzer"></div>
                    </div>
                    <p style={{ fontSize: "13px", fontStyle: "italic" }}>
                      No Data Found
                    </p>
                  </div>
                ) : (
                  <div style={{ width: "96%", marginTop: "4%" }}>
                    <Table
                      className="table table-striped table-bordered"
                      bordered
                    >
                      <TableHead>
                        <StyledTableCell>Service date </StyledTableCell>
                        <StyledTableCell>Payer name</StyledTableCell>
                        <StyledTableCell>Insurance Id</StyledTableCell>
                        <StyledTableCell>Service type</StyledTableCell>
                        <StyledTableCell>Coverage Status</StyledTableCell>
                      </TableHead>
                      <TableBody>
                        {this.state.pasteligibility &&
                          this.state.pasteligibility.map((data, index) => {
                            return (
                              <tr>
                                <StyledTableCell>
                                  {data.DateOfService
                                    ? moment(data.DateOfService).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.InsuranceName
                                    ? data.InsuranceName
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.PrimaryPolicyNo
                                    ? data.PrimaryPolicyNo
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {this.state.pasteligibilityworked}&nbsp;-
                                  {this.state.pasteligistatus}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.status ? data.status : "-"}
                                </StyledTableCell>
                              </tr>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Col></Col>
                <Col>
                  <button className="check" onClick={this.checkeligibility}>
                    Check
                  </button>
                </Col>
                <Col>
                  <button
                    className="closebtton"
                    onClick={() => {
                      this.toggle3();
                    }}
                  >
                    Close
                  </button>
                </Col>
              </ModalFooter>
            </div>
          )}
          {/* {this.state.tabindex === 3 && (
            <div>
              <p>Hello</p>
            </div>
          )} */}
        </Modal>

        {this.state.manualupdate && (
          <Modal
            // // style={{ width: "30%" }}
            isOpen={this.state.manualupdate}
            onHide={this.toggle4}
            onClose={this.handleClose}
            onExit={this.reset}
            size="lg"
            centered
            style={{ width: "1000px", height: "40px", fontSize:"13px" }}
          >
            <ModalBody
              style={{
                "max-height": "calc(110vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <Table
                style={{
                  borderCollapse: "collapse",
                  border: "1px solid black",
                  padding: "5px",
                  textAlign: "left",
                  overflowX: "scroll",
                }}
                border
              >
                <tbody
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                    padding: "5px",
                    textAlign: "left",
                    overflowX: "scroll",
                  }}
                >
                  <tr
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "left",
                    }}
                  >
                    <th>
                      {/* <Label style={{ width: "200px", height: "5px" }}> */}
                      Name
                      {/* </Label> */}
                    </th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      <Input
                        style={{ width: "200px", height: "40px" }}
                        type="text"
                        value={this.state.firstname}
                        onChange={(e) =>
                          this.setState({
                            firstname: e.target.value,
                          })
                        }
                      />
                    </td>

                    <th
                      style={{
                        width: "200px",
                        height: "40px",
                        textAlign: "left",
                      }}
                      className="label required1"
                      rowspan="3"
                    >
                      {" "}
                      Eligibility status
                    </th>
                    <td
                      rowspan="3"
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      <div>
                        <Input
                          style={{ width: "200px", height: "40px" }}
                          type="text"
                          value={this.state.status}
                          onChange={(e) =>
                            this.setState({
                              status: e.target.value,
                              selectuserErr: "",
                            })
                          }
                        ></Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.selectuserErr}
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "left",
                    }}
                  >
                    <th>DOB</th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      <Input
                        style={{ width: "200px", height: "40px" }}
                        type="text"
                        value={this.state.dob}
                        onChange={(e) =>
                          this.setState({
                            dob: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "left",
                    }}
                  >
                    <th>Gender</th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      <Input
                        style={{ width: "200px", height: "40px" }}
                        type="text"
                        value={this.state.gender}
                        onChange={(e) =>
                          this.setState({
                            gender: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "left",
                      width: "200px",

                      height: "10px",
                    }}
                  >
                    <th>Policy Number</th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      <Input
                        style={{ width: "200px", height: "40px" }}
                        type="text"
                        value={this.state.memberid}
                        onChange={(e) =>
                          this.setState({
                            memberid: e.target.value,
                          })
                        }
                      />
                    </td>
                    <th style={{ width: "200px", height: "40px" }}>
                      Provider Information
                    </th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      <Input
                        style={{ width: "200px", height: "40px" }}
                        type="text"
                        value={this.state.orgname}
                        onChange={(e) =>
                          this.setState({
                            orgname: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "left",
                    }}
                  >
                    <th>DOS</th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      <Input
                        style={{ width: "200px", height: "40px" }}
                        type="text"
                        value={this.state.dos}
                        onChange={(e) =>
                          this.setState({
                            dos: e.target.value,
                          })
                        }
                      />
                    </td>
                    <th>Provider NPI</th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      <Input
                        style={{ width: "200px", height: "40px" }}
                        type="text"
                        value={this.state.npi}
                        onChange={(e) =>
                          this.setState({
                            npi: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "left",
                    }}
                  >
                    <th colspan="2" style={{ textAlign: "center" }}>
                      In Network
                    </th>
                    <th colspan="2" style={{ textAlign: "center" }}>
                      Out of Network
                    </th>
                  </tr>
                  <tr
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "left",
                    }}
                  >
                    <th
                      className="label required1"
                      style={{ color: "#FF2E2E" }}
                    >
                      Plan begin from(Date)
                    </th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      <Input
                        style={{ width: "200px", height: "40px" }}
                        type="text"
                        value={this.state.planBeginfromInNetwork}
                        onChange={(e) =>
                          this.setState({
                            planBeginfromInNetwork: e.target.value,
                            selectuserErr2: "",
                          })
                        }
                      ></Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.selectuserErr2}
                      </div>
                    </td>
                    <th
                      style={{
                        color: "#FF2E2E",
                      }}
                      className="label required1"
                    >
                      Plan begin from(Date)
                    </th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      <Input
                        style={{ width: "200px", height: "40px" }}
                        type="text"
                        value={this.state.planBeginfromOutofNetwork}
                        onChange={(e) =>
                          this.setState({
                            planBeginfromOutofNetwork: e.target.value,
                            selectuserErr3: "",
                          })
                        }
                      ></Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.selectuserErr3}
                      </div>
                    </td>
                  </tr>
                  <tr
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "left",
                    }}
                  >
                    <th>Plan begin To(Date)</th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      <Input
                        style={{ width: "200px", height: "40px" }}
                        type="text"
                        value={this.state.planBegintoInNetwork}
                        onChange={(e) =>
                          this.setState({
                            planBegintoInNetwork: e.target.value,
                          })
                        }
                      />
                    </td>
                    <th>Plan begin To(Date)</th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      <Input
                        style={{ width: "200px", height: "40px" }}
                        type="text"
                        value={this.state.planBegintoOutofNetwork}
                        onChange={(e) =>
                          this.setState({
                            planBegintoOutofNetwork: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "left",
                    }}
                  >
                    <th>Copay</th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "right",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            position: "absolute",
                            marginLeft: "-26px",
                            marginTop: "8px",
                          }}
                        >
                          $
                        </span>
                        <Input
                          style={{ width: "200px", height: "40px" }}
                          type="text"
                          value={this.state.copayInNetwork}
                          onChange={(e) =>
                            this.setState({
                              copayInNetwork: e.target.value,
                            })
                          }
                        />
                      </div>
                    </td>
                    <th>Copay</th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "right",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            position: "absolute",
                            marginLeft: "-20px",
                            marginTop: "2px",
                          }}
                        >
                          $
                        </span>
                        <Input
                          type="text"
                          value={this.state.copayOutofNetwork}
                          onChange={(e) =>
                            this.setState({
                              copayOutofNetwork: e.target.value,
                            })
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "left",
                    }}
                  >
                    <th>Co insurance</th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "right",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            position: "absolute",
                            marginLeft: "-20px",
                            marginTop: "2px",
                          }}
                        >
                          %
                        </span>
                        <Input
                          type="text"
                          value={this.state.coinsuranceInNetwork}
                          onChange={(e) =>
                            this.setState({
                              coinsuranceInNetwork: e.target.value,
                            })
                          }
                        />
                      </div>
                    </td>
                    <th>Co insurance</th>
                    <td
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "right",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            position: "absolute",
                            marginLeft: "-20px",
                            marginTop: "2px",
                          }}
                        >
                          %
                        </span>
                        <Input
                          type="text"
                          value={this.state.coinsuranceOutofNetwork}
                          onChange={(e) =>
                            this.setState({
                              coinsuranceOutofNetwork: e.target.value,
                            })
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "left",
                    }}
                  >
                    <th
                      rowSpan={1}
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      Annual Deductible
                    </th>
                    <tr rowSpan={1}>
                      <tr colSpan={3}>
                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Total
                        </th>

                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Met
                        </th>

                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Open
                        </th>
                      </tr>
                      <tr
                        colSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: "5px",
                          textAlign: "left",
                        }}
                      >
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              $
                            </span>
                            <Input
                              type="text"
                              value={this.state.annualDeductibleTotalInNetwork}
                              onChange={(e) =>
                                this.setState({
                                  annualDeductibleTotalInNetwork:
                                    e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              $
                            </span>
                            <Input
                              type="text"
                              value={this.state.annualDeductibleMetInNetwork}
                              onChange={(e) =>
                                this.setState({
                                  annualDeductibleMetInNetwork: e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              $
                            </span>
                            <Input
                              type="text"
                              value={this.state.annualDeductibleOpenInNetwork}
                              onChange={(e) =>
                                this.setState({
                                  annualDeductibleOpenInNetwork: e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    </tr>
                    <th
                      rowSpan={1}
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      Annual Deductible
                    </th>
                    <tr rowSpan={1}>
                      <tr colSpan={3}>
                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Total
                        </th>

                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Met
                        </th>

                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Open
                        </th>
                      </tr>
                      <tr
                        colSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: "5px",
                          textAlign: "left",
                        }}
                      >
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              $
                            </span>
                            <Input
                              type="text"
                              value={
                                this.state.annualDeductibleTotalOutofNetwork
                              }
                              onChange={(e) =>
                                this.setState({
                                  annualDeductibleTotalOutofNetwork:
                                    e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              $
                            </span>
                            <Input
                              type="text"
                              value={this.state.annualDeductibleMetOutofNetwork}
                              onChange={(e) =>
                                this.setState({
                                  annualDeductibleMetOutofNetwork:
                                    e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              $
                            </span>
                            <Input
                              type="text"
                              value={
                                this.state.annualDeductibleOpenOutofNetwork
                              }
                              onChange={(e) =>
                                this.setState({
                                  annualDeductibleOpenOutofNetwork:
                                    e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    </tr>
                  </tr>

                  <tr
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "left",
                    }}
                  >
                    <th
                      rowSpan={1}
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      Units/ Days
                    </th>
                    <tr rowSpan={1}>
                      <tr colSpan={3}>
                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Total
                        </th>

                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Met
                        </th>

                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Open
                        </th>
                      </tr>
                      <tr
                        colSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: "5px",
                          textAlign: "left",
                        }}
                      >
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              #
                            </span>
                            <Input
                              type="text"
                              value={this.state.unitsTotalInNetwork}
                              onChange={(e) =>
                                this.setState({
                                  unitsTotalInNetwork: e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              #
                            </span>
                            <Input
                              type="text"
                              value={this.state.unitsMetInNetwork}
                              onChange={(e) =>
                                this.setState({
                                  unitsMetInNetwork: e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              #
                            </span>
                            <Input
                              type="text"
                              value={this.state.unitsOpenInNetwork}
                              onChange={(e) =>
                                this.setState({
                                  unitsOpenInNetwork: e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    </tr>
                    <th
                      rowSpan={1}
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      Units/ Days
                    </th>
                    <tr rowSpan={1}>
                      <tr colSpan={3}>
                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Total
                        </th>

                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Met
                        </th>

                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Open
                        </th>
                      </tr>
                      <tr
                        colSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: "5px",
                          textAlign: "left",
                        }}
                      >
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              #
                            </span>
                            <Input
                              type="text"
                              value={this.state.unitsTotalOutofNetwork}
                              onChange={(e) =>
                                this.setState({
                                  unitsTotalOutofNetwork: e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              #
                            </span>
                            <Input
                              type="text"
                              value={this.state.unitsMetOutofNetwork}
                              onChange={(e) =>
                                this.setState({
                                  unitsMetOutofNetwork: e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              #
                            </span>
                            <Input
                              type="text"
                              value={this.state.unitsOpenOutofNetwork}
                              onChange={(e) =>
                                this.setState({
                                  unitsOpenOutofNetwork: e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    </tr>
                  </tr>
                  <tr
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "left",
                    }}
                  >
                    <th
                      rowSpan={1}
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      Service allowable amount
                    </th>
                    <tr rowSpan={1}>
                      <tr colSpan={3}>
                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Total
                        </th>

                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Met
                        </th>

                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Open
                        </th>
                      </tr>
                      <tr
                        colSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: "5px",
                          textAlign: "left",
                        }}
                      >
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              marginLeft: "1px",
                              marginTop: "6px",
                            }}
                          >
                            $
                          </span>
                          <Input
                            type="text"
                            value={
                              this.state.serviceAllowedAmountTotalInNetwork
                            }
                            onChange={(e) =>
                              this.setState({
                                serviceAllowedAmountTotalInNetwork:
                                  e.target.value,
                              })
                            }
                          />
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              marginLeft: "1px",
                              marginTop: "6px",
                            }}
                          >
                            $
                          </span>
                          <Input
                            type="text"
                            value={this.state.serviceAllowedAmountMetInNetwork}
                            onChange={(e) =>
                              this.setState({
                                serviceAllowedAmountMetInNetwork:
                                  e.target.value,
                                // serviceAllowedAmountMetInNetworkErr: "",
                              })
                            }
                          />
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              marginLeft: "1px",
                              marginTop: "6px",
                            }}
                          >
                            $
                          </span>
                          <Input
                            type="text"
                            value={this.state.serviceAllowedAmountOpenInNetwork}
                            onChange={(e) =>
                              this.setState({
                                serviceAllowedAmountOpenInNetwork:
                                  e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                    </tr>
                    <th
                      rowSpan={1}
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      Service allowable amount
                    </th>
                    <tr rowSpan={1}>
                      <tr colSpan={3}>
                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Total
                        </th>

                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Met
                        </th>

                        <th
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          Open
                        </th>
                      </tr>
                      <tr
                        colSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: "5px",
                          textAlign: "left",
                        }}
                      >
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              $
                            </span>
                            <Input
                              type="text"
                              value={
                                this.state.serviceAllowedAmountTotalOutofNetwork
                              }
                              onChange={(e) =>
                                this.setState({
                                  serviceAllowedAmountTotalOutofNetwork:
                                    e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              $
                            </span>
                            <Input
                              type="text"
                              value={
                                this.state.serviceAllowedAmountMetOutofNetwork
                              }
                              onChange={(e) =>
                                this.setState({
                                  serviceAllowedAmountMetOutofNetwork:
                                    e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "left",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "1px",
                                marginTop: "6px",
                              }}
                            >
                              $
                            </span>
                            <Input
                              type="text"
                              value={
                                this.state.serviceAllowedAmountOpenOutofNetwork
                              }
                              onChange={(e) =>
                                this.setState({
                                  serviceAllowedAmountOpenOutofNetwork:
                                    e.target.value,
                                })
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    </tr>
                  </tr>
                </tbody>
              </Table>
            </ModalBody>
            <ModalFooter>
              <button
                className="Manualupdatesave"
                onClick={this.editmanualupdate}
              >
                Save
              </button>
              <button
                className="Manualupdateclose"
                onClick={(e) => {
                  this.toggle4();
                }}
              >
                Cancel
              </button>
            </ModalFooter>
          </Modal>
        )}
      </div>
      </div>
    );
  }
}

export default OutcomeData;
