import React, { useState, useEffect, useRef } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import "../../Styles/DataConversionOCR.css";
import { ip} from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link } from "react-router-dom";
import datafile from "../../Files/DeleteClaims.xlsx";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import excel from "../../Images/excel.png";
import { AiOutlineDownload } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { MdOutlineFileUpload } from "react-icons/md";

const DataDelete = () => {
  const [file, setFile] = useState(null);
  const [datadeletiondata, setDatadeletiondata] = useState([]);
  const [currentpage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const perPage = 10;
  const refreshtoken = sessionStorage.getItem("refreshtoken");
  const accesstoken = sessionStorage.getItem("accesstoken");
  const client = sessionStorage.getItem("clientid");
  const username =
    sessionStorage.getItem("firstname") +
    " " +
    sessionStorage.getItem("lastname");

  const fileInput = useRef();

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onFileUpload = async (e) => {
    if (!file) {
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    e.preventDefault();
    const value = new FormData();
    value.append("file", fileInput.current.files[0]);
    value.append("clientids", client);
    value.append("Username", username);
    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${accesstoken}`,
    };
    try {
      const response = await axios.post(
        `${ip}/helper/deleteclaimsandcpts`,
        value,
        { headers }
      );
      if (response.status === 200) {
        toast.success("File Deleted successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 500) {
          toast.error("Server Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        } else if (err.response.status === 401) {
          toast.error("Please reload and try again", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        } else if (err.response.status === 400) {
          toast.error("Please include all the fields in excel data", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        } else {
          toast.error("An unknown error occured", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      }
    }
  };

  const getdatadeletiondata = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    try {
      const res = await axios.get(
        `${ip}/helper/datadeletionlog?clientId=${client}&pageno=${currentpage}&Username=${username}`,
        { headers }
      );
      setPageCount(Math.ceil(res.data.count / perPage));
      setDatadeletiondata(res.data.arfollowuplog);
    } catch (err) {
      // Handle data fetching errors if needed
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffset(offset);
    getdatadeletiondata();
  };

  useEffect(() => {
    getdatadeletiondata();
  }, [currentpage]);

  return (
    <div>
      <Newsidebar name="Data Deletion" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <ToastContainer></ToastContainer>
        <div className="upload_container">
        <input
          type="file"
          id="file"
          ref={fileInput}
          onChange={onFileChange}
          style={{ display: "none" }}
          accept=".xlsx"
        />
        {file ? (
          <div className="file-card-upload">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                flexDirection: "column",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <div className="file-card" style={{ position: "relative" }}>
                  <span>
                    <img src={excel} alt="excel" style={{width:"30px", height:"30px"}}
                    />
                  </span>
                  <div className="file-info">
                    <div style={{ flex: 1 }}>
                      <h6>{file.name}</h6>
                    </div>
                  </div>
                  <button
                    className="close-icon"
                    onClick={() => setFile(null)} // Replace setFile with your state update function
                  > 
                  <IoMdClose />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="file-btn" onClick={() => fileInput.current.click()}>
            <span className="file-circle">
              <MdOutlineFileUpload />
            </span>
            Click file to this area to upload <br />
            <span style={{ color: "GrayText", fontSize: "11px" }}>
              The given input should be in .xlsx format
            </span>
          </div>
        )}
        </div>
        <div className="upload_container1">
          <div className="upload_head">
            <div className="vertical" />
            <span style={{ fontWeight: "800", fontSize: "18px" }}>
              Delete History
            </span>
          </div>
          <div className="btn-group" role="group"
          style={{ boxShadow: "0px 0px 6px rgb(124 119 119 / 50%)" }}
          
          >
          <Link
            style={{ fontWeight: "600", fontSize: "16px" }}
            to={datafile}
            download="DataDeletion.xlsx"
            target="_blank"
          >
            <button type="button" className="btn btn-danger" style={{backgroundColor:"red"}}>
              <AiOutlineDownload style={{ fontSize: "15px" }} />
              &nbsp;&nbsp;Sample
            </button>
          </Link>

          <button
            type="button"
            className="btn"
            onClick={onFileUpload}
          >
            Upload
          </button>
        </div>
        </div>
        <hr/>
        <div className="table-wrapper">
          <table bordered="true">
          <thead>
          <tr className="sticky_upload">
          <th className="sticky_upload">File Name</th>
                  <th className="sticky_upload">Upload At</th>
                  <th className="sticky_upload">Claim Deleted</th>
                  <th className="sticky_upload">Reasons</th>
                  <th className="sticky_upload">Deleted By</th>
                  <th className="sticky_upload">Deletion Upload</th>
                  <th className="sticky_upload">Deletion Status</th>
                  <th className="sticky_upload">Action</th>
          </tr>
          </thead>
          {datadeletiondata.length !== 0 ? (
                <>
              <tbody>
                {datadeletiondata.map((data, index) => (
                  <tr key={index}>
                    <td>{data.ClaimID}</td>
                    <td>{data.UserId}</td>
                    <td>{data.Eventdate}</td>
                  </tr>
                ))}
              </tbody>
              <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={currentpage}
              />
            </div>
            </>
            ):(
             <div    
             style={{
                  alignItems: "center",
                  textAlign: "center",
                }}>
                <p
                style={{
                  fontSize: "20px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                No Data Found
              </p>
              </div>
              )}
          </table> 
          </div>
            </div>
    </div>
  );
};

export default DataDelete;
