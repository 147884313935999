import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Row, Col, Label } from "reactstrap";
import { Input } from "reactstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ip } from "../../Api";
class DenialAllocation extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.state = {
      AllocatedTo: sessionStorage.getItem("AllocatedTo"),
      allocatemodal: false,
      bucketId: "",
      reallocmodal: false,
      anchorEl: null,
      allocateuser: "",
      allocatecount: [],
      show: false,
      show2: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      UserAllocate:
      sessionStorage.getItem("firstname") +
      " " +
      sessionStorage.getItem("lastname"),
      offset: 0,
      currentpage: 0,
      show5: [],
      count: [],
      allocatecount: [],
      status1: [],
      status2: [],
      status3: [],
      status4: [],
      status5: [],
      allocate: ["25", "50", "75", "100", "125", "150"],
      agegrp1: "",
      agegrp2: "",
      agegrp3: "",
      agegrp4: "",
      agegrp5: "",
      perPage: 10,
      query: [],
      users: [],
      selecteduser: [],
      selectuserErr: "",
      objectid: [],
      userid: [],
      claimid: [],
      usersErr: "",
    };
  }

  bulkallocatevalidate = () => {
    let hasErr = "true";
    let usersErr = "";
    if (this.multiselectRef.current.state.selectedValues.length === 0) {
      usersErr = "This field is required";
      this.setState({ usersErr });
      hasErr = false;
    }
    return hasErr;
  };
  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => this.setState({ anchorEl: null });

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentpage: selectedPage,
      offset: offset,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.client !== prevProps.client) {
      this.getusers();
    }
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };

     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getusers = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/users/all?clientId=${this.props.client}`, { headers: headers })
      .then((res) => {
        let data = res.data.user;
        if(this.props.client){
          data = data.filter((item) => {
            return (
              item.role === "Team Member" 
            );
          });
        }
        else{
          data = data.filter((item) => {
            return (
              item.role === "Admin" || item.role==="SuperAdmin"
            );
          });
        }
        
        this.setState({ users: data });
      })

      .catch((err) => {});
  };

  getbucketclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      if (this.props.Location) {
        url = `${ip}/denial/denialclaim?allocation_bucket=${this.props.data}&clientId=${this.props.client}&Location=${this.props.Location}&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/denial/denialclaim?allocation_bucket=${this.props.data}&clientId=${this.props.client}&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      }
    } else {
      if (this.state.Location) {
        url = `${ip}/denial/denialclaim?allocation_bucket=${this.props.data}&clientId=${this.props.client}&Location=${this.props.Location}`;
      } else {
        url = `${ip}/denial/denialclaim?allocation_bucket=${this.props.data}&clientId=${this.props.client}`;
      }
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ objectid: res.data.denial });
      })

      .catch((err) => {});
  };

  validate = () => {
    let hasErr = "true";
    let selectuserErr = "";
    if (this.state.selecteduser.length === 0) {
      selectuserErr = "This field is required";
      this.setState({ selectuserErr });
      hasErr = false;
    }
    return hasErr;
  };
  allocateclaims = () => {
    const isValid = this.validate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        ids: sessionStorage.getItem("ClaimDBID"),
      };
      const res = axios
        .put(
          `${ip}/allocation/allocate/${this.state.selecteduser[0]._id}`,
          value,
          {
            headers: headers,
          }
        )
        .then((res) => {
          alert(
            `claims allocated to user ${this.state.selecteduser[0].first_name}`
          );
          this.toggle4();
          window.location.reload();
        })

        .catch((err) => {});
    }
  };

  allocateusers = async () => {
    const isValid = true;
    let claimid = [];
    // this.getquery();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };

      if (this.state.status1.length !== 0) {
        this.state.userid.push(this.state.status1);

        this.state.count.push(this.state.agegrp1);
      }
      if (this.state.status2.length !== 0) {
        this.state.userid.push(this.state.status2);

        this.state.count.push(this.state.agegrp2);
      }
      if (this.state.status3.length !== 0) {
        this.state.userid.push(this.state.status3);

        this.state.count.push(this.state.agegrp3);
      }
      if (this.state.status4.length !== 0) {
        this.state.userid.push(this.state.status4);

        this.state.count.push(this.state.agegrp4);
      }
      if (this.state.status5.length !== 0) {
        this.state.userid.push(this.state.status5);

        this.state.count.push(this.state.agegrp5);
      }

      this.state.objectid.map((data, index) => {
        this.state.claimid.push(data._id);
        // this.state.userid.push();
        // this.state.count.push();
      });

      // this.state.show5.map((data,index)=>{
      //   this.state.count.push();
      // });

      const value = {
        claimid: this.state.objectid,
        userid: this.state.userid,
        allocatecount: this.state.count,
        logdetails:{
          // newUser:this.state.username,
          admin:this.state.UserAllocate,
          module:"Denial Management Allocation",
          clientId:this.state.client,

          // ClaimID:["11751701/25/2022INS-206"],
          // admin:"demoImagnum",
          // module:"Allocation",
          // clientId":2004
          }
      };

      if (this.state.objectid.length !== 0) {
        const res = await axios
          .put(`${ip}/denial/allocatemultipleuser`, value, {
            headers: headers,
          })
          .then((res) => {
            alert("Claims allocated successfully!");
            //this.setState({ userid: [], allocatecount: [] });
            this.toggle4();
          })

          .catch((err) => {});
      } else {
        alert("There are no claims to allocate!");
        this.toggle4();
      }
    }
  };

  filterdata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .put(
        `${ip}/denial.denialclaims?allocation_bucket=${this.state.bucketId}&userid=${this.state.selecteduser[0]._id}`,
        { headers: headers }
      )

      .then((res) => {
        alert(`claims allocated to userid ${this.state.selecteduser[0]._id}`);
        this.toggle4();
        window.location.reload();
      })
      .catch((err) => {});
  };

  toggle = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  toggle2 = () => {
    const { show2 } = this.state;
    this.setState({ show2: !show2 });
  };

  toggle4 = () => {
    this.setState(() => {
      this.setState({ allocatemodal: !this.state.allocatemodal });
    });
  };

  toggle3 = () => {
    this.setState(() => {
      this.setState({ reallocmodal: !this.state.reallocmodal });
    });
  };

  reset = () => {
    this.setState({
      selecteduser: [],
      selectuserErr: "",
      status1:[],
      status2:[],
      status3:[],
      status4:[],
      status5:[],
      agegrp1:"",
      agegrp2:"",
      agegrp3:"",
      agegrp4:"",
      agegrp5:"",
    });
  };

  componentDidMount() {
    
  }

  render() {
    const { anchorEl } = this.state;

    return (
      <div>
        <BsThreeDotsVertical
          style={{
            color: "#57534b",
            paddingTop: "1%",
            marginTop: "17%",
            fontSize: "1.5rem",
            marginLeft: "91%",
          }}
          onClick={this.handleClick}
        ></BsThreeDotsVertical>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          aria-labelledby="composition-button"
          style={{ marginTop: "2.5%" }}
        >
          <MenuItem
            onClick={() => {
              this.toggle3();
              this.setState({ bucketId: this.props.data });
              this.handleClose();
            }}
          >
            Allocate
          </MenuItem>
        </Menu>
        {this.state.allocatemodal && (
          <Modal
            // style={{ width: "30%" }}
            isOpen={this.state.allocatemodal}
            onHide={this.toggle4}
            onExit={this.reset}
            size="lg"
            centered
          >
            <ModalHeader toggle={this.toggle4}>Create Allocation</ModalHeader>
            <ModalBody>
              <Row style={{ margin: "0px" }}>
                <Col>
                  <Label>Agent/Team Member Name</Label>
                  {/* <MultiSelect
                    options={this.state.users}
                    displayValue="first_name"
                    ref={this.multiselectRef}g
                    onSelect={(event) => {
                      this.setState({ usersErr: "" });
                    }}
                    emptyRecordMsg="No users found"
                    onSearch={true}
                  /> */}
                </Col>
                {/* <div style={{ fontSize: 16, color: "red" }}>
                  {this.state.usersErr}
                </div> */}
              </Row>
              <br />
              <Row>
                <Row style={{ marginTop: "10px", marginLeft: "10px" }}>
                  <Col>
                    {/* <MultiSelect
                      options={this.state.users}
                      displayValue="first_name"
                      ref={this.multiselectRef}
                      onSelect={(event) => {
                        this.setState({ usersErr: "" });
                      }}
                      emptyRecordMsg="No users found"
                    /> */}
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.status1}
                      onChange={(e) => {
                        this.setState({ status1: e.target.value });
                      }}
                      // onClick={() => this.sorting("Claimname")}
                      // style={{ whiteSpace: "nowrap" }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select User
                      </option>
                      {this.state.users.map((data, index) => (
                        <option value={data._id}>
                          {data.first_name + data.last_name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.agegrp1}
                      onChange={(e) => {
                        this.setState({
                          agegrp1: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select the value
                      </option>

                      {this.state.allocate.map((data, index) => (
                        <option value={data}>{data}</option>
                      ))}
                    </Input>
                  </Col>
                  <br />
                </Row>

                <Row style={{ marginTop: "10px", marginLeft: "10px" }}>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.status2}
                      onChange={(e) => {
                        this.setState({ status2: e.target.value });
                      }}
                      // onClick={() => this.sorting("Claimname")}
                      // style={{ whiteSpace: "nowrap" }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select User
                      </option>
                      {this.state.users.map((data, index) => (
                        <option value={data._id}>
                          {data.first_name + data.last_name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.agegrp2}
                      onChange={(e) => {
                        this.setState({
                          agegrp2: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select the Value
                      </option>

                      {this.state.allocate.map((data, index) => (
                        <option value={data}>{data}</option>
                      ))}
                    </Input>
                  </Col>
                </Row>

                <Row style={{ marginTop: "10px", marginLeft: "10px" }}>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.status3}
                      onChange={(e) => {
                        this.setState({ status3: e.target.value });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select User
                      </option>
                      {this.state.users.map((data, index) => (
                        <option value={data._id}>
                          {data.first_name + data.last_name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.agegrp3}
                      onChange={(e) => {
                        this.setState({
                          agegrp3: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select the Value
                      </option>

                      {this.state.allocate.map((data, index) => (
                        <option value={data}>{data}</option>
                      ))}
                    </Input>
                  </Col>
                </Row>

                <Row style={{ marginTop: "10px", marginLeft: "10px" }}>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.status4}
                      onChange={(e) => {
                        this.setState({ status4: e.target.value });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select User
                      </option>
                      {this.state.users.map((data, index) => (
                        <option value={data._id}>
                          {data.first_name + data.last_name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.agegrp4}
                      onChange={(e) => {
                        this.setState({
                          agegrp4: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select the Value
                      </option>

                      {this.state.allocate.map((data, index) => (
                        <option value={data}>{data}</option>
                      ))}
                    </Input>
                  </Col>
                </Row>

                <Row style={{ marginTop: "10px", marginLeft: "10px" }}>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.status5}
                      onChange={(e) => {
                        this.setState({ status5: e.target.value });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select User
                      </option>
                      {this.state.users.map((data, index) => (
                        <option value={data._id}>
                          {data.first_name + data.last_name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.agegrp5}
                      onChange={(e) => {
                        this.setState({
                          agegrp5: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select the Value
                      </option>

                      {this.state.allocate.map((data, index) => (
                        <option value={data}>{data}</option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </Row>

              <br />
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.toggle4();
                }}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                style={{ backgroundColor: "black", color: "white" }}
                onClick={() => {
                  this.allocateusers();
                  this.reset();
                }}
              >
                Allocate
              </Button>
            </ModalFooter>
          </Modal>
        )}

        {this.state.reallocmodal && (
          <Modal
            style={{ width: "30%" }}
            isOpen={this.state.reallocmodal}
            onHide={this.toggle3}
            onExit={this.reset}
            size="md"
            centered
          >
            <ModalHeader toggle={this.toggle3}>Allocate</ModalHeader>
            <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <Row style={{ margin: "0px" }}>
                Do you want to Allocate this claim ?
              </Row>

              <br />
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.toggle3();
                }}
              >
                NO
              </Button>
              &nbsp;&nbsp;
              <Button
                onClick={() => {
                  this.toggle3();
                  this.toggle4();
                  this.getusers();
                  this.getbucketclaims();
                }}
                style={{ backgroundColor: "black", color: "white" }}
              >
                YES
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}
export default DenialAllocation;
