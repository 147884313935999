import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { curveCardinal } from "d3-shape";
import { ip } from "../../../../Api";
import axios from "axios";

const data2 = [
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
];
const cardinal = curveCardinal.tension(0.2);
let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};

const CPTCharts = ({ client, Location, from, to, cptwise }) => {
  const [cptWiseData, setCptWiseData] = useState([]);
  const [accesstoken, setAccessToken] = useState(sessionStorage.getItem("accesstoken"));
  const refreshtoken = sessionStorage.getItem("refreshtoken");

  const renewAccessToken = async () => {
    const renewHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshtoken}`,
    };
    try {
      const response = await axios.get(`${ip}/auth/renewAccessToken`, {
        headers: renewHeaders,
      });
      sessionStorage.setItem("accesstoken", response.data.accessToken);
      setAccessToken(response.data.accessToken);
    } catch (error) {
      console.error("Failed to renew access token", error);
    }
  };

  const getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };

    let url = `${ip}/outcome/cptwise`;
    if (client) {
      if (Location) {
        url = from && to
          ? `${url}?start_date=${from}&end_date=${to}&clientId=${client}&Location=${Location}`
          : `${url}?clientId=${client}&Location=${Location}`;
      } else {
        url = from && to
          ? `${url}?start_date=${from}&end_date=${to}&clientId=${client}`
          : `${url}?clientId=${client}`;
      }
    } else if (from && to) {
      url = `${url}?start_date=${from}&end_date=${to}`;
    }

    try {
      const response = await axios.get(url, { headers });
      const data = response.data.cpt_wise.denied.length
        ? response.data.cpt_wise.denied.slice(0, 5)
        : data2;
      setCptWiseData(data);
    } catch (error) {
      console.error("Failed to fetch details", error);
      setCptWiseData(data2);
    }
  };

  useEffect(() => {
    getDetails();
  }, [client, Location, from, to, accesstoken]);

  return (
    <div>
      {cptwise === "$" && (
        <div onMouseOver={() => (tooltip = "totalAmount")}>
          <AreaChart
            width={500}
            height={400}
            data={cptWiseData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <XAxis
              dataKey="_id"
              height={120}
              interval={0}
              angle={-45}
              minTickGap={6}
              tick={{ strokeWidth: 0, fontSize: "11", top: 400, textAnchor: "end", dy: 6 }}
            />
            <YAxis dataKey="totalAmount" />
            <Tooltip content={<CustomTooltip />} />
            <Area
              name="Total Amount"
              type="monotone"
              dataKey="totalAmount"
              stroke="#61A4BC"
              fill="#61A4BC"
            />
          </AreaChart>
        </div>
      )}
      {cptwise === "#" && (
        <div onMouseOver={() => (tooltip = "totalClaims")}>
          <AreaChart
            width={500}
            height={400}
            data={cptWiseData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <XAxis
              dataKey="_id"
              height={120}
              interval={0}
              angle={-45}
              minTickGap={6}
              tick={{ strokeWidth: 0, fontSize: "11", top: 400, textAnchor: "end", dy: 6 }}
            />
            <YAxis dataKey="totalClaims" />
            <Tooltip content={<ClaimTooltip />} />
            <Area
              name="Total Claims"
              type="monotone"
              dataKey="totalClaims"
              stroke="#61A4BC"
              fill="#61A4BC"
            />
          </AreaChart>
        </div>
      )}
    </div>
  );
};

export default CPTCharts;
