import React, { Component } from "react";
import axios from "axios";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import "../../Styles/loader.scss";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { styled } from "@mui/material/styles";
import Checkbox from "../Allocation/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Col } from "reactstrap";
import { Button,Label } from "reactstrap";
import { Row } from "react-bootstrap";
import Select from "react-dropdown-select";
import { ip } from "../../Api.js";
const options = [
  { value: "2", label: "PatientFirstName" },
  { value: "3", label: "PatientLastName" },
  { value: "4", label: "Physician" },
  { value: "5", label: "Age" },
  { value: "6", label: "PrimaryPolicyNo" },
  { value: "7", label: "PatientDOB" },
  { value: "8", label: "PlanCode" },
  { value: "9", label: "patientAccountNumber" },
  { value: "10", label: "DateOfService" },
  { value: "11", label: "InsuranceName" },
];

class Actionallocated3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalunallocate: false,
      doioptionSelected: [],
      UserAllocate:
      sessionStorage.getItem("firstname") +
      " " +
      sessionStorage.getItem("lastname"),
      filterpage: [],
      modalunallocate1: false,
      usernamekey: sessionStorage.getItem("usernamekey"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      username: sessionStorage.getItem("username"),
      filteredclaims: sessionStorage.getItem("filteredclaims"),
      completedclaims: sessionStorage.getItem("completedclaims"),
      nottouch: sessionStorage.getItem("nottouch"),
      filter: [],
      claimsdata: [],
      claimdetails1: [],
      reports: [],
      fetching: true,
      ar: [],
      loading: true,
      any: [],
      PatientFirstName: "",
      PatientLastName: "",
      webclaim: [],
      patientAccountNumber: "",
      searchtext: "",
      searchclaimdata: [],
      payercategories: [],
      cptdata: [],
      totalClaims: [],
      status: [],
      optionSelected: [],
      usernameact: sessionStorage.getItem("usernameact"),
      claimsact: sessionStorage.getItem("claimsact"),
      checkfiltertable: sessionStorage.getItem("checkfiltertable"),
      checkcompletetable: sessionStorage.getItem("checkcompletetable"),
      nottouchtable: sessionStorage.getItem("nottouchtable"),
      username1: "",
      subcategoryname: sessionStorage.getItem("querysub"),
      claimfield: "",
      user: "",
      claimvalue: "",
      claimtype: "",
      value: sessionStorage.getItem("claimurl"),

      payervalue: [],
      currentpage1: 0,
      perPage1: 10,
      offset1: 0,
      currentpage2: 0,
      keypageno: sessionStorage.getItem("keypageno"),
      perPage2: 10,
      currentpage3: 0,
      perPage3: 10,
      cptvalue: [],
      userdetails: [],
      selectuserErr: "",
      query: [],
      ids: [],
      offset: 0,
      currentpage: 0,
      currentpagesearch: 0,
      categoryname: sessionStorage.getItem("querycategory"),
      perPage: 10,
      checkedAll: false,
      alldataresponse: [],
      offset3: 0,
      claimtype: sessionStorage.getItem("claimtype"),
      searchdataresponse: [],
      selecteduser: [],
      users: [],
      user_id: sessionStorage.getItem("user_id"),
      role: sessionStorage.getItem("role_type"),
      clientid: sessionStorage.getItem("clientid"),
      Bucketnameforaction: sessionStorage.getItem("Bucketnameforaction"),
      clientname: sessionStorage.getItem("clientname"),
      clientslist: [],
      opendropdown: false,
      nottouch: [],
      complete: [],
      currentpageaction: 0,
      Location: "",
      locationslist: [],
      count: 10,
      Countfilter: [],
      countcomplete: [],
      counttouch: [],
      agegrplist: [
        "0-30days",
        "31-60days",
        "61-90days",
        "91-120days",
        "121-180days",
        "181-365days",
        "above 1year",
      ],

      doiagegrplist: [
        "0-30days",
        "31-60days",
        "61-90days",
        "91-120days",
        "121-180days",
        "181-365days",
        "above 1year",
      ],
      userstatuslist: ["Completed", "TO-DO", "Not Touch"],
      userstatus: "",
      agegrp: "",
      doiagegrp: "",
      unallocateid: "",
      sortstate: "ASC",
      openicon: false,
      claimids: [],
      sort: "",
      fieldsort: "",
      sorttable: "false",
      sortdata: [],
      perPagesort: 10,
      offsetsort: 0,
      currentpagesort: 0,
      pageCountsort: "",
    };
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  toggle1 = () => {
    const { modalunallocate } = this.state;
    this.setState({ modalunallocate: !modalunallocate });
  };
  toggle2 = () => {
    const { modalunallocate1 } = this.state;
    this.setState({ modalunallocate1: !modalunallocate1 });
  };
  handleChange = (selected) => {
    this.setState({
      optionSelected: selected,
    });
  };
  doihandleChange = (selected) => {
    this.setState({
      doioptionSelected: selected,
    });
  };
  checkboxsearchall = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.searchdataresponse.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {
          this.toggle2();
        }
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };

  handlePageClick3 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage1;

    this.setState(
      {
        currentpage1: selectedPage,
        offset1: offset,
      },
      () => {
        this.getbucketclaims();
      }
    );
  };

  allocateclaims = () => {
    const isValid = this.validate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        ids: this.state.ids,
        logdetails:{
          // newUser:this.state.username,
          admin:this.state.UserAllocate,
          module:"Action Allocation",
          clientId:this.state.client,

          // ClaimID:["11751701/25/2022INS-206"],
          // admin:"demoImagnum",
          // module:"Allocation",
          // clientId":2004
          }
      };
       axios
        .put(
          `${ip}/allocation/allocate/${this.state.selecteduser[0].value}`,
          value,
          {
            headers: headers,
          }
        )
        .then((res) => {
          alert(`claims allocated to user ${this.state.selecteduser[0].label}`);
          this.getbucketclaims();
          this.resetuser();
          this.toggle();
          this.toggle2();
          // window.location.reload();
        })

        .catch((err) => {});
    }
  };
  bulkunallocatedclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      ids: this.state.ids,
      logdetails:{
        // newUser:this.state.username,
        admin:this.state.UserAllocate,
      }
    };
     axios
      .put(`${ip}/claim/updatemultipleclaimunder`, value, {
        headers: headers,
      })
      .then((res) => {
        alert(`claim unallocated successfully`);
        this.getbucketclaims();

        this.resetuser();
        this.toggle2();
        // window.location.reload();
      })

      .catch((err) => {});
  };
  unallocateclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      id: this.state.unallocateid,
      logdetails:{
        // newUser:this.state.username,
        admin:this.state.UserAllocate,
      }
    };
     axios
      .put(`${ip}/claim/updateclaimunder`, value, {
        headers: headers,
      })
      .then((res) => {
        alert(`claim unallocated successfully`);
        this.getbucketclaims();

        this.toggle1();

        // window.location.reload();
      })

      .catch((err) => {});
  };
  toggledropdown = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };

  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        if (res.data.clientonboard.length !== 0) {
          this.setState({
            clientslist: res.data.clientonboard,
            // clientname: res.data.clientonboard[0].client,
          });
        } else {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        }
      })
      .catch((err) => {});
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getbucketclaims = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(
        `${ip}/actioncode/getactioncodeclaims?actioncode=${this.state.Bucketnameforaction}&clientid=${this.state.clientid}&allocatedpageno=${this.state.keypageno}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        res.data.claimdetails.map((values) => {
          return (
            values.username === this.state.usernamekey &&
            this.setState({
              totalClaims: values.nottouchedClaims,
              nottouch: values.nottouchedClaims,
              complete: values.completedClaims,
              filter: values.filteredClaimscount,
              fetching: false,
              alldataresponse: values.filteredClaims,
            })
          );
        });
        const slice = this.state.totalClaims.slice(
          this.state.offset1,
          this.state.offset1 + this.state.perPage1
        );
        this.setState({
          pageCount: Math.ceil(
            this.state.totalClaims.length / this.state.perPage1
          ),
          filterpage: slice,
        });
      })

      .catch((err) => {});
  };

  sorting = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.totalClaims].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ totalClaims: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.totalClaims].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ totalClaims: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  //getusers

  //getusers
  getallocatedclaims = async () => {
    //this.setState({ fetching: true });
    //api call to get all users

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    if (
      this.state.role === "Admin" ||
      this.state.role === "SuperAdmin" ||
      this.state.role === "Team Manager"
    ) {
      let url;
      if (this.state.clientid) {
        if (this.state.Location) {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.clientid
          }&pageno=${this.state.currentpage + 1}&count=${
            this.state.perPage
          }&Location=${this.state.Location}`;
        } else {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.clientid
          }&pageno=${this.state.currentpage + 1}&count=${this.state.perPage}`;
        }
      } else {
        url = `${ip}/allocation/allocated/all?pageno=${
          this.state.currentpage + 1
        }&count=${this.state.perPage}`;
      }
       axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          this.setState({
            claimsdata:res.data.claims,
          });
          this.resetuser();
        })
        .catch((err) => {});
    } 
  };

  getclaims = async () => {
    //this.setState({ fetching: true });
    //api call to get all users
    this.setState({ loading: true });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    if (
      this.state.role === "Admin" ||
      this.state.role === "SuperAdmin" ||
      this.state.role === "Team Manager"
    ) {
      let url;
      if (this.state.clientid) {
        if (this.state.Location) {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.clientid
          }&pageno=${this.state.currentpage + 1}&count=${
            this.state.perPage
          }&Location=${this.state.Location}`;
        } else {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.clientid
          }&pageno=${this.state.currentpage + 1}&count=${this.state.perPage}`;
        }
      } else {
        url = `${ip}/allocation/allocated/all?pageno=${
          this.state.currentpage + 1
        }&count=${this.state.perPage}`;
      }
       axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          this.setState({
            claimsdata: res.data.claims,
            loading: false,
          });

          res.data.claims.map((data, index) => {
            this.state.any[index] = {
              ClaimID: data.ClaimID,
              DateOfService: data.DateOfService,
              TlfAge: data.TlfAge,
              DOIAge: data.DOIAge,
              DOIAgeGrp: data.DOIAgeGrp,
              type: data.type,
            };

            this.resetuser();
          });
        })
        .catch((err) => {});
    } 
  };

  getusers = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(`${ip}/users/all?clientId=${this.state.clientid}`, { headers: headers })
      .then((res) => {
        let data = res.data.user;
        if(this.state.clientid){
          data = data.filter((item) => {
            return (
              item.role === "Team Member"
            );
          });
        }
        else{
          data = data.filter((item) => {
            return (
              item.role === "Admin" || item.role==="SuperAdmin"
            );
          });
        }
        

        this.setState({ users: data });
      })
      .catch((err) => {});
  };
  resetuser = () => {
    this.setState({
      ids: [],
    });
  };
  reset = () => {
    this.setState(
      {
        claimfield: "",
        claimids: [],
        claimvalue: "",
        username: "",
        userstatus: [],
        user: "",
        ar: [],
        claimtype: "",
        status: [],
        agegrp: "",
        payervalue: [],
        cptvalue: [],
        searchdataresponse: [],
        searchclaimdata: [],
        searchtext: "",
        currentpage: 0,
        currentpagesearch: 0,
        checkedAll: false,
        perPage: 10,
        doiagegrp: "",
        optionSelected: [],
        doioptionSelected: [],
      },
      () => this.getbucketclaims()
    );
  };

  componentDidMount() {
    this.getallocatedclaims();
    this.getclaims();
    this.getbucketclaims();
    this.getclients();
    this.getusers();
  }

  addsinglecheckboxid = (data, check) => {
    if (check) {
      this.setState({ ids: [...this.state.ids, data] }, () => this.toggle2());
    } else {
      let arr = this.state.ids.slice();
      let val = arr.filter((id) => {
        return id !== data;
      });
      this.setState({ ids: val }, () => {
        this.toggle2();
      });
    }
  };

  checkBoxAll = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.filterpage.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {
          this.toggle2();
        }
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  validate = () => {
    let hasErr = "true";
    let selectuserErr = "";
    if (this.state.selecteduser.length === 0) {
      selectuserErr = "This field is required";
      this.setState({ selectuserErr });
      hasErr = false;
    }
    return hasErr;
  };
  //sorting func
getsorting = (col) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${this.state.accesstoken}`,
  };
  let url;
  if (this.state.clientid) {
    if (this.state.Location) {
      url = `${ip}/actioncode/getactioncodeclaims/sort?clientId=${this.state.clientid}&username=${this.state.usernamekey}&OverallClaimStatus=NotTouched&actioncode=${this.state.Bucketnameforaction}&allocatedpageno=${this.state.currentpagesort}&sorting=${this.state.sort}&field=${this.state.fieldsort}&count=${this.state.perPagesort}&Location=${this.state.Location}`;
    } else {
      url = `${ip}/actioncode/getactioncodeclaims/sort?clientId=${this.state.clientid}&username=${this.state.usernamekey}&OverallClaimStatus=NotTouched&actioncode=${this.state.Bucketnameforaction}&allocatedpageno=${this.state.currentpagesort}&sorting=${this.state.sort}&field=${this.state.fieldsort}&count=${this.state.perPagesort}`;
    }
  } else {
    url = `${ip}/actioncode/getactioncodeclaims/sort?actioncode=${this.state.Bucketnameforaction}&username=${this.state.usernamekey}&OverallClaimStatus=NotTouched&allocatedpageno=${this.state.currentpagesort}&sorting=${this.state.sort}&field=${this.state.fieldsort}&count=${this.state.perPagesort}`;
  }
   axios
    .get(url, { headers: headers })
    .then((res) => {

      this.setState({
        sortdata: res.data.claims,
        pageCountsort: Math.ceil(res.data.claimcount / this.state.perPagesort),
      });
    })
    .catch((err) => {});
};
handlePagesort = (e) => {
  const selectedPage = e.selected;
  const offset = selectedPage * this.state.perPagesort;
  this.setState(
    {
      currentpagesort: selectedPage,
      offsetsort: offset,
    },
    () => {
      this.getsorting();
    }
  );
};
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    const checkboxstyle = {
      marginTop: "15px",
    };
    return (
      <div>
      <Newsidebar name="Action Allocated Claims" />
      <div className="align-row">
      <div className="align-column">
        <br />

        <div
          style={{ textAlign: "left", marginLeft: "1%", fontSize: "16px" }}
        >
          <p>
            <Link
              to="/action-bucket"
              style={{
                textDecoration: "none",
                color: "black",
                marginLeft: "2%",
              }}
            >
              Action Bucket
            </Link>
            {/* &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
             
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              {this.state.subcategoryname} */}
          </p>
        </div>
        <br />
        <br />

        <div style={{ display: "flex", flexDirection: "row" }}>
          {this.state.role === "SuperAdmin" ? (
            <ButtonDropdown
              isOpen={this.state.opendropdown}
              toggle={this.toggledropdown}
              style={{ width: "12%", marginLeft: "4%", position: "inherit" }}
            >
              <DropdownToggle caret>
                {this.state.clientname ? this.state.clientname : "All"}
              </DropdownToggle>
              <DropdownMenu>
                {this.state.clientslist.map((e) => {
                  return (
                    <DropdownItem
                      id={e.clientId}
                      key={e.clientId}
                      onClick={(e) =>
                        this.setState(
                          {
                            client: e.target.id,
                            clientname: e.target.innerText,
                          },
                          () => this.getbucketclaims()
                        )
                      }
                    >
                      {e.client}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </ButtonDropdown>
          ) : null}
        </div>

        {this.state.nottouchtable && (
          <div style={{ height: "100%", width: "100%" }}>
            {this.state.fetching ? (
              // <img src={loading} />
              <div>
                <p
                  style={{
                    fontSize: "23px",
                    marginTop: "10px",
                    color: "#000",
                  }}
                >
                  <span>Loading...</span>
                  <span>Please Wait...</span>
                </p>
                <div className="boxes body__loader dribbble">
                  <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            ) : this.state.filterpage.length === 0 ? (
              <div className="bot-ui">
                <div className="bot-head">
                  <div className="eyes-container">
                    <div className="to-left eye"></div>
                    <div className="to-right eye"></div>
                  </div>
                </div>
                <div className="bot-body">
                  <div className="analyzer"></div>
                </div>
                <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                  No Data Found
                </p>
              </div>
            ) : (
              <div>
                <div>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 700 }}
                      aria-label="customized table"
                      style={{ padding: "3%" }}
                    >
                      <TableHead>
                        <TableRow>
                          {this.state.role === "Admin" && (
                            <StyledTableCell>
                              <input
                                type="checkbox"
                                checked={this.state.checkedAll}
                                onChange={this.checkBoxAll}
                              />
                            </StyledTableCell>
                          )}
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({       
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "ClaimID",
                                  sorttable: true, 
                                }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({       
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "ClaimID",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim ID{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({       
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "ClaimID",
                                  sorttable: true, 
                                }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({       
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "ClaimID",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({   
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "Location",
                                  sorttable: true, }, ()=> this.getsorting())
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({             
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "Location",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Location{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({   
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "Location",
                                  sorttable: true, }, ()=> this.getsorting())
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({             
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "Location",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({           
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "patientAccountNumber",
                                    sorttable: true, })
                                }
                                />
                              ) : (
                                <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({     
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "patientAccountNumber",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                                />
                              )}
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Account No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({           
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "patientAccountNumber",
                                  sorttable: true, })
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({     
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "patientAccountNumber",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>  {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({      
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "DOS",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({            
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "DOS",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOS{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({      
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "DOS",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({            
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "DOS",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({       
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "PatientFirstName",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({        
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "PatientFirstName",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({       
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "PatientFirstName",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({        
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "PatientFirstName",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({          
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "PatientDOB",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                this.setState({          
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "PatientDOB",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient DOB{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({          
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "PatientDOB",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                this.setState({          
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "PatientDOB",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({       
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "PlanCode",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({          
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "PlanCode",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Plan Code{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({       
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "PlanCode",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({          
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "PlanCode",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({    
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "InsuranceName",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({         
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "InsuranceName",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Insurance Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({    
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "InsuranceName",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({         
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "InsuranceName",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({   
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "PrimaryPolicyNo",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({           
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "PrimaryPolicyNo",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Policy No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({   
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "PrimaryPolicyNo",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({           
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "PrimaryPolicyNo",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({                
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "Physician",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({           
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "Physician",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Physician Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({                
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "Physician",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({           
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "Physician",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({           
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "Age",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({       
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "Age",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({           
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "Age",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({       
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "Age",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({        
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "AgeGrp",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({          
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "AgeGrp",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age Grp{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({        
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "AgeGrp",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({          
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "AgeGrp",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({       
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "DOIAge",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({         
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "DOIAge",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOI Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({       
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "DOIAge",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({         
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "DOIAge",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ 
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "DOIAgeGrp",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({          
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "DOIAgeGrp",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOI AgeGrp
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ 
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "DOIAgeGrp",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({          
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "DOIAgeGrp",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({              
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "type",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({            
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "type",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Type{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({              
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "type",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({            
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "type",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ 
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "Claimunder",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({            
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "Claimunder",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Allocated To{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ 
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "Claimunder",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({            
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "Claimunder",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({   
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "OverallClaimStatus",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({          
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "OverallClaimStatus",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            User Status{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({   
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "OverallClaimStatus",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({          
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "OverallClaimStatus",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({            
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "ClaimStatus",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({         
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "ClaimStatus",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim Status{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                              onClick={() =>
                                this.setState({            
                                  openicon: false,
                                  sort: "ascending",
                                  fieldsort: "ClaimStatus",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            ) : (
                              <BiDownArrowAlt
                              onClick={() =>
                                this.setState({         
                                  openicon: true,
                                  sort: "descending",
                                  fieldsort: "ClaimStatus",
                                  sorttable: true, }, ()=> {this.getsorting()})
                              }
                              />
                            )}
                          </StyledTableCell>
                          {this.state.role === "Admin" && (
                            <StyledTableCell>Unallocate</StyledTableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      {this.state.sorttable === true ? (
                        <TableBody>
                        {this.state.sortdata &&
                          this.state.sortdata.map((data, index) => (
                            <TableRow key={data._id}>
                            {this.state.role === "Admin" &&
                              (this.state.checkedAll === true ? (
                                <div style={checkboxstyle}>
                                  <input
                                    type="checkbox"
                                    checked={this.state.checkedAll}
                                  />
                                </div>
                              ) : (
                                <div style={checkboxstyle}>
                                  <Checkbox
                                    handleidpush={this.addsinglecheckboxid}
                                    number={data._id}
                                    key={index}
                                  />
                                </div>
                              ))}
                            <StyledTableCell>
                              {data.ClaimID}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data.Location}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data.patientAccountNumber
                                ? data.patientAccountNumber
                                : "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data.DateOfService
                                ? data.DateOfService
                                : "-"}
                            </StyledTableCell>

                            <StyledTableCell>
                              {data.PatientFirstName}&nbsp;
                              {data.PatientLastName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data.PatientDOB ? data.PatientDOB : "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data.PlanCode ? data.PlanCode : "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data.InsuranceName
                                ? data.InsuranceName
                                : "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data.PrimaryPolicyNo}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data.Physician}
                            </StyledTableCell>

                            <StyledTableCell>{data.Age}</StyledTableCell>
                            <StyledTableCell>{data.AgeGrp}</StyledTableCell>
                            <StyledTableCell>{data.DOIAge}</StyledTableCell>
                            <StyledTableCell>
                              {data.DOIAgeGrp}
                            </StyledTableCell>
                            <StyledTableCell>{data.type}</StyledTableCell>
                            <StyledTableCell>
                              {data.Claimunder}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data.OverallClaimStatus}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data.ClaimStatus}
                            </StyledTableCell>
                            {this.state.role === "Admin" && (
                              <StyledTableCell>
                                <Button
                                  onClick={() =>
                                    this.setState(
                                      { unallocateid: data._id },
                                      () => {
                                        this.toggle1();
                                      }
                                    )
                                  }
                                >
                                  Unallocate
                                </Button>
                              </StyledTableCell>
                            )}
                          </TableRow>
                          ))}
                      </TableBody>
                      ) : (
                      <TableBody>
                        {this.state.filterpage &&
                          this.state.filterpage.map((data, index) => (
                            <TableRow key={data._id}>
                              {this.state.role === "Admin" &&
                                (this.state.checkedAll === true ? (
                                  <div style={checkboxstyle}>
                                    <input
                                      type="checkbox"
                                      checked={this.state.checkedAll}
                                    />
                                  </div>
                                ) : (
                                  <div style={checkboxstyle}>
                                    <Checkbox
                                      handleidpush={this.addsinglecheckboxid}
                                      number={data._id}
                                      key={index}
                                    />
                                  </div>
                                ))}
                              <StyledTableCell>
                                {data.ClaimID}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.Location}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.patientAccountNumber
                                  ? data.patientAccountNumber
                                  : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.DateOfService
                                  ? data.DateOfService
                                  : "-"}
                              </StyledTableCell>

                              <StyledTableCell>
                                {data.PatientFirstName}&nbsp;
                                {data.PatientLastName}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PatientDOB ? data.PatientDOB : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PlanCode ? data.PlanCode : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.InsuranceName
                                  ? data.InsuranceName
                                  : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PrimaryPolicyNo}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.Physician}
                              </StyledTableCell>

                              <StyledTableCell>{data.Age}</StyledTableCell>
                              <StyledTableCell>{data.AgeGrp}</StyledTableCell>
                              <StyledTableCell>{data.DOIAge}</StyledTableCell>
                              <StyledTableCell>
                                {data.DOIAgeGrp}
                              </StyledTableCell>
                              <StyledTableCell>{data.type}</StyledTableCell>
                              <StyledTableCell>
                                {data.Claimunder}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.OverallClaimStatus}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.ClaimStatus}
                              </StyledTableCell>
                              {this.state.role === "Admin" && (
                                <StyledTableCell>
                                  <Button
                                    onClick={() =>
                                      this.setState(
                                        { unallocateid: data._id },
                                        () => {
                                          this.toggle1();
                                        }
                                      )
                                    }
                                  >
                                    Unallocate
                                  </Button>
                                </StyledTableCell>
                              )}
                            </TableRow>
                          ))}
                      </TableBody>)}
                    </Table>
                  </TableContainer>
                  {this.state.sorttable === true ? (
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCountsort}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePagesort}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpagesort}
                      />
                    </div>
                  ) : (
                  <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                    <ReactPaginate
                      forcePage={0}
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick3}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>)}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {this.state.ids.length !== 0 && this.state.modal && (
        <Modal
          style={{ width: "30%" }}
          isOpen={this.state.modal}
          onHide={this.toggle}
          size="md"
          centered
        >
          <ModalHeader toggle={this.toggle}>Reallocate</ModalHeader>
          <ModalBody>
            <Row style={{ margin: "0px" }}>
              <Col>
                <Label>Agent/Team Member Name</Label>
                <Select
                  options={this.state.users.map((data) => ({
                    value: data._id,
                    label: data.first_name + " " + data.last_name,
                  }))}
                  values={this.state.selecteduser}
                  searchable={true}
                  dropdownHandle={true}
                  searchBy="first_name"
                  noDataLabel="No matches found"
                  onChange={(values) =>
                    this.setState({
                      selecteduser: values,
                      selectuserErr: "",
                    })
                  }
                  required
                />
              </Col>
              <div style={{ fontSize: 16, color: "red" }}>
                {this.state.selectuserErr}
              </div>
            </Row>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.toggle();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.allocateclaims();
                this.resetuser();
              }}
              style={{ backgroundColor: "grey", color: "#fff" }}
            >
              Allocate
            </Button>
          </ModalFooter>
        </Modal>
      )}

      <Modal
        style={{ width: "30%" }}
        isOpen={this.state.modalunallocate}
        onHide={this.toggle1}
        size="md"
        centered
      >
        <ModalHeader toggle={this.toggle1}>Unallocate</ModalHeader>
        <ModalBody>Do you want to unallocate this claim..?</ModalBody>
        <ModalFooter>
          <Button
            style={{
              border: "1px solid grey",
              color: "black",
              backgroundColor: "#fff",
            }}
            onClick={() => {
              this.toggle1();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.unallocateclaims();
            }}
            style={{ backgroundColor: "grey", color: "#fff" }}
          >
            Unallocate
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        style={{ width: "30%" }}
        isOpen={this.state.modalunallocate1}
        onHide={this.toggle2}
        size="md"
        centered
      >
        <ModalHeader toggle={this.toggle2}>Reallocate/Unallocate</ModalHeader>
        <ModalBody>
          <span
            style={{
              fontSize: "20px",
              color: "#041c3c",
              textAlign: "center",
            }}
          >
            Do you want to..?
          </span>
          <br />
          <div className="multi-button">
            <button
              className="reunalloc"
              onClick={() => {
                this.toggle();
              }}
            >
              Reallocate
            </button>
            <button
              className="reunalloc"
              onClick={() => {
                this.bulkunallocatedclaims();
                this.resetuser();
              }}
            >
              Unallocate
            </button>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{
              border: "1px solid grey",
              color: "black",
              backgroundColor: "#fff",
            }}
            onClick={() => {
              this.toggle2();
            }}
          >
            Cancel
          </Button>
          {/* <Button
            onClick={() => {
              this.unallocateclaims();
            }}
            style={{ backgroundColor: "grey", color: "#fff" }}
          >
            Unallocate
          </Button> */}
        </ModalFooter>
      </Modal>
    </div>
      </div>
 
    );
  }
}

export default Actionallocated3;
