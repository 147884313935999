import React, { Component } from "react";
import axios from "axios";
import { ip } from "./Api";
import { Button, FormGroup, Form, Input,  } from "reactstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import "./Styles/changepassword.css";
import {Link} from "react-router-dom";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Row , Col} from "react-bootstrap";
export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      username: "",
      new_password: "",
      confirm_password: "",
      passwordsMatch:false,
      newpassword: false,
      confirmpassword: false,
      successmodal:false,
    };
  }
  toggleNewPassword = () => {
    const { newpassword } = this.state;
    this.setState({ newpassword: ! newpassword });
  };
  toggleConfirmPassword = () => {
    const { confirmpassword } = this.state;
    this.setState({ confirmpassword: ! confirmpassword });
  };
  successbox = () => {
    this.setState({
   successmodal : !this.state.successmodal
    })
  }
  passValidation = () => {
    if(this.state.new_password !== this.state.confirm_password){
     this.setState({ passwordsMatch: true})
     return false
    }
    else{
     this.setState({ passwordsMatch: false})
    return true
    }
   }
   handleReset= () => {
    this.setState({
      passwordsMatch:false,
      new_password:"",
      confirm_password:""
    })
   }
  handleSubmit = async (data) => {    
    const isValid = this.passValidation();
    if(isValid){
    let value;
    value = {
      newpassword: this.state.new_password,
      confirmpassword: this.state.confirm_password,
      username: this.state.username,
    };
    // eslint-disable-next-line
    const res = await axios
      .post(`${ip}/auth/changepassword`, value)
      .then((res) => {
        this.successbox();
      })
        .catch((err) => {});
    }
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
  }
  render() {
    return (
      <div className="container_change">
 
            <div className="login">
      
              <Form className="login-form-change">
                <br />

                <h5 style={{ fontWeight: "bold" }}>
                  <h4 align="center" style={{ fontWeight: "bold" }}>
                    Change Password
                  </h4>
                  <br />
                  { this.state.passwordsMatch === true ?
                <p style={{color:"red",fontSize:"15px", textAlign:"center"}}>Passwords does not match.</p>
                : null}
                </h5>
                <br />
                <div>
                  <br />
                  <div
                    style={{
                      marginLeft: "54%",
                      fontSize: "20px",
                      fontStyle: "italic",
                      marginTop: "-15%",
                    }}
                  ></div>
                </div>
                <FormGroup className="input-group-lg">
                  <label className="label1">User Name</label>
                  <Input
                    className="input-text"
                    type="text"
                    placeholder="Enter your user name"
                    value={this.state.username}
                    onChange={(e) =>
                      this.setState({ username: e.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup className="input-group-lg">
                  <label className="label2">New Password</label>
                  <Input
                    className="input-text"
                    type={this.state.newpassword ? "text" : "password"}
                    placeholder="Enter your password"
                    value={this.state.new_password}
                    onChange={(e) =>
                      this.setState({ new_password: e.target.value })
                    }
                  />
                  {this.state.newpassword ? (
                    <AiFillEyeInvisible
                      className="password-icon"
                      onClick={this.toggleNewPassword}
                    />
                  ) : (
                    <AiFillEye
                      className="password-icon"
                      onClick={this.toggleNewPassword}
                    />
                  )}
                </FormGroup>
                <FormGroup className="input-group-lg">
                  <label className="label3-change">Confirm Password</label>
                  <Input
                    className="input-text"
                    type={this.state.confirmpassword ? "text" : "password"}
                    placeholder="Enter your password"
                    value={this.state.confirm_password}
                    onChange={(e) =>
                      this.setState({ confirm_password: e.target.value })
                    }
                  />
                  {this.state.confirmpassword ? (
                    <AiFillEyeInvisible
                      className="password-icon"
                      onClick={this.toggleConfirmPassword}
                    />
                  ) : (
                    <AiFillEye
                      className="password-icon"
                      onClick={this.toggleConfirmPassword}
                    />
                  )}
                </FormGroup>
                <br />
                <Row>
                <Col >
                <Button
                  className="btn-login"
                  color="primary"
                  onClick={() => this.handleSubmit()}
                >
                  Confirm
                </Button>
                </Col>
                <Col>
                <Button
                  // className="btn-login"
                  color="primary"
                  onClick={() => this.handleReset()}
                >
                  Reset
                </Button>
                </Col>
                </Row>
                <br />
                <br />
              </Form>
    
          <Modal
              isOpen={this.state.successmodal}
              onHide={this.successbox}
              centered
            >
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Set your question and answers</Row>
                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid #268da5",
                    color: "#268da5",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.successbox}
                >
                  Cancel
                </Button>
                <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/setAnswers",
                              state: {
                               username: this.state.username
                              },
                            }}
                          >
                <Button
                  style={{ backgroundColor: "#268da5" }}
                >
                  Go
                </Button>
                </Link>
              </ModalFooter>
            </Modal>
        </div>
      </div>
    );
  }
}
